import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { LICENSE_DETAIL_ROUTE, MYPAGE_ROUTE, USER_LICENSE_REFERENCE_ROUTE } from "../consts/routes";
import { CrossCircleIcon } from "../components/Icons";
import { useUserContext } from "../contexts/UserContext";
import { convertUtcToLocalDate, formatUtcToLocalDateTime } from "../utils/DateUtils";
import { getUser } from "../clients/UserClient";
import { getLicenses } from "../clients/LicenseClient";
import i18n from "../i18n/configs";
import { User } from "../types/User";
import { License } from "../types/License";
import { ScrollToTop } from "../components/ScrollToTop";
import { ACTIVATION_STATUS_OFFLINE } from "../consts/constants";
import { Helmet } from "react-helmet";

/**
 * ユーザーライセンス管理画面（参照専用）
 *
 * ユーザータイプ=一般ユーザー向けの確認専用ページ。
 * 自身のユーザー情報および自身に紐づくライセンス情報のみを確認する。
 * @returns JSX.Element
 */
const UserLicenseReference = () => {
    const { t } = useTranslation();
    const { user, dbUserCashe, setDbUserCashe, getRecentToken } = useUserContext();
    const [userList, setUserList] = useState<User[]>([]);
    const [licenseList, setLicenseList] = useState<License[]>([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * ユーザーおよびライセンス情報一覧の取得処理
     * @returns
     */
    async function fetchUsersLicenses() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]ユーザー情報（自身）の取得
            let users: User[] = [dbUserCashe];
            if (!dbUserCashe) {
                const userResponse = await getUser(idToken, user.attributes.sub);
                setDbUserCashe(userResponse);
                users = [userResponse];
            }
            setUserList(users);

            // [API]ライセンス情報一覧の取得
            const licenseListResponse: License[] = await getLicenses(idToken, {
                user_id: users[0].user_id,
            });
            const sortedLicenseList: License[] = licenseListResponse.sort((a, b) => {
                return new Date(b.activation_date).getTime() - new Date(a.activation_date).getTime();
            });
            setLicenseList(sortedLicenseList);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!user) {
            // ページリロード時はコンテキストも初期化されるため、空の場合は一度リターンする
            // コンテキストが生成されユーザー情報が生成された時点で再実行する
            return;
        }
        if (!effectRun.current) {
            fetchUsersLicenses();
            return () => {
                effectRun.current = true;
            };
        }
    }, [user]);

    /**
     * 購入管理番号が同じライセンス（同時に購入したライセンス）の中に、
     * オフラインアクティベーション中のライセンスが存在するかを調べる。
     *
     * ライセンス詳細画面で、ライセンス更新操作が可能かどうかを調べるために使用する。
     * @param license ターゲットとなるライセンス情報
     * @returns 成否
     */
    function hasOfflineActivationInPaymentGroup(license: License): boolean {
        return licenseList
            .filter((l) => l.payment_history_id === license.payment_history_id)
            .map((l) => l.activation_status)
            .includes(ACTIVATION_STATUS_OFFLINE);
    }

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("UserLicenseReference.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow items-center justify-center px-6 py-8 pt-20 lg:px-20">
                <h1 className="h1-common py-3">{t("UserLicenseReference.title")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="p-2 font-bold text-lime-800">Loading...</p>
                    </div>
                ) : (
                    <>
                        {/* ユーザー管理部分 */}
                        <div id="accordionForUsers">
                            <div className="my-5 rounded border-none border-neutral-200 bg-white">
                                <h2 className="mb-0">
                                    <div
                                        className={`flex w-full items-center rounded border-0 bg-lime-700 px-5 py-2 text-left text-lg font-bold text-neutral-200`}
                                    >
                                        {t("UserLicenseReference.userManagement")}
                                    </div>
                                </h2>

                                <table className="my-3 min-w-full divide-y divide-gray-200 border">
                                    <thead className="bg-lime-100">
                                        <tr
                                            className={`${
                                                i18n.language === "ja" ? "text-base" : "text-sm"
                                            } font-semibold uppercase tracking-wide text-lime-800`}
                                        >
                                            {/* [ヘッダ] メールアドレス */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.email")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 姓 */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.familyName")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 名 */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.givenName")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 所属 */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.organization")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] ユーザータイプ */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.userType")}</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200">
                                        {userList.map((u) => (
                                            <tr key={u.user_id} className="text-sm font-semibold text-gray-800">
                                                {/* メールアドレス */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{u.email}</span>
                                                    </div>
                                                </td>
                                                {/* 姓 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{u.family_name}</span>
                                                    </div>
                                                </td>
                                                {/* 名 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{u.given_name}</span>
                                                    </div>
                                                </td>
                                                {/* 所属 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{u.organization}</span>
                                                    </div>
                                                </td>
                                                {/* ユーザータイプ */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{t(`UserType.${u.user_type}`)}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* ライセンス管理部分 */}
                        <div id="accordionForLicenses">
                            <div className="my-10 rounded border-none border-neutral-200 bg-white">
                                <h2 className="mb-0">
                                    <div
                                        className={`flex w-full items-center rounded border-0 bg-lime-700 px-5 py-2 text-left text-lg font-bold text-neutral-200`}
                                    >
                                        {t("UserLicenseReference.licenseManagement")}
                                    </div>
                                </h2>

                                <table className="my-3 min-w-full divide-y divide-gray-200 border">
                                    <thead className="bg-lime-100">
                                        <tr
                                            className={`${
                                                i18n.language === "ja" ? "text-base" : "text-sm"
                                            } font-semibold uppercase tracking-wide text-lime-800`}
                                        >
                                            {/* [ヘッダ] 製品名 */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.packageName")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] ライセンスコード */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.licenseKey")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 利用開始日 */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.activationDate")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 有効期限 */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.expirationDate")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] ユーザー */}
                                            <th scope="col" className="px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.user")}</span>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-2 text-right"></th>
                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200">
                                        {licenseList.map((license) => (
                                            <tr
                                                key={license.license_id}
                                                className="text-sm font-semibold text-gray-800"
                                            >
                                                {/* 製品名 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{license.package_name}</span>
                                                    </div>
                                                </td>
                                                {/* ライセンスコード */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{license.license_key}</span>
                                                    </div>
                                                </td>
                                                {/* 利用開始日 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{formatUtcToLocalDateTime(license.activation_date)}</span>
                                                    </div>
                                                </td>
                                                {/* 有効期限 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span
                                                            className={
                                                                new Date() > convertUtcToLocalDate(license.expire_date)
                                                                    ? "text-red-700"
                                                                    : ""
                                                            }
                                                        >
                                                            {formatUtcToLocalDateTime(license.expire_date)}
                                                        </span>
                                                    </div>
                                                </td>
                                                {/* ユーザー */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{license.email || "-"}</span>
                                                    </div>
                                                </td>
                                                {/* 詳細 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-2 py-1.5">
                                                        <Link
                                                            className="inline-flex items-center gap-x-1.5 text-sm font-medium text-blue-600 decoration-2 hover:underline"
                                                            to={LICENSE_DETAIL_ROUTE}
                                                            state={{
                                                                license: license,
                                                                hasOfflineActivationInPaymentGroup:
                                                                    hasOfflineActivationInPaymentGroup(license),
                                                                backUrl: USER_LICENSE_REFERENCE_ROUTE,
                                                            }}
                                                        >
                                                            {t("CommonLabel.detail")}
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )}

                {/* マイページへ戻る */}
                <div className="flex justify-center pt-2">
                    <Link to={MYPAGE_ROUTE}>
                        <button className="btn-secondary px-5">{t("UserLicenseReference.backToMypage")}</button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default UserLicenseReference;
