import { t } from "i18next";
import { ACTIVATION_STATUS_AVAILABLE, ACTIVATION_STATUS_BEFORE, ACTIVATION_STATUS_EXPIRED } from "../consts/constants";
import { License } from "../types/License";
import { convertUtcToLocalDate, formatUtcToLocalDateTime } from "./DateUtils";
import { User } from "../types/User";
import { LicenseForOutput } from "../types/LicenseForOutput";

/**
 * アクティベーションステータスを判別し、対応する文言を返す
 * @param license ライセンス情報
 * @returns アクティベーションステータス名
 */
export function getActivationStatusName(license: License) {
    let status = license.activation_status;
    // [利用開始前]もしくは[有効期限切れ]は日時データから判断する
    if (new Date() < convertUtcToLocalDate(license.activation_date)) {
        status = ACTIVATION_STATUS_BEFORE;
    } else if (new Date() > convertUtcToLocalDate(license.expire_date)) {
        status = ACTIVATION_STATUS_EXPIRED;
    } else if (status === ACTIVATION_STATUS_BEFORE) {
        // 有効期限内であるが、ステータスが「利用開始前」のままの場合、未アクティベーションとして扱う
        status = ACTIVATION_STATUS_AVAILABLE;
    }
    return t(`ActivationStatus.${status}`);
}

/**
 * データベースから取得したライセンス情報リストを、出力用のライセンス情報リストへ変換
 * @param licenseList DBから取得したライセンス情報リスト
 * @param userList DBから取得したユーザー情報リスト
 */
export function convertLicenseListForOutput(licenseList: License[], userList: User[]) {
    const outputList: LicenseForOutput[] = licenseList.map((l) => {
        const user = specifyUser(l.user_id, userList);
        const licenseForOutput: LicenseForOutput = {
            license_id: l.license_id,
            package_name: l.package_name,
            license_key: l.license_key,
            activation_date: formatUtcToLocalDateTime(l.activation_date),
            expire_date: formatUtcToLocalDateTime(l.expire_date),
            email: l.email,
            free_trial_flg: l.free_trial_flg,
            activation_status: l.activation_status,
            activation_status_string: getActivationStatusName(l),
            activation_record_date: formatUtcToLocalDateTime(l.activation_record_date),
            computer_name: l.computer_name,
            sid: l.sid,
            user_id: l.user_id,
            family_name: user ? user.family_name : "",
            given_name: user ? user.given_name : "",
            organization: user ? user.organization : "",
            owner_group_id: l.owner_group_id,
            payment_history_id: l.payment_history_id,
        };
        return licenseForOutput;
    });
    return outputList;
}

/**
 * ユーザーIDからユーザー情報を特定
 * @param user_id ユーザーID
 * @param userList 検索対象のユーザー情報リスト
 * @returns ユーザー情報
 */
function specifyUser(user_id: string, userList: User[]) {
    if (user_id === null) {
        return null;
    }
    const user: User | undefined = userList.find((u) => u.user_id === user_id);
    return user ? user : null;
}
