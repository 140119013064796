import { Trans, useTranslation } from "react-i18next";
import Modal from "react-modal";
import { CrossCircleIcon } from "../../components/Icons";

type ModalProps = {
    showModal: boolean;
    email: string;
    handleCancel: () => void;
    handleSending: () => void;
    isProcessing: boolean;
    errorMessage: string;
};

/**
 * メール認証要求モーダル
 *
 * サインアップ後のメール認証をせずにログインしようとした場合に表示する
 * @param ModalProps
 * @returns JSX.Element
 */
const MailVerificationModal: React.FC<ModalProps> = ({
    showModal,
    email,
    handleCancel,
    handleSending,
    isProcessing,
    errorMessage,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 -translate-x-1/2 -translate-y-1/2 transform rounded-lg border bg-white p-4 shadow-md sm:w-1/2 lg:w-1/3"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 grid grid-cols-3 items-center justify-center p-4 sm:m-2">
                <div className="col-span-3 text-lg font-bold">{t("Signin.emailVerificationNotCompleted")}</div>
                <hr className="col-span-3 my-2 border-t-2"></hr>
                {/* 説明 */}
                <p className="col-span-3">
                    <Trans i18nKey="Signin.emailSendToText" values={{ email: email }} />
                </p>
                <p className="col-span-3 py-2 text-sm text-gray-700">{t("Signin.emailSendToComment")}</p>
                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="col-span-3 flex items-center justify-center py-1">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {/* キャンセル/送信ボタン */}
                <div className="col-span-3 mt-5 flex items-center justify-evenly">
                    <button className="btn-secondary w-1/3" onClick={handleCancel}>
                        {t("CommonLabel.cancel")}
                    </button>
                    <button
                        onClick={handleSending}
                        className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                        disabled={isProcessing}
                    >
                        {isProcessing ? (
                            <div className="flex items-center justify-center">
                                <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                <label className="">Processing...</label>
                            </div>
                        ) : (
                            t("CommonLabel.send")
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default MailVerificationModal;
