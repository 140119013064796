/**
 * [日付＋時刻]の文字列を[日付]文字列にフォーマット
 *
 * 文字列が未指定の場合は'-'を返す。
 * また、ブラウザのロケールに基づいてタイムゾーンおよびフォーマット指定も自動で行う。
 *
 * ex) 引数のdatetime = 2023/8/1 12:00:00+00(UTC) の場合
 *
 *   日本の場合、dateオブジェクトは2023/8/1 21:00:00+09(JST)、
 *   フォーマット後の日付は「2023/8/1」となる
 *
 *   サンフランシスコの場合、dateオブジェクトは2023/8/1 05:00:00-07(PDT)、
 *   フォーマット後の日付は「8/1/2023」となる
 *
 * @param datetime 文字列
 * @returns フォーマット後文字列
 */
export function formatUtcToLocalDate(utcDatetime: string | null | undefined) {
    if (utcDatetime === null || utcDatetime === undefined) {
        return "-";
    } else {
        // UTCを明示指定（Zを付加）してDateオブジェクトを生成
        const date = new Date(utcDatetime + "Z");
        return date.toLocaleDateString();
    }
}

/**
 * [日付＋時刻]の文字列をフォーマット
 *
 * 文字列が未指定の場合は'-'を返す。
 * また、ブラウザのロケールに基づいてタイムゾーンおよびフォーマット指定も自動で行う。
 *
 * @param datetime 文字列
 * @returns フォーマット後文字列
 */
export function formatUtcToLocalDateTime(utcDatetime: string | null | undefined) {
    if (utcDatetime === null || utcDatetime === undefined) {
        return "-";
    } else {
        // UTCを明示指定（Zを付加）してDateオブジェクトを生成
        const date = new Date(utcDatetime + "Z");
        return formatDateTime(date);
    }
}

/**
 * UTC日時の文字列をブラウザのロケールに変換したDateオブジェクトに変換する
 * @param utcDatetime UTC日時の文字列
 * @returns Dateオブジェクト
 */
export function convertUtcToLocalDate(utcDatetime: string) {
    // UTCを明示指定（Zを付加）してDateオブジェクトを生成
    return new Date(utcDatetime + "Z");
}

/**
 * 現在時刻 + X年後の日付を取得
 * @param years X年後
 * @returns 計算後のDateオブジェクト
 */
export function getYearsLaterDate(years: number) {
    const currentDate = new Date();
    const calculatedDate = new Date(currentDate);
    calculatedDate.setFullYear(currentDate.getFullYear() + years);
    return calculatedDate;
}

/**
 * [YYYY年MM月DD日 HH:mm]の形式でフォーマット
 *
 * @param datetime UTC時間の文字列
 * @returns フォーマット後文字列
 */
export function formatUtcToLocalDateTimeJAFormat(utcDatetime: string | null | undefined) {
    if (utcDatetime === null || utcDatetime === undefined) {
        return "-";
    } else {
        // UTCを明示指定（Zを付加）してDateオブジェクトを生成
        const date = new Date(utcDatetime + "Z");

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        return `${year}年${month}月${day}日 ${hours}:${minutes}`;
    }
}

/**
 * [YYYY年MM月DD日]の形式でフォーマット
 *
 * @param datetime UTC時間の文字列
 * @returns フォーマット後文字列
 */
export function formatUtcToLocalDateJAFormat(utcDatetime: string | null | undefined) {
    if (utcDatetime === null || utcDatetime === undefined) {
        return "-";
    } else {
        // UTCを明示指定（Zを付加）してDateオブジェクトを生成
        const date = new Date(utcDatetime + "Z");

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return `${year}年${month}月${day}日`;
    }
}

/**
 * DateオブジェクトをYYYY/MM/DD HH:mmのフォーマット文字列に変換
 * @param date
 * @returns
 */
export function formatDateTime(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}/${month}/${day} ${hours}:${minutes}`;
}
