import awsconfig from "./aws-exports";
import { Amplify } from "aws-amplify";

import { UserContextProvider } from "./contexts/UserContext";
import "./App.css";
import AppRoutes from "./AppRoutes";

Amplify.configure(awsconfig);

// バックエンドAPI呼び出し用の設定
Amplify.configure({
    API: {
        endpoints: [
            {
                name: process.env.REACT_APP_API_ENDPOINT_NAME,
                endpoint: process.env.REACT_APP_API_ENDPOINT,
            },
        ],
    },
});

function App() {
    return (
        <UserContextProvider>
            <AppRoutes />
        </UserContextProvider>
    );
}

export default App;
