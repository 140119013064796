import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import dfwalker_icon from "./../../img/dfwalker/icon.png";
import dfwalker_screen from "./../../img/dfwalker/screen.png";
import dfwalker_display_ortho from "./../../img/dfwalker/displayortho.png";
import dfwalker_create_polygon from "./../../img/dfwalker/createpolygon.png";
import dfwalker_display_polygon from "./../../img/dfwalker/displaypolygon.png";
import { ScrollToTop } from "../../components/ScrollToTop";
import { Helmet } from "react-helmet";
import { DF_WALKER_GOOGLE_PLAY_LINK } from "../../consts/Installers";

/**
 * DF Walker詳細画面
 * @returns JSX.Element
 */
const DetailDfwalker = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("DetailDfwalker.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <img className="h-20 w-20 p-3" src={dfwalker_icon} alt="dfwalker" />
                        <h1 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl ">DF Walker</h1>
                    </div>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfwalker_screen}
                            alt="DF Walker Screen Image"
                            className="mx-auto w-1/3 object-cover object-center"
                        />
                    </div>

                    {/* 概要 */}
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfwalker.about1" />
                    </p>

                    {/* オルソ画像表示 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfwalker.displayOrthoTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfwalker.displayOrthoText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfwalker_display_ortho}
                            alt="DF Walker Display Ortho"
                            className="mx-auto h-full w-1/2 object-cover object-center"
                        />
                    </div>

                    {/* ポリゴン作成 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfwalker.createPolygonTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfwalker.createPolygonText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfwalker_create_polygon}
                            alt="DF Walker Create Polygpn"
                            className="mx-auto h-full w-2/3 object-cover object-center"
                        />
                    </div>

                    {/* ポリゴン描画 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfwalker.displayPolygonTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfwalker.displayPolygonText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfwalker_display_polygon}
                            alt="DF Walker Create Polygpn"
                            className="mx-auto h-full w-2/3 object-cover object-center"
                        />
                    </div>

                    {/* その他マップ機能 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfwalker.otherFeatureTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfwalker.otherFeatureText1" />
                    </p>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfwalker.otherFeatureText2" />
                    </p>

                    {/* ダウンロード */}
                    <h2 id="download" className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfwalker.downloadTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfwalker.downloadText" />
                        <br />
                        <a
                            className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                            href={DF_WALKER_GOOGLE_PLAY_LINK}
                        >
                            {DF_WALKER_GOOGLE_PLAY_LINK}
                        </a>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DetailDfwalker;
