import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import dfscanner_icon from "./../img/dfscanner/icon.png";
import dflat_icon from "./../img/dflat/icon.png";
import dfviwer_icon from "./../img/dfviewer/icon.jpg";
import icon_account from "./../img/icon/account.svg";
import icon_gear from "./../img/icon/gear.svg";
import icon_clock from "./../img/icon/clock.svg";
import icon_document from "./../img/icon/document.svg";
import icon_cloud_database from "./../img/icon/cloud-database.svg";
import icon_map from "./../img/icon/map.svg";
import {
    ACCOUNT_SETTING_ROUTE,
    DATA_MANAGEMENT_ROUTE,
    DETAIL_DF_LAT_ROUTE,
    DETAIL_DF_SCANNER_ROUTE,
    DETAIL_DF_VIEWER_ROUTE,
    LICENSE_ISSUE_HISTORY_ROUTE,
    LICENSE_ISSUE_START_ROUTE,
    PAYMENT_HISTORY_ROUTE,
    USER_LICENSE_REFERENCE_ROUTE,
    USER_LICENSE_ROUTE,
    WEB_GIS_ROUTE,
} from "../consts/routes";
import { useUserContext } from "../contexts/UserContext";
import { ScrollToTop } from "../components/ScrollToTop";
import { Helmet } from "react-helmet";

/**
 * マイページ画面
 * @returns JSX.Element
 */
const Mypage = () => {
    const { t } = useTranslation();
    const { user } = useUserContext();
    const [userType, setUserType] = useState<number | null>(null);

    // コンテキスト情報の取得
    useEffect(() => {
        setUserType(Number(user?.attributes["custom:user_type"]) ?? null);
    }, [user]);

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("Mypage.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                {/* My メニュー */}
                <div className="container mx-auto px-5 py-5">
                    <div className="flex items-center py-4">
                        <div className="h-2 flex-grow bg-lime-800 opacity-70"></div>
                        <span className="flex-shrink px-4 text-2xl text-lime-800">{t("Mypage.myMenu")}</span>
                        <div className="h-2 flex-grow bg-lime-800 opacity-70"></div>
                    </div>

                    <div className="-m-4 flex flex-wrap">
                        {/* アカウント情報設定 */}
                        <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                            <Link to={ACCOUNT_SETTING_ROUTE}>
                                <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                    <div className="mb-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-green-900">
                                        <img src={icon_gear} alt="account setting" />
                                    </div>
                                    <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                        {t("Mypage.accountSettingTitle")}
                                    </h2>
                                    <p className="text-base leading-relaxed">{t("Mypage.accountSettingText")}</p>
                                </button>
                            </Link>
                        </div>

                        {/* ユーザー・ライセンス管理 */}
                        {userType != null && [0, 1, 3].includes(userType) ? (
                            // マスターユーザー、管理ユーザー、マネージャーの場合 => 編集可能な管理画面
                            <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                                <Link to={USER_LICENSE_ROUTE}>
                                    <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                        <div className="mb-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-green-900">
                                            <img src={icon_account} alt="User license control" />
                                        </div>
                                        <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                            {t("Mypage.userLicenseTitle")}
                                        </h2>
                                        <p className="text-base leading-relaxed">{t("Mypage.userLicenseText")}</p>
                                    </button>
                                </Link>
                            </div>
                        ) : (
                            // それ以外の場合 => 参照専用の画面
                            <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                                <Link to={USER_LICENSE_REFERENCE_ROUTE}>
                                    <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                        <div className="mb-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-green-900">
                                            <img src={icon_account} alt="User license reference" />
                                        </div>
                                        <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                            {t("Mypage.userLicenseReferenceTitle")}
                                        </h2>
                                        <p className="text-base leading-relaxed">
                                            {t("Mypage.userLicenseReferenceText")}
                                        </p>
                                    </button>
                                </Link>
                            </div>
                        )}

                        {/* 購入履歴管理 */}
                        {userType != null && [0, 1, 3].includes(userType) ? (
                            // マスターユーザー、管理ユーザー、マネージャーの場合
                            <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                                <Link to={PAYMENT_HISTORY_ROUTE}>
                                    <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                        <div className="mb-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-green-900">
                                            <img src={icon_clock} alt="Payment history" />
                                        </div>
                                        <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                            {t("Mypage.paymentHistoryTitle")}
                                        </h2>
                                        <p className="text-base leading-relaxed">{t("Mypage.paymentHistoryText")}</p>
                                    </button>
                                </Link>
                            </div>
                        ) : (
                            // それ以外の場合
                            <></>
                        )}

                        {/* データ管理 */}
                        <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                            <Link to={DATA_MANAGEMENT_ROUTE}>
                                <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                    <div className="mb-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-green-900">
                                        <img src={icon_cloud_database} alt="cloud database" />
                                    </div>
                                    <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                        {t("Mypage.dataManagementTitle")}
                                    </h2>
                                    <p className="text-base leading-relaxed">{t("Mypage.dataManagementText")}</p>
                                </button>
                            </Link>
                        </div>

                        {/* 地図上にデータを表示 */}
                        <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                            <Link to={WEB_GIS_ROUTE}>
                                <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                    <div className="mb-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-green-900">
                                        <img src={icon_map} alt="map" />
                                    </div>
                                    <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                        {t("Mypage.dataManagementWebGISTitle")}
                                    </h2>
                                    <p className="text-base leading-relaxed">{t("Mypage.dataManagementWebGISText")}</p>
                                </button>
                            </Link>
                        </div>

                        {/* ライセンス発行 */}
                        {userType != null && [0].includes(userType) ? (
                            // マスターユーザーの場合
                            <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                                <Link to={LICENSE_ISSUE_START_ROUTE}>
                                    <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                        <div className="mb-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-green-900">
                                            <img src={icon_document} alt="License issue" />
                                        </div>
                                        <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                            {t("Mypage.licenseIssueTitle")}
                                        </h2>
                                        <p className="text-base leading-relaxed">{t("Mypage.licenseIssueText")}</p>
                                    </button>
                                </Link>
                            </div>
                        ) : (
                            // それ以外の場合
                            <></>
                        )}

                        {/* ライセンス発行履歴確認 */}
                        {userType != null && [0, 3].includes(userType) ? (
                            // マスターユーザー、マネージャーの場合
                            <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                                <Link to={LICENSE_ISSUE_HISTORY_ROUTE}>
                                    <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                        <div className="mb-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-green-900">
                                            <img src={icon_clock} alt="License issue history" />
                                        </div>
                                        <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                            {t("Mypage.licenseIssueHistoryTitle")}
                                        </h2>
                                        <p className="text-base leading-relaxed">
                                            {t("Mypage.licenseIssueHistoryText")}
                                        </p>
                                    </button>
                                </Link>
                            </div>
                        ) : (
                            // それ以外の場合
                            <></>
                        )}
                    </div>
                </div>

                {/* デスクトップアプリ */}
                <div className="container mx-auto px-5 py-5">
                    <div className="flex items-center py-4">
                        <div className="h-2 flex-grow bg-lime-800 opacity-70"></div>
                        <span className="flex-shrink px-4 text-2xl text-lime-800">{t("Mypage.desktopApp")}</span>
                        <div className="h-2 flex-grow bg-lime-800 opacity-70"></div>
                    </div>

                    <div className="-m-4 flex flex-wrap">
                        {/* DF Scanner */}
                        <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                            <Link to={DETAIL_DF_SCANNER_ROUTE}>
                                <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                    <img className="h-16 w-16" src={dfscanner_icon} alt="dfscanner" />
                                    <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                        {t("Mypage.dfScannerTitle")}
                                    </h2>
                                    <p className="text-base leading-relaxed">{t("Mypage.dfScannerText")}</p>
                                </button>
                            </Link>
                        </div>

                        {/* DF Viewer */}
                        <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                            <Link to={DETAIL_DF_VIEWER_ROUTE}>
                                <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                    <img className="h-16 w-16" src={dfviwer_icon} alt="dfviewer" />
                                    <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                        {t("Mypage.dfViewerTitle")}
                                    </h2>
                                    <p className="text-base leading-relaxed">{t("Mypage.dfViewerText")}</p>
                                </button>
                            </Link>
                        </div>

                        {/* DF Viewer */}
                        <div className="w-full p-4 md:w-1/2 xl:w-1/3">
                            <Link to={DETAIL_DF_LAT_ROUTE}>
                                <button className="flex h-full w-full flex-col justify-start rounded-lg border border-gray-200 p-6 text-left hover:border-gray-400 hover:bg-lime-100 active:bg-lime-200">
                                    <img className="h-16 w-16" src={dflat_icon} alt="dfviewer" />
                                    <h2 className="title-font mb-2 text-lg font-medium text-gray-900">
                                        {t("Mypage.dfLatTitle")}
                                    </h2>
                                    <p className="text-base leading-relaxed">{t("Mypage.dfLatText")}</p>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Webアプリ */}
                <div className="container mx-auto px-5 py-5">
                    <div className="flex items-center py-4">
                        <div className="h-2 flex-grow bg-lime-800 opacity-70"></div>
                        <span className="flex-shrink px-4 text-2xl text-lime-800">{t("Mypage.webApp")}</span>
                        <div className="h-2 flex-grow bg-lime-800 opacity-70"></div>
                    </div>

                    <div className="flex items-center justify-center py-10">
                        <div className="text-xl font-bold text-gray-700">Commig soon・・・</div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Mypage;
