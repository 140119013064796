import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { COMMON_UNAUTHORIZED_ROUTE } from "../consts/routes";
import { USER_TYPE_LICENSED_USER } from "../consts/constants";
import { useUserContext } from "../contexts/UserContext";
import { loggingInfo } from "../utils/LoggingUtils";

/**
 * 一般ユーザーが参照権限のないページを開いたときにエラー画面へ誘導するためのコンポーネント
 * @returns
 */
export const LicensedUserUnauthorized = () => {
    const { userType } = useUserContext();
    const navigate = useNavigate();
    // 一般ユーザーはページ参照不可
    useEffect(() => {
        if (userType != null && userType === USER_TYPE_LICENSED_USER) {
            loggingInfo(userType);
            navigate(COMMON_UNAUTHORIZED_ROUTE);
        }
    }, [userType]);
    return null;
};
