import { useEffect } from "react";

/**
 * ページ表示時に画面最上部へ移動するためのコンポーネント
 * @returns
 */
export const ScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
};
