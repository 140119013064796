import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { CrossCircleIcon } from "../../components/Icons";
import { User } from "../../types/User";

type ModalProps = {
    showModal: boolean;
    selectedUser: User | undefined;
    handleClose: () => void;
    handleDelete: () => void;
    isProcessing: boolean;
    errorMessage: string;
};

/**
 * ユーザー削除確認モーダル
 *
 * @param ModalProps
 * @returns JSX.Element
 */
const UserDeleteConfirmModal: React.FC<ModalProps> = ({
    showModal,
    selectedUser,
    handleClose,
    handleDelete,
    isProcessing,
    errorMessage,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md sm:w-1/2"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 px-4 py-2 sm:m-2">
                <div className="text-lg font-bold">{t("UserLicense.userDeleteModalTitle")}</div>
                <hr className="my-2 border-t-2"></hr>
                <p className="">{t("UserLicense.userDeleteModalText")}</p>
                <ul className="list-disc pl-5 text-gray-700">
                    <li>{t("UserLicense.userDeleteModalAttention1")}</li>
                    <li>{t("UserLicense.userDeleteModalAttention2")}</li>
                </ul>
                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-2">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                <div className="m-0 grid grid-cols-2 items-center justify-center p-2 font-medium text-gray-900 sm:m-2">
                    {/* メールアドレス */}
                    <div className="col-span-2 ml-5 sm:col-span-1">
                        <label>{t("CommonLabel.email")}</label>
                    </div>
                    <div className="col-span-2 ml-5 sm:col-span-1 sm:ml-0">
                        <label>{selectedUser?.email}</label>
                    </div>
                    {/* 姓 */}
                    <div className="col-span-2 ml-5 pt-3 sm:col-span-1 sm:pt-0">
                        <label>{t("CommonLabel.familyName")}</label>
                    </div>
                    <div className="col-span-2 ml-5 sm:col-span-1 sm:ml-0">
                        <label>{selectedUser?.family_name}</label>
                    </div>
                    {/* 名 */}
                    <div className="col-span-2 ml-5 pt-3 sm:col-span-1 sm:pt-0">
                        <label>{t("CommonLabel.givenName")}</label>
                    </div>
                    <div className="col-span-2 ml-5 sm:col-span-1 sm:ml-0">
                        <label>{selectedUser?.given_name}</label>
                    </div>

                    {/* キャンセル/削除ボタン */}
                    <div className="col-span-2 mt-8 flex items-center justify-evenly">
                        <button className="btn-primary w-1/3" onClick={handleClose}>
                            {t("CommonLabel.cancel")}
                        </button>
                        <button
                            onClick={handleDelete}
                            className={`w-1/3 rounded-lg  py-2.5 text-center font-medium text-gray-50 ${
                                isProcessing
                                    ? "bg-red-900"
                                    : "bg-red-600 hover:bg-red-800 active:bg-red-900 active:font-bold"
                            }  `}
                            disabled={isProcessing}
                        >
                            {isProcessing ? (
                                <div className="flex items-center justify-center">
                                    <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                    <label className="">Processing...</label>
                                </div>
                            ) : (
                                t("CommonLabel.delete")
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UserDeleteConfirmModal;
