import { ChangeEventHandler, MouseEventHandler, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { USER_CREATE_ROUTE, USER_LICENSE_ROUTE } from "../consts/routes";
import { formatUtcToLocalDate } from "../utils/DateUtils";
import { CrossCircleIcon } from "../components/Icons";
import { useUserContext } from "../contexts/UserContext";
import { linkLicense } from "../clients/LicenseClient";
import { E201, E202, E203, E204, E205 } from "../consts/ErrorCodes";
import { ScrollToTop } from "../components/ScrollToTop";
import { User } from "../types/User";
import { License } from "../types/License";
import { LicensedUserUnauthorized } from "../components/LicensedUserUnauthorized";
import { Helmet } from "react-helmet";

interface LocationState {
    license: License;
    userList: User[];
}

/**
 * ライセンス紐づけ画面
 * @returns JSX.Element
 */
const LicenseLinking = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { getRecentToken } = useUserContext();
    const { license, userList } = location.state as LocationState;
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * メールアドレス選択時のイベント処理
     * @param event
     */
    const handleSelectUser: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSelectedUser(userList.find((user) => user.user_id === event.target.value) ?? null);
    };

    /**
     * 実行押下時のイベント処理
     * @param event
     * @returns
     */
    const handleLinking: MouseEventHandler<HTMLButtonElement> = async (event) => {
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            // ユーザー選択有無チェック
            if (!selectedUser) {
                setErrorMessage(t("ErrorMessage.emailNotSelected"));
                return;
            }
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // ライセンス紐づけ
            await linkLicense(idToken, license.license_key, selectedUser.user_id);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.code) {
                const errorCode = error.response.data.code;
                if (errorCode === E201) {
                    // ライセンス情報が見つからない
                    setErrorMessage(t("ErrorMessage.licenseNotFound"));
                } else if (errorCode === E202) {
                    // ユーザーが見つからない
                    setErrorMessage(t("ErrorMessage.userNotFound"));
                } else if (errorCode === E203) {
                    // ライセンス所有者との不整合
                    setErrorMessage(t("ErrorMessage.cannotLinkingSpecified"));
                } else if (errorCode === E204) {
                    // ライセンス有効期限外
                    setErrorMessage(t("ErrorMessage.expiredLicense"));
                } else if (errorCode === E205) {
                    // ライセンス使用中
                    setErrorMessage(t("ErrorMessage.licenseInUse"));
                } else {
                    // その他エラー
                    setErrorMessage(t("ErrorMessage.failToLicenselinking"));
                }
            } else {
                // その他エラー
                setErrorMessage(t("ErrorMessage.failToLicenselinking"));
            }
            return;
        } finally {
            setIsProcessing(false);
            window.scrollTo(0, 0);
        }
        navigate(USER_LICENSE_ROUTE);
    };

    /**
     * 戻る押下時のイベント処理
     */
    const handleBack = () => {
        navigate(USER_LICENSE_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("LicenseLinking.meta.title")} />
            <ScrollToTop />
            <LicensedUserUnauthorized />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("LicenseLinking.licenseLinkingTitle")}</h1>
                <ul className="mx-auto ml-10 list-disc text-gray-700">
                    <li>{t("LicenseLinking.licenseLinkingText1")}</li>
                </ul>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                <div className="m-0 grid grid-cols-3 items-center justify-center p-2 sm:m-2">
                    {/* 製品名 */}
                    <div className="col-span-1 py-1">
                        <label className="block font-medium text-gray-900">{t("CommonLabel.packageName")}</label>
                    </div>
                    <div className="col-span-2 py-1">{license.package_name}</div>

                    {/* ライセンスコード */}
                    <div className="col-span-1 py-1">
                        <label className="block font-medium text-gray-900">{t("CommonLabel.licenseKey")}</label>
                    </div>
                    <div className="col-span-2 py-1">{license.license_key}</div>

                    {/* 利用開始日 */}
                    <div className="col-span-1 py-1">
                        <label className="block font-medium text-gray-900">{t("CommonLabel.activationDate")}</label>
                    </div>
                    <div className="col-span-2 py-1">{formatUtcToLocalDate(license.activation_date)}</div>

                    {/* 有効期限 */}
                    <div className="col-span-1 py-1">
                        <label className="block font-medium text-gray-900">{t("CommonLabel.expirationDate")}</label>
                    </div>
                    <div className="col-span-2 py-1">{formatUtcToLocalDate(license.expire_date)}</div>

                    {/* メールアドレス */}
                    <div className="col-span-1 py-2">
                        <label htmlFor="email" className="block font-medium text-gray-900">
                            {t("CommonLabel.email")}
                        </label>
                    </div>
                    <div className="col-span-2 py-2">
                        <select name="email" id="email" className="textbox-common" onChange={handleSelectUser}>
                            <option hidden>{t("CommonMessage.pleaseSelect")}</option>
                            {userList.map((user) => (
                                <option key={user.user_id} value={user.user_id}>
                                    {user.email}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* ユーザー作成へのリンク */}
                <p className="mx-auto pb-5 text-center">
                    {t("LicenseLinking.ifCreateNewUser")}
                    <Link to={USER_CREATE_ROUTE}>
                        <label className="link-text">{t("CommonMessage.clickHere")}</label>
                    </Link>
                </p>

                {/* 戻る/実行ボタン */}
                <div className="my-5 flex items-center justify-evenly">
                    <button onClick={handleBack} className="btn-secondary w-1/3">
                        {t("CommonLabel.back")}
                    </button>
                    <button
                        onClick={handleLinking}
                        className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                        disabled={isProcessing}
                    >
                        {isProcessing ? (
                            <div className="flex items-center justify-center">
                                <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                <label className="">Processing...</label>
                            </div>
                        ) : (
                            t("CommonLabel.execute")
                        )}
                    </button>
                </div>
                <div className="flex justify-center" aria-label="読み込み中"></div>
            </div>
            <Footer />
        </div>
    );
};

export default LicenseLinking;
