import { useState } from "react";
import { useTranslation } from "react-i18next";

import i18n from "../i18n/configs";
import ContactModal from "../pages/modals/ContactModal";

/**
 * フッター
 * @returns JSX.Element
 */
const Footer = () => {
    const { t } = useTranslation();
    const [showContactModal, setShowContactModal] = useState<boolean>(false);

    /**
     * お問い合わせモーダルを開く
     */
    const handleOpenContactModal = () => {
        setShowContactModal(true);
    };

    /**
     * お問い合わせモーダルを閉じる
     */
    const handleCloseContactModal = () => {
        setShowContactModal(false);
    };

    return (
        <div>
            <footer className="mt-8 w-full bg-black shadow">
                <div className="mx-auto w-full max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                    {/* Copyright */}
                    <span className="text-sm text-white dark:text-gray-400 sm:text-center">
                        &copy; 2023 &nbsp;
                        <a
                            href={process.env.REACT_APP_HOMEPAGE_URL}
                            className="hover:text-lime-200 hover:underline"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            DeepForest Technologies Co., Ltd.
                        </a>
                    </span>

                    <ul className="mt-3 flex flex-wrap items-center text-sm text-white dark:text-gray-400 sm:mt-0">
                        {/* 会社概要 */}
                        <li>
                            <a
                                href={process.env.REACT_APP_ABOUT_URL}
                                className="mr-4 hover:text-lime-200 hover:underline md:mr-6"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t("Footer.about")}
                            </a>
                        </li>

                        {/* プライバシーポリシー */}
                        <li>
                            <a
                                href={
                                    i18n.language === "ja"
                                        ? process.env.REACT_APP_PRIVACY_POLICY_JA_PATH
                                        : process.env.REACT_APP_PRIVACY_POLICY_EN_PATH
                                }
                                className="mr-4 hover:text-lime-200 hover:underline md:mr-6"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t("Footer.privacyPolicy")}
                            </a>
                        </li>

                        {/* 利用規約 */}
                        <li>
                            <a
                                href={
                                    i18n.language === "ja"
                                        ? process.env.REACT_APP_TERMS_OF_SERVICE_JA_PATH
                                        : process.env.REACT_APP_TERMS_OF_SERVICE_EN_PATH
                                }
                                className="mr-4 hover:text-lime-200 hover:underline md:mr-6"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t("Footer.termsOfService")}
                            </a>
                        </li>

                        {/* 特定商取引法に基づく表記 */}
                        <li>
                            <a
                                href={process.env.REACT_APP_TOKUSHOHO_URL}
                                className="mr-4 hover:text-lime-200 hover:underline md:mr-6"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t("Footer.transactionDisclosure")}
                            </a>
                        </li>

                        {/* お問い合わせ */}
                        <li>
                            <button
                                onClick={handleOpenContactModal}
                                className="mr-4 hover:text-lime-200 hover:underline md:mr-6"
                            >
                                {t("Footer.contact")}
                            </button>
                        </li>
                    </ul>
                </div>
                <ContactModal showModal={showContactModal} handleClose={handleCloseContactModal} />
            </footer>
        </div>
    );
};

export default Footer;
