import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { CrossCircleIcon } from "../../components/Icons";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";
import { formatDateTime } from "../../utils/DateUtils";

type ModalProps = {
    showModal: boolean;
    setShowModale: (bool: boolean) => void;
    selectedFile: S3ProviderListOutputItem | undefined;
    handleConvert: (file: S3ProviderListOutputItem) => void;
    isProcessing: boolean;
    errorMessage: string;
};

/**
 * TIFF変換処理呼び出し確認モーダル
 *
 * @param ModalProps
 * @returns JSX.Element
 */
const TiffConvertConfirmModal: React.FC<ModalProps> = ({
    showModal,
    setShowModale,
    selectedFile,
    handleConvert,
    isProcessing,
    errorMessage,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 max-w-xl -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 px-4 py-2 sm:m-2">
                <div className="text-lg font-bold">{t("DataManagement.convertTiff")}</div>
                <hr className="my-2 border-t-2"></hr>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center py-2">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                <div className="m-0 grid grid-cols-2 items-center justify-center text-gray-900">
                    {/* ファイル名 */}
                    <div className="col-span-2 sm:col-span-1">
                        <label>{t("CommonLabel.fileName")}</label>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <label>{selectedFile?.key?.split("/").slice(2).join("/")}</label>
                    </div>
                    {/* 最終更新日時 */}
                    <div className="col-span-2 pt-3 sm:col-span-1 sm:pt-0">
                        <label>{t("CommonLabel.lastModified")}</label>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <label>{selectedFile?.lastModified ? formatDateTime(selectedFile?.lastModified) : ""}</label>
                    </div>
                    {/* サイズ */}
                    <div className="col-span-2 pt-3 sm:col-span-1 sm:pt-0">
                        <label>{t("CommonLabel.dataSize")}</label>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <label>
                            {selectedFile?.size &&
                                (selectedFile?.size < 1024 * 1024 ? (
                                    <span>{Number((selectedFile?.size / 1024).toFixed(1)).toLocaleString()} KB</span>
                                ) : (
                                    <span>
                                        {Number((selectedFile?.size / (1024 * 1024)).toFixed(1)).toLocaleString()} MB
                                    </span>
                                ))}
                        </label>
                    </div>
                </div>

                <ul className="my-5 ml-5 list-disc text-sm">
                    <li>{t("DataManagement.convertNote1")}</li>
                    <li>{t("DataManagement.convertNote2")}</li>
                    <li className="font-semibold text-orange-600">{t("DataManagement.convertNote3")}</li>
                </ul>

                {/* キャンセル/実行ボタン */}
                <div className="mt-4 flex items-center justify-evenly">
                    <button className="btn-secondary w-1/3" onClick={() => setShowModale(false)}>
                        {t("CommonLabel.cancel")}
                    </button>
                    <button
                        onClick={() => selectedFile && handleConvert(selectedFile)}
                        className={`w-1/3 rounded-lg  py-2.5 text-center font-medium text-gray-50 ${
                            isProcessing
                                ? "bg-lime-900"
                                : "bg-lime-700 hover:bg-lime-800 active:bg-lime-900 active:font-bold"
                        }  `}
                        disabled={isProcessing}
                    >
                        {isProcessing ? (
                            <div className="flex items-center justify-center">
                                <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                <label className="">Processing...</label>
                            </div>
                        ) : (
                            t("CommonLabel.execute")
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default TiffConvertConfirmModal;
