import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { CrossCircleIcon } from "../../components/Icons";
import { License } from "../../types/License";
import { formatUtcToLocalDate } from "../../utils/DateUtils";

type ModalProps = {
    showModal: boolean;
    selectedLicense: License | undefined;
    isOffline: boolean;
    handleClose: () => void;
    handleUnlinking: () => void;
    isProcessing: boolean;
    errorMessage: string;
};

/**
 * ライセンス紐づけ解除モーダル
 *
 * @param ModalProps
 * @returns JSX.Element
 */
const UnlinkingConfirmModal: React.FC<ModalProps> = ({
    showModal,
    selectedLicense,
    isOffline,
    handleClose,
    handleUnlinking,
    isProcessing,
    errorMessage,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md sm:w-1/2"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 items-center justify-center p-2 sm:m-2">
                <div className="text-lg font-bold">{t("UserLicense.unlinkingModalTitle")}</div>
                <hr className="my-2 border-t-2"></hr>
                <p className="">{t("UserLicense.unlinkingModalText")}</p>
                <ul className="list-disc pl-5 text-gray-700">
                    <li>{t("UserLicense.unlinkingModalAttention1")}</li>
                    <li>{t("UserLicense.unlinkingModalAttention2")}</li>
                </ul>
                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-2">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                <div className="m-0 grid grid-cols-2 items-center justify-center p-2 font-medium text-gray-900 sm:m-2">
                    {/* 製品名 */}
                    <div className="col-span-2 ml-5 sm:col-span-1">
                        <label>{t("CommonLabel.packageName")}</label>
                    </div>
                    <div className="col-span-2 ml-5 sm:col-span-1">
                        <label>{selectedLicense?.package_name}</label>
                    </div>
                    {/* ライセンスコード */}
                    <div className="col-span-2 ml-5 pt-3 sm:col-span-1 sm:pt-0">
                        <label>{t("CommonLabel.licenseKey")}</label>
                    </div>
                    <div className="col-span-2 ml-5 sm:col-span-1">
                        <label>{selectedLicense?.license_key}</label>
                    </div>
                    {/* 利用開始日 */}
                    <div className="col-span-2 ml-5 pt-3 sm:col-span-1 sm:pt-0">
                        <label>{t("CommonLabel.activationDate")}</label>
                    </div>
                    <div className="col-span-2 ml-5 sm:col-span-1">
                        <label>{formatUtcToLocalDate(selectedLicense?.activation_date)}</label>
                    </div>
                    {/* 有効期限 */}
                    <div className="col-span-2 ml-5 pt-3 sm:col-span-1 sm:pt-0">
                        <label>{t("CommonLabel.expirationDate")}</label>
                    </div>
                    <div className="col-span-2 ml-5 sm:col-span-1">
                        <label>{formatUtcToLocalDate(selectedLicense?.expire_date)}</label>
                    </div>
                    {/* 現在のユーザー */}
                    <div className="col-span-2 ml-5 pt-3 sm:col-span-1 sm:pt-0">
                        <label>{t("CommonLabel.user")}</label>
                    </div>
                    <div className="col-span-2 ml-5 sm:col-span-1">
                        <label>{selectedLicense?.email}</label>
                    </div>

                    {/* キャンセル/実行ボタン */}
                    <div className="col-span-2 mt-5 flex items-center justify-evenly">
                        <button className="btn-secondary w-1/3" onClick={handleClose}>
                            {t("CommonLabel.cancel")}
                        </button>
                        {!isOffline && (
                            <button
                                className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                                onClick={handleUnlinking}
                                disabled={isProcessing}
                            >
                                {isProcessing ? (
                                    <div className="flex items-center justify-center">
                                        <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                        <label className="">Processing...</label>
                                    </div>
                                ) : (
                                    t("CommonLabel.execute")
                                )}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UnlinkingConfirmModal;
