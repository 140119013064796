import { API } from "aws-amplify";
import { API_ENDPOINT_NAME } from "../consts/constants";
import { loggingInfo, loggingWarn } from "../utils/LoggingUtils";

type GetLicensesRequest = {
    user_id?: string;
    group_id?: number;
    is_active?: boolean;
};

/**
 * ライセンス情報一括取得処理
 * @param idToken IDトークン
 * @param requestInfo リクエストパラメータ
 * @returns ライセンス情報一括取得APIレスポンス
 */
export async function getLicenses(idToken: string, requestInfo: GetLicensesRequest) {
    const path = "/licenses";
    const body: { [name: string]: any } = {};
    if (requestInfo.user_id) {
        body["user_id"] = requestInfo.user_id;
    }
    if (requestInfo.group_id) {
        body["group_id"] = requestInfo.group_id;
    }
    if (requestInfo.is_active) {
        body["is_active"] = requestInfo.is_active;
    }
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[GetLicenses] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[GetLicenses] Failed:", error);
        throw error;
    }
}

/**
 * ライセンス紐づけ処理
 * @param idToken IDトークン
 * @param  リクエストパラメータ
 * @returns ライセンス紐づけ解除APIレスポンス
 */
export async function linkLicense(idToken: string, licenseKey: string, userId: string) {
    const path = "/license/linking";
    const body = {
        license_key: licenseKey,
        user_id: userId,
    };
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[LinkLicense] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[LinkLicense] Failed:", error);
        throw error;
    }
}

/**
 * ライセンス紐づけ解除処理
 * @param idToken IDトークン
 * @param  リクエストパラメータ
 * @returns ライセンス紐づけ解除APIレスポンス
 */
export async function unlinkLicense(idToken: string, licenseKey: string, userId: string) {
    const path = "/license/unlinking";
    const body = {
        license_key: licenseKey,
        user_id: userId,
    };
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[UnlinkLicense] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[UnlinkLicense] Failed:", error);
        throw error;
    }
}
