import { Auth, Hub } from "aws-amplify";
import { ChangeEventHandler, FormEventHandler, MouseEventHandler, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { useUserContext } from "../contexts/UserContext";
import {
    HOME_ROUTE,
    MYPAGE_ROUTE,
    PRODUCT_CONFIRM_ROUTE,
    PRODUCT_CONFIRM_TRIAL_ROUTE,
    SIGNIN_ROUTE,
} from "../consts/routes";
import { CheckmarkCircleIcon, CrossCircleIcon } from "../components/Icons";
import { VERIFICATION_CODE_MAX_LENGTH } from "../consts/constants";
import { reflectUserInfo } from "../clients/UserClient";
import { ScrollToTop } from "../components/ScrollToTop";
import { loggingError } from "../utils/LoggingUtils";
import { Helmet } from "react-helmet";

/**
 * サインアップ確認画面
 * @returns JSX.Element
 */
const SignupConfirm = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = location.state as { email: string };
    const { user, setUser, selectedProductType, setDbUserCashe } = useUserContext();
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [completed, setCompleted] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    // 認証情報が存在する場合はトップ画面へ遷移
    useEffect(() => {
        if (user) {
            navigate(HOME_ROUTE);
        }
    });

    /**
     * 認証コード入力イベント処理
     * @param event
     */
    const handleVerificationCodeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setVerificationCode(event.target.value);
    };

    /**
     * 認証コード確認のイベント処理
     * @param event
     */
    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            await Auth.confirmSignUp(userInfo.email, verificationCode);
            setCompleted(true);
            setSuccessMessage(t("SignupConfirm.confirmSuccess"));

            // ユーザー情報をDBに反映（非同期で呼び出し）
            reflectUserInfo(setDbUserCashe);
            // 自動ログインイベントをリッスン
            listenToAutoSignInEvent();
        } catch (error: any) {
            loggingError("error confirming sign up", error);
            if (error.code === "CodeMismatchException") {
                setErrorMessage(t("ErrorMessage.codeMismatch"));
            } else if (error.code === "ExpiredCodeException") {
                setErrorMessage(t("ErrorMessage.codeExpired"));
            } else if (error.code === "UserNotFoundException") {
                setErrorMessage(t("ErrorMessage.userNotFoundAndNeedRecreate"));
            } else {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
            }
            setVerificationCode("");
        } finally {
            setIsProcessing(false);
        }
    };

    /**
     * 認証コード再送のイベント処理
     * @param event
     */
    const handleResendCode: MouseEventHandler<HTMLAnchorElement> = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            await Auth.resendSignUp(userInfo.email);
            alert(t("CommonMessage.resendCertCode"));
        } catch (error: any) {
            if (error.code === "UserNotFoundException") {
                setErrorMessage(t("ErrorMessage.userNotFoundAndNeedRecreate"));
            } else {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
            }
        } finally {
            setVerificationCode("");
            setIsProcessing(false);
        }
    };

    /**
     * サインアップ後（認証コード確認後）の自動ログイン
     */
    function listenToAutoSignInEvent() {
        Hub.listen("auth", ({ payload }) => {
            const { event } = payload;
            if (event === "autoSignIn") {
                const user = payload.data;
                setUser(user);
                if (selectedProductType && selectedProductType.isFreeTrial) {
                    // コンテキストに商品タイプIDがある、かつ、無料体験フラグtrue
                    navigate(PRODUCT_CONFIRM_TRIAL_ROUTE);
                } else if (selectedProductType) {
                    // コンテキストに商品タイプIDがある、かつ、無料体験フラグfalse
                    navigate(PRODUCT_CONFIRM_ROUTE);
                } else {
                    // それ以外はマイページへ
                    navigate(MYPAGE_ROUTE);
                }
            } else if (event === "autoSignIn_failure") {
                // redirect to sign in page
                navigate(SIGNIN_ROUTE);
            }
        });
    }

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("SignupConfirm.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <div className="w-full max-w-md rounded-lg bg-white shadow">
                    <div className="sp-4 space-y-4 p-8 md:space-y-4">
                        <h1 className="h1-common flex items-center justify-center">
                            {t("SignupConfirm.signupConfirmTitle")}
                        </h1>
                        {!completed ? (
                            // 認証コード確認前の表示
                            <>
                                <p className="px-4 py-2 text-gray-800">
                                    <Trans
                                        i18nKey="SignupConfirm.codeInputAskText"
                                        values={{ email: userInfo.email }}
                                    />
                                </p>
                                {/* エラーメッセージ */}
                                {errorMessage && (
                                    <div className="flex items-center justify-center">
                                        <CrossCircleIcon />
                                        <span className="pl-1 text-center font-bold text-red-600">
                                            {errorMessage.split("\n").map((line, index) => (
                                                <div key={index}>{line}</div>
                                            ))}
                                        </span>
                                    </div>
                                )}

                                <form className="" onSubmit={handleSubmit}>
                                    <div className="m-0 grid grid-cols-3 items-center justify-center px-4  sm:m-2">
                                        {/* 認証コード */}
                                        <div className="col-span-3 py-2">
                                            <input
                                                type="text"
                                                name="verificationCode"
                                                id="verificationCode"
                                                placeholder={t("CommonLabel.verificationCodeExapmle")}
                                                className="textbox-common"
                                                onChange={handleVerificationCodeChange}
                                                maxLength={VERIFICATION_CODE_MAX_LENGTH}
                                                autoComplete="off"
                                                required
                                                value={verificationCode}
                                            />
                                        </div>

                                        {/* 確認 */}
                                        <div className="col-span-3 py-4">
                                            <button
                                                type="submit"
                                                className={`${
                                                    isProcessing ? "btn-primary-disabled" : "btn-primary"
                                                } w-full`}
                                                disabled={isProcessing}
                                            >
                                                {isProcessing ? (
                                                    <div className="flex items-center justify-center">
                                                        <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                                        <label className="">Processing...</label>
                                                    </div>
                                                ) : (
                                                    t("CommonLabel.confirm")
                                                )}
                                            </button>
                                        </div>

                                        {/* 認証コード再送 */}
                                        <div className="col-span-3 flex justify-center py-2">
                                            <Link to="#" onClick={handleResendCode}>
                                                <label className="link-text">{t("SignupConfirm.resendCode")}</label>
                                            </Link>
                                        </div>

                                        {/* 補足コメント */}
                                        <ul className="col-span-3 pt-2 text-sm text-gray-700">
                                            <li>{t("SignupConfirm.comment1")}</li>
                                            <li>{t("SignupConfirm.comment2")}</li>
                                        </ul>
                                    </div>
                                </form>
                            </>
                        ) : (
                            // 認証コード確認後の表示
                            // ★通常は自動ログインで遷移する想定だが、稀に自動ログインに失敗する挙動がみられるため、
                            // 手動でログインを促すよう画面表示している
                            <>
                                {/* 成功メッセージ */}
                                {successMessage && (
                                    <div className="flex items-center justify-center pt-5">
                                        <CheckmarkCircleIcon />
                                        <span className="pl-2 text-center text-xl font-bold text-green-500">
                                            {successMessage.split("\n").map((line, index) => (
                                                <div key={index}>{line}</div>
                                            ))}
                                        </span>
                                    </div>
                                )}
                                {/* 説明 */}
                                <p className="px-4 pt-2 text-gray-800">
                                    <Trans i18nKey="SignupConfirm.autoSigninText"></Trans>
                                </p>

                                {/* ログイン画面へのリンク */}
                                <div className="flex justify-center">
                                    <Link to={SIGNIN_ROUTE}>
                                        <label className="link-text">{t("SignupConfirm.goToSignin")}</label>
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SignupConfirm;
