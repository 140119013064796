import { Auth } from "aws-amplify";
import { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { CrossCircleIcon } from "../components/Icons";
import { useUserContext } from "../contexts/UserContext";
import { HOME_ROUTE, SIGNIN_ROUTE } from "../consts/routes";
import { EMAIL_MAX_LENGTH, PASSWORD_MAX_LENGTH, VERIFICATION_CODE_MAX_LENGTH } from "../consts/constants";
import { ScrollToTop } from "../components/ScrollToTop";
import { loggingError } from "../utils/LoggingUtils";
import { Helmet } from "react-helmet";

/**
 * パスワードリセット画面
 * @returns JSX.Element
 */
const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [codePublished, setCodePublished] = useState<Boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { user } = useUserContext();
    const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    // 認証情報が存在する場合はトップ画面へ遷移
    useEffect(() => {
        if (user) {
            navigate(HOME_ROUTE);
        }
    });

    /**
     * パスワード要件表示用トグル
     */
    const togglePasswordRequirements = () => {
        setShowPasswordRequirements(!showPasswordRequirements);
    };

    /**
     * メールアドレス入力イベント処理
     * @param target
     */
    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setEmail(target.value);
    };

    /** ログイン画面へ戻るイベント処理 */
    const handleBackToSigiin = () => {
        navigate(SIGNIN_ROUTE);
    };

    /**
     * 認証コード入力イベント処理
     * @param target
     */
    const handleCodeChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setCode(target.value);
    };

    /**
     * パスワード入力イベント処理
     * @param target
     */
    const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setPassword(target.value);
    };

    /**
     * パスワード（確認）入力イベント処理
     * @param target
     */
    const handleConfirmPasswordChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setConfirmPassword(target.value);
    };

    /**
     * 認証コードを発行ボタン押下時のイベント処理
     * @param event
     */
    const handleIssueCode: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            await Auth.forgotPassword(email);
            setCodePublished(true);
        } catch (error: any) {
            loggingError("error password forgot", error);
            if (error.code === "UserNotFoundException") {
                setErrorMessage(t("ErrorMessage.userNotFound"));
            } else if (error.code === "LimitExceededException") {
                setErrorMessage(t("ErrorMessage.limitExceed"));
            } else {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
            }
        } finally {
            setIsProcessing(false);
        }
    };

    /**
     * パスワード再設定のイベント処理
     * @param event
     */
    const handleResetPasword: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            if (password !== confirmPassword) {
                setErrorMessage(t("ErrorMessage.passwordMismatch"));
                setCode("");
                setPassword("");
                setConfirmPassword("");
                return;
            }
            await Auth.forgotPasswordSubmit(email, code, password);
            // ログイン画面へ
            navigate(SIGNIN_ROUTE);
        } catch (error: any) {
            loggingError("error password forgot", error);
            if (error.code === "CodeMismatchException") {
                setErrorMessage(t("ErrorMessage.codeMismatch"));
            } else if (error.code === "ExpiredCodeException") {
                setErrorMessage(t("ErrorMessage.codeExpired"));
            } else if (error.code === "UserNotFoundException") {
                setErrorMessage(t("ErrorMessage.userNotFound"));
            } else if (error.code === "InvalidPasswordException") {
                setErrorMessage(t("ErrorMessage.weakPassword"));
            } else if (error.code === "LimitExceededException") {
                setErrorMessage(t("ErrorMessage.limitExceed"));
            } else {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
            }
            setCode("");
            setPassword("");
            setConfirmPassword("");
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("ResetPassword.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <div className="w-full max-w-md rounded-lg bg-white shadow">
                    <div className="sp-4 space-y-4 p-8 md:space-y-4">
                        <h1 className="h1-common flex items-center justify-center">
                            {t("ResetPassword.resetPasswordTitle")}
                        </h1>

                        {!codePublished ? (
                            // 認証コード発行前のコンポーネント
                            <>
                                <p className="px-2 text-gray-700">
                                    <Trans i18nKey="ResetPassword.emailInputAskText"></Trans>
                                </p>
                                {/* エラーメッセージ */}
                                {errorMessage && (
                                    <div className="flex items-center justify-center">
                                        <CrossCircleIcon />
                                        <span className="pl-1 text-center font-bold text-red-600">
                                            {errorMessage.split("\n").map((line, index) => (
                                                <div key={index}>{line}</div>
                                            ))}
                                        </span>
                                    </div>
                                )}

                                <form onSubmit={handleIssueCode}>
                                    <div className="m-0 grid grid-cols-3 items-center justify-center pb-4 sm:m-2">
                                        {/* メールアドレス */}
                                        <div className="col-span-1 py-2 pr-2">
                                            <label htmlFor="email" className="block font-medium text-gray-900">
                                                {t("CommonLabel.email")}
                                            </label>
                                        </div>
                                        <div className="col-span-2 py-2">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="name@example.com"
                                                className="textbox-common"
                                                onChange={handleEmailChange}
                                                maxLength={EMAIL_MAX_LENGTH}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* 認証コードを発行 */}
                                    <div className="px-12 pb-4">
                                        <button
                                            type="submit"
                                            className={`${
                                                isProcessing ? "btn-primary-disabled" : "btn-primary"
                                            } w-full`}
                                            disabled={isProcessing}
                                        >
                                            {isProcessing ? (
                                                <div className="flex items-center justify-center">
                                                    <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                                    <label className="">Processing...</label>
                                                </div>
                                            ) : (
                                                t("ResetPassword.codeIssueButton")
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </>
                        ) : (
                            // 認証コード発行後のコンポーネント
                            <>
                                <p className="px-2 text-gray-800">
                                    <Trans i18nKey="ResetPassword.codeInputAskText" values={{ email: email }}></Trans>
                                </p>
                                {/* エラーメッセージ */}
                                {errorMessage && (
                                    <div className="flex items-center justify-center">
                                        <CrossCircleIcon />
                                        <span className="pl-1 text-center font-bold text-red-600">
                                            {errorMessage.split("\n").map((line, index) => (
                                                <div key={index}>{line}</div>
                                            ))}
                                        </span>
                                    </div>
                                )}

                                <form onSubmit={handleResetPasword}>
                                    <div className="m-0 grid grid-cols-3 items-center justify-center pb-4 sm:m-2">
                                        {/* 認証コード */}
                                        <div className="col-span-1 py-2">
                                            <label htmlFor="code" className="block font-medium text-gray-900">
                                                {t("CommonLabel.verificationCode")}
                                            </label>
                                        </div>
                                        <div className="col-span-2 py-2">
                                            <input
                                                type="text"
                                                name="code"
                                                id="code"
                                                placeholder={t("CommonLabel.verificationCodeExapmle")}
                                                value={code}
                                                className="textbox-common"
                                                onChange={handleCodeChange}
                                                maxLength={VERIFICATION_CODE_MAX_LENGTH}
                                                required
                                                autoComplete="off"
                                            />
                                        </div>

                                        {/* パスワード */}
                                        <div className="col-span-1 py-2">
                                            <label htmlFor="password" className="block font-medium text-gray-900">
                                                {t("CommonLabel.password")}
                                            </label>
                                        </div>
                                        <div className="relative col-span-2 py-2">
                                            <div className="relative flex items-center">
                                                <input
                                                    type={passwordType}
                                                    name="password"
                                                    id="password"
                                                    placeholder="password"
                                                    value={password}
                                                    className="textbox-common"
                                                    onChange={handlePasswordChange}
                                                    onFocus={togglePasswordRequirements}
                                                    onBlur={togglePasswordRequirements}
                                                    maxLength={PASSWORD_MAX_LENGTH}
                                                    required
                                                    autoComplete="new-password"
                                                />
                                                {passwordType === "password" && (
                                                    <VisibilityOffIcon
                                                        fontSize="small"
                                                        onClick={() => setPasswordType("text")}
                                                        className="Password__visual absolute right-2"
                                                    />
                                                )}
                                                {passwordType === "text" && (
                                                    <VisibilityIcon
                                                        fontSize="small"
                                                        onClick={() => setPasswordType("password")}
                                                        className="Password__visual absolute right-2"
                                                    />
                                                )}
                                                {showPasswordRequirements && (
                                                    // パスワード要件の吹き出し
                                                    <div className="absolute bottom-full left-0 rounded-md border bg-yellow-50 p-2 shadow-md">
                                                        <ul className="list-disc pl-5 text-sm text-gray-800">
                                                            <li>{t("CommonMessage.passwordPolicy1")}</li>
                                                            <li>{t("CommonMessage.passwordPolicy2")}</li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* パスワード（確認） */}
                                        <div className="col-span-1 py-2">
                                            <label
                                                htmlFor="confirmPassword"
                                                className="block font-medium text-gray-900"
                                            >
                                                {t("CommonLabel.passwordConfirm")}
                                            </label>
                                        </div>
                                        <div className="col-span-2 py-2">
                                            <div className="relative flex items-center">
                                                <input
                                                    type={confirmPasswordType}
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                    value={confirmPassword}
                                                    placeholder="password"
                                                    className="textbox-common"
                                                    onChange={handleConfirmPasswordChange}
                                                    maxLength={PASSWORD_MAX_LENGTH}
                                                    required
                                                    autoComplete="new-password"
                                                />
                                                {confirmPasswordType === "password" && (
                                                    <VisibilityOffIcon
                                                        fontSize="small"
                                                        onClick={() => setConfirmPasswordType("text")}
                                                        className="Password__visual absolute right-2"
                                                    />
                                                )}
                                                {confirmPasswordType === "text" && (
                                                    <VisibilityIcon
                                                        fontSize="small"
                                                        onClick={() => setConfirmPasswordType("password")}
                                                        className="Password__visual absolute right-2"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* パスワードを再設定 */}
                                    <div className="px-12 pb-4">
                                        <button
                                            type="submit"
                                            className={`${
                                                isProcessing ? "btn-primary-disabled" : "btn-primary"
                                            } w-full`}
                                            disabled={isProcessing}
                                        >
                                            {isProcessing ? (
                                                <div className="flex items-center justify-center">
                                                    <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                                    <label className="">Processing...</label>
                                                </div>
                                            ) : (
                                                t("ResetPassword.resetPasswordButton")
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                        {/* ログイン画面に戻る */}
                        <div className="px-12 pb-2">
                            <button onClick={handleBackToSigiin} className="btn-secondary w-full">
                                {t("ResetPassword.backButton")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ResetPassword;
