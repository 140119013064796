import { Auth } from "aws-amplify";
import { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { useUserContext } from "../contexts/UserContext";
import { HOME_ROUTE, SIGNUP_CONFIRM_ROUTE } from "../consts/routes";
import { CrossCircleIcon } from "../components/Icons";
import {
    EMAIL_MAX_LENGTH,
    FAMILY_NAME_MAX_LENGTH,
    GIVEN_NAME_MAX_LENGTH,
    ORGANIZATION_MAX_LENGTH,
    PASSWORD_MAX_LENGTH,
    USER_TYPE_LICENSED_ADMIN,
} from "../consts/constants";
import { ScrollToTop } from "../components/ScrollToTop";
import { loggingError } from "../utils/LoggingUtils";
import { Helmet } from "react-helmet";

/**
 * サインアップ画面
 * @returns JSX.Element
 */
const Signup = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { user } = useUserContext();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [familyName, setfamilyName] = useState<string>("");
    const [givenName, setGivenName] = useState<string>("");
    const [organization, setOrganization] = useState<string>("");
    const [termsChecked, setTermsChecked] = useState<boolean>(false);
    const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    // 認証情報が存在する場合はトップ画面へ遷移
    useEffect(() => {
        if (user) {
            navigate(HOME_ROUTE);
        }
    });

    /**
     * パスワード要件表示用トグル
     */
    const togglePasswordRequirements = () => {
        setShowPasswordRequirements(!showPasswordRequirements);
    };

    /**
     * メールアドレス入力イベント処理
     * @param event
     */
    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setEmail(event.target.value);
    };

    /**
     * パスワード入力イベント処理
     * @param event
     */
    const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setPassword(event.target.value);
    };

    /**
     * パスワード（確認用）入力イベント処理
     * @param event
     */
    const handleConfirmPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setConfirmPassword(event.target.value);
    };

    /**
     * 姓入力イベント処理
     * @param event
     */
    const handleFamilyNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setfamilyName(event.target.value);
    };

    /**
     * 名入力イベント処理
     * @param event
     */
    const handleGivenNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setGivenName(event.target.value);
    };

    /**
     * 所属入力イベント処理
     * @param event
     */
    const handleOrganizationChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setOrganization(event.target.value);
    };

    /**
     * 利用規約チェックイベント処理
     * @param event
     */
    const handleTermsCheckedChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setTermsChecked(event.target.checked);
    };

    /**
     * プライバシーポリシーチェックイベント処理
     * @param event
     */
    const handlePrivacyPolicyChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setPrivacyPolicyChecked(event.target.checked);
    };

    /**
     * 登録押下時のイベント処理
     * @param event
     */
    const handleSignup: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            if (password !== confirmPassword) {
                setErrorMessage(t("ErrorMessage.passwordMismatch"));
                return;
            }
            if (!termsChecked) {
                setErrorMessage(t("ErrorMessage.requiredAgreeTOS"));
                return;
            }
            if (!privacyPolicyChecked) {
                setErrorMessage(t("ErrorMessage.requiredAgreePrivacyPolicy"));
                return;
            }
            await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email,
                    family_name: familyName,
                    given_name: givenName,
                    "custom:organization": organization,
                    // ユーザータイプ=1（管理ユーザー）は固定
                    "custom:user_type": USER_TYPE_LICENSED_ADMIN.toString(),
                },
                autoSignIn: {
                    enabled: true,
                },
            });
            // メール認証画面へ
            navigate(SIGNUP_CONFIRM_ROUTE, { state: { email: email } });
        } catch (error: any) {
            loggingError("error siging up:", error);
            if (error.code === "UsernameExistsException") {
                setErrorMessage(t("ErrorMessage.emailInUse"));
            } else if (error.code === "InvalidPasswordException") {
                setErrorMessage(t("ErrorMessage.weakPassword"));
            } else {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
            }
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("Signup.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <div className="w-full max-w-md rounded-lg bg-white shadow">
                    <div className="sp-4 space-y-4 p-8 md:space-y-4">
                        <form className="" onSubmit={handleSignup}>
                            <h1 className="h1-common mb-2 flex items-center justify-center">
                                {t("Signup.signupTitle")}
                            </h1>
                            {/* エラーメッセージ */}
                            {errorMessage && (
                                <div className="flex items-center justify-center">
                                    <CrossCircleIcon />
                                    <span className="pl-1 text-center font-bold text-red-600">
                                        {errorMessage.split("\n").map((line, index) => (
                                            <div key={index}>{line}</div>
                                        ))}
                                    </span>
                                </div>
                            )}

                            <div className="m-0 grid grid-cols-3 items-center justify-center sm:m-2 sm:px-4">
                                {/* メールアドレス */}
                                <div className="col-span-1 py-2">
                                    <label htmlFor="email" className="block font-medium text-gray-900">
                                        {t("CommonLabel.email")}
                                    </label>
                                </div>
                                <div className="col-span-2 py-2">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="name@example.com"
                                        className="textbox-common"
                                        onChange={handleEmailChange}
                                        maxLength={EMAIL_MAX_LENGTH}
                                        required
                                    />
                                </div>

                                {/* パスワード */}
                                <div className="col-span-1 py-2">
                                    <label htmlFor="password" className="block font-medium text-gray-900">
                                        {t("CommonLabel.password")}
                                    </label>
                                </div>
                                <div className="relative col-span-2 py-2">
                                    <div className="relative flex items-center">
                                        <input
                                            type={passwordType}
                                            name="password"
                                            id="password"
                                            placeholder="password"
                                            className="textbox-common pr-10"
                                            onChange={handlePasswordChange}
                                            onFocus={togglePasswordRequirements}
                                            onBlur={togglePasswordRequirements}
                                            maxLength={PASSWORD_MAX_LENGTH}
                                            required
                                            autoComplete="new-password"
                                        />
                                        {passwordType === "password" && (
                                            <VisibilityOffIcon
                                                fontSize="small"
                                                onClick={() => setPasswordType("text")}
                                                className="Password__visual absolute right-2"
                                            />
                                        )}
                                        {passwordType === "text" && (
                                            <VisibilityIcon
                                                fontSize="small"
                                                onClick={() => setPasswordType("password")}
                                                className="Password__visual absolute right-2"
                                            />
                                        )}
                                        {showPasswordRequirements && (
                                            // パスワード要件の吹き出し
                                            <div className="absolute bottom-full left-0 rounded-md border bg-yellow-50 p-2 shadow-md">
                                                <ul className="list-disc pl-5 text-sm text-gray-800">
                                                    <li>{t("CommonMessage.passwordPolicy1")}</li>
                                                    <li>{t("CommonMessage.passwordPolicy2")}</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* パスワード（確認） */}
                                <div className="col-span-1 py-2">
                                    <label htmlFor="confirmPassword" className="block font-medium text-gray-900">
                                        {t("CommonLabel.passwordConfirm")}
                                    </label>
                                </div>
                                <div className="col-span-2 py-2">
                                    <div className="relative flex items-center">
                                        <input
                                            type={confirmPasswordType}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            placeholder="password"
                                            className="textbox-common"
                                            onChange={handleConfirmPasswordChange}
                                            maxLength={PASSWORD_MAX_LENGTH}
                                            required
                                            autoComplete="new-password"
                                        />
                                        {confirmPasswordType === "password" && (
                                            <VisibilityOffIcon
                                                fontSize="small"
                                                onClick={() => setConfirmPasswordType("text")}
                                                className="Password__visual absolute right-2"
                                            />
                                        )}
                                        {confirmPasswordType === "text" && (
                                            <VisibilityIcon
                                                fontSize="small"
                                                onClick={() => setConfirmPasswordType("password")}
                                                className="Password__visual absolute right-2"
                                            />
                                        )}
                                    </div>
                                </div>

                                {/* 氏名 */}
                                <div className="col-span-1 py-2">
                                    <p className="block font-medium text-gray-900">{t("CommonLabel.fullName")}</p>
                                </div>
                                <div className="col-span-2 flex flex-row items-center justify-center space-x-2 py-2">
                                    <div>
                                        <label htmlFor="lname" className="sr-only">
                                            {t("CommonLabel.familyName")}
                                        </label>
                                        <input
                                            type="text"
                                            name="lname"
                                            id="lname"
                                            placeholder={t("CommonLabel.familyName")}
                                            className="textbox-common"
                                            onChange={handleFamilyNameChange}
                                            maxLength={FAMILY_NAME_MAX_LENGTH}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="fname" className="sr-only">
                                            {t("CommonLabel.givenName")}
                                        </label>
                                        <input
                                            type="text"
                                            name="fname"
                                            id="fname"
                                            placeholder={t("CommonLabel.givenName")}
                                            className="textbox-common"
                                            onChange={handleGivenNameChange}
                                            maxLength={GIVEN_NAME_MAX_LENGTH}
                                            required
                                        />
                                    </div>
                                </div>

                                {/* 所属 */}
                                <div className="col-span-1 py-1">
                                    <label htmlFor="organization" className="block font-medium text-gray-900">
                                        <div className={i18n.language === "en" ? "text-sm" : ""}>
                                            {t("CommonLabel.organization")}
                                        </div>
                                    </label>
                                </div>
                                <div className="col-span-2 py-2">
                                    <input
                                        type="text"
                                        name="organization"
                                        id="organization"
                                        placeholder={t("CommonLabel.organizationExample")}
                                        className="textbox-common"
                                        onChange={handleOrganizationChange}
                                        maxLength={ORGANIZATION_MAX_LENGTH}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col items-start pb-4 text-gray-900 sm:px-12">
                                {/* 利用規約チェック */}
                                <div className="mt-2 flex flex-row items-center justify-center text-sm font-medium">
                                    <div className="pr-2">
                                        <input
                                            type="checkbox"
                                            id="terms"
                                            checked={termsChecked}
                                            className="h-4 w-4 rounded bg-gray-50 accent-lime-900"
                                            onChange={handleTermsCheckedChange}
                                        />
                                    </div>
                                    <label htmlFor="terms" className="">
                                        <Trans
                                            i18nKey={"Signup.termsOfUse"}
                                            components={{
                                                l: (
                                                    <a
                                                        href={
                                                            i18n.language === "ja"
                                                                ? process.env.REACT_APP_TERMS_OF_SERVICE_JA_PATH
                                                                : process.env.REACT_APP_TERMS_OF_SERVICE_EN_PATH
                                                        }
                                                        className="link-text"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    />
                                                ),
                                            }}
                                        />
                                    </label>
                                </div>

                                {/* プライバシーポリシーチェック */}
                                <div className="mt-2 flex flex-row items-center justify-center text-sm font-medium">
                                    <div className="pr-2">
                                        <input
                                            type="checkbox"
                                            id="privacyPolicy"
                                            checked={privacyPolicyChecked}
                                            className="h-4 w-4 rounded bg-gray-50 accent-lime-900"
                                            onChange={handlePrivacyPolicyChange}
                                        />
                                    </div>
                                    <label htmlFor="privacyPolicy" className="">
                                        <Trans
                                            i18nKey={"Signup.privacyPolicy"}
                                            components={{
                                                l: (
                                                    <a
                                                        href={
                                                            i18n.language === "ja"
                                                                ? process.env.REACT_APP_PRIVACY_POLICY_JA_PATH
                                                                : process.env.REACT_APP_PRIVACY_POLICY_EN_PATH
                                                        }
                                                        className="link-text"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    />
                                                ),
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* 登録ボタン */}
                            <div className="px-12 pb-4">
                                <button
                                    type="submit"
                                    className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-full`}
                                    disabled={isProcessing}
                                >
                                    {isProcessing ? (
                                        <div className="flex items-center justify-center">
                                            <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                            <label className="">Processing...</label>
                                        </div>
                                    ) : (
                                        t("Signup.registerButton")
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Signup;
