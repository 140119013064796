import { API } from "aws-amplify";
import { API_ENDPOINT_NAME } from "../consts/constants";
import { loggingInfo, loggingWarn } from "../utils/LoggingUtils";

/**
 * 商品情報取得処理
 * @param idToken IDトークン
 * @param productTypeId 商品タイプID
 * @param agencyId 代理店ID
 * @returns 商品情報一覧取得APIレスポンス
 */
export async function getProduct(idToken: string, productId: number) {
    const path = `/product/${productId}`;
    const request = {
        headers: {
            Authorization: idToken,
        },
    };
    try {
        const response = await API.get(API_ENDPOINT_NAME, path, request);
        loggingInfo("[GetProduct] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[GetProduct] Failed:", error);
        throw error;
    }
}

/**
 * 商品情報一覧取得処理
 * @param idToken IDトークン
 * @param productTypeId 商品タイプID
 * @param agencyId 代理店ID
 * @returns 商品情報一覧取得APIレスポンス
 */
export async function getProducts(idToken: string, productTypeId: number | null, agencyId: number | null) {
    const path = "/products";
    const body: { [name: string]: any } = {};
    if (productTypeId) {
        body["product_type_id"] = productTypeId;
    }
    if (agencyId !== null) {
        body["agency_id"] = agencyId;
    }
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[GetProducts] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[GetProducts] Failed:", error);
        throw error;
    }
}
