import ExcelJS from "exceljs";

/**
 * CSVファイルを出力
 *
 * @param headers ヘッダー情報
 * @param outputList ボディ情報
 * @param filename ファイル名
 */
export async function outputCsv(headers: any, outputList: any, filename: string) {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet("sheet1");
    const worksheet = workbook.getWorksheet("sheet1");

    if (worksheet) {
        worksheet.columns = headers;
        worksheet.addRows(outputList);
        const format = "csv";
        const uint8Array = await workbook.csv.writeBuffer();
        const blob = new Blob([uint8Array], { type: "application/octet-binary" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename + "." + format;
        a.click();
        a.remove();
    }
}

/**
 * Excelファイルを出力
 * @param headers ヘッダー情報
 * @param outputList ボディ情報
 * @param filename ファイル名
 */
export async function outputExcel(headers: any, outputList: any, filename: string) {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet("sheet1");
    const worksheet = workbook.getWorksheet("sheet1");

    if (worksheet) {
        worksheet.columns = headers;
        worksheet.addRows(outputList);
        const format = "xlsx";
        const uint8Array = await workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], { type: "application/octet-binary" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename + "." + format;
        a.click();
        a.remove();
    }
}
