import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import { ScrollToTop } from "../../components/ScrollToTop";

/**
 * プライバシーポリシー（英語）
 * @returns JSX.Element
 */
const PrivacyPolicyEn = () => {
    return (
        <div className="flex min-h-screen flex-col">
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <h1 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl ">PRIVACY POLICY</h1>
                    </div>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        DeepForest Technologies Co., Ltd. (hereinafter referred to as "the Company") establishes the
                        following privacy policy (hereinafter referred to as "this Policy") regarding the handling of
                        users' personal information in the services provided by the Company, including websites and
                        desktop applications (hereinafter collectively referred to as "the Services").
                    </div>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        1. Personal Information
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            "Personal Information" refers to the term "personal information" as defined in the Personal
                            Information Protection Law. It is information about a living individual that can identify a
                            specific individual based on the name, date of birth, address, telephone number, contact
                            information, and other descriptions included in the information, as well as data related to
                            physical features, fingerprints, voiceprints, and information that can identify a specific
                            individual from the information alone, such as the insurer number on a health insurance card
                            (personal identification information).
                        </li>
                        <li className="my-3">
                            Information on cookies and IP addresses alone cannot identify a specific individual, but
                            when these pieces of information are used in conjunction with personal information, they are
                            also treated as personal information.
                        </li>
                        <li className="my-3">
                            The Company collects the following personal data:
                            <ul className="ml-5 list-disc">
                                <li>Email address</li>
                                <li>Password</li>
                                <li>Name</li>
                                <li>Affiliation (company name, etc.)</li>
                                <li>Payment information</li>
                                <li>IP address and other online identifiers</li>
                                <li>Device information (computer name, SID)</li>
                                <li>Location information </li>
                                <li>Inquiries to the Company</li>
                            </ul>
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        2. Methods of Collecting Personal Information
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            Our company acquires user's personal data through the following methods:
                        </li>
                        <p className="mt-3">a. Direct acquisition from users</p>
                        <p>
                            We actively obtain information provided by users, such as when they register for services on
                            our website, make purchases of our products, or submit inquiries to our company.
                        </p>
                        <p className="mt-3">b. Automatic acquisition</p>
                        <p>
                            When there is access to our website, we may automatically collect certain online
                            identification information, including IP addresses. Additionally, during the use of our
                            products, we may acquire device information from the user's access point (computer name,
                            SID).
                        </p>
                        <p className="mt-3">c. Acquisition from business partners</p>
                        <p>
                            We may collect transaction records and information related to transactions, including user's
                            personal information, from our business partners (referred to as "partners," including
                            information providers, advertisers, and ad distributors) in transactions conducted between
                            users and our partners.
                        </p>
                        <li className="my-3">
                            Our company collects personal information when customers engage in the following activities:
                        </li>
                        <ul className="ml-5 list-disc">
                            <li>Browsing or using our company website</li>
                            <li>Registering for user accounts on our company website</li>
                            <li>Purchasing our company's products</li>
                            <li>Utilizing our company's products, including desktop applications</li>
                            <li>Making inquiries to our company</li>
                        </ul>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        3. Purposes of Collecting and Using Personal Information
                    </h2>
                    <p>The purposes for which the Company collects and uses personal information are as follows:</p>
                    <ul className="ml-5 list-disc">
                        <li>To provide and operate our services</li>
                        <li>To respond to inquiries from users (including identity verification)</li>
                        <li>
                            To send emails about new features, updates, campaigns, and other services provided by the
                            Company
                        </li>
                        <li>For necessary communication, such as maintenance and important notices</li>
                        <li>
                            To identify users who violate the terms of use or attempt to use the service for fraudulent
                            or unjust purposes, and to refuse their use
                        </li>
                        <li>
                            To allow users to view, modify, delete their registration information, and to view their
                            usage status
                        </li>
                        <li>To charge users for the use of paid services</li>
                        <li>For purposes related to the above uses</li>
                        <li>For the purpose of providing to third parties as described in this Policy</li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        4. Use of LocalStorage
                    </h2>
                    <p>
                        Our company website use LocalStorage for session management of logged-in users. LocalStorage is
                        a mechanism for saving user data on the browser while visiting a website, allowing users to
                        avoid entering their password each time. If LocalStorage is disabled through browser settings
                        changes, some functions of our services may not work properly.
                    </p>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        5. Retention Period of Personal Information
                    </h2>
                    <p>
                        The Company will retain users' personal information only for the period necessary to achieve the
                        purposes of collection and processing of personal information mentioned above. However, this
                        excludes cases where the law mandates the retention of personal information for a longer period.
                    </p>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        6. Change of Purpose of Use
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            The Company shall change the purpose of use of personal information only if it is reasonably
                            deemed that the new purpose is related to the original one.
                        </li>
                        <li className="my-3">
                            In the event of a change in the purpose of use, the Company will notify users of the changed
                            purpose by the Company's prescribed method or publish it on this website.
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        7. Provision of Personal Information to Third Parties
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            The Company will not provide personal information to third parties without obtaining the
                            user's prior consent, except in the following cases. However, this does not apply to cases
                            permitted under the Personal Information Protection Law or other laws and regulations.
                            <ul className="ml-5 list-disc">
                                <li>
                                    When it is necessary to protect the life, body, or property of a person and it is
                                    difficult to obtain the consent of the person.
                                </li>
                                <li>
                                    When it is particularly necessary for the improvement of public health or the
                                    promotion of the sound growth of children and it is difficult to obtain the consent
                                    of the person.
                                </li>
                                <li>
                                    When it is necessary to cooperate with a national agency or local public entity or a
                                    person entrusted by them in executing the affairs prescribed by laws and
                                    regulations, and obtaining the person's consent would impede the execution of the
                                    affairs.
                                </li>
                                <li>
                                    When the following matters have been notified or announced in advance, and the
                                    Company has filed a notification with the Personal Information Protection
                                    Commission:
                                    <ul className="">
                                        <li>
                                            - Name or title and address of the personal information handler who provides
                                            the information to third parties, and the name of their representative.
                                        </li>
                                        <li>- Inclusion of the provision to third parties in the purpose of use.</li>
                                        <li>- Items of data provided to third parties.</li>
                                        <li>- Method of acquisition of the data provided to third parties.</li>
                                        <li>- Means or method of provision to third parties.</li>
                                        <li>
                                            - Suspension of provision of personal information to third parties upon
                                            request of the person.
                                        </li>
                                        <li>- Method of accepting requests from the person.</li>
                                        <li>- Method of updating the data provided to third parties.</li>
                                        <li>
                                            - Scheduled start date of the provision of data to third parties in relation
                                            to the optout notification.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="my-3">
                            Notwithstanding the provisions of the preceding paragraph, in the following cases, the
                            recipients of the information shall not be considered third parties:
                            <ul className="ml-5 list-disc">
                                <li>
                                    When the Company entrusts all or part of the handling of personal information within
                                    the scope necessary to achieve the purpose of use.
                                </li>
                                <li>
                                    When personal information is provided in conjunction with business succession due to
                                    merger or other reasons.
                                </li>
                                <li>
                                    When personal information is used jointly with specific persons, and this fact, as
                                    well as the items of personal information to be jointly used, the scope of the
                                    persons who will jointly use the information, the purpose of use of those who use
                                    the information, and the name or title of the person responsible for managing the
                                    personal information, have been notified to the person in advance or made readily
                                    available to the person.
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        8. Privacy of Children
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            The Services are intended for general customers. If children provide personal information
                            while using the Services, we ask that it be done with the consent of a guardian. Users
                            represent and warrant that they have the legal capacity and capability to use the Services
                            or, if the user is a child, that they use the Services under the consent of a guardian. If
                            it is believed that a child's personal information has been provided without the consent of
                            a guardian, please contact the inquiry desk (14. Inquiry Contact).
                        </li>
                        <li className="my-3">
                            The Company recognizes the necessity for higher protection of privacy for personal
                            information obtained from users under the age of 16 (or the age related to minors under GDPR
                            or similar legislation in various countries) and does not intentionally collect personal
                            information from children. If the Company inadvertently collects personal information from a
                            child, it will take reasonable measures to disable the related account and promptly delete
                            the relevant personal information from its records.
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        9. Safety Management Measures
                    </h2>
                    <p>
                        The Company shall take necessary and appropriate measures to prevent the leakage, loss, or
                        damage of personal information it handles and to ensure the security management of personal
                        information. For details about the safety management measures taken by the Company, please
                        contact the inquiry desk (14. Inquiry Contact).
                    </p>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        10. Disclosure of Personal Information
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            When the Company is requested by the individual to disclose or notify the purpose of use of
                            personal information or third-party provision records, it will disclose or notify these
                            without delay after verifying that the request is made by the individual themselves.
                            However, the Company may decide not to disclose all or part of the information if disclosure
                            falls under any of the following, and will notify the individual of such a decision without
                            delay. A fee of 1,000 yen per case will be charged for the disclosure of personal
                            information.
                            <ul className="ml-5 list-disc">
                                <li>
                                    If there is a risk of harm to the life, body, property, or other rights and
                                    interests of the individual or a third party.
                                </li>
                                <li>
                                    If there is a significant impediment to the proper conduct of the Company's
                                    business.
                                </li>
                                <li>If it would violate other laws and regulations.</li>
                            </ul>
                        </li>
                        <li className="my-3">
                            Notwithstanding the provisions of the preceding paragraph, information other than personal
                            information, such as history information and characteristic information, will not be
                            disclosed in principle.
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        11. Correction and Deletion of Personal Information
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            Users can request the Company to correct, add to, or delete (hereinafter referred to as
                            "correction, etc.") their personal information if it is incorrect, through the procedures
                            established by the Company.
                        </li>
                        <li className="my-3">
                            The Company will make the necessary corrections, etc. to the personal information without
                            delay if it deems it necessary to comply with a request from a user as mentioned in the
                            preceding paragraph.
                        </li>
                        <li className="my-3">
                            The Company will notify the user without delay when it has made a correction, etc., based on
                            the provisions of the preceding paragraph, or when it has decided not to make such
                            correction, etc.
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        12. Suspension of Use, etc., of Personal Information
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            If the Company is requested by the individual to suspend or erase (hereinafter referred to
                            as "suspension, etc.") the use of personal information on the grounds that it is being
                            handled beyond the scope of the purpose of use or that it was obtained by wrongful means, it
                            will conduct the necessary investigation without delay.
                        </li>
                        <li className="my-3">
                            Based on the results of the investigation in the preceding paragraph, if the Company deems
                            it necessary to comply with the request, it will suspend the use of the personal information
                            without delay.
                        </li>
                        <li className="my-3">
                            The Company will notify the user without delay when it has performed suspension, etc., based
                            on the provisions of the preceding paragraph, or when it has decided not to perform such
                            suspension, etc.
                        </li>
                        <li className="my-3">
                            Notwithstanding the preceding two paragraphs, if suspension, etc., involves a large expense
                            or is difficult to perform, and if alternative measures can be taken to protect the rights
                            and interests of the user, such alternative measures will be taken.
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        13. Changes to the Privacy Policy
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            The content of this Policy can be changed without notifying users, except for matters
                            stipulated separately in laws and regulations or in this Policy.
                        </li>
                        <li className="my-3">
                            Unless otherwise specified by the Company, the revised Privacy Policy will take effect from
                            the time it is posted on this website.
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        14. Inquiry Contact
                    </h2>
                    <p className="my-3">For inquiries regarding this Policy, please contact the following:</p>
                    <ul className="ml-5">
                        <li>
                            Address: International Science Innovation Building, Kyoto University 36-1, Yoshida-Honmachi,
                            Sakyo-ku, Kyoto 606-8501, JAPAN
                        </li>
                        <li>Company Name: DeepForest Technologies Co., Ltd.</li>
                        <li>Personal Information Handling Officer: Kengo Ikebata</li>
                        <li>Email Address: mail@deepforest-tech.co.jp</li>
                    </ul>

                    <p className="mb-2 mt-8">Last updated: December 8, 2023</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicyEn;
