import { API } from "aws-amplify";
import { API_ENDPOINT_NAME } from "../consts/constants";
import { loggingInfo, loggingWarn } from "../utils/LoggingUtils";

type SendPurchaseInquiryEmailRequest = {
    name?: string;
    company?: string;
    email?: string;
    purpose?: string;
    software?: string;
    licence?: string;
    number?: number;
    other?: string;
    known_from?: string;
};

/**
 * ライセンス購入お問い合わせメール送信
 *
 * @param requestBody リクエストボディ
 * @returns ライセンス購入お問い合わせメール送信レスポンス
 */
export async function sendPurchaseInquiryEmail(requestBody?: SendPurchaseInquiryEmailRequest) {
    const path = `/send_purchase_inquiry_email`;
    const request = {
        body: requestBody,
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[SendPurchaseInquiryEmail] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[SendPurchaseInquiryEmail] Failed:", error);
        throw error;
    }
}
