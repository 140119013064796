import { Auth } from "aws-amplify";
import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { loggingInfo, loggingWarn } from "../utils/LoggingUtils";

/**
 * 商品一覧画面で選択した商品情報の型定義
 */
interface SelectedProductType {
    id: number;
    isFreeTrial: boolean;
}

/**
 * UserContextで管理するパラメータの型定義
 */
interface UserContextType {
    // Cognitoから発行されるユーザー情報
    user: any | null;
    setUser: React.Dispatch<React.SetStateAction<any | null>>;

    // 仮認証状態のユーザー情報
    // * 他者発行のユーザーで初めてログインしたときに取得されるユーザーオブジェクト。
    // * パスワード再設定時に使用する。
    challengeUser: any | null;
    setChallengeUser: React.Dispatch<React.SetStateAction<any | null>>;

    // DBのユーザー情報キャッシュ
    dbUserCashe: any | null;
    setDbUserCashe: React.Dispatch<React.SetStateAction<any | null>>;

    // ユーザータイプ
    userType: number | null;

    // 商品一覧画面で選択した商品情報
    selectedProductType: SelectedProductType | null;
    setSelectedProductType: React.Dispatch<React.SetStateAction<SelectedProductType | null>>;

    // ユーザー情報確認中フラグ
    isLoading: boolean;

    // 最新のIDトークン取得用関数
    getRecentToken: () => Promise<string>;

    // ログアウト処理関数
    logout: () => void;
}

/** UserContex */
const UserContext = createContext<UserContextType | undefined>(undefined);

/**
 * UserContext取得用カスタムフック
 * @returns UserContext
 */
export function useUserContext() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUserContext must be used within a UserContextProvider");
    }
    return context;
}

/**
 * UserContextプロバイダー
 * @param children
 * @returns UserContextProvider
 */
export function UserContextProvider({ children }: { children: ReactNode }) {
    const [user, setUser] = useState<any | null>(null);
    const [challengeUser, setChallengeUser] = useState<any | null>(null);
    const [userType, setUserType] = useState<number | null>(null);
    const [dbUserCashe, setDbUserCashe] = useState<any | null>(null);
    const [selectedProductType, setSelectedProductType] = useState<SelectedProductType | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const contextValue = {
        user,
        setUser,
        challengeUser,
        dbUserCashe,
        setDbUserCashe,
        userType,
        setChallengeUser,
        selectedProductType,
        setSelectedProductType,
        isLoading,
        getRecentToken,
        logout,
    };

    // ページの読み込み時にログイン状態を確認してコンテキストに設定し直す
    useEffect(() => {
        const checkSession = async () => {
            try {
                const userData = await Auth.currentAuthenticatedUser();
                // セッション（ユーザー情報）が取得できた場合はコンテキストにセット
                setUser(userData);
                setUserType(Number(userData?.attributes["custom:user_type"]) ?? null);
            } catch (error) {
                // エラーの場合はログアウト処理を実行
                loggingInfo("Fail to get session: ", error);
                logout();
            } finally {
                setIsLoading(false);
            }
        };
        checkSession();
    }, []);

    // ユーザー情報からユーザータイプを抜き出してState管理
    useEffect(() => {
        setUserType(Number(user?.attributes["custom:user_type"]) ?? null);
    }, [user]);

    /**
     * 最新のIDトークンを取得するための関数
     *
     * IDトークンの有効期限が切れている場合、自動でリフレッシュを実行して有効なIDトークンを返す
     * @returns IDトークン
     */
    async function getRecentToken() {
        try {
            const userData = await Auth.currentAuthenticatedUser();
            return userData.signInUserSession.idToken.jwtToken;
        } catch (error) {
            loggingInfo("Fail to get idtoken: ", error);
            return null;
        }
    }

    /**
     * ログアウト処理
     */
    async function logout() {
        try {
            await Auth.signOut();
            setUser(null);
            setChallengeUser(null);
            setUserType(null);
            setDbUserCashe(null);
            setSelectedProductType(null);
        } catch (error) {
            loggingWarn("Logout error:", error);
        }
    }

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}
