import { Auth } from "aws-amplify";
import { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import DFNavbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useUserContext } from "../contexts/UserContext";
import { CrossCircleIcon } from "../components/Icons";
import {
    COMPLETE_PASSWORD_ROUTE,
    HOME_ROUTE,
    MYPAGE_ROUTE,
    PRODUCT_CONFIRM_ROUTE,
    PRODUCT_CONFIRM_TRIAL_ROUTE,
    RESER_PASSWORD_ROUTE,
    SIGNUP_CONFIRM_ROUTE,
    SIGNUP_ROUTE,
} from "../consts/routes";
import { EMAIL_MAX_LENGTH, PASSWORD_MAX_LENGTH } from "../consts/constants";
import { reflectUserInfo } from "../clients/UserClient";
import MailVerificationModal from "./modals/MailVerificationModal";
import { ScrollToTop } from "../components/ScrollToTop";
import { loggingError } from "../utils/LoggingUtils";
import { Helmet } from "react-helmet";

/**
 * ログイン画面
 * @returns JSX.Element
 */
const Signin = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordType, setPasswordType] = useState("password");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { user, setUser, setChallengeUser, selectedProductType, setDbUserCashe } = useUserContext();
    // メール認証要求モーダル関連
    const [showRequiredConfirmModal, setShowRequiredConfirmModal] = useState<boolean>(false);
    const [errorMessageForRequiredConfirm, setErrorMessageForRequiredConfirm] = useState<string>("");

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    // 認証情報が存在する場合はトップ画面へ遷移
    useEffect(() => {
        if (user) {
            navigate(HOME_ROUTE);
        }
    });

    /**
     * メールアドレス入力イベント処理
     * @param target
     */
    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setEmail(target.value);
    };

    /**
     * パスワード入力イベント処理
     * @param target
     */
    const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setPassword(target.value);
    };

    /**
     * ログイン押下時のイベント処理
     * @param event
     */
    const handleLogin: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            const user = await Auth.signIn(email, password);

            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                // ユーザーのステータスが「パスワード再設定が必要」であればパスワード確定画面へ
                // 仮認証情報をコンテキストに保存
                setChallengeUser(user);
                navigate(COMPLETE_PASSWORD_ROUTE);
            } else {
                // DBのユーザー情報確認
                // ※サインアップ時にDBへのユーザー登録が失敗するケースがあるためログイン時にデータチェックをし、
                // ユーザー情報が未登録であれば登録を行う
                reflectUserInfo(setDbUserCashe);

                // 認証情報をコンテキストに保存
                setUser(user);
                if (selectedProductType && selectedProductType.isFreeTrial) {
                    // コンテキストに商品タイプIDがある、かつ、無料体験フラグtrue
                    navigate(PRODUCT_CONFIRM_TRIAL_ROUTE);
                } else if (selectedProductType) {
                    // コンテキストに商品タイプIDがある、かつ、無料体験フラグfalse
                    navigate(PRODUCT_CONFIRM_ROUTE);
                } else {
                    // それ以外はマイページへ
                    navigate(MYPAGE_ROUTE);
                }
            }
        } catch (error: any) {
            loggingError("error signing in", error);
            if (error.code === "UserNotFoundException" || error.code === "NotAuthorizedException") {
                setErrorMessage(t("ErrorMessage.emailOrPasswordInvalid"));
            } else if (error.code === "UserNotConfirmedException") {
                // メール認証がされていない場合
                setShowRequiredConfirmModal(true);
            } else {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
            }
        } finally {
            setIsProcessing(false);
        }
    };

    /**
     * [メール認証要求モーダル]
     * 送信押下時のイベント処理
     */
    const handleSending = async () => {
        setErrorMessageForRequiredConfirm("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            await Auth.resendSignUp(email);
            // メール認証画面へ
            navigate(SIGNUP_CONFIRM_ROUTE, { state: { email: email } });
        } catch (error: any) {
            if (error.code === "UserNotFoundException") {
                setErrorMessageForRequiredConfirm(t("ErrorMessage.userNotFoundAndNeedRecreate"));
            } else {
                setErrorMessageForRequiredConfirm(t("ErrorMessage.systemErrorMessage"));
            }
        } finally {
            setIsProcessing(false);
        }
    };

    /**
     * [メール認証要求モーダル]
     * キャンセル押下時のイベント処理
     */
    const handleCancelSending = () => {
        setShowRequiredConfirmModal(false);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("Signin.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <div className="w-full rounded-lg bg-white shadow sm:max-w-md">
                    <div className="space-y-4 p-4 sm:p-8 md:space-y-4">
                        <h1 className="h1-common">{t("Signin.signinTitle")}</h1>
                        {/* エラーメッセージ */}
                        {errorMessage && (
                            <div className="flex items-center justify-center">
                                <CrossCircleIcon />
                                <span className="pl-1 text-center font-bold text-red-600">
                                    {errorMessage.split("\n").map((line, index) => (
                                        <div key={index}>{line}</div>
                                    ))}
                                </span>
                            </div>
                        )}

                        <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
                            {/* メールアドレス */}
                            <div>
                                <label htmlFor="email" className="mb-2 block text-sm font-medium text-gray-900">
                                    {t("CommonLabel.email")}
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={email}
                                    className="textbox-common"
                                    placeholder="name@example.com"
                                    onChange={handleEmailChange}
                                    maxLength={EMAIL_MAX_LENGTH}
                                    required
                                />
                            </div>

                            {/* パスワード */}
                            <div>
                                <label htmlFor="password" className="mb-2 block text-sm font-medium text-gray-900">
                                    {t("CommonLabel.password")}
                                </label>
                                <div className="relative flex items-center">
                                    <input
                                        type={passwordType}
                                        name="password"
                                        id="password"
                                        placeholder="password"
                                        className="textbox-common pr-10"
                                        onChange={handlePasswordChange}
                                        maxLength={PASSWORD_MAX_LENGTH}
                                        required
                                    />
                                    {passwordType === "password" && (
                                        <VisibilityOffIcon
                                            fontSize="small"
                                            onClick={() => setPasswordType("text")}
                                            className="Password__visual absolute right-2"
                                        />
                                    )}
                                    {passwordType === "text" && (
                                        <VisibilityIcon
                                            fontSize="small"
                                            onClick={() => setPasswordType("password")}
                                            className="Password__visual absolute right-2"
                                        />
                                    )}
                                </div>
                            </div>

                            {/* ログインボタン */}
                            <button
                                id="singin-btn"
                                type="submit"
                                className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-full`}
                                disabled={isProcessing}
                            >
                                {isProcessing ? (
                                    <div className="flex items-center justify-center">
                                        <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                        <label className="">Processing...</label>
                                    </div>
                                ) : (
                                    t("Signin.signinButton")
                                )}
                            </button>

                            {/* パスワードを忘れた場合 */}
                            <Link to={RESER_PASSWORD_ROUTE}>
                                <p className="mt-2 text-sm font-light text-gray-500 hover:text-lime-800 hover:underline active:font-normal active:text-lime-950">
                                    {t("Signin.forgetPasswordLink")}
                                </p>
                            </Link>
                        </form>
                    </div>
                </div>

                {/* アカウントの作成 */}
                <div className="inline-flex w-full items-center justify-center">
                    <hr className="my-8 h-px w-64 border-0 bg-gray-200" />
                    <span className="absolute left-1/2 -translate-x-1/2 bg-white px-3 font-medium text-gray-900">
                        {t("Signin.newUserLabel")}
                    </span>
                </div>
                <div className="px-4 sm:px-8">
                    <Link to={SIGNUP_ROUTE}>
                        <button className="btn-white w-full">{t("Signin.signupButton")}</button>
                    </Link>
                </div>
            </div>
            <MailVerificationModal
                showModal={showRequiredConfirmModal}
                email={email}
                handleCancel={handleCancelSending}
                handleSending={handleSending}
                isProcessing={isProcessing}
                errorMessage={errorMessageForRequiredConfirm}
            />
            <Footer />
        </div>
    );
};

export default Signin;
