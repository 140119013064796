import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { PRODUCT_SELECT_ROUTE, PRODUCT_COMPLETE_ROUTE } from "../consts/routes";
import { useUserContext } from "../contexts/UserContext";
import { getProducts } from "../clients/ProductClient";
import { AGENCY_ID_DF, JPY, USD } from "../consts/constants";
import { getUser } from "../clients/UserClient";
import { getPaymentLinkUrl } from "../clients/PaymentClient";
import { CrossCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import { Product } from "../types/Product";
import { E400, E401 } from "../consts/ErrorCodes";
import { getDfLatNum, getDfScannerNum, getStripePriceId } from "../utils/ProductUtils";
import { Helmet } from "react-helmet";

/**
 * ライセンス購入確認画面（無料体験版用）
 * @returns JSX.Element
 */
const ProductConfirmTrial = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { user, selectedProductType, dbUserCashe, setDbUserCashe, getRecentToken } = useUserContext();
    const [productList, setProductList] = useState<Product[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [selectedLicenseTypeId, setSelectedLicenseTypeId] = useState<number | null>(null);
    const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null);
    const [dfscannerNum, setDfScannerNum] = useState<number>(0);
    const [dflatNum, setDflatNum] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * 商品情報の取得処理
     * @returns
     */
    async function fetchProducts() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            if (selectedProductType) {
                // [API]商品情報一覧取得
                const response: Product[] = await getProducts(idToken, selectedProductType.id, AGENCY_ID_DF);
                setProductList(response);

                // 無料体験の商品情報を特定
                for (const product of response) {
                    if (selectedProductType.isFreeTrial && product.free_trial_flg) {
                        setSelectedLicenseTypeId(product.license_type_id);
                        setSelectedQuantity(product.quantity);
                    }
                }
            }
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!selectedProductType) {
            // コンテキストに商品タイプ情報がなければ選択画面へ戻る
            navigate(PRODUCT_SELECT_ROUTE);
        }
        if (!user) {
            // ページリロード時はコンテキストも初期化されるため、空の場合は一度リターンする
            // コンテキストが生成されユーザー情報が生成された時点で再実行する
            return;
        }
        if (!effectRun.current) {
            // コンテキストの商品タイプIDを元に選択可能な商品一覧を取得
            fetchProducts();
            return () => {
                effectRun.current = true;
            };
        }
    }, [selectedProductType, user, fetchProducts, navigate]);

    // ライセンスタイプ、購入数選択時に商品を特定するための処理
    useEffect(() => {
        setSelectedProduct(
            productList.find(
                (product) => product.license_type_id === selectedLicenseTypeId && product.quantity === selectedQuantity,
            ) ?? null,
        );
    }, [selectedLicenseTypeId, selectedQuantity, productList]);

    // ライセンスキー発行数の自動計算
    useEffect(() => {
        if (selectedProduct) {
            setDfScannerNum(getDfScannerNum(selectedProduct));
            setDflatNum(getDfLatNum(selectedProduct));
        }
    }, [selectedProduct]);

    /**
     * 商品選択画面へ戻る押下時のイベント処理
     */
    const handleBackToSelection = () => {
        navigate(PRODUCT_SELECT_ROUTE);
    };

    /**
     * 決済画面へ押下時のイベント処理
     */
    const handleForwardToPayment = async () => {
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            if (!selectedLicenseTypeId) {
                setErrorMessage(t("ErrorMessage.licenseTypeNotSelected"));
                return;
            }
            if (!selectedQuantity) {
                setErrorMessage(t("ErrorMessage.quantityNotSelected"));
                return;
            }
            if (!selectedProduct) {
                setErrorMessage(t("ErrorMessage.productNotSelected"));
                return;
            }
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }

            // [API]ユーザー情報の取得
            let stripeCustomerId;
            try {
                if (dbUserCashe) {
                    stripeCustomerId = dbUserCashe.stripe_customer_id;
                } else {
                    const userResponse = await getUser(idToken, user.attributes.sub);
                    setDbUserCashe(userResponse);
                    stripeCustomerId = userResponse.stripe_customer_id;
                }
            } catch (error: any) {
                setErrorMessage(t("ErrorMessage.userNotFoundAndAbort"));
                return;
            }

            // Stripe価格IDの特定
            const selectedCurrency = i18n.language === "ja" ? JPY : USD;
            let stripePriceId = getStripePriceId(selectedProduct, selectedCurrency);
            if (!stripePriceId) {
                setErrorMessage(t("ErrorMessage.productNotFoundAndAbort"));
                return;
            }

            // 戻り先URLの決定
            const successUrl = window.location.origin + PRODUCT_COMPLETE_ROUTE;
            const canselUrl = window.location.origin + PRODUCT_SELECT_ROUTE;

            let paymentLink;
            const selectedDate = new Date();
            try {
                // [API]決済ページURL取得処理
                const paymentResponse = await getPaymentLinkUrl(
                    idToken,
                    stripeCustomerId,
                    stripePriceId,
                    1, // 現状は1固定
                    selectedProduct.product_id,
                    successUrl,
                    canselUrl,
                    selectedDate.toUTCString(),
                );
                paymentLink = paymentResponse.payment_link_url;
            } catch (error: any) {
                if (error.response && error.response.data && error.response.data.code) {
                    const errorCode = error.response.data.code;
                    if (errorCode === E400) {
                        setErrorMessage(t("ErrorMessage.freeTrialAlreadyUsed"));
                    } else if (errorCode === E401) {
                        setErrorMessage(t("ErrorMessage.freeTrialOnePerUser"));
                    } else {
                        setErrorMessage(t("ErrorMessage.failToConnectPayment"));
                    }
                } else {
                    setErrorMessage(t("ErrorMessage.failToConnectPayment"));
                }
                return;
            }

            // Stripeページへ移動
            window.location.replace(paymentLink);
        } finally {
            setIsProcessing(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("ProductConfirmForTrial.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto max-w-3xl flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("ProductConfirmForTrial.productConfirmTitle")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center p-2">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="p-2 font-bold text-lime-800">Loading...</p>
                    </div>
                ) : (
                    <>
                        {/* 購入内容表示パネル */}
                        <div className="m-0 rounded-lg border border-gray-200 bg-yellow-50 p-2 pb-4 shadow sm:m-2">
                            <p className="mx-auto py-2 text-center text-lg font-bold">
                                {t("ProductConfirmForTrial.purchaseFollowing")}
                            </p>
                            {/* 商品名 */}
                            <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                                <span className="">{t("CommonLabel.productName")}:&nbsp;&nbsp;&nbsp;</span>
                                <div id="product-name" className="">
                                    {selectedProduct
                                        ? i18n.language === "ja"
                                            ? selectedProduct.product_name_jp
                                            : selectedProduct.product_name_en
                                        : t("CommonLabel.unselected")}
                                </div>
                            </div>

                            {/* ライセンス発行数 */}
                            {selectedProduct && dfscannerNum > 0 ? (
                                <div id="dfscanner-num" className="flex items-center px-10 font-bold text-gray-600">
                                    &nbsp;&nbsp;*
                                    <Trans
                                        i18nKey={"ProductConfirmForTrial.issuedLicensesNum"}
                                        values={{ productName: "DF Scanner", licenseNum: dfscannerNum }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {selectedProduct && dflatNum > 0 ? (
                                <div id="dflat-num" className="flex items-center px-10 font-bold text-gray-600">
                                    &nbsp;&nbsp;*
                                    <Trans
                                        i18nKey={"ProductConfirmForTrial.issuedLicensesNum"}
                                        values={{ productName: "DF LAT", licenseNum: dflatNum }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}

                            {/* 有効期間 */}
                            <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                                <span className="">{t("CommonLabel.validityPeriod")}:&nbsp;&nbsp;&nbsp;</span>
                                <div className="">{t("ProductConfirmForTrial.validityPeriodText")}</div>
                            </div>

                            <hr className="mx-8 my-2 border border-gray-300" />

                            {/* 合計金額 */}
                            <div className="flex items-center pl-10 pt-2 text-lg font-bold text-orange-600">
                                <span className="">{t("CommonLabel.totalAmount")}:&nbsp;</span>
                                <span>{t("CommonLabel.free")}</span>
                            </div>
                        </div>

                        {/* 購入前注意事項 */}
                        <p className="list-disc px-10 pt-7 text-center text-xl font-bold text-red-600">
                            {t("ProductConfirmForTrial.checkBeforePurchasingTitle")}
                        </p>
                        <ul className="list-disc px-10 pb-10 pt-2 text-gray-800">
                            <li>{t("ProductConfirmForTrial.checkBeforePurchasingText1")}</li>
                            <li>{t("ProductConfirmForTrial.checkBeforePurchasingText2")}</li>
                            <li>{t("ProductConfirmForTrial.checkBeforePurchasingText3")}</li>
                        </ul>

                        {/* 戻る/進むボタン */}
                        <div className="flex justify-evenly pb-5 pt-2 text-sm font-medium">
                            <button onClick={handleBackToSelection} className="btn-secondary w-1/3">
                                {t("ProductConfirmForTrial.backToProductSelect")}
                            </button>
                            <button
                                id="payment-btn"
                                onClick={handleForwardToPayment}
                                className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                                disabled={isProcessing}
                            >
                                {isProcessing ? (
                                    <div className="flex items-center justify-center">
                                        <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                        <label className="">Processing...</label>
                                    </div>
                                ) : (
                                    t("ProductConfirmForTrial.goToPaymentPage")
                                )}
                            </button>
                        </div>
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default ProductConfirmTrial;
