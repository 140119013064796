import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { MYPAGE_ROUTE } from "../consts/routes";
import { Link } from "react-router-dom";

/**
 * 共通権限無しエラー画面
 * @returns
 */
const CommonUnauthorized = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-5">{t("CommonUnauthorized.commonUnauthorizedTitle")}</h1>
                <ul className="mx-auto ml-10 list-disc text-gray-700">
                    <li>{t("CommonUnauthorized.commonUnauthorizedText1")}</li>
                    <li>{t("CommonUnauthorized.commonUnauthorizedText2")}</li>
                </ul>

                {/* マイページへ戻る */}
                <div className="flex justify-center pt-10">
                    <Link to={MYPAGE_ROUTE}>
                        <button className="btn-secondary px-5">{t("CommonUnauthorized.backToMyPage")}</button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default CommonUnauthorized;
