import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { USER_TYPE_LICENSED_USER } from "../consts/constants";
import {
    PRODUCT_CONFIRM_UPDATE_REQUEST_ROUTE,
    PRODUCT_CONFIRM_UPDATE_ROUTE,
    USER_LICENSE_REFERENCE_ROUTE,
    USER_LICENSE_ROUTE,
} from "../consts/routes";
import { CrossCircleIcon } from "../components/Icons";
import { useUserContext } from "../contexts/UserContext";
import { ScrollToTop } from "../components/ScrollToTop";
import { License } from "../types/License";
import { formatUtcToLocalDateTime } from "../utils/DateUtils";
import { getPaymentHistory } from "../clients/PaymentHistoryClient";
import { PaymentHistory } from "../types/PaymentHistory";
import LicenseUpdateConfirmModal from "./modals/LicenseUpdateConfirmModal";
import i18n from "../i18n/configs";
import { getActivationStatusName } from "../utils/LicenseUtils";
import { Helmet } from "react-helmet";

interface LocationState {
    license: License;
    hasOfflineActivationInPaymentGroup: boolean;
}

/**
 * ライセンス情報詳細画面
 * @returns JSX.Element
 */
const LicenseDetail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { license, hasOfflineActivationInPaymentGroup } = location.state as LocationState;
    const { userType, getRecentToken } = useUserContext();
    const [loading, setLoading] = useState(true);
    const [paymentHistory, setPaymentHistory] = useState<PaymentHistory>();
    const [updatable, setUpdatable] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");

    // ライセンス更新確認モーダル関連
    const [showLicenseUpdateConfirmModal, setShowLicenseUpdateConfirmModal] = useState<boolean>(false);

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * 購入履歴情報の取得処理
     * @returns
     */
    async function fetchUsersLicenses() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]購入履歴情報取得
            const response: PaymentHistory = await getPaymentHistory(idToken, license.payment_history_id);
            setPaymentHistory(response);

            // 更新可能かどうかの判断。以下の場合は更新は不可。
            // ・同時購入したライセンスの中にオフラインアクティベーションしたものが存在（hasOfflineActivationInPaymentGroup）
            // ・無料体験版の場合
            if (hasOfflineActivationInPaymentGroup || license.free_trial_flg) {
                setUpdatable(false);
            }
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!effectRun.current) {
            fetchUsersLicenses();
            return () => {
                effectRun.current = true;
            };
        }
    }, []);

    /**
     * ライセンスの有効期限延長押下時の処理
     */
    const handleLicenseUpdate = () => {
        setShowLicenseUpdateConfirmModal(true);
    };

    /**
     * 戻る押下時のイベント処理
     */
    const handleBack = () => {
        if (userType === USER_TYPE_LICENSED_USER) {
            // 一般ユーザーの場合は参照専用画面へ
            navigate(USER_LICENSE_REFERENCE_ROUTE);
        } else {
            // それ以外の場合は通常の管理画面へ
            navigate(USER_LICENSE_ROUTE);
        }
    };

    /**
     * [ライセンス更新確認モーダル]
     * 購入手続きへ押下時のイベント処理
     */
    const handleProceed = () => {
        if (paymentHistory?.stripe_payment_id !== null) {
            // 前回Web経由で購入している場合は通常の更新画面へ
            navigate(PRODUCT_CONFIRM_UPDATE_ROUTE, { state: { paymentHistory: paymentHistory, license: license } });
        } else {
            // 前回Web経由以外で購入している場合は更新リクエスト画面へ
            navigate(PRODUCT_CONFIRM_UPDATE_REQUEST_ROUTE, {
                state: { paymentHistory: paymentHistory, license: license },
            });
        }
    };

    /**
     * [ライセンス更新確認モーダル]
     * キャンセル押下時のイベント処理
     */
    const handleCloseLicenseUpdateConfirmModal = () => {
        setShowLicenseUpdateConfirmModal(false);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("LicenseDetail.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("LicenseDetail.licenseDetailTitle")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="p-2 font-bold text-lime-800">Loading...</p>
                    </div>
                ) : (
                    <>
                        {/* ライセンス情報 */}
                        <div className="flex items-center pt-2">
                            <div className="h-0.5 flex-grow bg-gray-800 opacity-70"></div>
                            <span className="flex-shrink px-2 text-lg font-bold text-gray-800">
                                {t("LicenseDetail.licenseInfo")}
                            </span>
                            <div className="h-0.5 flex-grow bg-gray-800 opacity-70"></div>
                        </div>
                        <div className="m-0 grid grid-cols-3 items-center justify-center py-2">
                            {/* 製品名 */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.packageName")}</div>
                            <div className="col-span-2 pb-1">{license.package_name}</div>

                            {/* ライセンスキー */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.licenseKey")}</div>
                            <div className="col-span-2 pb-1">{license.license_key}</div>

                            {/* 利用開始日 */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.activationDate")}</div>
                            <div className="col-span-2 pb-1">{formatUtcToLocalDateTime(license.activation_date)}</div>

                            {/* 有効期限 */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.expirationDate")}</div>
                            <div className="col-span-2 pb-1">{formatUtcToLocalDateTime(license.expire_date)}</div>

                            {/* ユーザー */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.user")}</div>
                            <div className="col-span-2 pb-1">{license.email || "-"}</div>

                            {/* 使用状況 */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.status")}</div>
                            <div className="col-span-2 pb-1">{getActivationStatusName(license)}</div>

                            {/* コンピューター名 */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.computerName")}</div>
                            <div className="col-span-2 pb-1">{license.computer_name || "-"}</div>
                        </div>

                        {/* 購入情報 */}
                        <div className="flex items-center pt-4">
                            <div className="h-0.5 flex-grow bg-gray-800 opacity-70"></div>
                            <span className="flex-shrink px-2 text-lg font-bold text-gray-800">
                                {t("LicenseDetail.paymentHistoryInfo")}
                            </span>
                            <div className="h-0.5 flex-grow bg-gray-800 opacity-70"></div>
                        </div>
                        <div className="m-0 grid grid-cols-3 items-center justify-center py-2">
                            {/* 購入日時 */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.paymentDate")}</div>
                            <div className="col-span-2 pb-1">
                                {formatUtcToLocalDateTime(paymentHistory?.payment_date)}
                            </div>

                            {/* 商品名 */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.productName")}</div>
                            <div className="col-span-2 pb-1">
                                {i18n.language === "ja"
                                    ? paymentHistory?.p_product_type_name.split("%%")[0]
                                    : paymentHistory?.p_product_type_name.split("%%")[1]}
                            </div>

                            {/* 購入者氏名 */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.purchaser")}</div>
                            <div className="col-span-2 pb-1">{paymentHistory?.p_name}</div>

                            {/* ライセンス更新回数 */}
                            <div className="col-span-1 pb-1">{t("CommonLabel.updateCount")}</div>
                            <div className="col-span-2 pb-1">{paymentHistory?.license_updated_count}</div>
                        </div>

                        {/* 更新ボタン */}
                        <div className="my-6 flex items-center justify-evenly">
                            <button
                                type="button"
                                onClick={handleLicenseUpdate}
                                className={`${
                                    updatable ? "btn-primary" : "btn-primary-disabled bg-gray-300 text-gray-500"
                                } px-10 py-1`}
                                disabled={!updatable}
                            >
                                <Trans i18nKey="LicenseDetail.updateLicenseButton" />
                            </button>
                        </div>
                    </>
                )}

                {/* 戻るボタン */}
                <div className="my-5 flex items-center justify-evenly">
                    <button type="button" onClick={handleBack} className="btn-secondary w-[150px]">
                        {t("CommonLabel.back")}
                    </button>
                </div>
            </div>
            {/* ライセンス更新時の注意事項モーダル */}
            <LicenseUpdateConfirmModal
                showModal={showLicenseUpdateConfirmModal}
                handleClose={handleCloseLicenseUpdateConfirmModal}
                handleProceed={handleProceed}
                isProcessing={isProcessing}
            />
            <Footer />
        </div>
    );
};

export default LicenseDetail;
