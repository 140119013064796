import { API } from "aws-amplify";
import { API_ENDPOINT_NAME } from "../consts/constants";
import { loggingInfo, loggingWarn } from "../utils/LoggingUtils";

type GetPaymentHistoryListRequest = {
    productId?: number;
    stripePaymentId?: string;
    pUserId?: string;
    groupId?: number;
    agencyId?: number;
    enableUpdate?: boolean;
};

/**
 * 購入履歴情報取得処理
 * @param idToken IDトークン
 * @param paymentHistoryId 購入管理番号
 * @returns 購入履歴取得処理APIレスポンス
 */
export async function getPaymentHistory(idToken: string, paymentHistoryId: number) {
    const path = `/payment_history/${paymentHistoryId}`;
    const request = {
        headers: {
            Authorization: idToken,
        },
    };
    try {
        const response = await API.get(API_ENDPOINT_NAME, path, request);
        loggingInfo("[GetPaymentHistory] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[GetPaymentHistory] Failed:", error);
        throw error;
    }
}

/**
 * 購入履歴一覧取得処理
 * @param idToken IDトークン
 * @param stripePaymentId Stripe決済ID
 * @returns 購入履歴一覧取得処理APIレスポンス
 */
export async function getPaymentHistoryList(
    idToken: string,
    getPaymentHistoryListRequest: GetPaymentHistoryListRequest,
) {
    const path = "/payment_history_list";
    const body: { [name: string]: any } = { enable_update: false };
    if (getPaymentHistoryListRequest.productId != undefined) {
        body["product_id"] = getPaymentHistoryListRequest.productId;
    }
    if (getPaymentHistoryListRequest.stripePaymentId) {
        body["stripe_payment_id"] = getPaymentHistoryListRequest.stripePaymentId;
    }
    if (getPaymentHistoryListRequest.pUserId) {
        body["p_user_id"] = getPaymentHistoryListRequest.pUserId;
    }
    if (getPaymentHistoryListRequest.groupId != undefined) {
        body["group_id"] = getPaymentHistoryListRequest.groupId;
    }
    if (getPaymentHistoryListRequest.agencyId != undefined) {
        body["agency_id"] = getPaymentHistoryListRequest.agencyId;
    }
    if (getPaymentHistoryListRequest.enableUpdate) {
        body["enable_update"] = getPaymentHistoryListRequest.enableUpdate;
    }
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[GetPaymentHistoryList] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[GetPaymentHistoryList] Failed:", error);
        throw error;
    }
}
