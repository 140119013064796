import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import { ScrollToTop } from "../../components/ScrollToTop";
import { Helmet } from "react-helmet";

const DTMLink = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("DTMLinks.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow items-center justify-center px-6 py-8 pt-20 lg:px-20">
                <h1 className="mb-4 mt-2 text-center text-2xl font-bold text-gray-800 sm:text-3xl">DTM Links</h1>

                <div className="mx-auto max-w-3xl">
                    <div className="mb-8 mt-4 rounded-lg border-2 border-gray-200 p-2">
                        <p className="text-center font-semibold text-lime-600 underline underline-offset-2">
                            {t("DTMLinks.precautionsTitle")}
                        </p>
                        <ul className="ml-5 list-disc text-lime-700">
                            <li>{t("DTMLinks.precautions1")}</li>
                        </ul>
                    </div>

                    {/* GLOBAL */}
                    <h2 className="my-2 text-3xl font-bold text-lime-800">GLOBAL</h2>
                    <div className="mb-12 mt-4">
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                <li className="mt-1">
                                    NASA: SRTM Version 3.0 Global 1" DTMs
                                    <br />
                                    <a className="link-text-blue" href={"https://dwtkns.com/srtm30m/"} target="_blank">
                                        https://dwtkns.com/srtm30m/
                                    </a>
                                </li>
                            </li>
                        </ul>
                    </div>

                    {/* EUROPE */}
                    <h2 className="my-2 text-3xl font-bold text-lime-800">EUROPE</h2>
                    <div className="my-4">
                        <p className="font-bold">AUSTRIA</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Amt der Burgenländischen Landesregierung: DGM 5 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://geodaten.bgld.gv.at/de/downloads/fachdaten.html"}
                                    target="_blank"
                                >
                                    https://geodaten.bgld.gv.at/de/downloads/fachdaten.html
                                </a>
                            </li>
                            <li className="mt-1">
                                Amt der Kärntner Landesregierung: DGM 5 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.data.gv.at/katalog/dataset/a188992b-4071-45c3-99ce-65662395ebe6"}
                                    target="_blank"
                                >
                                    https://www.data.gv.at/katalog/dataset/a188992b-4071-45c3-99ce-65662395ebe6
                                </a>
                            </li>
                            <li className="mt-1">
                                Amt der Niederösterreichischen Landesregierung: DGM 10 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://noe.gv.at/noe/OGD_Detailseite.html?id=46a7a06a-f69b-405e-aac2-77f775449ad3"
                                    }
                                    target="_blank"
                                >
                                    https://noe.gv.at/noe/OGD_Detailseite.html?id=46a7a06a-f69b-405e-aac2-77f775449ad3
                                </a>
                            </li>
                            <li className="mt-1">
                                Amt der Oberösterreichischen Landesregierung: DGM 10 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.land-oberoesterreich.gv.at/124923.htm"}
                                    target="_blank"
                                >
                                    https://www.land-oberoesterreich.gv.at/124923.htm
                                </a>
                            </li>
                            <li className="mt-1">
                                Amt der Salzburger Landesregierung: DGM 5 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://service.salzburg.gv.at/ogd/client/showDetail/d585e816-1a36-4c76-b2dc-6db487d22ca3"
                                    }
                                    target="_blank"
                                >
                                    https://service.salzburg.gv.at/ogd/client/showDetail/d585e816-1a36-4c76-b2dc-6db487d22ca3
                                </a>
                            </li>
                            <li className="mt-1">
                                Amt der Steiermärkischen Landesregierung: DGM 10 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://data.steiermark.at/cms/beitrag/11822084/97108894/?AppInt_OGD_ID=63"}
                                    target="_blank"
                                >
                                    https://data.steiermark.at/cms/beitrag/11822084/97108894/?AppInt_OGD_ID=63
                                </a>
                            </li>
                            <li className="mt-1">
                                Amt der Tiroler Landesregierung: DGM 10 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.data.gv.at/katalog/dataset/land-tirol_tirolgelnde"}
                                    target="_blank"
                                >
                                    https://www.data.gv.at/katalog/dataset/land-tirol_tirolgelnde
                                </a>
                            </li>
                            <li className="mt-1">
                                Amt der Vorarlberger Landesregierung: DGM 10 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://vorarlberg.at/-/geodaten-des-vorarlberger-geografischen-informationssystems-vogis-nutzen?article_id=327499"
                                    }
                                    target="_blank"
                                >
                                    https://vorarlberg.at/-/geodaten-des-vorarlberger-geografischen-informationssystems-vogis-nutzen?article_id=327499
                                </a>
                            </li>
                            <li className="mt-1">
                                Magistrat der Stadt Wien: DGM 1 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.wien.gv.at/stadtentwicklung/stadtvermessung/geodaten/dgm/"}
                                    target="_blank"
                                >
                                    https://www.wien.gv.at/stadtentwicklung/stadtvermessung/geodaten/dgm/
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">BELGIUM</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Agentschap Informatie Vlaanderen: Digitaal Hoogtemodel Vlaanderen II: DTM 5 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "http://www.geopunt.be/catalogus/datasetfolder/9b0f82c7-57c4-463a-8918-432e41a66355"
                                    }
                                    target="_blank"
                                >
                                    http://www.geopunt.be/catalogus/datasetfolder/9b0f82c7-57c4-463a-8918-432e41a66355
                                </a>
                            </li>
                            <li className="mt-1">
                                Géoportail de la Wallonie: Relief de la Wallonie - Modèle Numérique de Terrain (MNT)
                                2013-2014 (DTM 1m)
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://geoportail.wallonie.be/catalogue/6029e738-f828-438b-b10a-85e67f77af92.html"
                                    }
                                    target="_blank"
                                >
                                    https://geoportail.wallonie.be/catalogue/6029e738-f828-438b-b10a-85e67f77af92.html
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">CROATIA</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Viewfinder Panoramas by Jonathan de Ferranti: 1" DTMs
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://viewfinderpanoramas.org/dem3.html"}
                                    target="_blank"
                                >
                                    http://viewfinderpanoramas.org/dem3.html
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">CYPRUS</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Department of Lands and Surveys - INSPIRE GeoPortal: DTM 5 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://eservices.dls.moi.gov.cy/#/national/inspiregeoportalmapviewer"}
                                    target="_blank"
                                >
                                    https://eservices.dls.moi.gov.cy/#/national/inspiregeoportalmapviewer
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">CZECHIA</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Ceský úrad zememerický a katastrální (CÚZK): INSPIRE theme Elevation (EL) GRID: DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://geoportal.cuzk.cz/(S(vlcpw4sik4esfuwqissp0ngy))/Default.aspx?lng=EN&mode=TextMeta&side=vyskopis&metadataID=CZ-CUZK-EL&mapid=8&menu=304"
                                    }
                                    target="_blank"
                                >
                                    https://geoportal.cuzk.cz/(S(vlcpw4sik4esfuwqissp0ngy))/Default.aspx?lng=EN&mode=TextMeta&side=vyskopis&metadataID=CZ-CUZK-EL&mapid=8&menu=304
                                </a>
                            </li>
                            <li className="mt-1">
                                Institut plánování a rozvoje hl. m. Prahy: Digitální model terénu - rastr: DTM 1 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://www.geoportalpraha.cz/en/data/opendata/609AB233-4F4B-4010-A6E0-011E232E2390"
                                    }
                                    target="_blank"
                                >
                                    https://www.geoportalpraha.cz/en/data/opendata/609AB233-4F4B-4010-A6E0-011E232E2390
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">DENMARK</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Kortforsyningen | Styrelsen for DataForsyning og Effektivisering : DHM/Terræn (0,4 m
                                grid): DTM 0.4 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://download.kortforsyningen.dk/content/dhmterr%C3%A6n-04-m-grid"}
                                    target="_blank"
                                >
                                    https://download.kortforsyningen.dk/content/dhmterr%C3%A6n-04-m-grid
                                </a>
                            </li>
                            <li className="mt-1">
                                Umhvørvisstovan | DSM hæddarmodell: DTM 2 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.foroyakort.fo/tak-datur-nidur/dsm-haeddarmodell/"}
                                    target="_blank"
                                >
                                    https://www.foroyakort.fo/tak-datur-nidur/dsm-haeddarmodell/
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">ESTONIA</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Estonian Land Board: DTM 10 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://geoportaal.maaamet.ee/index.php?lang_id=1&page_id=607#tab3"}
                                    target="_blank"
                                >
                                    https://geoportaal.maaamet.ee/index.php?lang_id=1&page_id=607#tab3
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">FRANCE</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Institut national de l’information géographique et forestière (IGN): RGE ALTI, DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://geoservices.ign.fr/rgealti"}
                                    target="_blank"
                                >
                                    https://geoservices.ign.fr/rgealti
                                </a>
                            </li>
                            <li className="mt-1">
                                Métropole Bordeaux: DTM 0.5m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://data.bordeaux-metropole.fr/data.php"}
                                    target="_blank"
                                >
                                    https://data.bordeaux-metropole.fr/data.php
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">GERMANY</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Bayerische Vermessungsverwaltung (www.geodaten.bayern.de): DTM 1 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://geodaten.bayern.de/opengeodata/OpenDataDetail.html?pn=dgm1"}
                                    target="_blank"
                                >
                                    https://geodaten.bayern.de/opengeodata/OpenDataDetail.html?pn=dgm1
                                </a>
                            </li>
                            <li className="mt-1">
                                Senatsverwaltung für Stadtentwicklung und Umwelt Berlin: ATKIS® DTM 1 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "http://www.stadtentwicklung.berlin.de/geoinformation/geodateninfrastruktur/de/geodienste/atom.shtml"
                                    }
                                    target="_blank"
                                >
                                    http://www.stadtentwicklung.berlin.de/geoinformation/geodateninfrastruktur/de/geodienste/atom.shtml
                                </a>
                            </li>
                            <li className="mt-1">
                                Land Brandenburg, Landesvermessung und Geobasisinformation Brandenburg (LGB): DTM 1 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://geoportal.brandenburg.de/detailansichtdienst/render?view=gdibb&url=https://geoportal.brandenburg.de/gs-json/xml?fileid=488a2b53-564f-43eb-88ec-0d87bb43ed20"
                                    }
                                    target="_blank"
                                >
                                    https://geoportal.brandenburg.de/detailansichtdienst/render?view=gdibb&url=https://geoportal.brandenburg.de/gs-json/xml?fileid=488a2b53-564f-43eb-88ec-0d87bb43ed20
                                </a>
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://geobasis-bb.de/lgb/de/startseite/geodaten/3d-produkte/gelaendemodell/"
                                    }
                                    target="_blank"
                                >
                                    https://geobasis-bb.de/lgb/de/startseite/geodaten/3d-produkte/gelaendemodell/
                                </a>
                            </li>
                            <li className="mt-1">
                                Freie und Hansestadt Hamburg, Landesbetrieb Geoinformation und Vermessung, 2014 : DTM 10
                                m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "http://suche.transparenz.hamburg.de/dataset/digitales-hohenmodell-hamburg-dgm-101"
                                    }
                                    target="_blank"
                                >
                                    http://suche.transparenz.hamburg.de/dataset/digitales-hohenmodell-hamburg-dgm-101
                                </a>
                            </li>
                            <li className="mt-1">
                                Hessisches Landesamt für Bodenmanagement und Geoinformation: DTM 1 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://hvbg.hessen.de/landesvermessung/geotopographie/3d-daten/digitale-gelaendemodelle"
                                    }
                                    target="_blank"
                                >
                                    https://hvbg.hessen.de/landesvermessung/geotopographie/3d-daten/digitale-gelaendemodelle
                                </a>
                            </li>
                            <li className="mt-1">
                                Land Nordrhein-Westfalen (2017), DTM 1 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://open.nrw/de/dataset/0c6796e5-9eca-4ae6-8b32-1fcc5ae5c481bkg"}
                                    target="_blank"
                                >
                                    https://open.nrw/de/dataset/0c6796e5-9eca-4ae6-8b32-1fcc5ae5c481bkg
                                </a>
                            </li>
                            <li className="mt-1">
                                Landesamt für Vermessung und Geobasisinformation Rhineland-Palatinate: DTM 25 m (DGM25)
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://www.lvermgeo.rlp.de/index.php?id=opendata"}
                                    target="_blank"
                                >
                                    http://www.lvermgeo.rlp.de/index.php?id=opendata
                                </a>
                            </li>
                            <li className="mt-1">
                                Staatsbetrieb Geobasisinformation und Vermessung Sachsen : DTM 2 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.geodaten.sachsen.de/digitale-hoehenmodelle-3994.html"}
                                    target="_blank"
                                >
                                    https://www.geodaten.sachsen.de/digitale-hoehenmodelle-3994.html
                                </a>
                            </li>
                            <li className="mt-1">
                                Landesamt für Vermessung und Geoinformation Sachsen-Anhalt: DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://www.lvermgeo.sachsen-anhalt.de/de/kostenfreie_geobasisdaten_lvermgeo.html"
                                    }
                                    target="_blank"
                                >
                                    https://www.lvermgeo.sachsen-anhalt.de/de/kostenfreie_geobasisdaten_lvermgeo.html
                                </a>
                            </li>
                            <li className="mt-1">
                                Freistaat Thüringen, Landesamt für Vermessung und Geoinformation: DTM 1 m (2014-2019),
                                DTM 2 m (2010-2013)
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "http://www.geoportal-th.de/de-de/downloadbereiche/downloadoffenegeodatenth%C3%BCringen/downloadh%C3%B6hendaten.aspx"
                                    }
                                    target="_blank"
                                >
                                    http://www.geoportal-th.de/de-de/downloadbereiche/downloadoffenegeodatenth%C3%BCringen/downloadh%C3%B6hendaten.aspx
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">HUNGARY</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Viewfinder Panoramas by Jonathan de Ferranti: 1" DTMs
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://viewfinderpanoramas.org/dem3.html"}
                                    target="_blank"
                                >
                                    http://viewfinderpanoramas.org/dem3.html
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">ICELAND</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Landmælingar Íslands: ÍslandsDEM útgáfa 1.0 (DTM 10 m)
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://gatt.lmi.is/geonetwork/srv/eng/catalog.search#/metadata/e6712430-a63c-4ae5-9158-c89d16da6361"
                                    }
                                    target="_blank"
                                >
                                    https://gatt.lmi.is/geonetwork/srv/eng/catalog.search#/metadata/e6712430-a63c-4ae5-9158-c89d16da6361
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">IRELAND</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Geological Survey Ireland: different DTM sources from 0.25m - 2m resolution
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://data.gov.ie/dataset/open-topographic-lidar-data"}
                                    target="_blank"
                                >
                                    https://data.gov.ie/dataset/open-topographic-lidar-data
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">ITALY</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Regione Abruzzo: DTM 10 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "http://opendata.regione.abruzzo.it/content/modello-digitale-del-terreno-risoluzione-10x10-metri"
                                    }
                                    target="_blank"
                                >
                                    http://opendata.regione.abruzzo.it/content/modello-digitale-del-terreno-risoluzione-10x10-metri
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Basilicata: DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://rsdi.regione.basilicata.it/dbgt-ctr/"}
                                    target="_blank"
                                >
                                    http://rsdi.regione.basilicata.it/dbgt-ctr/
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Calabria: DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://geoportale.regione.calabria.it/opendata"}
                                    target="_blank"
                                >
                                    http://geoportale.regione.calabria.it/opendata
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Campania - Citta Metropolitana di Napoli: DTM 1 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://sit.cittametropolitana.na.it/"}
                                    target="_blank"
                                >
                                    https://sit.cittametropolitana.na.it/
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Emilia-Romagna: DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://geoportale.regione.emilia-romagna.it/catalogo/dati-cartografici/altimetria/layer-2"
                                    }
                                    target="_blank"
                                >
                                    https://geoportale.regione.emilia-romagna.it/catalogo/dati-cartografici/altimetria/layer-2
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Autonoma Friuli Venezia Giulia: DTM 10 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://irdat.regione.fvg.it/CTRN/ricerca-cartografia/"}
                                    target="_blank"
                                >
                                    http://irdat.regione.fvg.it/CTRN/ricerca-cartografia/
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Lazio: DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://geoportale.regione.lazio.it/documents/272"}
                                    target="_blank"
                                >
                                    https://geoportale.regione.lazio.it/documents/272
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Liguria: Modello Digitale del Terreno: DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://geoportal.regione.liguria.it/archivio-focus/item/803"}
                                    target="_blank"
                                >
                                    https://geoportal.regione.liguria.it/archivio-focus/item/803
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Lombardia: DTM 5X5 - Modello digitale del terreno (ed. 2015): DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.geoportale.regione.lombardia.it/en-GB/home"}
                                    target="_blank"
                                >
                                    https://www.geoportale.regione.lombardia.it/en-GB/home
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Piemonte: RIPRESA AEREA ICE 2009-2011 - DTM: DTM 5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://www.geoportale.piemonte.it/geonetwork/srv/api/records/r_piemon:224de2ac-023e-441c-9ae0-ea493b217a8e"
                                    }
                                    target="_blank"
                                >
                                    https://www.geoportale.piemonte.it/geonetwork/srv/api/records/r_piemon:224de2ac-023e-441c-9ae0-ea493b217a8e
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Puglia: DTM 8 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "http://www.sit.puglia.it/portal/portale_cartografie_tecniche_tematiche/Download/Cartografie"
                                    }
                                    target="_blank"
                                >
                                    http://www.sit.puglia.it/portal/portale_cartografie_tecniche_tematiche/Download/Cartografie
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Autonoma della Sardegna, Sardegna Geoportale: DTM 1 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://www.sardegnageoportale.it/webgis2/sardegnamappe/?map=download_raster"}
                                    target="_blank"
                                >
                                    http://www.sardegnageoportale.it/webgis2/sardegnamappe/?map=download_raster
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Siciliana: MDT 2012-2013: DTM 2 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.sitr.regione.sicilia.it/geoportale/it/metadata/details/947"}
                                    target="_blank"
                                >
                                    https://www.sitr.regione.sicilia.it/geoportale/it/metadata/details/947
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Toscana: DTM 10 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://dati.toscana.it/dataset/dem10mt"}
                                    target="_blank"
                                >
                                    http://dati.toscana.it/dataset/dem10mt
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Toscana-Comune di Firenze : DTM 1m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://dati.toscana.it/dataset/dem10mt"}
                                    target="_blank"
                                >
                                    http://dati.toscana.it/dataset/dem10mt
                                </a>
                            </li>
                            <li className="mt-1">
                                Provincia Autonoma di Trento: Lidar 2014 PAT 0,5m / Lidar 2011 Campolongo 1m / Lidar
                                2009 PAT 1m+2m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "http://www.territorio.provincia.tn.it/portal/server.pt/community/lidar/847/lidar/23954"
                                    }
                                    target="_blank"
                                >
                                    http://www.territorio.provincia.tn.it/portal/server.pt/community/lidar/847/lidar/23954
                                </a>
                            </li>
                            <li className="mt-1">
                                Autonome Provinz Bozen-Südtirol: DTM 2.5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://natur-raum.provinz.bz.it/de/digitale-hohenmodelle"}
                                    target="_blank"
                                >
                                    https://natur-raum.provinz.bz.it/de/digitale-hohenmodelle
                                </a>
                            </li>
                            <li className="mt-1">
                                Regione Autonoma Valle d'Aosta: DTM 2005 0.5m & DTM 2008 2m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://geoportale.regione.vda.it/download/dtm/"}
                                    target="_blank"
                                >
                                    https://geoportale.regione.vda.it/download/dtm/
                                </a>
                            </li>
                            <li className="mt-1">
                                Tinitaly DEM v1.1: DTM 10 m
                                <br />
                                <a className="link-text-blue" href={"http://tinitaly.pi.ingv.it/"} target="_blank">
                                    http://tinitaly.pi.ingv.it/
                                </a>
                            </li>
                            <li className="mt-1">
                                Malta Planning Authority: DTM 10 m
                                <br />
                                <a className="link-text-blue" href={"https://www.pa.org.mt/"} target="_blank">
                                    https://www.pa.org.mt/
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">LATVIA</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Latvian Geospatial Information Agency: DTM 20 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://opendata.lgia.gov.lv/zf_wp/index.php/category/citi/"}
                                    target="_blank"
                                >
                                    https://opendata.lgia.gov.lv/zf_wp/index.php/category/citi/
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">LITHUANIA</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Nacionaline žemes tarnyba prie Aplinkos ministerijos: DTM 5 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://www.inspire-geoportal.lt/geonetwork/srv/api/records/7ce59d66-159c-4b81-9951-20f801f05748"
                                    }
                                    target="_blank"
                                >
                                    https://www.inspire-geoportal.lt/geonetwork/srv/api/records/7ce59d66-159c-4b81-9951-20f801f05748
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">LUXEMBOURG</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Luxembourg Administration du cadastre et de la topographie: DTM 0.5 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://data.public.lu/fr/datasets/lidar-2019-modele-numerique-de-terrain-mnt/"
                                    }
                                    target="_blank"
                                >
                                    https://data.public.lu/fr/datasets/lidar-2019-modele-numerique-de-terrain-mnt/
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">NETHERLANDS</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Actueel Hoogtebestand Nederland (AHN2): DTM 5 Meter
                                <br />
                                <a className="link-text-blue" href={"http://www.ahn.nl/index.html"} target="_blank">
                                    http://www.ahn.nl/index.html
                                </a>
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.pdok.nl/introductie?articleid=1948857"}
                                    target="_blank"
                                >
                                    https://www.pdok.nl/introductie?articleid=1948857
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">NORWAY</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Kartverket Høydedata: DTM 10 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://hoydedata.no/LaserInnsyn/"}
                                    target="_blank"
                                >
                                    https://hoydedata.no/LaserInnsyn/
                                </a>
                            </li>
                            <li className="mt-1">
                                Norwegian Polar Institute: S0 Terrengmodell (DTM 20 Meter), J0 Terrengmodell (DTM 25
                                Meter)
                                <br />
                                <a className="link-text-blue" href={"https://geodata.npolar.no/"} target="_blank">
                                    https://geodata.npolar.no/
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">POLAND</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Glówny Urzad Geodezji i Kartografii: DTM 1 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.geoportal.gov.pl/en/dane/numeryczny-model-terenu"}
                                    target="_blank"
                                >
                                    https://www.geoportal.gov.pl/en/dane/numeryczny-model-terenu
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">PORTUGAL</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Secretaria Regional do Ambiente e Alterações Climáticas/Governo dos Açores: Modelo
                                Digital do Terreno: DTM 0.5m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://sma.idea.azores.gov.pt/geonetwork/srv/por/catalog.search#/metadata/13e9d67a-dfa5-46d1-9ab8-210c4d7fb2f7"
                                    }
                                    target="_blank"
                                >
                                    https://sma.idea.azores.gov.pt/geonetwork/srv/por/catalog.search#/metadata/13e9d67a-dfa5-46d1-9ab8-210c4d7fb2f7
                                </a>
                            </li>
                            <li className="mt-1">
                                Direção Regional do Ordenamento do Território: Modelo Digital do Terreno da Ilha da
                                Madeira, Porto Santo: DTM 10m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://catalogo-irig.madeira.gov.pt"}
                                    target="_blank"
                                >
                                    https://catalogo-irig.madeira.gov.pt
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">ROMANIA</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Agentia Nationala de Cadastru si Publicitate Imobiliara (ANCPI): LAKI II MNT (DTM 1
                                Meter)
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://geoportal.ancpi.ro/portal/apps/webappviewer/index.html?id=3f34ee5af71c400396dda574f0d53274"
                                    }
                                    target="_blank"
                                >
                                    https://geoportal.ancpi.ro/portal/apps/webappviewer/index.html?id=3f34ee5af71c400396dda574f0d53274
                                </a>
                            </li>
                            <li className="mt-1">
                                Viewfinder Panoramas by Jonathan de Ferranti: 1" DTMs
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"http://viewfinderpanoramas.org/dem3.html"}
                                    target="_blank"
                                >
                                    http://viewfinderpanoramas.org/dem3.html
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">SLOVAKIA</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Geodetický a kartografický ústav Bratislava: DTM v5.0 (1 Meter)
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.geoportal.sk/en/zbgis/als-dtm.html"}
                                    target="_blank"
                                >
                                    https://www.geoportal.sk/en/zbgis/als-dtm.html
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">SLOVENIA</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Slovenian ministry of Environment and Spatial Planning: DTM 1 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "http://gis.arso.gov.si/evode/profile.aspx?id=atlas_voda_Lidar@Arso&culture=en-US"
                                    }
                                    target="_blank"
                                >
                                    http://gis.arso.gov.si/evode/profile.aspx?id=atlas_voda_Lidar@Arso&culture=en-US
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">SPAIN</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                LiDAR-PNOA assigned by Instituto Geográfico Nacional (IGN)
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://centrodedescargas.cnig.es/CentroDescargas/"}
                                    target="_blank"
                                >
                                    https://centrodedescargas.cnig.es/CentroDescargas/
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">SWEDEN</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Lantmäteriet (Swedish Land Survey): "Höjddata, grid 50+" DTM 50 Meter
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://www.lantmateriet.se/en/maps-and-geographic-information/geodataprodukter/produktlista/hojddata-grid-50/"
                                    }
                                    target="_blank"
                                >
                                    https://www.lantmateriet.se/en/maps-and-geographic-information/geodataprodukter/produktlista/hojddata-grid-50/
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">SWITZERLAND</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Bundesamt für Landestopografie swisstopo: swissALTI3D DTM 2m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.swisstopo.admin.ch/de/geodata/height/alti3d.html"}
                                    target="_blank"
                                >
                                    https://www.swisstopo.admin.ch/de/geodata/height/alti3d.html
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="my-4">
                        <p className="font-bold">UNITED KINGDOM</p>
                        <ul className="ml-5 list-disc text-sm">
                            <li className="mt-1">
                                Environment Agency: LiDAR Composite DTM 10 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={
                                        "https://environment.data.gov.uk/dataset/ce8fe7e7-bed0-4889-8825-19b042e128d2"
                                    }
                                    target="_blank"
                                >
                                    https://environment.data.gov.uk/dataset/ce8fe7e7-bed0-4889-8825-19b042e128d2
                                </a>
                            </li>
                            <li className="mt-1">
                                Welsh Government: LiDAR DTM 1 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://datamap.gov.wales/maps/lidar-viewer/"}
                                    target="_blank"
                                >
                                    https://datamap.gov.wales/maps/lidar-viewer/
                                </a>
                            </li>
                            <li className="mt-1">
                                Scottish Government: Different LiDAR sources DTM 0.5 - 2 m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://remotesensingdata.gov.scot/"}
                                    target="_blank"
                                >
                                    https://remotesensingdata.gov.scot/
                                </a>
                            </li>
                            <li className="mt-1">
                                Ordnance Survey of Northern Ireland, Land & Property Services: 10m DTM-Sheets, River
                                Basin LIDAR 2004-2014 DTM 1m
                                <br />
                                Department for Infrastructure of Northern Ireland: LIDAR Belfast 2006 DTM 0.5m, LIDAR
                                Lough Neagh Environs 2014 DTM 1m
                                <br />
                                <a className="link-text-blue" href={"https://www.opendatani.gov.uk/"} target="_blank">
                                    https://www.opendatani.gov.uk/
                                </a>
                            </li>
                            <li className="mt-1">
                                Department of Infrastructure, Isle of Man: DTM 20m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.gov.im/categories/planning-and-building-control/mapping/"}
                                    target="_blank"
                                >
                                    https://www.gov.im/categories/planning-and-building-control/mapping/
                                </a>
                            </li>
                            <li className="mt-1">
                                Ordnance Survey: OS Terrain DTM 50m
                                <br />
                                <a
                                    className="link-text-blue"
                                    href={"https://www.ordnancesurvey.co.uk/business-government/products/terrain-50"}
                                    target="_blank"
                                >
                                    https://www.ordnancesurvey.co.uk/business-government/products/terrain-50
                                </a>
                            </li>
                        </ul>
                    </div>

                    <hr className="mb-2 mt-10"></hr>
                    <div className="text-gray-500">
                        <p>References</p>
                        <ul className="ml-5 list-disc">
                            <li>SONNY's LiDAR DIGITAL TERRAIN MODELS of EUROPE [Sonny](http://sonny.4lima.de)</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DTMLink;
