import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import dflat_icon from "./../../img/dflat/icon.png";
import dflat_screen from "./../../img/dflat/screen.png";
import dflat_ground_detection from "./../../img/dflat/ground-detection.gif";
import dflat_manual_filtering from "./../../img/dflat/manual-filtering.gif";
import dflat_manual_filtering2 from "./../../img/dflat/manual-filtering2.gif";
import dflat_ortho_dtm_chm from "./../../img/dflat/ortho-dtm-chm.png";
import { ScrollToTop } from "../../components/ScrollToTop";
import { DOWNLOAD_ROUTE } from "../../consts/routes";
import { Helmet } from "react-helmet";

/**
 * DF LAT詳細画面
 * @returns JSX.Element
 */
const DetailDflat = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("DetailDflat.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <img className="h-20 w-20 p-3" src={dflat_icon} alt="dflat" />
                        <h1 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl ">DF LAT</h1>
                    </div>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dflat_screen}
                            alt="DF LAT Screen Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* 概要 */}
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDflat.about1" />
                        <br />
                        <br />
                        <Trans i18nKey="DetailDflat.about2" />
                        <br />
                        <br />
                        <Trans i18nKey="DetailDflat.about3" />
                    </p>

                    {/* 地面部検出 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDflat.groundDetectionTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDflat.groundDetectionText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dflat_ground_detection}
                            alt="DF LAT Ground Detection Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* 手動フィルタリング */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDflat.manualFilteringTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDflat.manualFilteringText1" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dflat_manual_filtering}
                            alt="DF LAT Manual Filtering Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDflat.manualFilteringText2" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dflat_manual_filtering2}
                            alt="DF LAT Manual Filtering Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* DSM・DTM・CHM作成 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDflat.creationRasterTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDflat.creationRasterText1" />
                        <br />
                        <br />
                        <Trans i18nKey="DetailDflat.creationRasterText2" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dflat_ortho_dtm_chm}
                            alt="DF LAR Ortho DTM CHM Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* ダウンロード */}
                    <h2 id="download" className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDflat.downloadTitle")}
                    </h2>
                    <p className="mb-2 text-gray-700 sm:text-lg md:mb-2">
                        <Link to={DOWNLOAD_ROUTE}>
                            <Trans
                                i18nKey="DetailDflat.downloadText"
                                components={{
                                    l: <button className="link-text" />,
                                }}
                            />
                        </Link>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DetailDflat;
