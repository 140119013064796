/**
 * INFOコンソールログ出力
 * 
 * 環境変数（.envファイル）のREACT_APP_ENABLE_LOGGINGがtrueの場合のみログを出力する。
 * 本番リリース時はfalseで運用すること。
 * @param message 
 * @param optionalParams 
 */
export function loggingInfo(message?: any, ...optionalParams: any[]) {
    const enableLoggingStr = process.env.REACT_APP_ENABLE_LOGGING
    if (enableLoggingStr && enableLoggingStr.toLowerCase() === "true") {
        console.log(message, optionalParams)
    }
}

/**
 * WARNコンソールログ出力
 * 
 * 環境変数（.envファイル）のREACT_APP_ENABLE_LOGGINGがtrueの場合のみログを出力する。
 * 本番リリース時はfalseで運用すること。
 * @param message 
 * @param optionalParams 
 */
export function loggingWarn(message?: any, ...optionalParams: any[]) {
    const enableLoggingStr = process.env.REACT_APP_ENABLE_LOGGING
    if (enableLoggingStr && enableLoggingStr.toLowerCase() === "true") {
        console.warn(message, optionalParams)
    }
}

/**
 * ERRORコンソールログ出力
 * 
 * 環境変数（.envファイル）のREACT_APP_ENABLE_LOGGINGがtrueの場合のみログを出力する。
 * 本番リリース時はfalseで運用すること。
 * @param message 
 * @param optionalParams 
 */
export function loggingError(message?: any, ...optionalParams: any[]) {
    const enableLoggingStr = process.env.REACT_APP_ENABLE_LOGGING
    if (enableLoggingStr && enableLoggingStr.toLowerCase() === "true") {
        console.error(message, optionalParams)
    }
}