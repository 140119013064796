import {
    DF_SCANNER_CPU_FILENAME_V1_2_0,
    DF_SCANNER_CPU_FILENAME_V1_2_4,
    DF_SCANNER_CPU_FILENAME_V1_3_1,
    DF_SCANNER_CPU_FILENAME_V1_3_2,
    DF_SCANNER_CPU_FILENAME_V1_3_3,
    DF_SCANNER_CPU_FILENAME_V1_4_0,
    DF_SCANNER_CPU_FILENAME_V1_4_1,
    DF_SCANNER_CPU_FILENAME_V1_5_0,
    DF_SCANNER_CPU_FILENAME_V1_5_1,
    DF_SCANNER_CPU_FILENAME_V1_5_2,
    DF_SCANNER_CPU_FILENAME_V1_6_0,
    DF_SCANNER_CPU_FILENAME_V1_6_1,
    DF_SCANNER_CPU_URL_V1_2_0,
    DF_SCANNER_CPU_URL_V1_2_4,
    DF_SCANNER_CPU_URL_V1_3_1,
    DF_SCANNER_CPU_URL_V1_3_2,
    DF_SCANNER_CPU_URL_V1_3_3,
    DF_SCANNER_CPU_URL_V1_4_0,
    DF_SCANNER_CPU_URL_V1_4_1,
    DF_SCANNER_CPU_URL_V1_5_0,
    DF_SCANNER_CPU_URL_V1_5_1,
    DF_SCANNER_CPU_URL_V1_5_2,
    DF_SCANNER_CPU_URL_V1_6_0,
    DF_SCANNER_CPU_URL_V1_6_1,
    DF_SCANNER_FILENAME_V1_7_0,
    DF_SCANNER_GPU_FILENAME_V1_2_0,
    DF_SCANNER_GPU_FILENAME_V1_2_4,
    DF_SCANNER_GPU_FILENAME_V1_3_1,
    DF_SCANNER_GPU_FILENAME_V1_3_2,
    DF_SCANNER_GPU_FILENAME_V1_3_3,
    DF_SCANNER_GPU_FILENAME_V1_4_0,
    DF_SCANNER_GPU_FILENAME_V1_4_1,
    DF_SCANNER_GPU_FILENAME_V1_5_0,
    DF_SCANNER_GPU_FILENAME_V1_5_1,
    DF_SCANNER_GPU_FILENAME_V1_5_2,
    DF_SCANNER_GPU_FILENAME_V1_6_0,
    DF_SCANNER_GPU_FILENAME_V1_6_1,
    DF_SCANNER_GPU_URL_V1_2_0,
    DF_SCANNER_GPU_URL_V1_2_4,
    DF_SCANNER_GPU_URL_V1_3_1,
    DF_SCANNER_GPU_URL_V1_3_2,
    DF_SCANNER_GPU_URL_V1_3_3,
    DF_SCANNER_GPU_URL_V1_4_0,
    DF_SCANNER_GPU_URL_V1_4_1,
    DF_SCANNER_GPU_URL_V1_5_0,
    DF_SCANNER_GPU_URL_V1_5_1,
    DF_SCANNER_GPU_URL_V1_5_2,
    DF_SCANNER_GPU_URL_V1_6_0,
    DF_SCANNER_GPU_URL_V1_6_1,
    DF_SCANNER_LITE_CPU_FILENAME_V1_0_0,
    DF_SCANNER_LITE_CPU_FILENAME_V1_0_1,
    DF_SCANNER_LITE_CPU_FILENAME_V1_0_2,
    DF_SCANNER_LITE_CPU_FILENAME_V1_1_0,
    DF_SCANNER_LITE_CPU_FILENAME_V1_1_1,
    DF_SCANNER_LITE_CPU_URL_V1_0_0,
    DF_SCANNER_LITE_CPU_URL_V1_0_1,
    DF_SCANNER_LITE_CPU_URL_V1_0_2,
    DF_SCANNER_LITE_CPU_URL_V1_1_0,
    DF_SCANNER_LITE_CPU_URL_V1_1_1,
    DF_SCANNER_LITE_FILENAME_V1_2_0,
    DF_SCANNER_LITE_GPU_FILENAME_V1_0_0,
    DF_SCANNER_LITE_GPU_FILENAME_V1_0_1,
    DF_SCANNER_LITE_GPU_FILENAME_V1_0_2,
    DF_SCANNER_LITE_GPU_FILENAME_V1_1_0,
    DF_SCANNER_LITE_GPU_FILENAME_V1_1_1,
    DF_SCANNER_LITE_GPU_URL_V1_0_0,
    DF_SCANNER_LITE_GPU_URL_V1_0_1,
    DF_SCANNER_LITE_GPU_URL_V1_0_2,
    DF_SCANNER_LITE_GPU_URL_V1_1_0,
    DF_SCANNER_LITE_GPU_URL_V1_1_1,
    DF_SCANNER_LITE_URL_V1_2_0,
    DF_SCANNER_PRO_CPU_FILENAME_V1_0_0,
    DF_SCANNER_PRO_CPU_FILENAME_V1_0_1,
    DF_SCANNER_PRO_CPU_FILENAME_V1_0_2,
    DF_SCANNER_PRO_CPU_FILENAME_V1_1_0,
    DF_SCANNER_PRO_CPU_FILENAME_V1_1_1,
    DF_SCANNER_PRO_CPU_URL_V1_0_0,
    DF_SCANNER_PRO_CPU_URL_V1_0_1,
    DF_SCANNER_PRO_CPU_URL_V1_0_2,
    DF_SCANNER_PRO_CPU_URL_V1_1_0,
    DF_SCANNER_PRO_CPU_URL_V1_1_1,
    DF_SCANNER_PRO_FILENAME_V1_2_0,
    DF_SCANNER_PRO_GPU_FILENAME_V1_0_0,
    DF_SCANNER_PRO_GPU_FILENAME_V1_0_1,
    DF_SCANNER_PRO_GPU_FILENAME_V1_0_2,
    DF_SCANNER_PRO_GPU_FILENAME_V1_1_0,
    DF_SCANNER_PRO_GPU_FILENAME_V1_1_1,
    DF_SCANNER_PRO_GPU_URL_V1_0_0,
    DF_SCANNER_PRO_GPU_URL_V1_0_1,
    DF_SCANNER_PRO_GPU_URL_V1_0_2,
    DF_SCANNER_PRO_GPU_URL_V1_1_0,
    DF_SCANNER_PRO_GPU_URL_V1_1_1,
    DF_SCANNER_PRO_URL_V1_2_0,
    DF_SCANNER_URL_V1_7_0,
} from "../../consts/Installers";

/**
 * リリースノート（DF Scanner・英語版）
 * @returns JSX.Element
 */
export const ReleaseNotesDfScannerEn = () => {
    return (
        <div>
            {/* Pro版 v1.2.0, Lite版 v1.2.0, v1.7.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro v1.2.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite v1.2.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.7.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-08-23</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro v1.2.0 has been released.</p>
                    <p>DF Scanner Lite v1.2.0 has been released.</p>
                    <p>DF Scanner v1.7.0 has been released.</p>
                    <br />
                    <p className="font-bold">■ Common</p>
                    <p>New Features</p>
                    <p>・Ground correction function updated.</p>
                    <p>・Updated sugi-hinoki classification model.</p>
                    <p>・Change from carbon stock estimation to CO2 stock estimation.</p>
                    <p>・Added Vegetation Map Creation function.</p>
                    <p>・Supports importing GeoJSON files.</p>
                    <p>・Supports display of donut-shaped polygons and multi-polygons.</p>
                    <p>・Supports multiple file import.</p>
                    <p>・Check polygon information with the Information button.</p>
                    <br />
                    <p>Function Improvements</p>
                    <p>・Improved performance of polygon display.</p>
                    <p>・Fix basemap display.</p>
                    <p>・Improved loading GeoTIFF files with overview.</p>
                    <p>・Improved selection statistics calculation.</p>
                    <p>・Partially improved the tree top detection algorithm.</p>
                    <br />
                    <p>Others</p>
                    <p>・Added DF account authentication when using online backup.</p>
                    <p>・Unified installer for GPU and CPU versions.</p>
                    <p>
                        ・Some of the text on the screen has been corrected and explanations of analysis parameters have
                        been added.
                    </p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_URL_V1_2_0}>
                    {DF_SCANNER_PRO_FILENAME_V1_2_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_URL_V1_2_0}>
                    {DF_SCANNER_LITE_FILENAME_V1_2_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_URL_V1_7_0}>
                    {DF_SCANNER_FILENAME_V1_7_0}
                </a>
            </div>

            {/* Pro版 v1.1.1, Lite版 v1.1.1, v1.6.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro v1.1.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite v1.1.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.6.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-06-10</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro v1.1.1 has been released.</p>
                    <p>DF Scanner Lite v1.1.1 has been released.</p>
                    <p>DF Scanner v1.6.1 has been released.</p>
                    <br />
                    <p className="font-bold">■ Common</p>
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue where saving a project was not possible on first launch.</p>
                    <p>・Fixed an issue where polygons with empty geometry could be created when editing polygons.</p>
                    <p>
                        ・Fixed an issue where a column could not be added to the left of a newly created file when
                        editing polygons.
                    </p>
                    <p>・Fixed an issue where GeoTIFF files were not recognized when a tfw file was present.</p>
                    <p>Others</p>
                    <p>・The default installation location for the software has been changed.</p>
                    <br />
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_1_1}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_1_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_1_1}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_1_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_1_1}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_1_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_1_1}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_1_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_6_1}>
                    {DF_SCANNER_GPU_FILENAME_V1_6_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_6_1}>
                    {DF_SCANNER_CPU_FILENAME_V1_6_1}
                </a>
            </div>

            {/* Pro版 v1.1.0, Lite版 v1.1.0, v1.6.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro v1.1.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite v1.1.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.6.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-05-14</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro v1.1.0 has been released.</p>
                    <p>DF Scanner Lite v1.1.0 has been released.</p>
                    <p>DF Scanner v1.6.0 has been released.</p>
                    <br />
                    <p className="font-bold">■ Common</p>
                    <p>New Features</p>
                    <p>・Added options for ground correction (vertical correction).</p>
                    <br />
                    <p>Function Improvements</p>
                    <p>・Improved DBH estimation accuracy.</p>
                    <p>・Corrected to exclude non-vegetation in Statistics Calculation.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed a bug where polygons were missing in Tree Crown Segmentation.</p>
                    <p>・Fixed a bug when editing polygons.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_1_0}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_1_0}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_1_0}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_1_0}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_6_0}>
                    {DF_SCANNER_GPU_FILENAME_V1_6_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_6_0}>
                    {DF_SCANNER_CPU_FILENAME_V1_6_0}
                </a>
            </div>

            {/* Pro版 v1.0.2, Lite版 v1.0.2, v1.5.2 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro v1.0.2</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite v1.0.2</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.5.2</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-03-27</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro v1.0.2 has been released.</p>
                    <p>DF Scanner Lite v1.0.2 has been released.</p>
                    <p>DF Scanner v1.5.2 has been released.</p>
                    <br />
                    <p className="font-bold">■ Common</p>
                    <p>Function Improvements</p>
                    <p>・Improved tree top detection and tree crown segmentation process.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed a bug when editing polygons.</p>
                    <p>
                        ・Fixed an issue where the minimum crown size parameter during multi-layer segmentation was not
                        working properly.
                    </p>
                    <p>・Fixed a bug in log file output.</p>
                    <br />
                    <p className="font-bold">■ For Lite v1.0.2</p>
                    <p>New Features</p>
                    <p>・Added size recalculation menu.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_0_2}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_0_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_0_2}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_0_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_0_2}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_0_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_0_2}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_0_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_5_2}>
                    {DF_SCANNER_GPU_FILENAME_V1_5_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_5_2}>
                    {DF_SCANNER_CPU_FILENAME_V1_5_2}
                </a>
            </div>

            {/* Pro版 v1.0.1, Lite版 v1.0.1, v1.5.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro v1.0.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite v1.0.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.5.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-03-11</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro v1.0.1 has been released.</p>
                    <p>DF Scanner Lite v1.0.1 has been released.</p>
                    <p>DF Scanner v1.5.1 has been released.</p>
                    <br />
                    <p className="font-bold">■ Common</p>
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue where the tree species identification did not work.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_0_1}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_0_1}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_0_1}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_0_1}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_5_1}>
                    {DF_SCANNER_GPU_FILENAME_V1_5_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_5_1}>
                    {DF_SCANNER_CPU_FILENAME_V1_5_1}
                </a>
            </div>

            {/* Pro版 v1.0.0, Lite版 v1.0.0, v1.5.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro v1.0.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite v1.0.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.5.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-03-07</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro v1.0.0 has been released.</p>
                    <p>DF Scanner Lite v1.0.0 has been released.</p>
                    <p>DF Scanner v1.5.0 has been released.</p>
                    <br />
                    <p className="font-bold">■ Common</p>
                    <p>New Features</p>
                    <p>・Added fully automatic analysis function.</p>
                    <p>・Added area clipping function.</p>
                    <br />
                    <p>Function Improvements</p>
                    <p>・The sugi hinoki identification model has been improved.</p>
                    <p>・Modified to delete unnecessary cache when starting the software.</p>
                    <br />
                    <p className="font-bold">■ For Pro v1.0.0, DF Scanner v1.5.0</p>
                    <p>Function Improvements</p>
                    <p>・Modified to allow DBH estimation even when there is no tree species information.</p>
                    <p>・Corrected to recalculate tree crown area when estimating DBH.</p>
                    <p>・Some improvements have been made to the polygon creation function.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue where columns were not referenced when creating My Model.</p>
                    <p>・Fixed an issue where the image would shift when cropping in My Model Creation.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_0_0}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_0_0}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_0_0}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_0_0}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_5_0}>
                    {DF_SCANNER_GPU_FILENAME_V1_5_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_5_0}>
                    {DF_SCANNER_CPU_FILENAME_V1_5_0}
                </a>
            </div>

            {/* v1.4.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.4.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-02-02</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.4.1 has been released.</p>
                    <br />
                    <p>Function Improvements</p>
                    <p>・The thickness of polygon lines now automatically changes during zooming and scaling.</p>
                    <p>・The point size now automatically changes during zooming and scaling.</p>
                    <p>・Added shortcut keys for polygon editing (add, delete, merge, split, remove).</p>
                    <p>・Enabled movement by pressing the wheel during polygon editing.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue where errors occur when calculating selection statistics.</p>
                    <p>
                        ・Fixed a problem where column names would change to "Species_x, Species_y" when analyzing the
                        same file twice with sitetuning or Onetime Deeplearning.
                    </p>
                    <p>・Fixed an error that occurs when reassigning the "polygonID" column during table editing.</p>
                    <p>
                        ・Corrected an issue where the number of vertices would decrease when small polygons are loaded.
                    </p>
                    <p>・Fixed a problem where new shapefiles would not display when toggling visibility on/off.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_4_1}>
                        {DF_SCANNER_GPU_FILENAME_V1_4_1}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_4_1}>
                        {DF_SCANNER_CPU_FILENAME_V1_4_1}
                    </a>
                </div>
            </div>

            {/* v1.4.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.4.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-01-24</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.4.0 has been released.</p>
                    <br />
                    <p>New Features</p>
                    <p>・It is now possible to edit the table when editing polygon data.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue where "Statistical Calculation" is conducted.</p>
                    <p>
                        ・Fixed a problem that stopped calculations when generating mask images if the CRS of ortho
                        images and CHM data were different.
                    </p>
                    <p>
                        ・Fixed an issue where tree apex detection would not work when the smoothing option for CHM is
                        unchecked.
                    </p>
                    <p>・Changed the encoding of shapefiles outputted by multi-layer segmentation to Shift_JIS.</p>
                    <p>・Fixed a problem where CHM calculation would stop for large-sized files.</p>
                    <br />
                    <p>Function Improvements</p>
                    <p>・Accelerated the process of coniferous tree crown separation.</p>
                    <p>・Accelerated the tree species identification process.</p>
                    <p>・Speeded up the rendering process for large GeoTIFF files.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_4_0}>
                        {DF_SCANNER_GPU_FILENAME_V1_4_0}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_4_0}>
                        {DF_SCANNER_CPU_FILENAME_V1_4_0}
                    </a>
                </div>
            </div>

            {/* v1.3.3*/}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.3.3</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-13</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.3.3 has been released.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue with the functionality of being able to log in for 7 days offline.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_3_3}>
                        {DF_SCANNER_GPU_FILENAME_V1_3_3}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_3_3}>
                        {DF_SCANNER_CPU_FILENAME_V1_3_3}
                    </a>
                </div>
            </div>

            {/* v1.3.2*/}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.3.2</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-12</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.3.2 has been released.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue with Onetime Deeplearning not working.</p>
                    <p>・Fixed an issue with the project save functionality not working in the commercial version.</p>
                    <p>・Adjusted behavior during masking for data that is not operational.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_3_2}>
                        {DF_SCANNER_GPU_FILENAME_V1_3_2}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_3_2}>
                        {DF_SCANNER_CPU_FILENAME_V1_3_2}
                    </a>
                </div>
            </div>

            {/* v1.3.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.3.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-08</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.3.1 has been released.</p>
                    <br />
                    <p>DF Scanner v1.3.1 includes the following additional features:</p>
                    <p>- Polygon editing capabilities (addition, deletion, splitting, merging)</p>
                    <p>
                        - Forest compartment statistics calculation (corresponding to crown polygons and tree vertices)
                    </p>
                    <p>- Mask image creation function compatible with CHM created from LiDAR data</p>
                    <p>- Tree height calculation function for tree vertices</p>
                    <br />
                    <p>Additionally, the following items have been improved:</p>
                    <p>Acceleration of coniferous tree crown separation function</p>
                    <p>Acceleration of polygon tree height calculation function</p>
                    <br />
                    <p>Furthermore, documentation has been added, including:</p>
                    <p>Manual updates</p>
                    <p>Addition of purpose-specific procedural explanations</p>
                    <p>Simple manual in PDF format</p>
                    <p>YouTube link explaining analysis methods</p>
                    <p>Supplement to the privacy policy</p>
                    <br />
                    <p>We have also addressed minor issues.</p>
                    <br />
                    <p>Additionally, starting from this release, we now support credit card payments online.</p>
                    <p>For more details, please check our DF WebService page.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_3_1}>
                        {DF_SCANNER_GPU_FILENAME_V1_3_1}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_3_1}>
                        {DF_SCANNER_CPU_FILENAME_V1_3_1}
                    </a>
                </div>
            </div>

            {/* v1.2.4 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.2.4</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-10-02</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Feature Improvements</p>
                    <p>・Updated the tree species identification model.</p>
                    <p>・Simplified some of the confusing UI elements.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Addressed issues with loading certain data.</p>
                    <p>・Fixed other minor issues.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_2_4}>
                        {DF_SCANNER_GPU_FILENAME_V1_2_4}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_2_4}>
                        {DF_SCANNER_CPU_FILENAME_V1_2_4}
                    </a>
                </div>
                <p className="text-sm text-red-800">
                    *Not compatible with new licenses (licenses issued after December 8, 2023 cannot be activated)
                </p>
            </div>

            {/* v1.2.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.2.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-04-02</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Additional Features</p>
                    <p>・Implemented project save functionality.</p>
                    <p>・Implemented online backup functionality for project data.</p>
                    <p>・Implemented "Multi-Layer Segmentation" feature for deciduous tree crown separation.</p>
                    <p>
                        ・Implemented the ability to use National Land Information Division (GSI) DEM data online during
                        CHM calculation.
                    </p>
                    <p>・Added support for drawing and analyzing coordinate reference system data other than WGS84.</p>
                    <p>・Updated the tree species identification model.</p>
                    <p>・Implemented unsupervised classification of tree species.</p>
                    <p>・Implemented the functionality to create DBH estimation equations from on-site data.</p>
                    <p>・Enabled transparent rendering of shapefiles.</p>
                    <p>・Performed English localization for all tools.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue where drawing of certain orthoimages failed.</p>
                    <p>・Fixed an issue where coniferous tree crown separation did not work for some data.</p>
                    <p>
                        ・Fixed an issue where the coordinate reference system conversion function was not available for
                        some data.
                    </p>
                    <br />
                    <p>Other</p>
                    <p>・Updated the terms of service.</p>
                    <p>・Updated the manual.</p>
                    <p>・Released an English version of the manual.</p>
                    <p>・Made the installer multilingual.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_2_0}>
                        {DF_SCANNER_GPU_FILENAME_V1_2_0}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_2_0}>
                        {DF_SCANNER_CPU_FILENAME_V1_2_0}
                    </a>
                </div>
                <p className="text-sm text-red-800">
                    *Not compatible with new licenses (licenses issued after December 8, 2023 cannot be activated)
                </p>
            </div>

            {/* v1.1.6 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.6</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-11-25</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Bug Fixes</p>
                    <p>・Resolved the issue where DF Scanner would not launch when newly installed.</p>
                </div>
            </div>

            {/* v1.1.5 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.5</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-11-17</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Additional Features</p>
                    <p>・Added English language support.</p>
                    <p>・Improved the tree species identification accuracy for deciduous trees.</p>
                    <p>・Added the ability to import analysis results after analysis.</p>
                    <p>・Increased the calculation speed for coniferous tree crown separation.</p>
                    <p>・Introduced a new crown separation program.</p>
                    <p>・Enhanced drawing speed for TIFF files, especially from the second time onward.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed the issue where the program would not launch after updating.</p>
                    <p>・Resolved the problem where analysis could not be performed on large-sized data.</p>
                    <p>・Implemented other minor fixes.</p>
                    <br />
                    <p>Other</p>
                    <p>・Updated the manual.</p>
                </div>
            </div>

            {/* v1.1.4 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.4</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-09-30</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Additional Features</p>
                    <p>・Added coordinate reference system conversion functionality.</p>
                    <p>・Introduced the capability to retrieve raster values.</p>
                    <p>・Added the ability to import analysis results after analysis.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed a bug in the calculation formula of the timber volume calculation program.</p>
                    <p>
                        ・Resolved an issue where the calculation was not performed correctly during CHM calculation
                        when some DEM values were missing.
                    </p>
                    <br />
                    <p>Implemented other minor fixes.</p>
                    <p>Updated the manual.</p>
                </div>
            </div>

            {/* v1.1.3 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.3</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-09-20</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Bug Fixes</p>
                    <p>・Fixed a bug in the calculation formula of the timber volume calculation program.</p>
                </div>
            </div>

            {/* v1.1.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-08-26</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Bug Fixes</p>
                    <p>・Fixed the issue where the program would not launch on some PCs.</p>
                </div>
            </div>

            {/* v1.1.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-08-26</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Additional Features</p>
                    <p>・Added functionality for estimating carbon accumulation.</p>
                    <p>・Implemented deactivation functionality.</p>
                    <p>
                        ・Added area, density, volume per unit area of timber, and carbon accumulation to statistical
                        information.
                    </p>
                    <br />
                    <p>Accuracy and Functionality Improvements</p>
                    <p>・Improved the identification model for Japanese cedar (Sugi) and cypress (Hinoki).</p>
                    <p>・Increased the speed of image extraction during tree species identification.</p>
                    <p>・Updated the timber volume estimation functionality.</p>
                    <p>・Enhanced the speed of timber volume estimation.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed a bug where the splash screen appeared during analysis.</p>
                </div>
            </div>

            {/* v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-07-01</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Released DF Scanner v1.0.0.</p>
                </div>
            </div>
        </div>
    );
};

export default ReleaseNotesDfScannerEn;
