import {
    DF_SCANNER_CPU_FILENAME_V1_2_0,
    DF_SCANNER_CPU_FILENAME_V1_2_4,
    DF_SCANNER_CPU_FILENAME_V1_3_1,
    DF_SCANNER_CPU_FILENAME_V1_3_2,
    DF_SCANNER_CPU_FILENAME_V1_3_3,
    DF_SCANNER_CPU_FILENAME_V1_4_0,
    DF_SCANNER_CPU_FILENAME_V1_4_1,
    DF_SCANNER_CPU_FILENAME_V1_5_0,
    DF_SCANNER_CPU_FILENAME_V1_5_1,
    DF_SCANNER_CPU_FILENAME_V1_5_2,
    DF_SCANNER_CPU_FILENAME_V1_6_0,
    DF_SCANNER_CPU_FILENAME_V1_6_1,
    DF_SCANNER_CPU_URL_V1_2_0,
    DF_SCANNER_CPU_URL_V1_2_4,
    DF_SCANNER_CPU_URL_V1_3_1,
    DF_SCANNER_CPU_URL_V1_3_2,
    DF_SCANNER_CPU_URL_V1_3_3,
    DF_SCANNER_CPU_URL_V1_4_0,
    DF_SCANNER_CPU_URL_V1_4_1,
    DF_SCANNER_CPU_URL_V1_5_0,
    DF_SCANNER_CPU_URL_V1_5_1,
    DF_SCANNER_CPU_URL_V1_5_2,
    DF_SCANNER_CPU_URL_V1_6_0,
    DF_SCANNER_CPU_URL_V1_6_1,
    DF_SCANNER_FILENAME_V1_7_0,
    DF_SCANNER_GPU_FILENAME_V1_2_0,
    DF_SCANNER_GPU_FILENAME_V1_2_4,
    DF_SCANNER_GPU_FILENAME_V1_3_1,
    DF_SCANNER_GPU_FILENAME_V1_3_2,
    DF_SCANNER_GPU_FILENAME_V1_3_3,
    DF_SCANNER_GPU_FILENAME_V1_4_0,
    DF_SCANNER_GPU_FILENAME_V1_4_1,
    DF_SCANNER_GPU_FILENAME_V1_5_0,
    DF_SCANNER_GPU_FILENAME_V1_5_1,
    DF_SCANNER_GPU_FILENAME_V1_5_2,
    DF_SCANNER_GPU_FILENAME_V1_6_0,
    DF_SCANNER_GPU_FILENAME_V1_6_1,
    DF_SCANNER_GPU_URL_V1_2_0,
    DF_SCANNER_GPU_URL_V1_2_4,
    DF_SCANNER_GPU_URL_V1_3_1,
    DF_SCANNER_GPU_URL_V1_3_2,
    DF_SCANNER_GPU_URL_V1_3_3,
    DF_SCANNER_GPU_URL_V1_4_0,
    DF_SCANNER_GPU_URL_V1_4_1,
    DF_SCANNER_GPU_URL_V1_5_0,
    DF_SCANNER_GPU_URL_V1_5_1,
    DF_SCANNER_GPU_URL_V1_5_2,
    DF_SCANNER_GPU_URL_V1_6_0,
    DF_SCANNER_GPU_URL_V1_6_1,
    DF_SCANNER_LITE_CPU_FILENAME_V1_0_0,
    DF_SCANNER_LITE_CPU_FILENAME_V1_0_1,
    DF_SCANNER_LITE_CPU_FILENAME_V1_0_2,
    DF_SCANNER_LITE_CPU_FILENAME_V1_1_0,
    DF_SCANNER_LITE_CPU_FILENAME_V1_1_1,
    DF_SCANNER_LITE_CPU_URL_V1_0_0,
    DF_SCANNER_LITE_CPU_URL_V1_0_1,
    DF_SCANNER_LITE_CPU_URL_V1_0_2,
    DF_SCANNER_LITE_CPU_URL_V1_1_0,
    DF_SCANNER_LITE_CPU_URL_V1_1_1,
    DF_SCANNER_LITE_FILENAME_V1_2_0,
    DF_SCANNER_LITE_GPU_FILENAME_V1_0_0,
    DF_SCANNER_LITE_GPU_FILENAME_V1_0_1,
    DF_SCANNER_LITE_GPU_FILENAME_V1_0_2,
    DF_SCANNER_LITE_GPU_FILENAME_V1_1_0,
    DF_SCANNER_LITE_GPU_FILENAME_V1_1_1,
    DF_SCANNER_LITE_GPU_URL_V1_0_0,
    DF_SCANNER_LITE_GPU_URL_V1_0_1,
    DF_SCANNER_LITE_GPU_URL_V1_0_2,
    DF_SCANNER_LITE_GPU_URL_V1_1_0,
    DF_SCANNER_LITE_GPU_URL_V1_1_1,
    DF_SCANNER_LITE_URL_V1_2_0,
    DF_SCANNER_PRO_CPU_FILENAME_V1_0_0,
    DF_SCANNER_PRO_CPU_FILENAME_V1_0_1,
    DF_SCANNER_PRO_CPU_FILENAME_V1_0_2,
    DF_SCANNER_PRO_CPU_FILENAME_V1_1_0,
    DF_SCANNER_PRO_CPU_FILENAME_V1_1_1,
    DF_SCANNER_PRO_CPU_URL_V1_0_0,
    DF_SCANNER_PRO_CPU_URL_V1_0_1,
    DF_SCANNER_PRO_CPU_URL_V1_0_2,
    DF_SCANNER_PRO_CPU_URL_V1_1_0,
    DF_SCANNER_PRO_CPU_URL_V1_1_1,
    DF_SCANNER_PRO_FILENAME_V1_2_0,
    DF_SCANNER_PRO_GPU_FILENAME_V1_0_0,
    DF_SCANNER_PRO_GPU_FILENAME_V1_0_1,
    DF_SCANNER_PRO_GPU_FILENAME_V1_0_2,
    DF_SCANNER_PRO_GPU_FILENAME_V1_1_0,
    DF_SCANNER_PRO_GPU_FILENAME_V1_1_1,
    DF_SCANNER_PRO_GPU_URL_V1_0_0,
    DF_SCANNER_PRO_GPU_URL_V1_0_1,
    DF_SCANNER_PRO_GPU_URL_V1_0_2,
    DF_SCANNER_PRO_GPU_URL_V1_1_0,
    DF_SCANNER_PRO_GPU_URL_V1_1_1,
    DF_SCANNER_PRO_URL_V1_2_0,
    DF_SCANNER_URL_V1_7_0,
} from "../../consts/Installers";

/**
 * リリースノート（DF Scanner）
 * @returns JSX.Element
 */
export const ReleaseNotesDfScanner = () => {
    return (
        <div>
            {/* Pro版 v1.2.0, Lite版 v1.2.0, v1.7.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro版 v1.2.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite版 v1.2.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.7.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-08-23</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro版 v1.2.0がリリースされました。</p>
                    <p>DF Scanner Lite版 v1.2.0がリリースされました。</p>
                    <p>DF Scanner v1.7.0がリリースされました。</p>
                    <br />
                    <p className="font-bold">■ 共通内容</p>
                    <p>機能追加</p>
                    <p>・地面補正機能をリニューアルしました。</p>
                    <p>・スギヒノキ分類のモデル更新をしました。</p>
                    <p>・炭素蓄積量推定からCO2固定量推定に変更しました。</p>
                    <p>・林相図作成機能を追加しました。</p>
                    <p>・GeoJSONファイルの表示に対応しました。</p>
                    <p>・ドーナツ型ポリゴン、マルチポリゴンの表示に対応しました。</p>
                    <p>・複数ファイルインポートに対応しました。</p>
                    <p>・Informationボタンでポリゴン情報が確認できるようになりました。</p>
                    <br />
                    <p>機能改善</p>
                    <p>・ポリゴン描画のパフォーマンスを改善しました。</p>
                    <p>・ベースマップ表示を修正しました。</p>
                    <p>・overview付きのGeoTIFFファイルの読み込みを高速化しました。</p>
                    <p>・選択統計情報計算を高速化しました。</p>
                    <p>・樹頂点検出アルゴリズムを一部改善しました。</p>
                    <br />
                    <p>その他</p>
                    <p>・オンラインバックアップ使用時のDFアカウント認証が追加されました。</p>
                    <p>・GPU版とCPU版のインストーラを統一しました。</p>
                    <p>・画面の一部文言修正や解析パラメータの説明等を追加しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_URL_V1_2_0}>
                    {DF_SCANNER_PRO_FILENAME_V1_2_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_URL_V1_2_0}>
                    {DF_SCANNER_LITE_FILENAME_V1_2_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_URL_V1_7_0}>
                    {DF_SCANNER_FILENAME_V1_7_0}
                </a>
            </div>

            {/* Pro版 v1.1.1, Lite版 v1.1.1, v1.6.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro版 v1.1.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite版 v1.1.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.6.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-06-10</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro版 v1.1.1がリリースされました。</p>
                    <p>DF Scanner Lite版 v1.1.1がリリースされました。</p>
                    <p>DF Scanner v1.6.1がリリースされました。</p>
                    <br />
                    <p className="font-bold">■ 共通内容</p>
                    <p>バグ修正</p>
                    <p>・初回起動時にプロジェクト保存が使用できない問題を修正しました。</p>
                    <p>・ポリゴン編集時にgeometryが空のポリゴンができる問題を修正しました。</p>
                    <p>・ポリゴン編集時に新規に作成したファイルの左に列が追加できない問題を修正しました。</p>
                    <p>・tfwファイルがあるときにGeoTIFFファイルとして認識されない問題を修正しました。</p>
                    <br />
                    <p>その他</p>
                    <p>・ソフトウェアのデフォルトのインストール先を変更しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_1_1}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_1_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_1_1}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_1_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_1_1}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_1_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_1_1}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_1_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_6_1}>
                    {DF_SCANNER_GPU_FILENAME_V1_6_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_6_1}>
                    {DF_SCANNER_CPU_FILENAME_V1_6_1}
                </a>
            </div>

            {/* Pro版 v1.1.0, Lite版 v1.1.0, v1.6.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro版 v1.1.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite版 v1.1.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.6.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-05-14</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro版 v1.1.0がリリースされました。</p>
                    <p>DF Scanner Lite版 v1.1.0がリリースされました。</p>
                    <p>DF Scanner v1.6.0がリリースされました。</p>
                    <br />
                    <p className="font-bold">■ 共通内容</p>
                    <p>機能追加</p>
                    <p>・地面補正時のオプションを追加しました（鉛直補正）。</p>
                    <br />
                    <p>機能改善</p>
                    <p>・DBH推定精度が向上しました。</p>
                    <p>・林班統計情報計算で非植生を計算対象外とするよう修正しました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・針葉樹樹冠分離時にポリゴンが抜ける問題を修正しました。</p>
                    <p>・ポリゴン編集時のバグを修正しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_1_0}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_1_0}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_1_0}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_1_0}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_6_0}>
                    {DF_SCANNER_GPU_FILENAME_V1_6_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_6_0}>
                    {DF_SCANNER_CPU_FILENAME_V1_6_0}
                </a>
            </div>

            {/* Pro版 v1.0.2, Lite版 v1.0.2, v1.5.2 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro版 v1.0.2</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite版 v1.0.2</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.5.2</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-03-27</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro版 v1.0.2がリリースされました。</p>
                    <p>DF Scanner Lite版 v1.0.2がリリースされました。</p>
                    <p>DF Scanner v1.5.2がリリースされました。</p>
                    <br />
                    <p className="font-bold">■ 共通内容</p>
                    <p>機能改善</p>
                    <p>・樹頂点検出、樹冠分離時の挙動を一部改善しました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・ポリゴン編集時のバグを修正しました。</p>
                    <p>
                        ・マルチレイヤーセグメンテーション時の最小樹冠サイズのパラメータがうまく機能していない問題を修正しました。
                    </p>
                    <p>・ログファイル出力の不具合を修正しました。</p>
                    <br />
                    <p className="font-bold">■ Lite版 v1.0.2向け</p>
                    <p>機能追加</p>
                    <p>・サイズ再計算のメニューを追加しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_0_2}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_0_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_0_2}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_0_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_0_2}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_0_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_0_2}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_0_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_5_2}>
                    {DF_SCANNER_GPU_FILENAME_V1_5_2}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_5_2}>
                    {DF_SCANNER_CPU_FILENAME_V1_5_2}
                </a>
            </div>

            {/* Pro版 v1.0.1, Lite版 v1.0.1, v1.5.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro版 v1.0.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite版 v1.0.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.5.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-03-11</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro版 v1.0.1がリリースされました。</p>
                    <p>DF Scanner Lite版 v1.0.1がリリースされました。</p>
                    <p>DF Scanner v1.5.1がリリースされました。</p>
                    <br />
                    <p className="font-bold">■ 共通内容</p>
                    <p>バグ修正</p>
                    <p>・樹種識別時にエラーになる問題を解消しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_0_1}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_0_1}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_0_1}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_0_1}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_5_1}>
                    {DF_SCANNER_GPU_FILENAME_V1_5_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_5_1}>
                    {DF_SCANNER_CPU_FILENAME_V1_5_1}
                </a>
            </div>

            {/* Pro版 v1.0.0, Lite版 v1.0.0, v1.5.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF Scanner Pro版 v1.0.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner Lite版 v1.0.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF Scanner v1.5.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-03-07</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner Pro版 v1.0.0がリリースされました。</p>
                    <p>DF Scanner Lite版 v1.0.0がリリースされました。</p>
                    <p>DF Scanner v1.5.0がリリースされました。</p>
                    <br />
                    <p className="font-bold">■ 共通内容</p>
                    <p>新機能</p>
                    <p>・全自動解析機能を追加しました。</p>
                    <p>・領域クリッピング機能を追加しました。</p>
                    <br />
                    <p>機能改善</p>
                    <p>・スギ・ヒノキ識別モデルを改良しました。</p>
                    <p>・ソフトウェア起動時に不要なキャッシュの削除をするよう修正しました。</p>
                    <br />
                    <p className="font-bold">■ Pro版 v1.0.0, DF Scanner v1.5.0向け</p>
                    <p>機能改善</p>
                    <p>・樹種情報がない場合でもDBH推定ができるよう修正しました。</p>
                    <p>・DBH推定時に樹冠面積を再計算するよう修正しました。</p>
                    <p>・ポリゴン作成機能を一部改善しました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・Myモデル作成時に列が参照されない問題を修正しました。</p>
                    <p>・Myモデル作成の画像切り抜き時にずれる問題を修正しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_SCANNER_PRO_GPU_URL_V1_0_0}>
                    {DF_SCANNER_PRO_GPU_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_PRO_CPU_URL_V1_0_0}>
                    {DF_SCANNER_PRO_CPU_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_GPU_URL_V1_0_0}>
                    {DF_SCANNER_LITE_GPU_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_LITE_CPU_URL_V1_0_0}>
                    {DF_SCANNER_LITE_CPU_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_5_0}>
                    {DF_SCANNER_GPU_FILENAME_V1_5_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_5_0}>
                    {DF_SCANNER_CPU_FILENAME_V1_5_0}
                </a>
            </div>

            {/* v1.4.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.4.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-02-02</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.4.1がリリースされました。</p>
                    <br />
                    <p>機能改善</p>
                    <p>・ポリゴンラインの太さが、拡大縮小時に自動で変化するようになりました。</p>
                    <p>・ポイントサイズが、拡大縮小時に自動で変化するようになりました。</p>
                    <p>・ポリゴン編集の際のショートカットキー（追加・削除・結合・分割・削除）を追加しました。</p>
                    <p>・ポリゴン編集時に、ホイールを押し込みながら移動できるようになりました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・選択統計情報計算を行うとエラーが出る問題を修正しました。</p>
                    <p>
                        ・sitetuningやOnetime Deeplearningで、同じファイルで2回解析したときに列名が「樹種_x,
                        樹種_y」になる問題を修正しました。
                    </p>
                    <p>・テーブル編集時に「polygonID」列を付けなおすとエラーが出る問題を修正しました。</p>
                    <p>・小さいポリゴンが読み込まれた際に頂点数が減ってしまう問題を修正しました。</p>
                    <p>・新規シェープファイル作成時、表示/非表示を切り替えると表示されなくなる問題を修正しました。</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_4_1}>
                        {DF_SCANNER_GPU_FILENAME_V1_4_1}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_4_1}>
                        {DF_SCANNER_CPU_FILENAME_V1_4_1}
                    </a>
                </div>
            </div>

            {/* v1.4.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.4.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-01-24</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.4.0がリリースされました。</p>
                    <br />
                    <p>新機能</p>
                    <p>・ポリゴンデータの編集時に、テーブルの編集ができるようになりました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・林班統計情報計算ができない問題を修正しました。</p>
                    <p>・マスク画像生成時にオルソ画像とCHMデータのCRSが異なる際に計算が止まる問題を修正しました。</p>
                    <p>・樹頂点検出時に、CHMの平滑化の有効化チェックを外したときに動かない問題を修正しました。</p>
                    <p>
                        ・マルチレイヤーセグメンテーションで出力されるシェープファイルのエンコーディングをShift_JISに変更しました。
                    </p>
                    <p>・サイズの大きなファイルでCHM計算が止まることのある問題を修正しました。</p>
                    <br />
                    <p>機能改善</p>
                    <p>・針葉樹樹冠分離処理を高速化しました。</p>
                    <p>・樹種識別処理を高速化しました。</p>
                    <p>・大きなGeoTIFFファイルの描画処理を高速化しました。</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_4_0}>
                        {DF_SCANNER_GPU_FILENAME_V1_4_0}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_4_0}>
                        {DF_SCANNER_CPU_FILENAME_V1_4_0}
                    </a>
                </div>
            </div>

            {/* v1.3.3 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.3.3</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-13</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.3.3がリリースされました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・オフライン時7日間ログインできる機能の問題修正</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_3_3}>
                        {DF_SCANNER_GPU_FILENAME_V1_3_3}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_3_3}>
                        {DF_SCANNER_CPU_FILENAME_V1_3_3}
                    </a>
                </div>
            </div>

            {/* v1.3.2*/}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.3.2</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-12</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.3.2がリリースされました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・Onetime Deeplearningが動かない問題の修正</p>
                    <p>・製品版でプロジェクト保存機能が動かない問題の修正</p>
                    <p>・マスキングの際に、デフォルトで動かないデータに対する挙動の修正</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_3_2}>
                        {DF_SCANNER_GPU_FILENAME_V1_3_2}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_3_2}>
                        {DF_SCANNER_CPU_FILENAME_V1_3_2}
                    </a>
                </div>
            </div>

            {/* v1.3.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.3.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-08</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.3.1がリリースされました。</p>
                    <br />
                    <p>DF Scanner v1.3.1では、以下の追加機能が追加されています。</p>
                    <p>- ポリゴンの編集機能（追加・削除・分割・結合）</p>
                    <p>- 林班統計情報計算（樹冠ポリゴン・樹頂点に対応）</p>
                    <p>- LiDARデータから作成したCHMに対応したマスク画像作成機能</p>
                    <p>- 樹頂点の樹高計算機能</p>
                    <br />
                    <p>また、以下の項目を改善いたしました。</p>
                    <p>針葉樹樹冠分離機能の高速化</p>
                    <p>ポリゴンの樹高計算機能の高速化</p>
                    <br />
                    <p>加えて、マニュアルの更新等、ドキュメントを追加しました。</p>
                    <p>マニュアルの更新</p>
                    <p>目的別手順の説明を追加</p>
                    <p>PDFによる簡易マニュアル</p>
                    <p>解析方法を説明するYouTubeリンク</p>
                    <p>プライバシーポリシーの追記</p>
                    <br />
                    <p>その他軽微な問題を修正しました。</p>
                    <br />
                    <p>また、今回からWeb上でクレジット支払いにも対応するようになりました。</p>
                    <p>詳細は、弊社DF WebServiceページをご確認ください。</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_3_1}>
                        {DF_SCANNER_GPU_FILENAME_V1_3_1}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_3_1}>
                        {DF_SCANNER_CPU_FILENAME_V1_3_1}
                    </a>
                </div>
            </div>

            {/* v1.2.4 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.2.4</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-10-02</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>機能改善</p>
                    <p>・樹種識別モデルを更新しました。</p>
                    <p>・わかりにくかったUIを一部簡素化しました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・一部読み込めなかったデータに対応しました。</p>
                    <p>・その他軽微な問題を修正しました。</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_2_4}>
                        {DF_SCANNER_GPU_FILENAME_V1_2_4}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_2_4}>
                        {DF_SCANNER_CPU_FILENAME_V1_2_4}
                    </a>
                </div>
                <p className="text-sm text-red-800">
                    *新ライセンス未対応（2023/12/8以降に発行したライセンスではアクティベーションできません）
                </p>
            </div>

            {/* v1.2.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.2.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-04-02</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>追加機能</p>
                    <p>・プロジェクト保存機能を実装しました。</p>
                    <p>・プロジェクトデータのオンラインバックアップ機能を実装しました。</p>
                    <p>・広葉樹の樹冠分離機能「マルチレイヤーセグメンテーション」を実装しました。</p>
                    <p>・CHM計算時に国土地理院のDEMデータをオンラインから利用する機能を実装しました。</p>
                    <p>・WGS84以外の座標参照系データの描画・解析に対応しました。</p>
                    <p>・樹種識別モデルのアップデートを行いました。</p>
                    <p>・樹種の教師無し分類を実装しました。</p>
                    <p>・現地データからのDBH推定式作成機能を実装しました。</p>
                    <p>・シェイプファイルの透過描画を可能にしました。</p>
                    <p>・全ツールの英語化を行いました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・一部オルソ画像の描画に失敗する問題を修正しました。</p>
                    <p>・針葉樹樹冠分離が一部データで動かない問題を修正しました。</p>
                    <p>・座標参照系変換機能が一部データに利用できない問題を修正しました。</p>
                    <p>その他</p>
                    <p>・利用規約を更新しました。</p>
                    <p>・マニュアルをアップデートしました。</p>
                    <p>・英語版マニュアルを公開しました。</p>
                    <p>・インストーラーの多言語対応を行いました。</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_GPU_URL_V1_2_0}>
                        {DF_SCANNER_GPU_FILENAME_V1_2_0}
                    </a>
                </div>
                <div>
                    <a className="link-text-blue" href={DF_SCANNER_CPU_URL_V1_2_0}>
                        {DF_SCANNER_CPU_FILENAME_V1_2_0}
                    </a>
                </div>
                <p className="text-sm text-red-800">
                    *新ライセンス未対応（2023/12/8以降に発行したライセンスではアクティベーションできません）
                </p>
            </div>

            {/* v1.1.6 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.6</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-11-25</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>バグ修正</p>
                    <p>・新たにDF Scannerをインストールした際に、起動しない問題を解決しました。</p>
                </div>
            </div>

            {/* v1.1.5 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.5</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-11-17</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>追加機能</p>
                    <p>・英語対応しました。</p>
                    <p>・広葉樹の樹種識別精度が向上しました。</p>
                    <p>・解析後に解析結果をインポートする機能を追加しました。</p>
                    <p>・針葉樹樹冠分離計算速度が向上しました。</p>
                    <p>・新たな樹冠分離プログラムを追加しました。</p>
                    <p>・TIFFファイルの描画が、2回目以降高速化しました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・アップデート時にプログラムが起動しなくなる問題を修正しました。</p>
                    <p>・大きいサイズのデータで解析ができない問題を修正しました。</p>
                    <p>・その他軽微な修正を行いました。</p>
                    <p>その他</p>
                    <p>・マニュアルをアップデートしました。</p>
                </div>
            </div>

            {/* v1.1.4 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.4</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-09-30</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>追加機能</p>
                    <p>・座標参照系変換機能が追加されました。</p>
                    <p>・ラスター値の取得機能が追加されました。</p>
                    <p>・解析後に解析結果をインポートする機能を追加しました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・幹材積計算プログラムの計算式のバグを修正しました。</p>
                    <p>・CHM計算時にDEMの値が一部抜けている場合に正しく計算が行われないバグを修正しました。</p>
                    <br />
                    <p>その他軽微な修正を行いました。</p>
                    <p>マニュアルをアップデートしました。</p>
                </div>
            </div>

            {/* v1.1.3 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.3</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-09-20</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>バグ修正</p>
                    <p>・幹材積計算プログラムの計算式のバグを修正しました。</p>
                </div>
            </div>

            {/* v1.1.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-08-26</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>バグ修正</p>
                    <p>・一部のPCで起動しない問題を修正しました。</p>
                </div>
            </div>

            {/* v1.1.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.1.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-08-26</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>追加機能</p>
                    <p>・炭素蓄積量推定機能が追加されました。</p>
                    <p>・ディアクティベーション機能が追加されました。</p>
                    <p>・統計情報に面積・密度・面積あたり幹材積量・炭素蓄積量が追加されました。</p>
                    <br />
                    <p>精度・機能改良</p>
                    <p>・スギ・ヒノキ識別モデルが改良されました。</p>
                    <p>・樹種識別時の画像抽出機能が高速化されました。</p>
                    <p>・幹材積推定機能が更新されました。</p>
                    <p>・幹材積推定機能が高速化されました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・スプラッシュスクリーンが解析時表示されるバグを修正しました。</p>
                </div>
            </div>

            {/* v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Scanner v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-07-01</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Scanner v1.0.0をリリースしました。</p>
                </div>
            </div>
        </div>
    );
};

export default ReleaseNotesDfScanner;
