import { API } from "aws-amplify";
import { API_ENDPOINT_NAME } from "../consts/constants";
import { loggingInfo, loggingWarn } from "../utils/LoggingUtils";

/**
 * 代理店情報一覧取得
 *
 * @param userId ユーザーID
 * @returns 代理店情報一覧取得APIレスポンス
 */
export async function getAgencies(idToken: string, userId?: string) {
    const path = `/agencies`;
    const body: { [name: string]: any } = {};
    if (userId) {
        body["user_id"] = userId;
    }
    const request = {
        body: body,
        headers: {
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[GetAgencies] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[GetAgencies] Failed:", error);
        throw error;
    }
}
