import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";

interface RouteAuthGuardProps {
    component: React.ReactNode;
    redirect: string;
}

/**
 * ユーザーの認証状態を確認し、未認証の場合は指定したページへリダイレクトさせる
 * @param component 元のアクセス先コンポーネント
 * @param redirect 未認証時のリダイレクト先パス
 * @returns コンポーネント
 */
const RouteAuthGuard: React.FC<RouteAuthGuardProps> = ({ component, redirect }) => {
    const { user, isLoading } = useUserContext();
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        // ユーザー情報が取得されるまで待機
        if (!isLoading && !user) {
            setShouldRedirect(true);
        }
    }, [user, isLoading]);

    if (shouldRedirect) {
        return <Navigate to={redirect} />;
    }
    return <>{component}</>;
};
export default RouteAuthGuard;
