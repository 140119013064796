import React, { Dispatch, SetStateAction } from "react";

interface PagenationButtonsProps {
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    totalPages: number;
}

const PagenationButtons: React.FC<PagenationButtonsProps> = ({ currentPage, setCurrentPage, totalPages }) => {
    /**
     * ページネーション・次へ
     */
    const nextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    /**
     * ページネーション・前へ
     */
    const prevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    /**
     * ページネーション・指定ページへ
     * @param page
     */
    const goToPage = (page: number) => {
        setCurrentPage(Math.min(Math.max(page, 1), totalPages));
    };

    const renderPageNumbers = () => {
        const displayPages = [];
        const maxVisiblePages = 5; // 表示する最大ページ番号の数

        if (totalPages <= maxVisiblePages) {
            // ページ番号が最大表示数未満の場合、全てのページ番号を表示
            for (let i = 1; i <= totalPages; i++) {
                displayPages.push(i);
            }
        } else {
            // ページ番号が最大表示数以上の場合、途中のページ番号を省略して表示
            const startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
            const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

            // 最初のページ番号を表示
            if (startPage > 1) {
                displayPages.push(1);
                if (startPage > 2) {
                    displayPages.push("...");
                }
            }

            // 省略された途中のページ番号を表示
            for (let i = startPage; i <= endPage; i++) {
                displayPages.push(i);
            }

            // 最後のページ番号を表示
            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    displayPages.push("...");
                }
                displayPages.push(totalPages);
            }
        }

        // ページ番号ボタンをレンダリング
        return displayPages.map((pageNumber, index) => {
            if (typeof pageNumber === "number") {
                return (
                    <button
                        key={index}
                        onClick={() => goToPage(pageNumber)}
                        disabled={pageNumber === currentPage}
                        className={`rounded-md border border-gray-300 px-3 py-1 ${
                            pageNumber === currentPage ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-gray-100"
                        }`}
                    >
                        {pageNumber}
                    </button>
                );
            } else {
                return (
                    <span key={index} className="px-3 py-1">
                        {pageNumber}
                    </span>
                );
            }
        });
    };

    return (
        <div className="mt-1 flex items-center justify-center space-x-2">
            <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`rounded-md border border-gray-300 px-3 py-1 ${
                    currentPage === 1 ? "cursor-not-allowed text-gray-400" : "text-gray-700 hover:bg-gray-100"
                }`}
            >
                Prev
            </button>
            {renderPageNumbers()}
            <button
                onClick={nextPage}
                disabled={currentPage === totalPages}
                className={`rounded-md border border-gray-300 px-3 py-1 ${
                    currentPage === totalPages ? "cursor-not-allowed text-gray-400" : "text-gray-700 hover:bg-gray-100"
                }`}
            >
                Next
            </button>
        </div>
    );
};
export default PagenationButtons;
