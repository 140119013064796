/** E001:パラメータ異常 */
export const E001 = "E001";

/** E101:ユーザー登録済み */
export const E101 = "E101";
/** E102:ユーザーが見つからない */
export const E102 = "E102";
/** E103:管理ユーザー不在 */
export const E103 = "E103";

/** E201:ライセンス情報が見つからない */
export const E201 = "E201";
/** E202:対応するユーザー情報がみつからない */
export const E202 = "E202";
/** E203: ライセンスの所有者が異なる */
export const E203 = "E203";
/** E204: ライセンスの有効期限外 */
export const E204 = "E204";
/** E205: ライセンス使用中 */
export const E205 = "E205";

/** E400: 無料体験利用済み */
export const E400 = "E400";
/** E401: 無料体験複数購入不可 */
export const E401 = "E401";
