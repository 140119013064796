import jsPDF from "jspdf";
import { PaymentHistoryWithLicenses } from "../types/PaymentHistoryWithLicenses";
import { formatUtcToLocalDateJAFormat, formatUtcToLocalDateTimeJAFormat } from "./DateUtils";
import "../js/ipaexg-normal";

const DF_SCANNER_PACKAGE_ID = 221;
const DF_LAT_PACKAGE_ID = 7544;

/**
 * ライセンス証書（PDF）を発行する
 *
 * @param paymentHistoryWithLicenses 購入情報/ライセンス情報（Checkout APIのレスポンス）
 */
export function issueCertificate(paymentHistoryWithLicenses: PaymentHistoryWithLicenses) {
    // 必要パラメータの抽出
    const productName = paymentHistoryWithLicenses.p_product_type_name.split("%%")[0];
    const activationDate = paymentHistoryWithLicenses.licenses[0].activation_date;
    const expireDate = paymentHistoryWithLicenses.licenses[0].expire_date;
    const paymentDate = paymentHistoryWithLicenses.payment_date;

    // ライセンス情報の確認（DF Scannerがあるか / DF LATがあるか）
    const licenseList = paymentHistoryWithLicenses.licenses;
    const hasDFScanner = licenseList.map((l) => l.package_id).includes(DF_SCANNER_PACKAGE_ID);
    const hasDFLat = licenseList.map((l) => l.package_id).includes(DF_LAT_PACKAGE_ID);

    // ライセンス情報の内容によってタイトルを決定
    let title = "";
    let titleX = 0;
    if (hasDFScanner && hasDFLat) {
        title = "DF Scanner & DF LAT license";
        titleX = 70;
    } else if (hasDFScanner) {
        title = "DF Scanner license";
        titleX = 80;
    } else {
        title = "DF LAT license";
        titleX = 85;
    }

    const doc = new jsPDF({
        orientation: "p", // 縦向き
        format: "a4", // A4
    });
    doc.setFont("ipaexg", "normal"); // 日本語にするためのフォーマット

    let y = 0;
    y += 30;
    doc.setFontSize(12);
    doc.text(
        "---------------------------------------------------------------------------------------------------------",
        25,
        y,
    );
    y += 10;
    doc.text(title, titleX, y);
    y += 10;
    doc.text(
        "---------------------------------------------------------------------------------------------------------",
        25,
        y,
    );

    y += 15;
    doc.text("*** 商品名 ***", 25, y);
    y += 6;
    doc.text(productName, 25, y);

    y += 20;
    doc.text("*** ライセンス番号 ***", 25, y);
    if (hasDFScanner) {
        y += 7;
        doc.text("<DF Scanner>", 25, y);
        licenseList
            .filter((l) => l.package_id === DF_SCANNER_PACKAGE_ID)
            .forEach((l) => {
                y += 6;
                doc.text(l.license_key, 25, y);
            });
    }
    if (hasDFLat) {
        y += 7;
        doc.text("<DF LAT>", 25, y);
        licenseList
            .filter((l) => l.package_id === DF_LAT_PACKAGE_ID)
            .forEach((l) => {
                y += 6;
                doc.text(l.license_key, 25, y);
            });
    }

    y += 20;
    doc.text("*** 有効期間 ***", 25, y);
    y += 6;
    doc.text(formatUtcToLocalDateTimeJAFormat(activationDate), 25, y);
    doc.text("～", 75, y);
    doc.text(formatUtcToLocalDateTimeJAFormat(expireDate), 82, y);

    y += 20;
    doc.text("*** 発行日 ***", 25, y);
    y += 6;
    doc.text(formatUtcToLocalDateJAFormat(paymentDate), 25, y);

    y += 20;
    doc.text("*** 発行元 ***", 25, y);
    y += 6;
    doc.text("DeepForest Technologies 株式会社", 25, y);

    doc.save(title + ".pdf");
}
