import { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from "react";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";
import { Trans, useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import { ScrollToTop } from "../../components/ScrollToTop";
import {
    createFolder,
    extractFilesByProject,
    extractProjects,
    getFile,
    getUrl,
    getJsonObject,
    getFileList,
    getSTScredentials,
    putFile,
    removeFile,
    copyFile,
    getStorageSize,
} from "../../clients/S3ClientNew";

import { useUserContext } from "../../contexts/UserContext";
import S3FileDeleteConfirmModal from "../modals/S3FileDeleteConfirmModal";
import MapContent from "./MapContent";
import FileListTable from "./FileListTable";
import UploadFileContent from "./UploadFileContent";
import { SelectProject, SelectFolder, NEW_PROJECT, CreateNewProject } from "./ProjectSelector";
import MessageAndLoading from "./MessageAndLoading";
import ShareFolderModal from "./ShareFolderModal";
import StorageModal from "./StorageModal";
import TopButtonList from "./TopButtonList";
import { Helmet } from "react-helmet";

/**
 * 代理店ユーザーが所属するグループIDのリスト
 *
 * ★グループIDが該当する場合はグループ内でのフォルダの共有機能をオフにする
 */
const AGENCY_GROUP_ID_LIST = [27];

/**
 * 地図上でデータ確認
 * @returns JSX.Element
 */
const WebGIS = () => {
    const { t } = useTranslation();
    const { user } = useUserContext();
    const [fileList, setFileList] = useState<S3ProviderListOutputItem[]>([]);
    const [filteredFileList, setFilteredFileList] = useState<any[]>([]);
    const [colorColumnList, setColorColumnList] = useState<any[]>([]);
    const [projectList, setProjectList] = useState<string[]>([]);
    const [selectedProject, setSelectedProject] = useState<string>("");
    const [newProjectName, setNewProjectName] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<S3ProviderListOutputItem>();
    const [enableProjectDelete, setEnableProjectDelete] = useState(false);

    /*　共有機能を有効にするかどうか（代理店発行ユーザーの場合はfalseにする） */
    const [enableSharing, setEnableSharing] = useState(false);

    const [loading, setLoading] = useState(true);
    const [fileLoading, setFileLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [showShareFolderModal, setShowShareFolderModal] = useState<boolean>(false);
    const [showStorageModal, setShowStorageModal] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [modalErrorMessage, setModalErrorMessage] = useState<string>("");
    const [acceptedFileList, setAcceptedFileList] = useState<File[]>([]);
    const [credentials, setCredentials] = useState<any>(null);
    const [rootFolderList, setRootFolderList] = useState<string[]>([]);
    const [rootFolder, setRootFoleder] = useState<string>("");
    const [storageSize, setStorageSize] = useState<any>(null);

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);
    useEffect(() => {
        if (!user) {
            // ページリロード時はコンテキストも初期化されるため、空の場合は一度リターンする
            // コンテキストが生成されユーザー情報が生成された時点で再実行する
            return;
        }
        if (!effectRun.current) {
            initStorage();
            return () => {
                effectRun.current = true;
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const initCredential = async () => {
            if (!credentials) {
                const cred = await getSTScredentials();
                if (!rootFolder) {
                    setRootFoleder(cred.email);
                }
                setCredentials(cred);
            } else {
                if (AGENCY_GROUP_ID_LIST.includes(credentials.groupId)) {
                    // 代理店ユーザーの場合は共有機能を無効にする
                    setEnableSharing(false);
                    setRootFolderList([user.attributes.email]);
                } else {
                    // それ以外のユーザーの場合は共有機能を有効にする
                    setEnableSharing(true);
                    const shareFolder = `department_share/${credentials.groupId}`;
                    setRootFolderList([user.attributes.email, shareFolder]);
                }
            }
        };
        initCredential();
    }, [credentials]);

    useEffect(() => {
        if (rootFolder) {
            fetchFileList();
        }
    }, [rootFolder]);

    /**
     * ストレージサイズの取得
     * @returns
     */
    const initStorage = async () => {
        const storageSize = await getStorageSize();
        setStorageSize(storageSize);
    };

    /**
     * S3からファイルリストの取得
     * @returns
     */
    async function fetchFileList() {
        setLoading(true);
        try {
            const list: any = await getFileList(rootFolder, credentials);
            setFileList(list);
            // プロジェクトの抽出
            const pList = extractProjects(list, rootFolder);
            setProjectList(pList);
            // ストレージサイズの更新
            initStorage();
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        } finally {
            setLoading(false);
        }
    }

    /**
     * S3からファイルをダウンロード
     * @param file
     * @returns
     */
    async function downloadFile(file: S3ProviderListOutputItem) {
        setErrorMessage("");
        try {
            if (file.key) {
                const filename = file.key.substring(file.key.lastIndexOf("/") + 1);
                await getFile(file.key, filename, credentials);
            }
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        }
    }

    /**
     * S3からファイルを削除
     * @param file
     * @returns
     */
    async function deleteFile(file: any) {
        setIsProcessing(true);
        setModalErrorMessage("");
        try {
            if (file.key) {
                const fileList: any = await getFileList(file.filename, credentials);
                await fileList.forEach(async (element: any) => {
                    await removeFile(element.key, credentials);
                });
                // ※状態反映待機の１秒のインターバル
                await new Promise((resolve) => setTimeout(resolve, 1000));
                // 表示中のファイルリストから対象ファイルを削除
                setFilteredFileList(filteredFileList.filter((f) => f.key != file.key));
                // メモリ中のファイルリスト更新
                await fetchFileList();
            }
            setShowDeleteModal(false);
        } catch (error) {
            setModalErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        } finally {
            setIsProcessing(false);
        }
    }

    /**
     * S3からフォルダを削除
     * @param file
     * @returns
     */
    async function deleteFolder() {
        setLoading(true);
        try {
            const deletePath = `${rootFolder}/${selectedProject}/`;
            fileList
                .filter((item: any) => item.key.includes(deletePath))
                .forEach(async (element: any) => {
                    await removeFile(element.key, credentials);
                });
            // ※状態反映待機の１秒のインターバル
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setSelectedProject("");
            await fetchFileList();
            setSuccessMessage(t("WebGIS.proccessSuccessed"));
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        } finally {
            setLoading(false);
        }
    }

    /**
     * プロジェクト選択時のイベント処理
     */
    const handleProjectChange: ChangeEventHandler<HTMLSelectElement> = async (event) => {
        setFileLoading(true);
        try {
            setSuccessMessage("");
            setErrorMessage("");
            setSelectedProject(event.target.value);
            await updateFilteredFileList(event.target.value);
        } finally {
            setFileLoading(false);
        }
    };

    /**
     * ファイル一覧の更新
     * @returns
     */
    const updateFilteredFileList = async (pname: string) => {
        const filteredByProject = extractFilesByProject(fileList, pname);
        const fileNameList = filteredByProject.map((item: any) => item.key);
        if (fileNameList.length === 0) {
            setEnableProjectDelete(true);
        } else {
            setEnableProjectDelete(false);
        }
        const filterdByExtention = filteredByProject.filter((res: any) => {
            // ファイルの拡張子を取得
            const extension = res.key?.split(".").pop();

            // geojson
            if (extension === "geojson") {
                return true;

                // tiffの場合はwalkerとpngがあるか
            } else if (extension == "tiff" || extension == "tif") {
                const isWalker = fileNameList.includes(res.key.replace(`.${extension}`, ".walker"));
                const isPng = fileNameList.includes(res.key.replace(`.${extension}`, ".png"));
                if (isWalker && isPng) {
                    return true;
                } else {
                    return false;
                }
                // その他はフィルター
            } else {
                return false;
            }
        });
        const layerList = filterdByExtention.map(async (item: any) => {
            const filenameWithoutExtension = item.key?.substring(0, item.key.lastIndexOf("."));
            const extension = item.key?.split(".").pop();
            var data;
            if (extension === "geojson") {
                data = await createVectorData(item);
            } else {
                data = await createRasterData(item);
            }
            const layer = {
                filename: filenameWithoutExtension,
                type: extension === "geojson" ? "vector" : "raster",
                data: data,
                see: false,
                changed: false,
            };
            return Object.assign(layer, item);
        });
        await Promise.all(layerList).then((results) => {
            setFilteredFileList(results);
            const initColorColumnList = results
                .filter((layer: any) => layer.type === "vector")
                .map((item: any) => {
                    return { filename: item.filename, column: "nan" };
                });
            setColorColumnList(initColorColumnList);
        });
    };

    /**
     * ラスターデータの作成
     */
    const createRasterData = async (file: any) => {
        try {
            const filenameWithoutExtension = file.key.substring(0, file.key.lastIndexOf("."));
            const imageFilename = `${filenameWithoutExtension}.png`;
            const walkerFilename = `${filenameWithoutExtension}.walker`;
            const imageUrlData = await getUrl(imageFilename, credentials);
            const walkerData = await getJsonObject(walkerFilename, credentials);
            const bounds = [walkerData.bottomLeft, walkerData.topRight];
            const rData = {
                url: imageUrlData,
                bounds,
            };
            return rData;
        } catch {
            return null;
        }
    };

    /**
     * ベクターデータの作成
     */
    const createVectorData = async (file: any) => {
        try {
            const filenameWithoutExtension = file.key.substring(0, file.key.lastIndexOf("."));
            const jsonFilename = `${filenameWithoutExtension}.geojson`;
            const jsonData = await getJsonObject(jsonFilename, credentials);
            return jsonData;
        } catch {
            return null;
        }
    };

    /**
     * 新規プロジェクト登録押下時のイベント処理
     * @param event
     * @returns
     */
    const handleRegisterProject: MouseEventHandler<HTMLButtonElement> = async (event) => {
        setLoading(true);
        setErrorMessage("");
        try {
            // フォルダ作成
            await createFolder(`${rootFolder}/${newProjectName}/`, credentials);
            // ※状態反映待機の１秒のインターバル
            await new Promise((resolve) => setTimeout(resolve, 1000));
            // 選択状態初期化＆ファイル一覧再取得
            setNewProjectName("");
            setSelectedProject("");
            await fetchFileList();
            setSuccessMessage(t("DataManagement.proccessSuccessed"));
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        } finally {
            setLoading(false);
        }
    };

    /**
     * ファイルアップロード前の容量確認処理
     * @returns
     */
    const uploadCheck = () => {
        const usedSharedStorage = storageSize ? storageSize.used.share_folder : 0;
        const myUsedStorage = storageSize ? storageSize.used.myfolder : 0;
        const othersUsedStorage = storageSize ? storageSize.used.other_members : 0;
        const totalUsedStorage = usedSharedStorage + myUsedStorage + othersUsedStorage;
        let uploadFileSize = 0;
        acceptedFileList.forEach((item: any) => {
            uploadFileSize += item.size;
        });
        if (
            uploadFileSize + myUsedStorage > storageSize.allocated.myfolder ||
            uploadFileSize + usedSharedStorage > storageSize.allocated.share_folder
        ) {
            return false;
        } else {
            return true;
        }
    };

    /**
     * ファイルアップロード押下時の処理
     * @returns
     */
    const handleUpload = async () => {
        const uploadPromises = acceptedFileList.map(async (file) => {
            const key: string = rootFolder + "/" + selectedProject + "/" + file.name;
            await putFile(key, file, credentials);
        });
        await Promise.all(uploadPromises);
        await fetchFileList();
        await updateFilteredFileList(selectedProject);
        initStorage();
        setAcceptedFileList([]);
    };

    /**
     * ルートフォルダのチェンジの処理
     * @returns
     */
    const handleRootChange = (event: any) => {
        setRootFoleder(event.target.value);
        setSelectedProject("");
    };

    /**
     * フォルダ共有の処理
     * @returns
     */
    const handleShareFolder = async () => {
        fileList
            .filter((item: any) => item.key.includes(`${rootFolder}/${selectedProject}`))
            .forEach(async (item: any) => {
                const toPath = item.key.replace(rootFolder, rootFolderList.filter((item) => item !== rootFolder)[0]);
                await copyFile(item.key, toPath, credentials);
            });
    };

    /**
     * リストアップデートの処理
     * @returns
     */
    const handleListUpdate = async () => {
        fetchFileList();
        updateFilteredFileList(selectedProject);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("WebGIS.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("WebGIS.title")}</h1>

                {/* メッセージとローディング */}
                <MessageAndLoading successMessage={successMessage} errorMessage={errorMessage} loading={loading} />

                {!loading && (
                    <>
                        {/* フォルダ選択 */}
                        {enableSharing && (
                            <SelectFolder
                                rootFolder={rootFolder}
                                handleChange={handleRootChange}
                                rootFolderList={rootFolderList}
                            />
                        )}

                        {/* プロジェクト選択 */}
                        <SelectProject
                            selectedProject={selectedProject}
                            handleProjectChange={handleProjectChange}
                            projectList={projectList}
                            enableNewProject={true}
                        />

                        {/* 新規プロジェクト作成 */}
                        {selectedProject == NEW_PROJECT && (
                            <CreateNewProject
                                setNewProjectName={setNewProjectName}
                                handleRegisterProject={handleRegisterProject}
                            />
                        )}

                        {/* プロジェクト選択後のファイルロード中 */}
                        {selectedProject && selectedProject != NEW_PROJECT && fileLoading && (
                            <div className="flex flex-col items-center justify-center p-10">
                                <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                                <p className="p-2 font-bold text-gray-800">{t("WebGIS.checkingProjectFiles")}</p>
                            </div>
                        )}

                        {/* プロジェクト選択時に表示 */}
                        {selectedProject && selectedProject != NEW_PROJECT && !fileLoading && (
                            <div className="mx-auto max-w-5xl overflow-x-auto pt-4 transition duration-500 ease-in-out">
                                {/* 一覧のボタン */}
                                <TopButtonList
                                    setShowUploadModal={setShowUploadModal}
                                    setShowShareFolderModal={setShowShareFolderModal}
                                    setShowStrageModal={setShowStorageModal}
                                    deleteFolder={deleteFolder}
                                    deleteVisible={enableProjectDelete}
                                    fetchFileList={handleListUpdate}
                                    enableSharing={enableSharing}
                                />

                                {/* ファイル一覧処理 */}
                                <FileListTable
                                    filteredFileList={filteredFileList}
                                    downloadFile={downloadFile}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    setShowDeleteModal={setShowDeleteModal}
                                    setFilteredFileList={setFilteredFileList}
                                    setColorColumnList={setColorColumnList}
                                    colorColumnList={colorColumnList}
                                    setLoading={setLoading}
                                    rootFolderList={rootFolderList}
                                    credentials={credentials}
                                    fetchFileList={fetchFileList}
                                    enableSharing={enableSharing}
                                />
                                {/* WebGIS */}
                                {filteredFileList.length > 0 ? (
                                    <div className="pt-2">
                                        <MapContent
                                            filteredFileList={filteredFileList}
                                            colorColumnList={colorColumnList}
                                        />
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-center py-6 text-lg">
                                        {t("WebGIS.noFilesForWebGIS")}
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
            {/* フォルダ共有 */}
            <ShareFolderModal
                showShareFolderModal={showShareFolderModal}
                setShowShareFolderModal={setShowShareFolderModal}
                rootFolder={rootFolder}
                handleShareFolder={handleShareFolder}
            />
            {/* ストレージ確認 */}
            <StorageModal
                showStorageModal={showStorageModal}
                setShowStorageModal={setShowStorageModal}
                storageSize={storageSize}
                enableSharing={enableSharing}
            />
            {/* ファイルアップロード */}
            <UploadFileContent
                acceptedFileList={acceptedFileList}
                setAcceptedFileList={setAcceptedFileList}
                uploadCheck={uploadCheck}
                handleUpload={handleUpload}
                setShowUploadModal={setShowUploadModal}
                showUploadModal={showUploadModal}
            />
            {/* ファイル削除確認モーダル */}
            <S3FileDeleteConfirmModal
                showModal={showDeleteModal}
                setShowModale={setShowDeleteModal}
                selectedFile={selectedFile}
                handleDelete={deleteFile}
                isProcessing={isProcessing}
                errorMessage={modalErrorMessage}
            />
            <Footer />
        </div>
    );
};

export default WebGIS;
