/** トップ画面 */
export const HOME_ROUTE = "/";
/** ログイン画面 */
export const SIGNIN_ROUTE = "/signin";
/** サインアップ画面 */
export const SIGNUP_ROUTE = "/signup";
/** サインアップ（認証コード入力）画面 */
export const SIGNUP_CONFIRM_ROUTE = "/signup/confirm";
/** パスワードリセット画面 */
export const RESER_PASSWORD_ROUTE = "/reset-password";
/** 初回パスワード再設定画面 */
export const COMPLETE_PASSWORD_ROUTE = "/complete-password";
/** ログイン要求画面 */
export const REQUIRED_SIGNIN_ROUTE = "/required/signin";
/** ヘルプ画面 */
export const HELP_ROUTE = "/help";
/** DF Scanner詳細画面 */
export const DETAIL_DF_SCANNER_ROUTE = "/detail/dfscanner";
/** DF LAT詳細画面 */
export const DETAIL_DF_LAT_ROUTE = "/detail/dflat";
/** DF Viewer詳細画面 */
export const DETAIL_DF_VIEWER_ROUTE = "/detail/dfviewer";
/** DF Walker詳細画面 */
export const DETAIL_DF_WALKER_ROUTE = "/detail/dfwalker";
/** ダウンロード */
export const DOWNLOAD_ROUTE = "/download";
/** リリースノート */
export const RELEASE_NOTES_ROUTE = "/release-notes";
/** ライセンス購入フォーム */
export const LICENSE_PURCHASE_FORM = "/license-purchase-form";
/** Q&A */
export const QA_ROUTE = "/qa";
/** DTMリンク集 */
export const DTM_LINK_ROUTE = "/dtm-link";
/** プライバシーポリシー（日本語） */
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
/** プライバシーポリシー（英語） */
export const PRIVACY_POLICY_EN_ROUTE = "/privacy-policy-en";

/** マイページ */
export const MYPAGE_ROUTE = "/mypage";
/** アカウント情報設定 */
export const ACCOUNT_SETTING_ROUTE = "/account/setting";

/** 商品選択画面 */
export const PRODUCT_SELECT_ROUTE = "/product/select";
/** 商品購入内容確認画面 */
export const PRODUCT_CONFIRM_ROUTE = "/product/confirm";
/** 商品購入内容確認画面（無料体験版用） */
export const PRODUCT_CONFIRM_TRIAL_ROUTE = "/product/confirm/trial";
/** 商品購入内容確認画面（更新時） */
export const PRODUCT_CONFIRM_UPDATE_ROUTE = "/product/confirm/update";
/** 商品購入内容確認画面（更新リクエスト時） */
export const PRODUCT_CONFIRM_UPDATE_REQUEST_ROUTE = "/product/confirm/update-request";
/** 商品購入完了画面 */
export const PRODUCT_COMPLETE_ROUTE = "/product/complete";

/** ユーザー・ライセンス管理画面 */
export const USER_LICENSE_ROUTE = "/user-license";
/** ユーザー・ライセンス確認画面（参照のみ） */
export const USER_LICENSE_REFERENCE_ROUTE = "/user-license-reference";
/** ユーザー作成画面 */
export const USER_CREATE_ROUTE = "/user/create";
/** ユーザー編集画面 */
export const USER_UPDATE_ROUTE = "/user/update";
/** ライセンス紐づけ画面 */
export const LICENSE_LINKING_ROUTE = "/license/linking";
/** ライセンス情報詳細画面 */
export const LICENSE_DETAIL_ROUTE = "/license/detail";

/** 購入履歴一覧画面 */
export const PAYMENT_HISTORY_ROUTE = "/payment-history";

/** データ管理画面 */
export const DATA_MANAGEMENT_ROUTE = "/data-management";
/** WebGIS画面 */
export const WEB_GIS_ROUTE = "/web-gis";

/** ライセンス発行 スタート画面 */
export const LICENSE_ISSUE_START_ROUTE = "/license/issue/start";
/** ライセンス発行 利用者登録画面 */
export const LICENSE_ISSUE_USER_REGISTER_ROUTE = "/license/issue/user/register";
/** ライセンス発行 利用者検索画面 */
export const LICENSE_ISSUE_USER_SEARCH_ROUTE = "/license/issue/user/search";
/** ライセンス発行　購入タイプ選択画面 */
export const LICENSE_ISSUE_PURCHASE_TYPE_ROUTE = "/license/issue/purchase-type";
/** ライセンス発行　発行内容確認画面 */
export const LICENSE_ISSUE_CONFIRM_ROUTE = "/license/issue/confirm";
/** ライセンス発行　発行完了画面 */
export const LICENSE_ISSUE_COMPLETE_ROUTE = "/license/issue/complete";
/** ライセンス発行　発行履歴確認画面 */
export const LICENSE_ISSUE_HISTORY_ROUTE = "/license/issue/history";

/** 共通エラー画面 */
export const COMMON_ERROR_ROUTE = "/common/error";
/** 共通権限無しエラー画面 */
export const COMMON_UNAUTHORIZED_ROUTE = "/common/unauthorized";
