import React, { ErrorInfo, ReactNode } from "react";
import CommonError from "../pages/CommonError";

/**
 * 共通エラーハンドリングクラス
 *
 * アプリケーションの中で明示的に処理されなかった例外はこのクラスでキャッチされ、エラー専用ページを表示する。
 * ただし、API呼び出し時等の非同期処理内で発生した例外はここではキャッチされないため、別途考慮が必要。
 * ※公式ドキュメント記載の方法がclass型コンポーネントであったためそれに倣って実装している。
 *
 * ★このErrorBoundaryには頼らず、なるべく各コンポーネント内で例外処理すること。
 */
class ErrorBoundary extends React.Component<{ children: ReactNode }, { hasError: boolean }> {
    constructor(props: { children: ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // エラー発生時に他のサービスにログを送信する等の考慮が必要な場合ここに実装する
    }

    render() {
        if (this.state.hasError) {
            return <CommonError />;
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
