import { CheckmarkCircleIcon, CrossCircleIcon } from "../../components/Icons";
import { useTranslation } from "react-i18next";

/**
 * メッセージとローディング
 * @param param0
 * @returns
 */
const MessageAndLoading = ({ successMessage, errorMessage, loading }: any) => {
    const { t } = useTranslation();
    return (
        <>
            {/* 成功メッセージ */}
            {successMessage && (
                <>
                    <div className="flex items-center justify-center py-2">
                        <CheckmarkCircleIcon />
                        <span className="pl-2 text-center text-xl font-bold text-green-500">
                            {successMessage.split("\n").map((line: any, index: any) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                    <p className="pb-2 text-center font-bold text-green-700">{t("WebGIS.reloadPage")}</p>
                </>
            )}

            {/* エラーメッセージ */}
            {errorMessage && (
                <div className="flex items-center justify-center py-2">
                    <CrossCircleIcon />
                    <span className="pl-1 text-center font-bold text-red-600">
                        {errorMessage.split("\n").map((line: any, index: any) => (
                            <div key={index}>{line}</div>
                        ))}
                    </span>
                </div>
            )}

            {/* ローディング */}
            {loading && (
                <div className="flex flex-col items-center justify-center p-10">
                    <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                    <p className="text-gre-800 p-2 font-bold">Loading...</p>
                </div>
            )}
        </>
    );
};

export default MessageAndLoading;
