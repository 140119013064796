import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { MYPAGE_ROUTE } from "../consts/routes";
import { CrossCircleIcon } from "../components/Icons";
import { useUserContext } from "../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "../components/ScrollToTop";
import { LicensedUserUnauthorized } from "../components/LicensedUserUnauthorized";
import { PaymentHistory } from "../types/PaymentHistory";
import { getUser } from "../clients/UserClient";
import { getPaymentHistoryList } from "../clients/PaymentHistoryClient";
import i18n from "../i18n/configs";
import { formatUtcToLocalDateTime } from "../utils/DateUtils";
import { Helmet } from "react-helmet";

/**
 * 購入履歴一覧画面
 * @returns JSX.Element
 */
const PaymentHistoryList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, dbUserCashe, setDbUserCashe, getRecentToken } = useUserContext();
    const [loading, setLoading] = useState(true);
    const [paymentHistoryList, setPaymentHistoryList] = useState<PaymentHistory[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * 購入履歴情報の取得処理
     * @returns
     */
    async function fetchPaymentHistory() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]ユーザー情報（自身）の取得
            let groupId;
            if (dbUserCashe) {
                groupId = dbUserCashe.group_id;
            } else {
                const userResponse = await getUser(idToken, user.attributes.sub);
                setDbUserCashe(userResponse);
                groupId = userResponse.group_id;
            }
            // [API]購入履歴一覧取得
            const response: PaymentHistory[] = await getPaymentHistoryList(idToken, { groupId: groupId });
            const sortedList: PaymentHistory[] = response.sort((a, b) => {
                return new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime();
            });
            setPaymentHistoryList(sortedList);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!user) {
            // ページリロード時はコンテキストも初期化されるため、空の場合は一度リターンする
            // コンテキストが生成されユーザー情報が生成された時点で再実行する
            return;
        }
        if (!effectRun.current) {
            fetchPaymentHistory();
            return () => {
                effectRun.current = true;
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    /**
     * 戻る押下時のイベント処理
     */
    const handleBack = () => {
        navigate(MYPAGE_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("PaymentHistory.meta.title")} />
            <ScrollToTop />
            <LicensedUserUnauthorized />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow items-center justify-center px-6 py-8 pt-20 lg:px-20">
                <h1 className="h1-common py-3">{t("PaymentHistory.paymentHistoryTitle")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="text-gre-800 p-2 font-bold">Loading...</p>
                    </div>
                ) : (
                    <>
                        {paymentHistoryList.length === 0 ? (
                            // 購入履歴が存在しない場合
                            <div className="flex items-center justify-center py-4 text-lg font-bold text-gray-700">
                                {t("PaymentHistory.noHistory")}
                            </div>
                        ) : (
                            // 購入履歴が存在する場合
                            <div className="overflow-x-auto transition duration-500 ease-in-out">
                                <table className="my-3 min-w-full divide-y divide-gray-200 border">
                                    <thead className="bg-gray-400">
                                        <tr
                                            className={`${
                                                i18n.language === "ja" ? "text-base" : "text-sm"
                                            } font-semibold uppercase tracking-wide text-gray-100`}
                                        >
                                            {/* [ヘッダ] 購入日 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.paymentDate")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 商品名 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.productName")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 購入区分 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.purchaseCategory")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 購入者氏名 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.purchaser")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 購入者メールアドレス */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.email")}</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200">
                                        {paymentHistoryList.map((p) => (
                                            <tr
                                                key={p.payment_history_id}
                                                className="text-sm font-semibold text-gray-800"
                                            >
                                                {/* 購入日時 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{formatUtcToLocalDateTime(p.payment_date)}</span>
                                                    </div>
                                                </td>
                                                {/* 商品名 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>
                                                            {i18n.language === "ja"
                                                                ? p.p_product_type_name.split("%%")[0]
                                                                : p.p_product_type_name.split("%%")[1]}
                                                        </span>
                                                    </div>
                                                </td>
                                                {/* 購入区分 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>
                                                            {p.license_updated_count === 0
                                                                ? t("CommonLabel.categoryNew")
                                                                : t("CommonLabel.categoryUpdate")}
                                                        </span>
                                                    </div>
                                                </td>
                                                {/* 購入者氏名 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{p.p_name}</span>
                                                    </div>
                                                </td>
                                                {/* 購入者メールアドレス */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{p.p_email}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </>
                )}

                {/* 戻るボタン */}
                <div className="mb-5 mt-10 flex items-center justify-evenly">
                    <button type="button" onClick={handleBack} className="btn-secondary w-[150px]">
                        {t("PaymentHistory.backToMyPage")}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PaymentHistoryList;
