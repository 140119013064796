import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { EMAIL_MAX_LENGTH } from "../consts/constants";
import { LICENSE_ISSUE_PURCHASE_TYPE_ROUTE, LICENSE_ISSUE_START_ROUTE } from "../consts/routes";
import { useUserContext } from "../contexts/UserContext";
import { getUsers } from "../clients/UserClient";
import { CrossCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import { User } from "../types/User";
import i18n from "../i18n/configs";
import { OnlyMasterUserAuthorized } from "../components/OnlyMasterUserAuthorized";
import { Helmet } from "react-helmet";

/**
 * ライセンス発行 利用者検索画面
 * @returns JSX.Element
 */
const LicenseIssueUserSearch = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, getRecentToken } = useUserContext();
    const [userList, setUserList] = useState<User[]>([]);
    const [email, setEmail] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * メールアドレス入力時のイベント処理
     * @param event
     */
    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setEmail(event.target.value);
    };

    /**
     * 検索押下時のイベント処理
     * @param event
     */
    const handleSearchUser: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]ユーザー情報一覧の取得
            const userListResponse: any = await getUsers(idToken, { email: email });
            setUserList(userListResponse);
        } catch (error: any) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setIsProcessing(false);
        }
    };

    /**
     * 次へ押下時のイベント処理
     */
    const handleNext = () => {
        navigate(LICENSE_ISSUE_PURCHASE_TYPE_ROUTE, { state: { selectedUser: userList[0] } });
    };

    /**
     * 戻る押下時のイベント処理
     */
    const handleBack = () => {
        navigate(LICENSE_ISSUE_START_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("LicenseIssueUserSearch.meta.title")} />
            <ScrollToTop />
            {/* マスターユーザーのみ閲覧可 */}
            <OnlyMasterUserAuthorized />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("LicenseIssueUserSearch.title")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                <form className="mx-auto max-w-lg" onSubmit={handleSearchUser}>
                    <div className="grid grid-cols-3 items-center justify-center gap-4 pb-2 pt-2 sm:m-2">
                        {/* メールアドレス */}
                        <div className="col-span-1 py-2">
                            <label htmlFor="email" className="block font-medium text-gray-900">
                                {t("CommonLabel.email")}
                            </label>
                        </div>
                        <div className="col-span-2 py-2">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="name@example.com"
                                className="textbox-common"
                                onChange={handleEmailChange}
                                maxLength={EMAIL_MAX_LENGTH}
                                required
                            />
                        </div>
                    </div>

                    {/* 検索ボタン */}
                    <div className="flex items-center justify-evenly">
                        <button
                            type="submit"
                            className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3 py-2`}
                            disabled={isProcessing}
                        >
                            {isProcessing ? (
                                <div className="flex items-center justify-center">
                                    <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                    <label className="">Processing...</label>
                                </div>
                            ) : (
                                t("CommonLabel.search")
                            )}
                        </button>
                    </div>
                </form>

                <hr className="my-5 border-b border-t-2" />

                {userList.length === 0 ? (
                    // ユーザーが存在しない場合
                    <div className="flex items-center justify-center py-4 text-lg font-bold text-gray-700">
                        {t("LicenseIssueUserSearch.userNotFound")}
                    </div>
                ) : (
                    // ユーザーが見つかった場合
                    <div className="mx-auto max-w-2xl overflow-x-auto transition duration-500 ease-in-out">
                        <div className="flex items-center justify-center text-lg font-bold text-gray-700">
                            {t("LicenseIssueUserSearch.issueToUser")}
                        </div>
                        <table className="my-3 min-w-full divide-y divide-gray-200 border">
                            <thead className="bg-gray-400">
                                <tr
                                    className={`${
                                        i18n.language === "ja" ? "text-base" : "text-sm"
                                    } font-semibold uppercase tracking-wide text-gray-100`}
                                >
                                    {/* [ヘッダ] メールアドレス */}
                                    <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                        <div className="flex items-center gap-x-2">
                                            <span>{t("CommonLabel.email")}</span>
                                        </div>
                                    </th>
                                    {/* [ヘッダ] 氏 */}
                                    <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                        <div className="flex items-center gap-x-2">
                                            <span>{t("CommonLabel.familyName")}</span>
                                        </div>
                                    </th>
                                    {/* [ヘッダ] 名 */}
                                    <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                        <div className="flex items-center gap-x-2">
                                            <span>{t("CommonLabel.givenName")}</span>
                                        </div>
                                    </th>
                                    {/* [ヘッダ] 所属 */}
                                    <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                        <div className="flex items-center gap-x-2">
                                            <span>{t("CommonLabel.organization")}</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="divide-y divide-gray-200">
                                {userList.map((u) => (
                                    <tr key={u.user_id} className="text-sm font-semibold text-gray-800">
                                        {/* メールアドレス */}
                                        <td className="h-px w-px whitespace-nowrap">
                                            <div className="px-6 py-3">
                                                <span>{u.email}</span>
                                            </div>
                                        </td>
                                        {/* 氏 */}
                                        <td className="h-px w-px whitespace-nowrap">
                                            <div className="px-6 py-3">
                                                <span>{u.family_name}</span>
                                            </div>
                                        </td>
                                        {/* 名 */}
                                        <td className="h-px w-px whitespace-nowrap">
                                            <div className="px-6 py-3">
                                                <span>{u.given_name}</span>
                                            </div>
                                        </td>
                                        {/* 所属 */}
                                        <td className="h-px w-px whitespace-nowrap">
                                            <div className="px-6 py-3">
                                                <span>{u.organization}</span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {/* 戻る/次へボタン */}
                <div className="mx-auto flex max-w-xl items-center justify-evenly py-5 ">
                    <button type="button" onClick={handleBack} className="btn-secondary mx-2 w-[100px]">
                        {t("CommonLabel.back")}
                    </button>
                    {userList.length !== 0 && (
                        <button
                            type="submit"
                            onClick={handleNext}
                            className={"btn-primary mx-2 w-[100px]"}
                            disabled={userList.length === 0}
                        >
                            {t("CommonLabel.next")}
                        </button>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LicenseIssueUserSearch;
