import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { CrossCircleIcon } from "../../components/Icons";
import { useState } from "react";

type ModalProps = {
    showModal: boolean;
    handleClose: () => void;
    handleProceed: () => void;
    isProcessing: boolean;
};

/**
 * ライセンス更新時の注意事項モーダル
 *
 * @param ModalProps
 * @returns JSX.Element
 */
const LicenseUpdateConfirmModal: React.FC<ModalProps> = ({ showModal, handleClose, handleProceed, isProcessing }) => {
    const { t } = useTranslation();
    const [agreed, setAgreed] = useState(false);

    /**
     * 内容確認チェックボックス用トグル
     */
    const handleCheckboxChange = () => {
        setAgreed(!agreed);
    };

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md sm:w-1/2"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 grid grid-cols-2 items-center justify-center p-4 sm:m-2">
                <div className="col-span-2 text-lg font-bold">{t("LicenseDetail.modalTitle")}</div>
                <hr className="col-span-2 my-2 border-t-2"></hr>
                <div className="col-span-2 max-h-screen overflow-y-auto">
                    {/* 注意事項 */}
                    <div className="col-span-2">
                        <ul className="mx-auto ml-5 list-disc text-gray-700">
                            <li>{t("LicenseDetail.modalAttention1")}</li>
                            <li>{t("LicenseDetail.modalAttention2")}</li>
                            <li>{t("LicenseDetail.modalAttention3")}</li>
                        </ul>
                    </div>

                    {/* 注意事項確認チェック */}
                    <div className="col-span-2 flex items-center justify-evenly pt-2">
                        <label>
                            <input type="checkbox" checked={agreed} onChange={handleCheckboxChange} className="mr-2" />
                            {t("LicenseDetail.confirmedAbove")}
                        </label>
                    </div>

                    {/* キャンセル/購入手続きボタン */}
                    <div className="col-span-2 mt-5 flex items-center justify-evenly">
                        <button className="btn-secondary w-1/3" onClick={handleClose}>
                            {t("CommonLabel.cancel")}
                        </button>
                        <button
                            className={`w-1/3 rounded-lg py-2.5 text-center font-medium 
                            ${!agreed && "bg-gray-300 text-gray-500"} 
                            ${agreed && !isProcessing && "btn-primary"}
                            ${agreed && isProcessing && "btn-primary-disabled"}
                            }`}
                            onClick={handleProceed}
                            disabled={!agreed || isProcessing}
                        >
                            {isProcessing ? (
                                <div className="flex items-center justify-center">
                                    <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                    <label className="">Processing...</label>
                                </div>
                            ) : (
                                t("LicenseDetail.proceedUpdate")
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default LicenseUpdateConfirmModal;
