import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import { ScrollToTop } from "../../components/ScrollToTop";

/**
 * プライバシーポリシー
 * @returns JSX.Element
 */
const PrivacyPolicy = () => {
    return (
        <div className="flex min-h-screen flex-col">
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <h1 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl ">
                            プライバシーポリシー
                        </h1>
                    </div>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        DeepForest Technologies
                        株式会社（以下、「当社」といいます。）は、ウェブサイトおよびデスクトップアプリを含む、当社の提供するすべてのサービス（以下、「本サービス」と総称します。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
                    </div>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 1 条（個人情報）
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
                        </li>
                        <li className="my-3">
                            クッキー・IP
                            アドレス情報については、それら単独で特定の個人を識別することはできませんが、これら情報と個人情報が一体となって利用される場合にはこれら情報も個人情報として取り扱います。
                        </li>
                        <li className="my-3">
                            当社は以下の個人データを収集します。
                            <ul className="ml-5 list-disc">
                                <li>メールアドレス</li>
                                <li>パスワード</li>
                                <li>氏名</li>
                                <li>所属（会社名等）</li>
                                <li>支払情報</li>
                                <li>IP アドレス等のオンライン識別子</li>
                                <li>デバイス情報（コンピューター名、SID）</li>
                                <li>位置情報</li>
                                <li>当社への問い合わせ内容</li>
                            </ul>
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 2 条（個人情報の収集方法）
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">当社は以下の方法でユーザーの個人データを取得します。</li>
                        <p className="mt-3">a. ユーザーからの直接的な取得</p>
                        <p>
                            当社はユーザーから積極的に提供された情報を取得します。当社ウェブサイトから利用登録を行う場合、製品の購入を行う場合、当社への問い合わせを行う場合などがあります。
                        </p>
                        <p className="mt-3">b. 自動取得</p>
                        <p>
                            当社ウェブサイトへのアクセスがあった場合、IP
                            アドレス等の一部オンライン識別情報を自動で取得する場合があります。また、当社製品のご利用時に、アクセス元となるユーザーのデバイス情報を（コンピューター名、SID）を取得する場合があります。
                        </p>
                        <p className="mt-3">c. 提携先からの取得</p>
                        <p>
                            ユーザーと提携先との間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。
                        </p>
                        <li className="my-3">当社は以下の方法でユーザーの個人データを取得します。</li>
                        <ul className="ml-5 list-disc">
                            <li>当社ウェブサイトを閲覧若しくは使用した場合</li>
                            <li>当社ウェブサイトからユーザーの利用登録を行った場合</li>
                            <li>当社の製品を購入する場合</li>
                            <li>当社の製品（デスクトップアプリを含む）を利用する場合</li>
                            <li>当社に問い合わせを行う場合</li>
                        </ul>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 3 条（個人情報を収集・利用する目的）
                    </h2>
                    <p>当社が個人情報を収集・利用する目的は、以下のとおりです。</p>
                    <ul className="ml-5 list-disc">
                        <li>当社サービスの提供・運営のため</li>
                        <li>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</li>
                        <li>
                            ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
                        </li>
                        <li>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</li>
                        <li>
                            利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため
                        </li>
                        <li>ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため</li>
                        <li>有料サービスにおいて、ユーザーに利用料金を請求するため</li>
                        <li>上記の利用目的に付随する目的</li>
                        <li>本ポリシー記載の方法による第三者に対する提供の目的</li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 4 条（LocalStorage の使用）
                    </h2>
                    <p>
                        当社ウェブサイトでは、ログインしたユーザーのセッション管理のために LocalStorage
                        を使用します。LocalStorage とは、Web
                        サイトの閲覧時にユーザーのデータをブラウザ上に保存する仕組みであり、ユーザーが毎回パスワードを入力しなくても済むようにします。ブラウザの設定変更から無効化した場合、当社サービスの一部機能が正常に使えなくなる場合があります。
                    </p>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 5 条（個人情報の保存期間）
                    </h2>
                    <p>
                        当社はユーザーの個人情報を、上記の個人情報の取得および処理目的を達成するために必要な期間に限って保持します。ただし、法律上で個人情報をより長い期間保持することが義務付けられている場合は除くものとします。
                    </p>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 6 条（利用目的の変更）
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
                        </li>
                        <li className="my-3">
                            利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または本ウェブサイト上に公表するものとします。
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 7 条（個人情報の第三者提供）
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
                            <ul className="ml-5 list-disc">
                                <li>
                                    人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
                                </li>
                                <li>
                                    公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
                                </li>
                                <li>
                                    国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                                </li>
                                <li>
                                    予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき
                                    <ul className="">
                                        <li>
                                            -
                                            第三者への提供を行う個人情報取扱事業者の氏名又は名称及び住所並びに代表者の氏名
                                        </li>
                                        <li>- 利用目的に第三者への提供を含むこと</li>
                                        <li>- 第三者に提供されるデータの項目</li>
                                        <li>- 第三者に提供されるデータの取得の方法</li>
                                        <li>- 第三者への提供の手段または方法</li>
                                        <li>- 本人の求めに応じて個人情報の第三者への提供を停止すること</li>
                                        <li>- 本人の求めを受け付ける方法</li>
                                        <li>- 第三者に提供されるデータの更新の方法</li>
                                        <li>- オプトアウトの届出に係るデータの第三者への提供を開始する予定日</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="my-3">
                            前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
                            <ul className="ml-5 list-disc">
                                <li>
                                    当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                                </li>
                                <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                                <li>
                                    個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 8 条（子どものプライバシー）
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            本サービスは一般的なお客様のために意図されております。子どものお客様が本サービスを利用してユーザーの個人情報を提供される場合には、保護者の方の同意のもとに行っていただけますようお願いいたします。ユーザーは、本サービスを利用するのに必要な権利能力および行為能力を有すること、または、ユーザーが子どもの場合は、保護者の同意のもと本サービスを利用すること、を表明し保証するものとします。保護者の同意なく子どもの個人情報が提供されていると思われる場合は、お問い合わせ窓口（第
                            14 条）までお問い合わせください。
                        </li>
                        <li className="my-3">
                            当社は、16 歳（又は GDPR に係る各国法令によって異なる GDPR
                            と同趣旨の未成年に係る年齢）未満のユーザーから取得する個人情報について、より高いプライバシーの保護を行うことの必要性を認識しており、意図的に子どもから個人情報を取得することはありません。もし当社が誤って子どもから個人情報を取得していることが判明した場合、当社は関連するアカウントを無効化し、速やかに当社の記録から関連する個人情報を削除するための合理的な措置を講じます。
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 9 条（安全管理措置）
                    </h2>
                    <p>
                        当社は、その取り扱う個人情報の漏洩、滅失又は毀損の防止その他個人情報の安全管理のために、必要かつ適切な措置を講じます。当社が講じる安全管理措置については、お問い合わせ窓口（第
                        14条）までお問い合わせください。
                    </p>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 10 条（個人情報の開示）
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            当社は、本人から個人情報若しくは第三者提供記録の開示または利用目的の通知を求められたときは、ユーザー本人からの請求であることを確認したうえで本人に対し、遅滞なくこれを開示または通知します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、1件あたり1,000
                            円の手数料を申し受 けます。
                            <ul className="ml-5 list-disc">
                                <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
                                <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                                <li>その他法令に違反することとなる場合</li>
                            </ul>
                        </li>
                        <li className="my-3">
                            前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 11 条（個人情報の訂正および削除）
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。
                        </li>
                        <li className="my-3">
                            当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。
                        </li>
                        <li className="my-3">
                            当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 12 条（個人情報の利用停止等）
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行
                            います。
                        </li>
                        <li className="my-3">
                            前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。
                        </li>
                        <li className="my-3">
                            当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。
                        </li>
                        <li className="my-3">
                            前 2
                            項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 13 条（プライバシーポリシーの変更）
                    </h2>
                    <ul className="ml-5 list-decimal">
                        <li className="my-3">
                            本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
                        </li>
                        <li className="my-3">
                            当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
                        </li>
                    </ul>

                    <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        第 14 条（お問い合わせ窓口）
                    </h2>
                    <p className="my-3">本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。</p>
                    <ul className="ml-5">
                        <li>住所：京都市左京区吉田本町 36 番地 1 京都大学国際科学イノベーション棟</li>
                        <li>社名：DeepForest Technologies 株式会社</li>
                        <li>個人情報取扱責任者：池端建吾</li>
                        <li>E メールアドレス：mail@deepforest-tech.co.jp</li>
                    </ul>

                    <p className="mb-2 mt-8">最終更新：2023 年 12 月 8 日</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
