import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import Modal from "react-modal";
import { CrossCircleIcon, CheckmarkCircleIcon } from "../../components/Icons";

/** 最大同時ファイルアップロード数 */
const MAX_UPLOAD_FILES: number = 20;

const UploadFileContent = ({
    acceptedFileList,
    setAcceptedFileList,
    uploadCheck,
    handleUpload,
    setShowUploadModal,
    showUploadModal,
}: any) => {
    const { t } = useTranslation();
    const [isRejected, setRejected] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        // モーダル表示時の初期化処理
        setIsFinished(false);
        setSuccessMessage("");
        setErrorMessage("");
    }, [showUploadModal]);

    /** ドロップゾーン定義 */
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDropAccepted: (acceptedFiles: File[]) => {
            setRejected(false);
            setAcceptedFileList([...acceptedFiles]);
        },
        onDropRejected: (fileRejections: FileRejection[]) => {
            setRejected(true);
        },
        maxFiles: MAX_UPLOAD_FILES,
    });

    /** ドロップゾーン用スタイル */
    const dropZoneStyle = useMemo(() => {
        let combinedStyle =
            "flex flex-col items-center p-10 border-2 border-dashed border-gray-300 rounded bg-gray-100 text-gray-500 outline-none transition-all duration-300";
        if (isFocused) combinedStyle += " border-blue-500";
        if (isDragAccept) combinedStyle += " border-green-500";
        if (isDragReject) combinedStyle += " border-red-500";
        return combinedStyle;
    }, [isFocused, isDragAccept, isDragReject]);

    /** アップロード処理の呼び出し */
    const callUpload = async () => {
        setIsProcessing(true);
        setSuccessMessage("");
        setErrorMessage("");
        try {
            if (!uploadCheck()) {
                setErrorMessage(t("ErrorMessage.storageCapacityExceed"));
                return;
            }
            await handleUpload();
            setSuccessMessage(t("WebGIS.uploadCompleted"));
            setIsFinished(true);
        } catch (error: any) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
        } finally {
            setIsProcessing(false);
        }
    };

    /** モーダルを閉じる */
    const closeModal = () => {
        setShowUploadModal(false);
        setAcceptedFileList([]);
        setErrorMessage("");
    };

    return (
        <>
            <Modal
                className="fixed left-1/2 top-1/2 w-10/12 max-w-2xl -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                isOpen={showUploadModal}
            >
                <div>
                    <div className="mt-2 flex items-center justify-center">
                        <p className="text-center text-lg font-bold text-gray-600">{t("WebGIS.uploadFile")}</p>
                    </div>
                    {!isProcessing && !isFinished && (
                        <>
                            {(isRejected || isDragReject) && (
                                <>
                                    {/* アップロード拒否 */}
                                    <p className="my-1 text-center font-bold text-red-500">
                                        {t("WebGIS.cannotUpload")}
                                    </p>
                                </>
                            )}
                            {acceptedFileList.length == 0 ? (
                                <>
                                    {/* アップロード用選択ボックス */}
                                    <div {...getRootProps({ className: dropZoneStyle })}>
                                        <input {...getInputProps()} />
                                        <p>{t("WebGIS.selectFile")}</p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {/* アップロード確認 */}
                                    <div className="flex flex-col items-center rounded border-2 border-dashed border-gray-300 bg-gray-100 p-5 text-gray-500 outline-none transition-all duration-300">
                                        <p>{t("WebGIS.uploadBelow")}</p>
                                        <ul>
                                            {acceptedFileList.map((file: any) => (
                                                <li key={file.name}>{file.name}</li>
                                            ))}
                                        </ul>
                                        {/* キャンセル/アップロード */}
                                        <div className="mt-2">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setAcceptedFileList([]);
                                                    setErrorMessage("");
                                                }}
                                                className="mx-5 font-bold text-gray-500 underline"
                                            >
                                                {t("CommonLabel.cancel")}
                                            </button>
                                            <button
                                                type="button"
                                                onClick={callUpload}
                                                className="mx-5 font-bold text-gray-500 underline"
                                            >
                                                {t("CommonLabel.upload")}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    {/* アップロード中 */}
                    {isProcessing && (
                        <div className="flex flex-col items-center justify-center p-10">
                            <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                            <p className="text-gre-700 p-2 font-bold">{t("WebGIS.uploadProcessing")}</p>
                            <p className="text-gre-700 p-1 font-bold">{t("WebGIS.uploadMsg")}</p>
                        </div>
                    )}

                    {/* 失敗時メッセージ */}
                    {errorMessage && (
                        <div className="flex items-center justify-center py-2">
                            <CrossCircleIcon />
                            <span className="pl-1 text-center font-bold text-red-600">
                                {errorMessage.split("\n").map((line: any, index: any) => (
                                    <div key={index}>{line}</div>
                                ))}
                            </span>
                        </div>
                    )}

                    {/* 成功時メッセージ */}
                    {successMessage && (
                        <>
                            <div className="flex items-center justify-center py-2">
                                <CheckmarkCircleIcon />
                                <span className="pl-2 text-center text-xl font-bold text-green-500">
                                    {successMessage.split("\n").map((line: any, index: any) => (
                                        <div key={index}>{line}</div>
                                    ))}
                                </span>
                            </div>
                            <p className="pb-2 text-center font-bold text-green-700">{t("WebGIS.reloadAndCheck")}</p>
                        </>
                    )}

                    {/* アップロード時の注意事項 */}
                    <div className="m-0 px-4 py-2 sm:m-2">
                        <div className="text-lg font-bold">{t("WebGIS.uploadeNoteTitle")}</div>
                        <hr className="my-2 border-t-2"></hr>
                        <ul className="list-disc pl-4 text-sm text-gray-800">
                            <li>{t("WebGIS.uploadNote1")}</li>
                            <li>{t("WebGIS.uploadNote2")}</li>
                        </ul>
                    </div>

                    {/* 閉じるボタン */}
                    <div className="mt-4 flex items-center justify-evenly">
                        <button className="btn-secondary w-1/3" onClick={() => closeModal()}>
                            {t("CommonLabel.close")}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UploadFileContent;
