import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import i18n from "../../i18n/configs";

type ModalProps = {
    showModal: boolean;
    handleClose: () => void;
    handleProceedToConfirm: () => void;
    handleProceedToContact: () => void;
};

/**
 * ライセンス購入時の所在地確認モーダル
 *
 * @param ModalProps
 * @returns JSX.Element
 */
const LocationConfirmModal: React.FC<ModalProps> = ({
    showModal,
    handleClose,
    handleProceedToConfirm,
    handleProceedToContact,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 max-w-3xl -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md md:w-1/2"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 grid grid-cols-2 items-center justify-center p-4 sm:m-2">
                <div className="col-span-2 text-lg font-bold text-red-700">
                    {t("ProductSelect.locationConfirmModalTitle")}
                </div>
                <hr className="col-span-2 my-2 border-t-2"></hr>
                <div className="col-span-2 max-h-screen overflow-y-auto">
                    {/* 注意事項 */}
                    <div className="col-span-2">
                        <ul className="mx-auto ml-5 list-disc font-bold text-gray-800">
                            <li>{t("ProductSelect.locationConfirmModalText1")}</li>
                            <li>{t("ProductSelect.locationConfirmModalText2")}</li>
                        </ul>
                    </div>

                    <div className="mt-5 flex flex-col items-center justify-normal gap-5">
                        {/* 日本在住 */}
                        <button
                            onClick={handleProceedToConfirm}
                            className={`${
                                i18n.language === "ja" ? "text-base" : "py-1 text-base md:text-lg"
                            } btn-green-white w-2/3`}
                        >
                            {t("ProductSelect.locationConfirmModalButton1")}
                        </button>
                        {/* 日本以外在住 */}
                        <button
                            onClick={handleProceedToContact}
                            className={`${
                                i18n.language === "ja" ? "text-base" : "py-1 text-base md:text-lg"
                            } btn-green-white w-2/3`}
                        >
                            {t("ProductSelect.locationConfirmModalButton2")}
                        </button>
                        {/* キャンセル */}
                        <button className="btn-secondary w-1/3" onClick={handleClose}>
                            {t("CommonLabel.cancel")}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default LocationConfirmModal;
