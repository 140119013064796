/**
 * string型をint型にパースする
 *
 * パスパラメータ（string|undefined型）をnumber型で扱いたい場合等に使用。
 * @param str string型パラメータ
 * @returns パース後の値
 * @throws strがundefinedもしくは数値以外の値の場合
 */
export function parseStringToInt(str: string | undefined): number {
    const num: number | undefined = str && /^-?[0-9]+$/.test(str) ? parseInt(str, 10) : undefined;
    if (num === undefined) {
        // 未定義 or 数値でない
        throw new Error(`Fail to parse ${str} into number`);
    } else {
        return num;
    }
}
