import {
    DF_LAT_V1_0_0,
    DF_LAT_V1_0_2,
    DF_LAT_V1_0_3,
    DF_LAT_V1_0_4,
    DF_LAT_FILENAME_V1_0_0,
    DF_LAT_FILENAME_V1_0_2,
    DF_LAT_FILENAME_V1_0_3,
    DF_LAT_FILENAME_V1_0_4,
    DF_LAT_LITE_FILENAME_V1_0_0,
    DF_LAT_LITE_V1_0_0,
    DF_LAT_PRO_FILENAME_V1_0_0,
    DF_LAT_PRO_V1_0_0,
    DF_LAT_LITE_FILENAME_V1_0_1,
    DF_LAT_LITE_V1_0_1,
    DF_LAT_PRO_FILENAME_V1_0_1,
    DF_LAT_PRO_V1_0_1,
    DF_LAT_LITE_FILENAME_V1_1_0,
    DF_LAT_LITE_V1_1_0,
    DF_LAT_PRO_FILENAME_V1_1_0,
    DF_LAT_PRO_V1_1_0,
} from "../../consts/Installers";

/**
 * リリースノート（DF LAT・英語版）
 * @returns JSX.Element
 */
export const ReleaseNotesDfLatEn = () => {
    return (
        <div>
            {/* Pro版 v1.0.1, Lite版 v1.0.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF LAT Pro v1.1.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF LAT Lite v1.1.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-06-05</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF LAT Pro v1.1.0 has been released.</p>
                    <p>DF LAT Lite v1.1.0 has been released.</p>
                    <br />
                    <p className="font-bold">■ For Pro v1.1.0</p>
                    <p>・Added the function to specify slice positions on DEM.</p>
                    <br />
                    <p className="font-bold">■ Common</p>
                    <p>Bug Fixes</p>
                    <p>
                        ・Fixed an issue where the positions of DSM and DTM were sometimes misaligned during CHM
                        calculation.
                    </p>
                    <p>
                        ・Fixed an issue where shortcuts were not being added to the start menu for non-administrator
                        users.
                    </p>
                    <br />
                    <p>Others</p>
                    <p>・The default installation location for the software has been changed.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_LAT_PRO_V1_1_0}>
                    {DF_LAT_PRO_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_LAT_LITE_V1_1_0}>
                    {DF_LAT_LITE_FILENAME_V1_1_0}
                </a>
            </div>

            {/* Pro版 v1.0.1, Lite版 v1.0.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF LAT Pro v1.0.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF LAT Lite v1.0.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-04-12</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF LAT Pro v1.0.1 has been released.</p>
                    <p>DF LAT Lite v1.0.1 has been released.</p>
                    <br />
                    <p className="font-bold">■ Common</p>
                    <p>New Features</p>
                    <p>・Added laser forest map creation feature.</p>
                    <br />
                    <p>Function Improvements</p>
                    <p>・Optimized ground detection.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed startup issues on some PCs.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_LAT_PRO_V1_0_1}>
                    {DF_LAT_PRO_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_LAT_LITE_V1_0_1}>
                    {DF_LAT_LITE_FILENAME_V1_0_1}
                </a>
            </div>

            {/* Pro版 v1.0.0, Lite版 v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF LAT Pro v1.0.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF LAT Lite v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-03-07</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF LAT Pro v1.0.0 has been released.</p>
                    <p>DF LAT Lite v1.0.0 has been released.</p>
                    <br />
                    <p className="font-bold">■ Common</p>
                    <p>Function Improvements</p>
                    <p>・Improved ground detection algorithm.</p>
                    <p>・Part of point cloud drawing has been sped up.</p>
                    <p>・Modified to leave color information when detecting the ground.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Solved the problem of missing data when filtering with special conditions.</p>
                    <p>・Solved the problem where DTM output did not work at a resolution of 50cm.</p>
                    <p></p>
                    <br />
                    <p className="font-bold">■ For Pro v1.0.0</p>
                    <p>・The UI of the "Back" and "Forward" buttons has been partially revised.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_LAT_PRO_V1_0_0}>
                    {DF_LAT_PRO_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_LAT_LITE_V1_0_0}>
                    {DF_LAT_LITE_FILENAME_V1_0_0}
                </a>
            </div>

            {/* v1.0.4 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF LAT v1.0.4</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-01-05</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Update Information</p>
                    <p>・"Back" and "Forward" buttons have been added to manual filtering.</p>
                    <p>・Fixed a bug during login process.</p>
                    <p>・Other minor bugs have been fixed.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_LAT_V1_0_4}>
                        {DF_LAT_FILENAME_V1_0_4}
                    </a>
                </div>
            </div>

            {/* v1.0.3 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF LAT v1.0.3</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-13</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue with the functionality of being able to log in for 7 days offline.</p>
                    <br />
                    <p>Feature Improvements</p>
                    <p>・Improved the functionality of the point cloud decimation algorithm.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_LAT_V1_0_3}>
                        {DF_LAT_FILENAME_V1_0_3}
                    </a>
                </div>
            </div>

            {/* v1.0.2 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF LAT v1.0.2</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-08</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Bug Fixes</p>
                    <p>・Restoration of editing information when the software forcibly terminates during editing.</p>
                    <br />
                    <p>Other</p>
                    <p>・Free Trial Version</p>
                    <p>・Manual Updates</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_LAT_V1_0_2}>
                        {DF_LAT_FILENAME_V1_0_2}
                    </a>
                </div>
            </div>

            {/* v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF LAT v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-10-02</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Released DF LAT v1.0.0.</p>
                </div>
                <p className="mt-4">Download</p>
                <div>
                    <a className="link-text-blue" href={DF_LAT_V1_0_0}>
                        {DF_LAT_FILENAME_V1_0_0}
                    </a>
                </div>
                <p className="text-sm text-red-800">
                    *Not compatible with new licenses (licenses issued after December 8, 2023 cannot be activated)
                </p>
            </div>
        </div>
    );
};

export default ReleaseNotesDfLatEn;
