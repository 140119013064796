import { Tooltip } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import S3FileUploadConfirmModal from "../modals/S3FileUploadConfirmModal";
import { useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

/** 最大ストレージ容量 */
const MAX_STORAGE_SIZE: number = 15 * 1024 * 1024 * 1024; // 15GB in bytes
/** 最大同時ファイルアップロード数 */
const MAX_UPLOAD_FILES: number = 5;

const FileUploader = ({ totalSize, handleUpload }: any) => {
    const { t } = useTranslation();
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [acceptedFileList, setAcceptedFileList] = useState<File[]>([]);
    const [isRejected, setRejected] = useState<boolean>(false);

    /**
     * ドロップゾーン定義
     */
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDropAccepted: (acceptedFiles: File[]) => {
            setRejected(false);
            setAcceptedFileList([...acceptedFiles]);
        },
        onDropRejected: (fileRejections: FileRejection[]) => {
            setRejected(true);
        },
        maxFiles: MAX_UPLOAD_FILES,
        maxSize: MAX_STORAGE_SIZE - totalSize,
    });

    /**
     * ドロップゾーン用スタイル
     */
    const dropZoneStyle = useMemo(() => {
        let combinedStyle =
            "flex flex-col items-center p-10 border-2 border-dashed border-gray-300 rounded bg-gray-100 text-gray-500 outline-none transition-all duration-300";
        if (isFocused) combinedStyle += " border-blue-500";
        if (isDragAccept) combinedStyle += " border-green-500";
        if (isDragReject) combinedStyle += " border-red-500";
        return combinedStyle;
    }, [isFocused, isDragAccept, isDragReject]);

    return (
        <>
            {/* ファイルアップロード */}
            <div className="mt-2 flex items-center justify-center">
                <p className="text-center text-lg font-bold text-gray-600">{t("DataManagement.uploadFile")}</p>
                <Tooltip
                    style={{}}
                    title={
                        <ul>
                            <li>{t("DataManagement.uploadHelp1")}</li>
                            <li>{t("DataManagement.uploadHelp2")}</li>
                        </ul>
                    }
                    arrow
                >
                    <HelpOutlineIcon
                        fontSize="small"
                        className="ml-1 cursor-pointer text-gray-500 hover:text-blue-500"
                    />
                </Tooltip>
            </div>
            {(isRejected || isDragReject) && (
                <>
                    {/* アップロード拒否 */}
                    <p className="my-1 text-center font-bold text-red-500">{t("DataManagement.cannotUpload")}</p>
                </>
            )}
            {acceptedFileList.length == 0 ? (
                <>
                    {/* アップロード用選択ボックス */}
                    <div {...getRootProps({ className: dropZoneStyle })}>
                        <input {...getInputProps()} />
                        <p>{t("DataManagement.selectFile")}</p>
                    </div>
                </>
            ) : (
                <>
                    {/* アップロード確認 */}
                    <div className="flex flex-col items-center rounded border-2 border-dashed border-gray-300 bg-gray-100 p-5 text-gray-500 outline-none transition-all duration-300">
                        <p>{t("DataManagement.uploadBelow")}</p>
                        <ul>
                            {acceptedFileList.map((file) => (
                                <li key={file.name}>{file.name}</li>
                            ))}
                        </ul>
                        {/* キャンセル/アップロード */}
                        <div className="mt-2">
                            <button
                                type="button"
                                onClick={() => setAcceptedFileList([])}
                                className="mx-5 font-bold text-gray-500 underline"
                            >
                                {t("CommonLabel.cancel")}
                            </button>
                            <button
                                type="button"
                                onClick={() => handleUpload({ acceptedFileList, setAcceptedFileList })}
                                className="mx-5 font-bold text-gray-500 underline"
                            >
                                {t("CommonLabel.upload")}
                            </button>
                        </div>
                    </div>
                </>
            )}
            <div className="my-1 flex items-center justify-center text-sm text-gray-700">
                <Trans
                    i18nKey="DataManagement.uploadNotes"
                    components={{
                        l: <button className="link-text" onClick={() => setShowUploadModal(true)} />,
                    }}
                />
            </div>
            {/* ファイルアップロード確認モーダル */}
            <S3FileUploadConfirmModal showModal={showUploadModal} setShowModale={setShowUploadModal} />
        </>
    );
};
export default FileUploader;
