import { useTranslation } from "react-i18next";
import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { formatDateTime } from "../../utils/DateUtils";
import ListIcon from "@mui/icons-material/List";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import FileHandlingModal from "./FileHandlingModal";
import { t } from "i18next";

const TableHeader = () => {
    const { t } = useTranslation();
    return (
        <thead className="bg-lime-100">
            <tr className={`text-sm font-semibold uppercase tracking-wide text-gray-700`}>
                {/* [ヘッダ] ファイル名 */}
                <th scope="col" className="whitespace-nowrap px-2 py-2">
                    <div className="flex items-center gap-x-2">
                        <span>{t("CommonLabel.fileName")}</span>
                    </div>
                </th>
                {/* [ヘッダ] 表示 */}
                <th scope="col" className="w-[60px] whitespace-nowrap px-2 py-2">
                    <div className="text-center">
                        <span>{t("CommonLabel.show")}</span>
                    </div>
                </th>
                {/* [ヘッダ] 最終更新日時 */}
                <th scope="col" className="whitespace-nowrap px-2 py-2">
                    <div className="flex items-center gap-x-2">
                        <span>{t("CommonLabel.lastModified")}</span>
                    </div>
                </th>
                {/* [ヘッダ] サイズ */}
                <th scope="col" className="max-w-[50px] whitespace-nowrap px-2 py-2">
                    <div className="flex items-center gap-x-2">
                        <span>{t("CommonLabel.dataSize")}</span>
                    </div>
                </th>
                {/* [ヘッダ] ファイル操作 */}
                <th scope="col" className="whitespace-nowrap px-2 py-2">
                    <div className="text-center">
                        <span>{t("CommonLabel.operation")}</span>
                    </div>
                </th>
            </tr>
        </thead>
    );
};

const TableBody = ({
    filteredFileList,
    setSelectedFile,
    setFilteredFileList,
    setColorColumnList,
    colorColumnList,
    handleButtonClick,
}: any) => {
    const layerVisible = async (filename: string, seeFlag: boolean) => {
        setFilteredFileList(
            filteredFileList.map((item: any) => {
                item.see = item.filename === filename ? seeFlag : item.see;
                item.changed = item.filename === filename ? true : false;
                return item;
            }),
        );
    };
    const handleColumnChange: ChangeEventHandler<HTMLSelectElement> = async (event) => {
        const column = event.target.value;
        layerVisible(event.target.name, column !== "nan");
        setColorColumnList(
            colorColumnList.map((item: any) => {
                item.column = item.filename === event.target.name ? column : item.column;
                return item;
            }),
        );
    };
    return (
        <>
            <tbody className="divide-y divide-gray-200">
                {filteredFileList.map((file: any) => (
                    <tr key={file.key} className="text-sm font-semibold text-gray-800">
                        {/* ファイル名 */}
                        <td className="h-px w-px whitespace-nowrap">
                            <div className="px-2 py-2">
                                <span>{file.key?.split("/")[file.key?.split("/").length - 1]}</span>
                            </div>
                        </td>
                        {/* 表示 */}
                        <td className="h-px w-px whitespace-nowrap">
                            {file.type === "raster" ? (
                                <div className="px-2 py-2 text-center">
                                    {!file.see ? (
                                        <VisibilityOffIcon
                                            fontSize="small"
                                            className="cursor-pointer text-gray-600"
                                            onClick={() => layerVisible(file.filename, !file.see)}
                                        />
                                    ) : (
                                        <RemoveRedEyeIcon
                                            fontSize="small"
                                            className="cursor-pointer text-gray-600"
                                            onClick={() => layerVisible(file.filename, !file.see)}
                                        />
                                    )}
                                </div>
                            ) : (
                                <div className="px-2 py-2 text-center">
                                    <select
                                        name={file.filename}
                                        id={file.filename}
                                        className="textbox-common"
                                        onChange={handleColumnChange}
                                    >
                                        <option key={"nan"} value={"nan"}>
                                            {t("CommonLabel.hide")}
                                        </option>
                                        {Object.entries(file.data.features[0].properties).map(([k, v]: any) => (
                                            <option key={k} value={k}>
                                                {k}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </td>
                        {/* 最終更新日時 */}
                        <td className="h-px w-px whitespace-nowrap">
                            <div className="px-2 py-2">
                                <span>{file.lastModified ? formatDateTime(file.lastModified) : ""}</span>
                            </div>
                        </td>
                        {/* データサイズ */}
                        <td className="h-px w-px whitespace-nowrap">
                            <div className="px-2 py-2 text-right">
                                {file.size < 1024 * 1024 ? (
                                    <span>{Number((file.size / 1024).toFixed(1)).toLocaleString()} KB</span>
                                ) : (
                                    <span>{Number((file.size / (1024 * 1024)).toFixed(1)).toLocaleString()} MB</span>
                                )}
                            </div>
                        </td>
                        {/* ファイル操作 */}
                        <td className="h-px w-px whitespace-nowrap">
                            <div className="px-2 py-2 text-center">
                                <ListIcon
                                    fontSize="small"
                                    className="cursor-pointer text-gray-600"
                                    onClick={(e) => {
                                        handleButtonClick(e);
                                        setSelectedFile(file);
                                    }}
                                />
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </>
    );
};

const ContextMenu = ({
    show,
    position,
    onClose,
    setShowFileHandlingModal,
    menuRef,
    downloadFile,
    selectedFile,
    setShowDeleteModal,
    setFileCopyFlag,
}: any) => {
    if (!show) {
        return null;
    }
    const menuStyle: React.CSSProperties = {
        position: "absolute",
        top: `${position.y}px`,
        left: `${position.x}px`,
        backgroundColor: "white",
        border: "1px solid #ddd",
        padding: "5px",
        zIndex: 1000,
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", // オプション: 少し影を追加
    };

    const menuItemStyle: React.CSSProperties = {
        padding: "5px 10px",
        cursor: "pointer",
    };

    return (
        <>
            <style>{`.menu-item:hover {background-color: #f0f0f0;}`}</style>
            <ul style={menuStyle} ref={menuRef} onClick={onClose}>
                <li
                    style={menuItemStyle}
                    className="menu-item"
                    onClick={() => {
                        downloadFile(selectedFile);
                    }}
                >
                    {t("CommonLabel.download")}
                </li>
                <li
                    style={menuItemStyle}
                    className="menu-item"
                    onClick={() => {
                        setShowFileHandlingModal(true);
                        setFileCopyFlag(true);
                    }}
                >
                    {t("CommonLabel.copy")}
                </li>
                <li
                    style={menuItemStyle}
                    className="menu-item"
                    onClick={() => {
                        setShowFileHandlingModal(true);
                        setFileCopyFlag(false);
                    }}
                >
                    {t("CommonLabel.move")}
                </li>
                <li
                    style={menuItemStyle}
                    className="menu-item"
                    onClick={() => {
                        setShowDeleteModal(true);
                    }}
                >
                    {t("CommonLabel.delete")}
                </li>
            </ul>
        </>
    );
};

const FileListTable = ({
    filteredFileList,
    downloadFile,
    selectedFile,
    setSelectedFile,
    setShowDeleteModal,
    setFilteredFileList,
    setColorColumnList,
    colorColumnList,
    rootFolderList,
    credentials,
    fetchFileList,
    enableSharing,
}: any) => {
    const [showMenu, setShowMenu] = useState(false);
    const [fileCopyFlag, setFileCopyFlag] = useState(false);
    const [showFileHandlingModal, setShowFileHandlingModal] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef<any>(null);

    const handleButtonClick = (e: any) => {
        setShowMenu(true);
        setMenuPosition({
            x: e.clientX + window.scrollX,
            y: e.clientY + window.scrollY,
        });
    };

    const handleCloseMenu = () => {
        setShowMenu(false);
    };

    // クリックイベントがメニュー外で発生したか監視
    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (showMenu && menuRef.current && !menuRef.current.contains(event.target)) {
                handleCloseMenu();
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, [showMenu]);
    return (
        <div>
            {/* <TableInfo /> */}
            <table className="min-w-full divide-y divide-gray-200 border">
                <TableHeader />
                <TableBody
                    filteredFileList={filteredFileList}
                    setSelectedFile={setSelectedFile}
                    setFilteredFileList={setFilteredFileList}
                    setColorColumnList={setColorColumnList}
                    colorColumnList={colorColumnList}
                    handleButtonClick={handleButtonClick}
                    menuRef={menuRef}
                />
            </table>
            {/* 操作メニュー */}
            <ContextMenu
                show={showMenu}
                position={menuPosition}
                setShowFileHandlingModal={setShowFileHandlingModal}
                onClose={handleCloseMenu}
                menuRef={menuRef}
                downloadFile={downloadFile}
                selectedFile={selectedFile}
                setShowDeleteModal={setShowDeleteModal}
                setFileCopyFlag={setFileCopyFlag}
            />
            {/* コピー/移動モーダル */}
            <FileHandlingModal
                showFileHandlingModal={showFileHandlingModal}
                setShowFileHandlingModal={setShowFileHandlingModal}
                fileCopyFlag={fileCopyFlag}
                selectedFile={selectedFile}
                rootFolderList={rootFolderList}
                credentials={credentials}
                fetchFileList={fetchFileList}
                enableSharing={enableSharing}
            />
        </div>
    );
};

export default FileListTable;
