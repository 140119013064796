import { Auth } from "aws-amplify";
import { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import {
    FAMILY_NAME_MAX_LENGTH,
    GIVEN_NAME_MAX_LENGTH,
    ORGANIZATION_MAX_LENGTH,
    PASSWORD_MAX_LENGTH,
} from "../consts/constants";
import { useUserContext } from "../contexts/UserContext";
import { CheckmarkCircleIcon, CrossCircleIcon } from "../components/Icons";
import { HOME_ROUTE, MYPAGE_ROUTE } from "../consts/routes";
import { deleteUser, updateUser } from "../clients/UserClient";
import AccountDeleteModal from "./modals/AccountDeleteConfirmModal";
import { ScrollToTop } from "../components/ScrollToTop";
import { E102, E103 } from "../consts/ErrorCodes";
import { loggingError } from "../utils/LoggingUtils";
import { Helmet } from "react-helmet";

/**
 * アカウント情報設定画面
 * @returns JSX.Element
 */
const AccountSetting = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { user, setUser, setDbUserCashe, getRecentToken } = useUserContext();
    const [email, setEmail] = useState<string>("");
    const [oldPassword, setOldPassword] = useState<string>("");
    const [oldPasswordType, setOldPasswordType] = useState("password");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [newConfirmPassword, setNewConfirmPassword] = useState<string>("");
    const [newConfirmPasswordType, setNewConfirmPasswordType] = useState("password");
    const [familyName, setfamilyName] = useState<string>("");
    const [givenName, setGivenName] = useState<string>("");
    const [organization, setOrganization] = useState<string>("");
    const [userType, setUserType] = useState<number | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorMessageModal, setErrorMessageModal] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessingBaseInfo, setIsProcessingBaseInfo] = useState<boolean>(false);
    const [isProcessingPassword, setIsProcessingPassword] = useState<boolean>(false);
    const [isProcessingReset, setIsProcessingReset] = useState<boolean>(false);

    // コンテキスト情報の取得
    useEffect(() => {
        setEmail(user?.attributes.email ?? "");
        setfamilyName(user?.attributes.family_name ?? "");
        setGivenName(user?.attributes.given_name ?? "");
        setOrganization(user?.attributes["custom:organization"] ?? "");
        setUserType(Number(user?.attributes["custom:user_type"]) ?? null);
    }, [user]);

    /**
     * パスワード要件表示用トグル
     */
    const togglePasswordRequirements = () => {
        setShowPasswordRequirements(!showPasswordRequirements);
    };

    /**
     * 旧パスワード入力イベント処理
     * @param event
     */
    const handleOldPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setOldPassword(event.target.value);
    };

    /**
     * 新パスワード入力イベント処理
     * @param event
     */
    const handleNewPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setNewPassword(event.target.value);
    };

    /**
     * 新パスワード（確認用）入力イベント処理
     * @param event
     */
    const handleNewConfirmPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setNewConfirmPassword(event.target.value);
    };

    /**
     * 姓入力イベント処理
     * @param event
     */
    const handleFamilyNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setfamilyName(event.target.value);
    };

    /**
     * 名入力イベント処理
     * @param event
     */
    const handleGivenNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setGivenName(event.target.value);
    };

    /**
     * 所属入力イベント処理
     * @param event
     */
    const handleOrganizationChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setOrganization(event.target.value);
    };

    /**
     * 基本情報変更押下時のイベント処理
     * @param event
     */
    const handleBaseInfoUpdate: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setSuccessMessage("");
        setErrorMessage("");

        if (isProcessingBaseInfo) return;
        setIsProcessingBaseInfo(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // Cognito側の変更処理
            await Auth.updateUserAttributes(user, {
                given_name: givenName,
                family_name: familyName,
                "custom:organization": organization,
            });
            // DB側の変更処理（非同期呼び出し）
            const userResponse = await updateUser(idToken, user.attributes.sub, {
                familyName: familyName ?? undefined,
                givenName: givenName ?? undefined,
                organization: organization ?? undefined,
            });
            // 更新後のユーザー情報でコンテキストを更新
            const updatedUser = await Auth.currentAuthenticatedUser();
            setUser(updatedUser);
            setDbUserCashe(userResponse);
            setSuccessMessage(t("CommonMessage.updateCompleted"));
        } catch (error: any) {
            loggingError("error updating user attributes", error);
            if (error.code === "UserNotFoundException") {
                setErrorMessage(t("ErrorMessage.userNotFound"));
            } else if (error.code === "NotAuthorizedException") {
                setErrorMessage(t("ErrorMessage.notAuthorized"));
            } else if (error.code === "InvalidParameterException") {
                setErrorMessage(t("ErrorMessage.invalidParameter"));
            } else {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
            }
        } finally {
            setIsProcessingBaseInfo(false);
            // ページのトップにスクロール
            window.scrollTo(0, 0);
        }
    };

    /**
     * パスワード変更押下時のイベント処理
     * @param event
     */
    const handlePasswordUpdate: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setSuccessMessage("");
        setErrorMessage("");
        if (isProcessingPassword) return;
        setIsProcessingPassword(true);
        try {
            if (newPassword !== newConfirmPassword) {
                setErrorMessage(t("ErrorMessage.passwordMismatch"));
                return;
            }
            await Auth.changePassword(user, oldPassword, newPassword);
            // 更新後のユーザー情報でコンテキストを更新
            const updatedUser = await Auth.currentAuthenticatedUser();
            setUser(updatedUser);
            setSuccessMessage(t("CommonMessage.updateCompleted"));
        } catch (error: any) {
            loggingError("error changing password", error);
            if (error.code === "UserNotFoundException") {
                setErrorMessage(t("ErrorMessage.userNotFound"));
            } else if (error.code === "NotAuthorizedException") {
                setErrorMessage(t("ErrorMessage.invalidOldPassword"));
            } else if (error.code === "InvalidPasswordException") {
                setErrorMessage(t("ErrorMessage.weakPassword"));
            } else if (error.code === "LimitExceededException") {
                setErrorMessage(t("ErrorMessage.limitExceed"));
            } else {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
            }
        } finally {
            // 入力内容はリセット
            setOldPassword("");
            setNewPassword("");
            setNewConfirmPassword("");
            // ページのトップにスクロール
            window.scrollTo(0, 0);
            setIsProcessingPassword(false);
        }
    };

    /**
     * 退会する押下時のイベント処理
     */
    const handleAccountDelete = () => {
        setShowModal(true);
    };

    /**
     * [退会確認モーダル]
     * キャンセル押下時のイベント処理
     */
    const handleCloseModal = () => {
        setShowModal(false);
        setErrorMessageModal("");
    };

    /**
     * [退会確認モーダル]
     * 実行押下時のイベント処理
     * @param event
     */
    const handleAccountDeleteConfirm = async () => {
        setSuccessMessage("");
        setErrorMessageModal("");
        if (isProcessingReset) return;
        setIsProcessingReset(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                setIsProcessingReset(false);
                return;
            }
            // DBのユーザー情報削除処理
            await deleteUser(idToken, user.attributes.sub);
        } catch (error: any) {
            // DBの情報削除失敗時は処理中止
            loggingError("error deleting user", error);
            if (error.response && error.response.data && error.response.data.code) {
                const errorCode = error.response.data.code;
                if (errorCode === E102) {
                    // ユーザーが見つからない
                    setErrorMessageModal(t("ErrorMessage.userNotFound"));
                } else if (errorCode === E103) {
                    // 管理ユーザーがいない
                    setErrorMessageModal(t("ErrorMessage.adminMustExistInGroup"));
                } else {
                    // その他エラー
                    setErrorMessageModal(t("ErrorMessage.systemErrorMessage"));
                }
            } else {
                // その他エラー
                setErrorMessageModal(t("ErrorMessage.systemErrorMessage"));
            }
            setIsProcessingReset(false);
            return;
        }

        try {
            // Cognitoのユーザー削除処理
            await Auth.deleteUser();

            // ログアウトしてトップ画面へ
            await Auth.signOut();
            setUser(null);
            navigate(HOME_ROUTE);
            window.scrollTo(0, 0);
        } catch (error: any) {
            loggingError("error deleting user", error);
            if (error.code === "UserNotFoundException") {
                setErrorMessage(t("ErrorMessage.userNotFound"));
            } else if (error.code === "NotAuthorizedException") {
                setErrorMessage(t("ErrorMessage.notAuthorized"));
            } else if (error.code === "LimitExceededException") {
                setErrorMessage(t("ErrorMessage.limitExceed"));
            } else {
                setErrorMessageModal(t("ErrorMessage.systemErrorMessage"));
            }
        } finally {
            setIsProcessingReset(false);
        }
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("AccountSetting.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("AccountSetting.accountSettingTitle")}</h1>
                {/* 成功メッセージ */}
                {successMessage && (
                    <div className="flex items-center justify-center pt-5">
                        <CheckmarkCircleIcon />
                        <span className="pl-2 text-center text-xl font-bold text-green-500">
                            {successMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}
                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {/* 基本情報変更 */}
                <form onSubmit={handleBaseInfoUpdate}>
                    <div className="my-5 rounded-lg border border-gray-200 px-5 shadow sm:mx-5">
                        <div className="m-0 grid grid-cols-3 items-center justify-center p-4 sm:m-2">
                            <div className="col-span-3 text-lg font-bold">{t("AccountSetting.basicInfoUpdate")}</div>
                            <hr className="col-span-3 my-2 border-t-2"></hr>

                            {/* ユーザータイプ */}
                            <div className="col-span-1 py-1">
                                <label className="block font-medium text-gray-900">{t("CommonLabel.userType")}</label>
                            </div>
                            <div className="col-span-2 py-1">
                                <div className="block w-full select-none rounded-lg p-2.5 text-xs text-gray-900 focus:outline-none sm:text-sm">
                                    {userType !== null ? t(`UserType.${userType}`) : ""}
                                </div>
                            </div>

                            {/* メールアドレス */}
                            <div className="col-span-1 py-1">
                                <label className="block font-medium text-gray-900">{t("CommonLabel.email")}*</label>
                            </div>
                            <div className="col-span-2 py-1">
                                <div className="block w-full select-none rounded-lg p-2.5 text-xs text-gray-900 focus:outline-none sm:text-sm">
                                    {email}
                                </div>
                            </div>

                            {/* 氏名 */}
                            <div className="col-span-1 py-2">
                                <p className="block font-medium text-gray-900">{t("CommonLabel.fullName")}</p>
                            </div>
                            <div className="col-span-2 flex flex-row items-center justify-center space-x-2 py-2">
                                <div>
                                    <label htmlFor="lname" className="sr-only">
                                        {t("CommonLabel.familyName")}
                                    </label>
                                    <input
                                        type="text"
                                        name="lname"
                                        id="lname"
                                        placeholder={t("CommonLabel.familyName")}
                                        className="textbox-common"
                                        onChange={handleFamilyNameChange}
                                        maxLength={FAMILY_NAME_MAX_LENGTH}
                                        required
                                        autoComplete="off"
                                        value={familyName ?? ""}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="fname" className="sr-only">
                                        {t("CommonLabel.givenName")}
                                    </label>
                                    <input
                                        type="text"
                                        name="fname"
                                        id="fname"
                                        placeholder={t("CommonLabel.givenName")}
                                        className="textbox-common"
                                        onChange={handleGivenNameChange}
                                        maxLength={GIVEN_NAME_MAX_LENGTH}
                                        required
                                        autoComplete="off"
                                        value={givenName ?? undefined}
                                    />
                                </div>
                            </div>

                            {/* 所属 */}
                            <div className="col-span-1 py-1">
                                <label htmlFor="organization" className="block font-medium text-gray-900">
                                    <div className={i18n.language === "en" ? "text-sm sm:text-base" : ""}>
                                        {t("CommonLabel.organization")}
                                    </div>
                                </label>
                            </div>
                            <div className="col-span-2 py-2">
                                <input
                                    type="text"
                                    name="organization"
                                    id="organization"
                                    placeholder={t("CommonLabel.organizationExample")}
                                    className="textbox-common"
                                    onChange={handleOrganizationChange}
                                    maxLength={ORGANIZATION_MAX_LENGTH}
                                    required
                                    autoComplete="off"
                                    value={organization ?? ""}
                                />
                            </div>

                            {/* 変更ボタン */}
                            <div className="col-span-3 mt-5 flex items-center justify-center">
                                <button
                                    type="submit"
                                    className={`${isProcessingBaseInfo ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                                    disabled={isProcessingBaseInfo}
                                >
                                    {isProcessingBaseInfo ? (
                                        <div className="flex items-center justify-center">
                                            <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                            <label className="">Processing...</label>
                                        </div>
                                    ) : (
                                        t("CommonLabel.change")
                                    )}
                                </button>
                            </div>
                            <div className="col-span-3 pt-5">
                                <label htmlFor="organization" className="block text-sm font-medium text-gray-500">
                                    *{t("AccountSetting.cannotUpdateEmail")}
                                </label>
                            </div>
                        </div>
                    </div>
                </form>

                {/* パスワード変更 */}
                <form onSubmit={handlePasswordUpdate}>
                    <div className="my-5 rounded-lg border border-gray-200 px-5 shadow sm:mx-5">
                        <div className="m-0 grid grid-cols-3 items-center justify-center p-4 sm:m-2">
                            <div className="col-span-3 text-lg font-bold">{t("AccountSetting.passwordUpdate")}</div>
                            <hr className="col-span-3 my-2 border-t-2"></hr>

                            {/* 現在のパスワード */}
                            <div className="col-span-1 py-2">
                                <label htmlFor="old_password" className="block font-medium text-gray-900">
                                    {t("AccountSetting.oldPassword")}
                                </label>
                            </div>
                            <div className="col-span-2 py-2">
                                <div className="relative flex items-center">
                                    <input
                                        type={oldPasswordType}
                                        name="old_password"
                                        id="old_password"
                                        placeholder="old password"
                                        className="textbox-common"
                                        onChange={handleOldPasswordChange}
                                        maxLength={PASSWORD_MAX_LENGTH}
                                        required
                                        autoComplete="off"
                                        value={oldPassword}
                                    />
                                    {oldPasswordType === "password" && (
                                        <VisibilityOffIcon
                                            fontSize="small"
                                            onClick={() => setOldPasswordType("text")}
                                            className="Password__visual absolute right-2"
                                        />
                                    )}
                                    {oldPasswordType === "text" && (
                                        <VisibilityIcon
                                            fontSize="small"
                                            onClick={() => setOldPasswordType("password")}
                                            className="Password__visual absolute right-2"
                                        />
                                    )}
                                </div>
                            </div>

                            {/* 新しいのパスワード */}
                            <div className="col-span-1 py-2">
                                <label htmlFor="new_password" className="block font-medium text-gray-900">
                                    {t("AccountSetting.newPassword")}
                                </label>
                            </div>
                            <div className="relative col-span-2 py-2">
                                <div className="relative flex items-center">
                                    <input
                                        type={newPasswordType}
                                        name="new_password"
                                        id="new_password"
                                        placeholder="new password"
                                        className="textbox-common"
                                        onChange={handleNewPasswordChange}
                                        onFocus={togglePasswordRequirements}
                                        onBlur={togglePasswordRequirements}
                                        maxLength={PASSWORD_MAX_LENGTH}
                                        required
                                        autoComplete="new-password"
                                        value={newPassword}
                                    />
                                    {newPasswordType === "password" && (
                                        <VisibilityOffIcon
                                            fontSize="small"
                                            onClick={() => setNewPasswordType("text")}
                                            className="Password__visual absolute right-2"
                                        />
                                    )}
                                    {newPasswordType === "text" && (
                                        <VisibilityIcon
                                            fontSize="small"
                                            onClick={() => setNewPasswordType("password")}
                                            className="Password__visual absolute right-2"
                                        />
                                    )}
                                    {showPasswordRequirements && (
                                        // パスワード要件の吹き出し
                                        <div className="absolute bottom-full left-0 rounded-md border bg-yellow-50 p-2 shadow-md">
                                            <ul className="list-disc pl-5 text-sm text-gray-800">
                                                <li>{t("CommonMessage.passwordPolicy1")}</li>
                                                <li>{t("CommonMessage.passwordPolicy2")}</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* 新しいパスワード（確認用） */}
                            <div className="col-span-1 py-2">
                                <label htmlFor="new_confirm_password" className="block font-medium text-gray-900">
                                    {t("AccountSetting.newPasswordConfirm")}
                                </label>
                            </div>
                            <div className="col-span-2 py-2">
                                <div className="relative flex items-center">
                                    <input
                                        type={newConfirmPasswordType}
                                        name="new_confirm_password"
                                        id="new_confirm_password"
                                        placeholder="new password"
                                        className="textbox-common"
                                        onChange={handleNewConfirmPasswordChange}
                                        maxLength={PASSWORD_MAX_LENGTH}
                                        required
                                        autoComplete="new-password"
                                        value={newConfirmPassword}
                                    />
                                    {newConfirmPasswordType === "password" && (
                                        <VisibilityOffIcon
                                            fontSize="small"
                                            onClick={() => setNewConfirmPasswordType("text")}
                                            className="Password__visual absolute right-2"
                                        />
                                    )}
                                    {newConfirmPasswordType === "text" && (
                                        <VisibilityIcon
                                            fontSize="small"
                                            onClick={() => setNewConfirmPasswordType("password")}
                                            className="Password__visual absolute right-2"
                                        />
                                    )}
                                </div>
                            </div>

                            {/* 変更ボタン */}
                            <div className="col-span-3 mt-5 flex items-center justify-center">
                                <button
                                    type="submit"
                                    className={`${isProcessingPassword ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                                    disabled={isProcessingPassword}
                                >
                                    {isProcessingPassword ? (
                                        <div className="flex items-center justify-center">
                                            <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                            <label className="">Processing...</label>
                                        </div>
                                    ) : (
                                        t("CommonLabel.change")
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                {/* 退会 */}
                <div className="my-5 rounded-lg border border-gray-200 px-5 shadow sm:mx-5">
                    <div className="m-0 grid grid-cols-3 items-center justify-center p-4 sm:m-2">
                        <div className="col-span-3 text-lg font-bold">{t("AccountSetting.accountDeleteTitle")}</div>
                        <hr className="col-span-3 my-2 border-t-2"></hr>

                        {/* 退会するボタン */}
                        <div className="col-span-3 mt-5 flex items-center justify-center">
                            <button className="btn-primary w-1/3" onClick={handleAccountDelete}>
                                {t("AccountSetting.accountDelete")}
                            </button>
                        </div>
                    </div>
                </div>

                {/* マイページへ戻る */}
                <div className="flex justify-center pt-2">
                    <Link to={MYPAGE_ROUTE}>
                        <button className="btn-secondary px-5">{t("AccountSetting.backToMypage")}</button>
                    </Link>
                </div>
            </div>
            {/* 退会確認用モーダル */}
            <AccountDeleteModal
                showModal={showModal}
                handleClose={handleCloseModal}
                handleDelete={handleAccountDeleteConfirm}
                isProcessing={isProcessingReset}
                errorMessage={errorMessageModal}
            />
            <Footer />
        </div>
    );
};

export default AccountSetting;
