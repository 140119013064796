import {
    DF_LAT_V1_0_0,
    DF_LAT_V1_0_2,
    DF_LAT_V1_0_3,
    DF_LAT_V1_0_4,
    DF_LAT_FILENAME_V1_0_0,
    DF_LAT_FILENAME_V1_0_2,
    DF_LAT_FILENAME_V1_0_3,
    DF_LAT_FILENAME_V1_0_4,
    DF_LAT_PRO_V1_0_0,
    DF_LAT_PRO_FILENAME_V1_0_0,
    DF_LAT_LITE_V1_0_0,
    DF_LAT_LITE_FILENAME_V1_0_0,
    DF_LAT_PRO_V1_0_1,
    DF_LAT_LITE_FILENAME_V1_0_1,
    DF_LAT_LITE_V1_0_1,
    DF_LAT_PRO_FILENAME_V1_0_1,
    DF_LAT_LITE_FILENAME_V1_1_0,
    DF_LAT_LITE_V1_1_0,
    DF_LAT_PRO_FILENAME_V1_1_0,
    DF_LAT_PRO_V1_1_0,
} from "../../consts/Installers";

/**
 * リリースノート（DF LAT）
 * @returns JSX.Element
 */
export const ReleaseNotesDfLat = () => {
    return (
        <div>
            {/* Pro版 v1.1.0, Lite版 v1.1.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF LAT Pro版 v1.1.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF LAT Lite版 v1.1.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-06-05</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF LAT Pro版 v1.1.0がリリースされました。</p>
                    <p>DF LAT Lite版 v1.1.0がリリースされました。</p>
                    <br />
                    <p className="font-bold">■ Pro版 v1.1.0向け</p>
                    <p>・DEM上でのスライス位置の指定機能を追加しました。</p>
                    <br />
                    <p className="font-bold">■ 共通内容</p>
                    <p>バグ修正</p>
                    <p>・CHM計算時に、DSMとDTMの位置がずれる場合がある問題を修正しました。</p>
                    <p>・管理者以外のユーザーでスタートメニューにショートカットが追加されない問題を修正しました。</p>
                    <br />
                    <p>その他</p>
                    <p>・ソフトウェアのデフォルトのインストール先を変更しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_LAT_PRO_V1_1_0}>
                    {DF_LAT_PRO_FILENAME_V1_1_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_LAT_LITE_V1_1_0}>
                    {DF_LAT_LITE_FILENAME_V1_1_0}
                </a>
            </div>

            {/* Pro版 v1.0.1, Lite版 v1.0.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF LAT Pro版 v1.0.1</h2>
                <h2 className="my-2 text-2xl font-bold">DF LAT Lite版 v1.0.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-04-12</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF LAT Pro版 v1.0.1がリリースされました。</p>
                    <p>DF LAT Lite版 v1.0.1がリリースされました。</p>
                    <br />
                    <p className="font-bold">■ 共通内容</p>
                    <p>機能追加</p>
                    <p>・レーザー林相図作成機能を追加しました。</p>
                    <br />
                    <p>機能改善</p>
                    <p>・地面検出機能の最適化を行いました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・一部のPCで起動しない問題を修正しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_LAT_PRO_V1_0_1}>
                    {DF_LAT_PRO_FILENAME_V1_0_1}
                </a>
                <br />
                <a className="link-text-blue" href={DF_LAT_LITE_V1_0_1}>
                    {DF_LAT_LITE_FILENAME_V1_0_1}
                </a>
            </div>

            {/* Pro版 v1.0.0, Lite版 v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-2xl font-bold">DF LAT Pro版 v1.0.0</h2>
                <h2 className="my-2 text-2xl font-bold">DF LAT Lite版 v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-03-07</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF LAT Pro版 v1.0.0がリリースされました。</p>
                    <p>DF LAT Lite版 v1.0.0がリリースされました。</p>
                    <br />
                    <p className="font-bold">■ 共通内容</p>
                    <p>機能改善</p>
                    <p>・地面検出アルゴリズムを改良しました。</p>
                    <p>・点群描画を一部高速化しました。</p>
                    <p>・地面検出時に色情報を残すように修正しました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・特殊条件化でフィルタリング時にデータが欠ける問題を解消しました。</p>
                    <p>・DTM出力時に解像度50cmでうまくいかない問題を解消しました。</p>
                    <p></p>
                    <br />
                    <p className="font-bold">■ Pro版 v1.0.0向け</p>
                    <p>・「戻る・進む」ボタンのUIを一部修正しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_LAT_PRO_V1_0_0}>
                    {DF_LAT_PRO_FILENAME_V1_0_0}
                </a>
                <br />
                <a className="link-text-blue" href={DF_LAT_LITE_V1_0_0}>
                    {DF_LAT_LITE_FILENAME_V1_0_0}
                </a>
            </div>

            {/* v1.0.4 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF LAT v1.0.4</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-01-05</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>アップデート情報</p>
                    <p>・マニュアルフィルタリングに「戻る・進む」ボタンが追加されました。</p>
                    <p>・ログイン認証時のバグを修正しました。</p>
                    <p>・その他軽微なバグを修正しました。</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_LAT_V1_0_4}>
                        {DF_LAT_FILENAME_V1_0_4}
                    </a>
                </div>
            </div>

            {/* v1.0.3 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF LAT v1.0.3</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-13</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>バグ修正</p>
                    <p>・オフライン時7日間ログインできる機能の問題修正</p>
                    <br />
                    <p>機能改善</p>
                    <p>・点群間引きアルゴリズムの機能改善</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_LAT_V1_0_3}>
                        {DF_LAT_FILENAME_V1_0_3}
                    </a>
                </div>
            </div>

            {/* v1.0.2 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF LAT v1.0.2</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-12-08</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>バグ修正</p>
                    <p>・編集中にソフトウェアが強制終了した際の編集情報の復元</p>
                    <br />
                    <p>その他</p>
                    <p>・無料体験版</p>
                    <p>・マニュアル更新</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_LAT_V1_0_2}>
                        {DF_LAT_FILENAME_V1_0_2}
                    </a>
                </div>
            </div>

            {/* v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF LAT v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2023-10-02</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF LAT v1.0.0をリリースしました。</p>
                </div>
                <p className="mt-4">ダウンロード</p>
                <div>
                    <a className="link-text-blue" href={DF_LAT_V1_0_0}>
                        {DF_LAT_FILENAME_V1_0_0}
                    </a>
                </div>
                <p className="text-sm text-red-800">
                    *新ライセンス未対応（2023/12/8以降に発行したライセンスではアクティベーションできません）
                </p>
            </div>
        </div>
    );
};

export default ReleaseNotesDfLat;
