import {
    DF_VIEWER_FILENAME_V1_0_0,
    DF_VIEWER_FILENAME_V1_1_0,
    DF_VIEWER_URL_V1_0_0,
    DF_VIEWER_URL_V1_1_0,
} from "../../consts/Installers";

/**
 * リリースノート英語版（DF Viewer・英語版）
 * @returns JSX.Element
 */
export const ReleaseNotesDfViewerEn = () => {
    return (
        <div>
            {/* v1.1.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Viewer v1.1.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-03-29</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF Viewer v1.1.0 has been released.</p>
                    <br />
                    <p>Function Improvements</p>
                    <p>・The drawing function has been updated.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_VIEWER_URL_V1_1_0}>
                    {DF_VIEWER_FILENAME_V1_1_0}
                </a>
            </div>

            {/* v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF Viewer v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2022-08-01</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>Released DF Viewer v1.0.0.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_VIEWER_URL_V1_0_0}>
                    {DF_VIEWER_FILENAME_V1_0_0}
                </a>
            </div>
        </div>
    );
};

export default ReleaseNotesDfViewerEn;
