import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { LICENSE_ISSUE_CONFIRM_ROUTE, LICENSE_ISSUE_USER_SEARCH_ROUTE } from "../consts/routes";
import { useUserContext } from "../contexts/UserContext";
import { CrossCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import { User } from "../types/User";
import i18n from "../i18n/configs";
import { getPaymentHistoryList } from "../clients/PaymentHistoryClient";
import { PaymentHistory } from "../types/PaymentHistory";
import { formatUtcToLocalDateTime } from "../utils/DateUtils";
import { OnlyMasterUserAuthorized } from "../components/OnlyMasterUserAuthorized";
import { Helmet } from "react-helmet";

interface LocationState {
    selectedUser: User;
}

/**
 * ライセンス発行 新規購入/更新
 * @returns JSX.Element
 */
const LicenseIssuePurchaseType = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedUser } = location.state as LocationState;
    const { user, getRecentToken } = useUserContext();
    const [loading, setLoading] = useState(true);
    const [paymentHistoryList, setPaymentHistoryList] = useState<PaymentHistory[]>([]);
    const [selectedPaymentHistory, setSelectedPaymentHistory] = useState<PaymentHistory | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * 購入履歴情報の取得処理
     * @returns
     */
    async function fetchPaymentHistory() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]購入履歴一覧取得（再購入可能な購入履歴一覧）
            const response: PaymentHistory[] = await getPaymentHistoryList(idToken, {
                pUserId: selectedUser.user_id,
                enableUpdate: true,
            });
            const sortedList: PaymentHistory[] = response.sort((a, b) => {
                return new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime();
            });
            setPaymentHistoryList(sortedList);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!effectRun.current) {
            fetchPaymentHistory();
            return () => {
                effectRun.current = true;
            };
        }
    }, []);

    /**
     * 購入履歴一覧の行選択時のイベント処理
     * @param p
     */
    const handleRowClick = (p: PaymentHistory) => {
        setSelectedPaymentHistory(p);
    };

    /**
     * 選択した内容で再購入押下時の処理
     */
    const handleLicenseUpdate = () => {
        navigate(LICENSE_ISSUE_CONFIRM_ROUTE, {
            state: { selectedUser: selectedUser, selectedPaymentHistory: selectedPaymentHistory, isUpdate: true },
        });
    };

    /**
     * 新規にライセンスを発行押下時の処理
     */
    const handleLicenseIssue = () => {
        navigate(LICENSE_ISSUE_CONFIRM_ROUTE, { state: { selectedUser: selectedUser } });
    };

    /**
     * 戻る押下時のイベント処理
     */
    const handleBack = () => {
        navigate(LICENSE_ISSUE_USER_SEARCH_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("LicenseIssuePurchaseType.meta.title")} />
            <ScrollToTop />
            {/* マスターユーザーのみ閲覧可 */}
            <OnlyMasterUserAuthorized />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("LicenseIssuePurchaseType.title")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="text-gre-800 p-2 font-bold">Loading...</p>
                    </div>
                ) : (
                    <>
                        <div className="mt-2 flex items-center justify-center text-lg font-bold">
                            <Trans
                                i18nKey={"LicenseIssuePurchaseType.tableTitle"}
                                values={{ email: selectedUser.email }}
                            />
                        </div>
                        {paymentHistoryList.length === 0 ? (
                            // 購入履歴が存在しない場合
                            <div className="flex items-center justify-center py-4 text-lg font-bold text-gray-700">
                                {t("LicenseIssuePurchaseType.noHistory")}
                            </div>
                        ) : (
                            // 購入履歴が存在する場合
                            <div className="overflow-x-auto transition duration-500 ease-in-out xl:mx-20">
                                <table className="my-3 min-w-full divide-y divide-gray-200 border">
                                    <thead className="bg-gray-400">
                                        <tr
                                            className={`${
                                                i18n.language === "ja" ? "text-base" : "text-sm"
                                            } font-semibold uppercase tracking-wide text-gray-100`}
                                        >
                                            {/* [ヘッダ] 選択 */}
                                            <th scope="col" className="whitespace-nowrap px-2 py-2 text-center">
                                                <div className="flex items-center justify-center gap-x-2">
                                                    <span>{t("CommonLabel.select")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 購入日 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.paymentDate")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 商品名 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.productName")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 有効期限 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.expirationDate")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 購入者氏名 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.purchaser")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 購入者メールアドレス */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.email")}</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200">
                                        {paymentHistoryList
                                            // 有効期限がnullのものは除外（既に更新済みの購入履歴のため）
                                            .filter((p) => p.expire_date !== null)
                                            .map((p) => (
                                                <tr
                                                    key={p.payment_history_id}
                                                    className={`${
                                                        selectedPaymentHistory?.payment_history_id ===
                                                            p.payment_history_id && "bg-blue-100"
                                                    } text-sm font-semibold text-gray-800`}
                                                >
                                                    {/* 選択 */}
                                                    <td className="h-px w-px whitespace-nowrap text-center">
                                                        <input
                                                            type="radio"
                                                            checked={
                                                                selectedPaymentHistory?.payment_history_id ===
                                                                p.payment_history_id
                                                            }
                                                            onChange={() => handleRowClick(p)}
                                                        />
                                                    </td>
                                                    {/* 購入日時 */}
                                                    <td className="h-px w-px whitespace-nowrap">
                                                        <div className="px-6 py-3">
                                                            <span>{formatUtcToLocalDateTime(p.payment_date)}</span>
                                                        </div>
                                                    </td>
                                                    {/* 商品名 */}
                                                    <td className="h-px w-px whitespace-nowrap">
                                                        <div className="px-6 py-3">
                                                            <span>
                                                                {i18n.language === "ja"
                                                                    ? p.p_product_type_name.split("%%")[0]
                                                                    : p.p_product_type_name.split("%%")[1]}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    {/* ライセンス有効期限 */}
                                                    <td className="h-px w-px whitespace-nowrap">
                                                        <div className="px-6 py-3">
                                                            <span>{formatUtcToLocalDateTime(p.expire_date)}</span>
                                                        </div>
                                                    </td>
                                                    {/* 購入者氏名 */}
                                                    <td className="h-px w-px whitespace-nowrap">
                                                        <div className="px-6 py-3">
                                                            <span>{p.p_name}</span>
                                                        </div>
                                                    </td>
                                                    {/* 購入者メールアドレス */}
                                                    <td className="h-px w-px whitespace-nowrap">
                                                        <div className="px-6 py-3">
                                                            <span>{p.p_email}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </>
                )}

                {/* 更新ボタン */}
                <div className="mb-6 mt-2 flex items-center justify-evenly">
                    <button
                        type="button"
                        onClick={handleLicenseUpdate}
                        className={`${
                            selectedPaymentHistory !== null
                                ? "btn-primary"
                                : "btn-primary-disabled bg-gray-300 text-gray-500"
                        } px-10 py-1`}
                        disabled={selectedPaymentHistory === null}
                    >
                        <Trans i18nKey="LicenseIssuePurchaseType.updateLicenseButton" />
                    </button>
                </div>

                <hr className="my-5 border-b border-t-2" />

                {/* 新規ライセンス発行ボタン */}
                <div className="my-6 flex items-center justify-evenly">
                    <button type="button" onClick={handleLicenseIssue} className={"btn-primary px-14"}>
                        <Trans i18nKey="LicenseIssuePurchaseType.issueNewLicenseButton" />
                    </button>
                </div>

                {/* 戻るボタン */}
                <div className="mx-auto flex max-w-xl items-center justify-evenly py-5 ">
                    <button type="button" onClick={handleBack} className="btn-secondary mx-2 w-[100px]">
                        {t("CommonLabel.back")}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LicenseIssuePurchaseType;
