import { t } from "i18next";
import Modal from "react-modal";

const StorageModal = ({ showStorageModal, setShowStorageModal, storageSize, enableSharing }: any) => {
    const usedSharedStorage = storageSize ? storageSize.used.share_folder : 0;
    const myUsedStorage = storageSize ? storageSize.used.myfolder : 0;
    const othersUsedStorage = storageSize ? storageSize.used.other_members : 0;
    const totalUsedStorage = usedSharedStorage + myUsedStorage + othersUsedStorage;
    const maxGroupStorageSize = storageSize ? storageSize.allocated.group / (1024 * 1024 * 1024) : 0;
    const maxMyStorageSize = storageSize ? storageSize.allocated.myfolder / (1024 * 1024 * 1024) : 0;
    const maxSharedStorageSize = storageSize ? storageSize.allocated.share_folder / (1024 * 1024 * 1024) : 0;
    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 max-w-md -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showStorageModal}
        >
            <div>
                <div className="mt-2 flex items-center justify-center">
                    <p className="text-center text-lg font-bold text-gray-600">{t("WebGIS.storageUsage")}</p>
                </div>
                {/* {totalUsedStorage < 1024 * 1024 * 1024 ? (
                    <p>
                        グループ使用量: {Number((totalUsedStorage / (1024 * 1024)).toFixed(1)).toLocaleString()} MB /{" "}
                        {maxGroupStorageSize} GB
                    </p>
                ) : (
                    <p>
                        グループ使用量: {Number((totalUsedStorage / (1024 * 1024 * 1024)).toFixed(1)).toLocaleString()}{" "}
                        GB / {maxGroupStorageSize} GB
                    </p>
                )} */}

                {/* Myフォルダ */}
                {myUsedStorage < 1024 * 1024 * 1024 ? (
                    <p className="pl-5 pt-2 font-bold">
                        {t("WebGIS.myFolder")}: {Number((myUsedStorage / (1024 * 1024)).toFixed(1)).toLocaleString()} MB
                        / {maxMyStorageSize} GB
                    </p>
                ) : (
                    <p className="pl-5 font-bold">
                        {t("WebGIS.myFolder")}:{" "}
                        {Number((myUsedStorage / (1024 * 1024 * 1024)).toFixed(1)).toLocaleString()} GB /{" "}
                        {maxMyStorageSize} GB
                    </p>
                )}

                {/* 共有フォルダ */}
                {enableSharing && (
                    <>
                        {usedSharedStorage < 1024 * 1024 * 1024 ? (
                            <p className="pl-5 font-bold">
                                {t("WebGIS.sharedFolder")}:{" "}
                                {Number((usedSharedStorage / (1024 * 1024)).toFixed(1)).toLocaleString()} MB /{" "}
                                {maxSharedStorageSize} GB
                            </p>
                        ) : (
                            <p className="pl-5 font-bold">
                                {t("WebGIS.sharedFolder")}:{" "}
                                {Number((usedSharedStorage / (1024 * 1024 * 1024)).toFixed(1)).toLocaleString()} GB /{" "}
                                {maxSharedStorageSize} GB
                            </p>
                        )}
                    </>
                )}

                {/* キャンセル */}
                <div className="mt-4 flex items-center justify-evenly">
                    <button
                        type="button"
                        onClick={() => {
                            setShowStorageModal(false);
                        }}
                        className="btn-secondary w-[150px]"
                    >
                        {t("CommonLabel.close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default StorageModal;
