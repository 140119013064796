import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { CrossCircleIcon } from "../../components/Icons";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";
import { formatDateTime } from "../../utils/DateUtils";

type ModalProps = {
    showModal: boolean;
    setShowModale: (bool: boolean) => void;
};

/**
 * S3のファイルアップロード確認モーダル
 *
 * @param ModalProps
 * @returns JSX.Element
 */
const S3FileUploadConfirmModal: React.FC<ModalProps> = ({ showModal, setShowModale }) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 max-w-2xl -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 px-4 py-2 sm:m-2">
                <div className="text-lg font-bold">{t("DataManagement.uploadeNoteTitle")}</div>
                <hr className="my-2 border-t-2"></hr>

                <ul className="list-disc pl-4 text-gray-800">
                    <li>{t("DataManagement.uploadNote1")}</li>
                    <li>{t("DataManagement.uploadNote2")}</li>
                    <li>{t("DataManagement.uploadNote3")}</li>
                </ul>

                {/* 閉じるボタン */}
                <div className="mt-4 flex items-center justify-evenly">
                    <button className="btn-primary w-1/3" onClick={() => setShowModale(false)}>
                        {t("CommonLabel.close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default S3FileUploadConfirmModal;
