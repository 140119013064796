import { MouseEventHandler, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import dfscanner_icon from "./../img/dfscanner/icon.png";
import dfviewer_icon from "./../img/dfviewer/icon.jpg";
import dflat_icon from "./../img/dflat/icon.png";
import { ScrollToTop } from "../components/ScrollToTop";
import { DOWNLOAD_ROUTE } from "../consts/routes";
import ReleaseNotesDfScanner from "./releaseNotes/ReleaseNotesDfScanner";
import ReleaseNotesDfViewer from "./releaseNotes/ReleaseNotesViewer";
import ReleaseNotesDfLat from "./releaseNotes/ReleaseNotesDfLat";
import i18n from "../i18n/configs";
import ReleaseNotesDfLatEn from "./releaseNotes/ReleaseNotesDfLatEn";
import ReleaseNotesDfViewerEn from "./releaseNotes/ReleaseNotesViewerEn";
import ReleaseNotesDfScannerEn from "./releaseNotes/ReleaseNotesDfScannerEn";
import { Helmet } from "react-helmet";

const DF_SCANNER_VAL = 1;
const DF_VIEWER_VAL = 2;
const DF_LAT_VAL = 3;

/**
 * リリースノート画面
 * @returns JSX.Element
 */
const ReleaseNotes = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [selectedProduct, setSelectedProduct] = useState<Number>(DF_SCANNER_VAL);

    // 前のページから渡されたステート値によって初期表示を変更
    useEffect(() => {
        if (location.state != null) {
            setSelectedProduct(location.state.productValue);
        }
    }, []);

    /**
     * 商品選択時のイベント処理
     * @param event
     */
    const handleChangeView: MouseEventHandler<HTMLButtonElement> = (event) => {
        setSelectedProduct(Number(event.currentTarget.value));
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("ReleaseNotes.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <h1 className="mb-4 mt-2 text-center text-2xl font-bold text-gray-800 sm:text-3xl">
                            {t("ReleaseNotes.title")}
                        </h1>
                    </div>

                    {/* ダウンロード画面に戻る */}
                    <Link to={DOWNLOAD_ROUTE}>
                        <div className="link-text mb-3 flex items-center justify-center text-lg">
                            {t("ReleaseNotes.backToDownload")}
                        </div>
                    </Link>

                    {/* 注意書き */}
                    <p className="pl-2 text-sm text-gray-800">*{t("ReleaseNotes.note1")}</p>
                    <p className="mb-3 pl-2 text-sm text-gray-800">*{t("ReleaseNotes.note2")}</p>

                    <div className="mb-5 grid grid-cols-3 items-center justify-center">
                        {/* DF Scanner */}
                        <button
                            className={`${
                                selectedProduct === DF_SCANNER_VAL && "border-lime-700 bg-lime-100"
                            } col-span-3 flex items-center rounded border-b-4 px-3 sm:col-span-1`}
                            value={DF_SCANNER_VAL}
                            onClick={handleChangeView}
                        >
                            <img className="h-8 w-8 p-1" src={dfscanner_icon} alt="dfscanner" />
                            <h2 id="dfscanner" className="pl-2 text-xl font-semibold text-gray-800 sm:text-2xl">
                                DF Scanner
                            </h2>
                        </button>
                        {/* DF Viewer */}
                        <button
                            className={`${
                                selectedProduct === DF_VIEWER_VAL && "border-lime-700 bg-lime-100"
                            } col-span-3 flex items-center rounded border-b-4 px-3 sm:col-span-1`}
                            value={DF_VIEWER_VAL}
                            onClick={handleChangeView}
                        >
                            <img className="h-8 w-8 p-1" src={dfviewer_icon} alt="dfviewer" />
                            <h2 id="dfviewer" className="pl-2 text-xl font-semibold text-gray-800 sm:text-2xl">
                                DF Viewer
                            </h2>
                        </button>
                        {/* DF LAT */}
                        <button
                            className={`${
                                selectedProduct === DF_LAT_VAL && "border-lime-700 bg-lime-100"
                            } col-span-3 flex items-center rounded border-b-4 px-3 sm:col-span-1`}
                            value={DF_LAT_VAL}
                            onClick={handleChangeView}
                        >
                            <img className="h-8 w-8 p-1" src={dflat_icon} alt="dflat" />
                            <h2 id="dflat" className="pl-2 text-xl font-semibold text-gray-800 sm:text-2xl">
                                DF LAT
                            </h2>
                        </button>
                    </div>

                    {/* DF Scanner選択時 */}
                    {selectedProduct === DF_SCANNER_VAL &&
                        (i18n.language === "ja" ? (
                            // 日本語版
                            <ReleaseNotesDfScanner />
                        ) : (
                            // 英語版
                            <ReleaseNotesDfScannerEn />
                        ))}
                    {/* DF Viewer選択時 */}
                    {selectedProduct === DF_VIEWER_VAL &&
                        (i18n.language === "ja" ? (
                            // 日本語版
                            <ReleaseNotesDfViewer />
                        ) : (
                            // 英語版
                            <ReleaseNotesDfViewerEn />
                        ))}
                    {/* DF LAT選択時 */}
                    {selectedProduct === DF_LAT_VAL &&
                        (i18n.language === "ja" ? (
                            // 日本語版
                            <ReleaseNotesDfLat />
                        ) : (
                            // 英語版
                            <ReleaseNotesDfLatEn />
                        ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ReleaseNotes;
