/**
 * チェックマーク（処理完了）
 * @returns
 */
export const CheckmarkCircleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10" stroke="#34D399" strokeWidth="2" fill="transparent" />
        <path d="M7 13l3 3 7-7" stroke="#34D399" strokeWidth="2" fill="transparent" />
    </svg>
);

/**
 * エラーマーク（処理失敗）
 * @returns
 */
export const CrossCircleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10" stroke="#EF4444" strokeWidth="2" fill="transparent" />
        <path d="M7 7l10 10M17 7l-10 10" stroke="#EF4444" strokeWidth="2" fill="transparent" />
    </svg>
);
