import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

import dfscanner_dflat_icon from "./../../img/dfscanner&dflat/icon_lite_lite.png";
import dfscanner_dflat_screen from "./../../img/dfscanner&dflat/screen.png";
import { DETAIL_DF_SCANNER_ROUTE } from "../../consts/routes";
import {
    PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_LITE,
    PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_PRO,
} from "../../consts/constants";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../i18n/configs";

type PanelProps = {
    handleSelectProduct: MouseEventHandler<HTMLButtonElement>;
};

/**
 * DF Scanner Lite × DF LAT Lite 商品パネル
 * @returns JSX.Element
 */
const DfScannerLiteDfLatLitePanel: React.FC<PanelProps> = ({ handleSelectProduct }) => {
    const { t } = useTranslation();

    return (
        <div className="flex h-full w-full max-w-sm flex-col justify-between rounded-lg border border-gray-300 bg-green-50 bg-opacity-50 px-10 pt-5 shadow">
            <div className="flex items-center justify-center">
                <img className="h-20 w-20 p-3" src={dfscanner_dflat_icon} alt="dfscanner & dflat" />
                <div>
                    <label className="text-2xl font-semibold tracking-tight text-lime-900">DF Scanner</label>
                    <label className="text-xl font-semibold tracking-tight text-lime-900">
                        &nbsp;{t("CommonLabel.lite")}
                    </label>
                    <br />
                    <label className="text-2xl font-semibold tracking-tight text-lime-900">&&nbsp;</label>
                    <label className="text-2xl font-semibold tracking-tight text-lime-900">DF LAT</label>
                    <label className="text-xl font-semibold tracking-tight text-lime-900">
                        &nbsp;{t("CommonLabel.lite")}
                    </label>
                </div>
            </div>
            <img className="px-8 py-3" src={dfscanner_dflat_screen} alt="dfscanner & dflat" />
            {i18n.language === "ja" ? (
                <>
                    <p className="font-bold text-lime-800">
                        <Trans
                            i18nKey="ProductSelect.priceForGeneral"
                            values={{ yenPerYear: "85.5", yenPerMonth: "19" }}
                        />
                    </p>
                    <p className="font-bold text-lime-800">
                        <Trans
                            i18nKey="ProductSelect.priceForEducational"
                            values={{ yenPerYear: "38", yenPerMonth: "9.5" }}
                        />
                    </p>
                    <p className="pb-4 text-sm font-bold text-lime-800">*{t("ProductSelect.forSmallForestBusiness")}</p>
                </>
            ) : (
                <></>
            )}
            <p className="text-sm text-gray-800">
                <Trans
                    i18nKey="ProductSelect.dfscannerLiteAndDflatLite"
                    components={{
                        l: <Link className="link-text" to={DETAIL_DF_SCANNER_ROUTE} />,
                    }}
                />
            </p>
            <div className="flex flex-col items-center justify-center gap-3 py-12">
                {/* クレジット購入ボタンは非表示にしておく */}
                {/* <button
                    id="purchase-dfscanner-dflat-btn"
                    onClick={handleSelectProduct}
                    className="text-lx btn-green-white h-full w-2/3"
                    value={PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_LITE}
                >
                    {t("ProductSelect.purcahseLicense")}
                </button> */}
            </div>
        </div>
    );
};

export default DfScannerLiteDfLatLitePanel;
