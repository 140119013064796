import { Auth } from "aws-amplify";
import { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { CrossCircleIcon } from "../components/Icons";
import { useUserContext } from "../contexts/UserContext";
import { MYPAGE_ROUTE, PRODUCT_CONFIRM_ROUTE, PRODUCT_CONFIRM_TRIAL_ROUTE, SIGNIN_ROUTE } from "../consts/routes";
import { PASSWORD_MAX_LENGTH } from "../consts/constants";
import { ScrollToTop } from "../components/ScrollToTop";
import { loggingError, loggingWarn } from "../utils/LoggingUtils";
import { Helmet } from "react-helmet";

/**
 * 初回パスワード再設定画面
 * @returns JSX.Element
 */
const CompletePassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [password, setPassword] = useState<string>("");
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { setUser, challengeUser, setChallengeUser, selectedProductType } = useUserContext();
    const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    // 仮認証情報がない場合はログイン画面へ
    useEffect(() => {
        if (!challengeUser) {
            navigate(SIGNIN_ROUTE);
        }
    }, []);

    /**
     * パスワード要件表示用トグル
     */
    const togglePasswordRequirements = () => {
        setShowPasswordRequirements(!showPasswordRequirements);
    };

    /**
     * パスワード入力イベント処理
     * @param target
     */
    const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setPassword(target.value);
    };

    /**
     * パスワード（確認）入力イベント処理
     * @param target
     */
    const handleConfirmPasswordChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setConfirmPassword(target.value);
    };

    /**
     * パスワード確定のイベント処理
     * @param event
     */
    const handleComptelePassword: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            if (password !== confirmPassword) {
                setErrorMessage(t("ErrorMessage.passwordMismatch"));
                setPassword("");
                setConfirmPassword("");
                return;
            }
            // パスワード再設定
            await Auth.completeNewPassword(challengeUser, password);

            // 認証情報を取得してコンテキスト更新
            await getUserDataWithInterval();
            setChallengeUser(null);

            if (selectedProductType && selectedProductType.isFreeTrial) {
                // コンテキストに商品タイプIDがある、かつ、無料体験フラグtrue
                navigate(PRODUCT_CONFIRM_TRIAL_ROUTE);
            } else if (selectedProductType) {
                // コンテキストに商品タイプIDがある、かつ、無料体験フラグfalse
                navigate(PRODUCT_CONFIRM_ROUTE);
            } else {
                // それ以外はマイページへ
                navigate(MYPAGE_ROUTE);
            }
        } catch (error: any) {
            loggingError("error password complete", error);
            if (error.code === "UserNotFoundException") {
                setErrorMessage(t("ErrorMessage.userNotFound"));
            } else if (error.code === "InvalidPasswordException") {
                setErrorMessage(t("ErrorMessage.weakPassword"));
            } else if (error.code === "LimitExceededException") {
                setErrorMessage(t("ErrorMessage.limitExceed"));
            } else {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
            }
            setPassword("");
            setConfirmPassword("");
        } finally {
            setIsProcessing(false);
        }
    };

    /**
     * Cognitoに登録されたユーザー情報を取得してコンテキストにセットする
     *
     */
    async function getUserDataWithInterval() {
        // ※Cognito登録直後だとユーザー情報が取得できない場合があるので、インターバルを設けて数回リトライさせる
        const maxRetries = 10;
        let count = 0;
        let userData = null;
        const interval = 1000;
        while (count < maxRetries) {
            try {
                await new Promise((resolve) => setTimeout(resolve, interval));
                userData = await Auth.currentAuthenticatedUser();
                setUser(userData);
                break;
            } catch (error) {
                loggingWarn("[CheckUser] Fail to get user info: " + error);
                count++;
            }
        }
    }

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("CompletePassword.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <div className="w-full max-w-md rounded-lg bg-white shadow">
                    <div className="sp-4 space-y-4 p-8 md:space-y-4">
                        <h1 className="h1-common flex items-center justify-center">
                            {t("CompletePassword.completePasswordTitle")}
                        </h1>
                        <p className="px-2 text-gray-800">{t("CompletePassword.completePasswordText")}</p>
                        {/* エラーメッセージ */}
                        {errorMessage && (
                            <div className="flex items-center justify-center">
                                <CrossCircleIcon />
                                <span className="pl-1 text-center font-bold text-red-600">
                                    {errorMessage.split("\n").map((line, index) => (
                                        <div key={index}>{line}</div>
                                    ))}
                                </span>
                            </div>
                        )}

                        <form onSubmit={handleComptelePassword}>
                            <div className="m-0 grid grid-cols-3 items-center justify-center pb-4 sm:m-2">
                                {/* パスワード */}
                                <div className="col-span-1 py-2">
                                    <label htmlFor="password" className="block font-medium text-gray-900">
                                        {t("CommonLabel.password")}
                                    </label>
                                </div>
                                <div className="relative col-span-2 py-2">
                                    <div className="relative flex items-center">
                                        <input
                                            type={passwordType}
                                            name="password"
                                            id="password"
                                            placeholder="password"
                                            value={password}
                                            className="textbox-common"
                                            onChange={handlePasswordChange}
                                            onFocus={togglePasswordRequirements}
                                            onBlur={togglePasswordRequirements}
                                            maxLength={PASSWORD_MAX_LENGTH}
                                            required
                                            autoComplete="new-password"
                                        />
                                        {passwordType === "password" && (
                                            <VisibilityOffIcon
                                                fontSize="small"
                                                onClick={() => setPasswordType("text")}
                                                className="Password__visual absolute right-2"
                                            />
                                        )}
                                        {passwordType === "text" && (
                                            <VisibilityIcon
                                                fontSize="small"
                                                onClick={() => setPasswordType("password")}
                                                className="Password__visual absolute right-2"
                                            />
                                        )}
                                        {showPasswordRequirements && (
                                            // パスワード要件の吹き出し
                                            <div className="absolute bottom-full left-0 rounded-md border bg-yellow-50 p-2 shadow-md">
                                                <ul className="list-disc pl-5 text-sm text-gray-800">
                                                    <li>{t("CommonMessage.passwordPolicy1")}</li>
                                                    <li>{t("CommonMessage.passwordPolicy2")}</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* パスワード（確認） */}
                                <div className="col-span-1 py-2">
                                    <label htmlFor="confirmPassword" className="block font-medium text-gray-900">
                                        {t("CommonLabel.passwordConfirm")}
                                    </label>
                                </div>
                                <div className="col-span-2 py-2">
                                    <div className="relative flex items-center">
                                        <input
                                            type={confirmPasswordType}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            value={confirmPassword}
                                            placeholder="password"
                                            className="textbox-common"
                                            onChange={handleConfirmPasswordChange}
                                            maxLength={PASSWORD_MAX_LENGTH}
                                            required
                                            autoComplete="new-password"
                                        />
                                        {confirmPasswordType === "password" && (
                                            <VisibilityOffIcon
                                                fontSize="small"
                                                onClick={() => setConfirmPasswordType("text")}
                                                className="Password__visual absolute right-2"
                                            />
                                        )}
                                        {confirmPasswordType === "text" && (
                                            <VisibilityIcon
                                                fontSize="small"
                                                onClick={() => setConfirmPasswordType("password")}
                                                className="Password__visual absolute right-2"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* パスワードを再設定 */}
                            <div className="px-12 pb-4">
                                <button
                                    type="submit"
                                    className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-full`}
                                    disabled={isProcessing}
                                >
                                    {isProcessing ? (
                                        <div className="flex items-center justify-center">
                                            <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                            <label className="">Processing...</label>
                                        </div>
                                    ) : (
                                        t("ResetPassword.resetPasswordButton")
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CompletePassword;
