import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation_en from "./en.json";
import translation_en_help from "./en_help.json";
import translation_ja from "./ja.json";
import translation_ja_help from "./ja_help.json";

const resources = {
    ja: {
        translation: Object.assign(translation_ja, translation_ja_help),
    },
    en: {
        translation: Object.assign(translation_en, translation_en_help),
    },
};

// ブラウザの言語設定を取得
const browserLanguage = navigator.language.split('-')[0];

i18n.use(initReactI18next).init({
    resources,
    lng: browserLanguage === "ja" ? "ja" : "en",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
