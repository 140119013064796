import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { LICENSE_PURCHASE_FORM } from "../../consts/routes";
import { Link } from "react-router-dom";

type ModalProps = {
    showModal: boolean;
    handleClose: () => void;
};

/**
 * お問い合わせ先表示用モーダル
 *
 * @param ModalProps
 * @returns JSX.Element
 */
const ContactModal: React.FC<ModalProps> = ({ showModal, handleClose }) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-4/5 -translate-x-1/2 -translate-y-1/2 transform rounded-lg border bg-white p-4 shadow-md sm:w-auto"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 items-center justify-center p-2 sm:m-2">
                <div className="text-lg font-bold">{t("ContactModal.contactModalTitle")}</div>
                <hr className="my-2 border-t-2"></hr>
                <div className="max-h-screen overflow-y-auto">
                    {/* DeepForestへのお問い合わせ */}
                    <div className="text-lg font-bold text-gray-700">{t("ContactModal.aboutDeepForest")}</div>
                    <a
                        href={process.env.REACT_APP_CONTACT_URL}
                        className="link-text ml-2"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {t("ContactModal.goToInquiryForm")}
                    </a>

                    {/* DeepForest製品の購入・お見積り */}
                    <div className="mt-5 text-lg font-bold text-gray-700">{t("ContactModal.aboutQuotation")}</div>
                    <Link to={LICENSE_PURCHASE_FORM} className="link-text ml-2">
                        {t("ContactModal.goToQuotationForm")}
                    </Link>

                    {/* メールでの問い合わせ */}
                    <div className="mt-5 text-lg font-bold text-gray-700">
                        {t("ContactModal.abountWebSiteAndErrorsTitle")}
                    </div>
                    <div className="ml-2 text-gray-800">{t("ContactModal.abountWebSiteAndErrorsText")}</div>
                    <div className="ml-2 sm:flex">
                        <div className="text-gray-800">{t("ContactModal.sendTo")}:&nbsp;</div>
                        <div className="text-blue-600">{process.env.REACT_APP_CONTACT_EMAIL}</div>
                    </div>

                    {/* モーダルを閉じる */}
                    <div className="mt-5 flex justify-center">
                        <button className="btn-secondary w-1/3" onClick={handleClose}>
                            {t("CommonLabel.close")}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ContactModal;
