import Modal from "react-modal";
import { useState } from "react";
import { t } from "i18next";
import { CrossCircleIcon } from "../../components/Icons";

const ShareFolderModal = ({ showShareFolderModal, setShowShareFolderModal, rootFolder, handleShareFolder }: any) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<String>("");

    /**
     * フォルダ共有処理の呼び出し
     * @returns
     */
    const callShareFolder = async () => {
        setIsProcessing(true);
        setErrorMessage("");
        try {
            await handleShareFolder();
            setShowShareFolderModal(false);
        } catch (error: any) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 max-w-md -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showShareFolderModal}
        >
            <div>
                {/* タイトル */}
                <div className="mt-2 flex items-center justify-center">
                    <p className="text-center text-lg font-bold text-gray-600">
                        {rootFolder?.includes("department_share")
                            ? t("WebGIS.copyToMyFolder")
                            : t("WebGIS.copyToSharedFolder")}
                    </p>
                </div>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-2">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {/* 説明 */}
                <div className="mt-2 flex items-center justify-center">
                    <ul className="ml-7 list-disc text-gray-700">
                        <li>{t("WebGIS.copyFolderMsg1")}</li>
                        <li>{t("WebGIS.copyFolderMsg2")}</li>
                    </ul>
                </div>

                {/* キャンセル/共有ボタン */}
                <div className="mt-4 flex items-center justify-evenly">
                    <button
                        type="button"
                        onClick={() => {
                            setShowShareFolderModal(false);
                        }}
                        className="btn-secondary w-[150px]"
                    >
                        {t("CommonLabel.cancel")}
                    </button>
                    <button
                        className={`w-1/3 rounded-lg  py-2.5 text-center font-medium text-gray-50 ${
                            isProcessing
                                ? "bg-red-900"
                                : "bg-red-600 hover:bg-red-800 active:bg-red-900 active:font-bold"
                        }  `}
                        disabled={isProcessing}
                        onClick={callShareFolder}
                    >
                        {isProcessing ? (
                            <div className="flex items-center justify-center">
                                <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                <label className="">Processing...</label>
                            </div>
                        ) : (
                            t("CommonLabel.execute")
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ShareFolderModal;
