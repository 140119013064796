import Modal from "react-modal";
import { SelectProject, SelectFolder } from "./ProjectSelector";
import { useEffect, useState } from "react";
import { extractProjects, getFileList, moveFile, copyFile } from "../../clients/S3ClientNew";
import { t } from "i18next";

const FileHandlingModal = ({
    showFileHandlingModal,
    setShowFileHandlingModal,
    fileCopyFlag,
    selectedFile,
    rootFolderList,
    credentials,
    fetchFileList,
    enableSharing,
}: any) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<string>("");
    const [rootFolder, setRootFolder] = useState<string>("");
    const [projectList, setProjectList] = useState<string[]>([]);

    useEffect(() => {
        if (showFileHandlingModal && rootFolderList) {
            // デフォルトでMyフォルダを選択
            setRootFolder(rootFolderList[0]);
            reloadProjectList(rootFolderList[0]);
        }
    }, [showFileHandlingModal]);

    /** 実行 */
    const executeFileHandling = async () => {
        setIsProcessing(true);
        if (selectedProject != "new" && selectedProject != "") {
            // 同じファイル名のオブジェクトを全て処理
            const fileList: any = await getFileList(selectedFile.filename, credentials);
            fileList.forEach((element: any) => {
                const fromPath = element.key;
                const filename = fromPath.split("/")[fromPath.split("/").length - 1];
                const toPath = `${rootFolder}/${selectedProject}/${filename}`;
                if (fileCopyFlag) {
                    copyFile(fromPath, toPath, credentials);
                } else {
                    moveFile(fromPath, toPath, credentials);
                }
            });
            await fetchFileList();
            setShowFileHandlingModal(false);
        }
        setIsProcessing(false);
    };

    /** プロジェクトの選択 */
    const handleProjectChange = (event: any) => {
        const pname: string = event.target.value;
        setSelectedProject(pname);
    };

    /** Myフォルダ or 共有フォルダの選択 */
    const handleRootChange = async (event: any) => {
        setLoading(true);
        try {
            const newRootFolder = event.target.value;
            setRootFolder(newRootFolder);
            setSelectedProject("");
            setProjectList([]);
            if (newRootFolder) {
                await reloadProjectList(newRootFolder);
            }
        } finally {
            setLoading(false);
        }
    };

    /** プロジェクト一覧を再取得 */
    const reloadProjectList = async (rootFolder: string) => {
        const list: any = await getFileList(rootFolder, credentials);
        const pList = extractProjects(list, rootFolder);
        setProjectList(pList);
    };

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-10/12 max-w-md -translate-x-1/2 -translate-y-1/2 transform whitespace-pre-wrap break-words rounded-lg border bg-white p-4 shadow-md"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showFileHandlingModal}
        >
            <div>
                {/* タイトル */}
                <div className="mt-2 flex items-center justify-center">
                    <p className="text-center text-lg font-bold text-gray-600">
                        {fileCopyFlag && t("WebGIS.selectProjectForCopy")}
                        {!fileCopyFlag && t("WebGIS.selectProjectForMove")}
                    </p>
                    <p>（{selectedFile?.key.split("/")[selectedFile?.key.split("/").length - 1]}）</p>
                </div>

                {/* フォルダ選択 */}
                {enableSharing ? (
                    <SelectFolder
                        rootFolder={rootFolder}
                        handleChange={handleRootChange}
                        rootFolderList={rootFolderList}
                    />
                ) : (
                    <div className="my-3"></div>
                )}

                {/* プロジェクト選択 */}
                {loading ? (
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-8 w-8 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="text-gre-800 p-2 font-bold">Loading...</p>
                    </div>
                ) : (
                    <SelectProject
                        selectedProject={selectedProject}
                        handleProjectChange={handleProjectChange}
                        projectList={projectList}
                        enableNewProject={false}
                    />
                )}

                {/* キャンセル/削除ボタン */}
                <div className="mt-4 flex items-center justify-evenly">
                    <button
                        type="button"
                        onClick={() => {
                            setShowFileHandlingModal(false);
                        }}
                        className="btn-secondary w-[150px]"
                    >
                        {t("CommonLabel.cancel")}
                    </button>
                    <button
                        onClick={() => selectedFile && executeFileHandling()}
                        className={`w-1/3 rounded-lg  py-2.5 text-center font-medium text-gray-50 ${
                            isProcessing
                                ? "bg-lime-900"
                                : "bg-lime-700 hover:bg-lime-800 active:bg-lime-900 active:font-bold"
                        }  `}
                        disabled={isProcessing}
                    >
                        {isProcessing ? (
                            <div className="flex items-center justify-center">
                                <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                <label className="">Processing...</label>
                            </div>
                        ) : (
                            <>{t("CommonLabel.execute")}</>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default FileHandlingModal;
