import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";

/**
 * 共通エラー画面
 * @returns
 */
const CommonError = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-5">{t("CommonError.commonErrorTitle")}</h1>
                <ul className="mx-auto ml-10 list-disc text-gray-700">
                    <li>{t("CommonError.commonErrorText1")}</li>
                    <li>{t("CommonError.commonErrorText2")}</li>
                    <li>{t("CommonError.commonErrorText3")}</li>
                </ul>
            </div>
            <Footer />
        </div>
    );
};
export default CommonError;
