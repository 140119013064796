import { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { MYPAGE_ROUTE } from "../consts/routes";
import { CrossCircleIcon } from "../components/Icons";
import { useUserContext } from "../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "../components/ScrollToTop";
import { PaymentHistory } from "../types/PaymentHistory";
import { getPaymentHistoryList } from "../clients/PaymentHistoryClient";
import i18n from "../i18n/configs";
import { formatUtcToLocalDateTime } from "../utils/DateUtils";
import { AGENCY_ID_DF, AGENCY_ID_DF_OTHER, USER_TYPE_MANAGER, USER_TYPE_MASTER_USER } from "../consts/constants";
import { outputCsv, outputExcel } from "../utils/CsvUtils";
import { Agency } from "../types/Agency";
import { parseStringToInt } from "../utils/ValidateUtils";
import { getAgencies } from "../clients/AgencyClient";
import { OnlyMasterUserAndManagerAuthorized } from "../components/OnlyMasterUserAndManagerAuthorized";
import { Helmet } from "react-helmet";

/**
 * ライセンス発行履歴一覧画面
 * @returns JSX.Element
 */
const LicenseIssueHistory = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, userType, getRecentToken } = useUserContext();
    const [loading, setLoading] = useState(true);
    const [agencyList, setAgencyList] = useState<Agency[]>([]);
    const [licenseIssueHistoryList, setLicenseIssueHistoryList] = useState<PaymentHistory[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * 代理店情報の取得
     *
     * @returns
     */
    async function fetchAgencies() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            if (userType === USER_TYPE_MASTER_USER) {
                // マスタユーザーの場合は全代理店情報を取得
                // [API]代理店情報一覧取得
                const response: Agency[] = await getAgencies(idToken);

                // 購入履歴情報の取得（直販分をデフォルト表示）
                await fetchPaymentHistory(AGENCY_ID_DF);

                response
                    // 代理店ID=0（直販）が先頭になるようにソートしてリストに保存
                    .sort((a, b) => {
                        if (a.agency_id === AGENCY_ID_DF) {
                            return -1;
                        } else if (b.agency_id === AGENCY_ID_DF) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                    // 代理店ID=-1（直販・個別対応分）の表示名を変更
                    .map((a) => {
                        if (a.agency_id === AGENCY_ID_DF_OTHER) {
                            a.agency_name = "DF社 個別対応分ライセンス";
                        }
                    });
                setAgencyList(response);
            } else if (userType === USER_TYPE_MANAGER) {
                // マネージャーの場合は自身の所属する代理店情報のみを取得
                // [API]代理店情報一覧取得（ユーザーID指定）
                const response: Agency[] = await getAgencies(idToken, user?.attributes.sub);

                // 購入履歴情報の取得（先頭で取得した代理店ID）
                if (response.length > 0) {
                    await fetchPaymentHistory(response[0].agency_id);
                }

                // 代理店情報をリストに保存
                setAgencyList(response);
            }
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    /**
     * ライセンス発行履歴情報の取得処理
     * @returns
     */
    async function fetchPaymentHistory(agencyId: number) {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]購入履歴一覧取得
            const response: PaymentHistory[] = await getPaymentHistoryList(idToken, { agencyId: agencyId });
            const sortedList: PaymentHistory[] = response.sort((a, b) => {
                return new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime();
            });
            setLicenseIssueHistoryList(sortedList);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!user) {
            // ページリロード時はコンテキストも初期化されるため、空の場合は一度リターンする
            // コンテキストが生成されユーザー情報が生成された時点で再実行する
            return;
        }
        if (!effectRun.current) {
            // 代理店一覧の取得
            fetchAgencies();
            return () => {
                effectRun.current = true;
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    /**
     * 代理店選択時のイベント処理
     * @param event
     */
    const handleSelectAgency: ChangeEventHandler<HTMLSelectElement> = (event) => {
        fetchPaymentHistory(parseStringToInt(event.target.value));
    };

    /**
     * CSV/Excel出力押下時のイベント処理
     */
    const handleOutput: MouseEventHandler<HTMLButtonElement> = async (event) => {
        const format = event.currentTarget.value;
        const headers = [
            { header: "ID", key: "payment_history_id" },
            { header: "購入日時", key: "payment_date" },
            { header: "商品名", key: "p_product_type_name" },
            { header: "ライセンス有効期限", key: "expire_date" },
            { header: "購入者氏名", key: "p_name" },
            { header: "購入者メールアドレス", key: "p_email" },
            { header: "所属", key: "p_organization" },
            { header: "販売元", key: "p_agency_name" },
        ];
        const outputList: PaymentHistory[] = licenseIssueHistoryList.map((l) => {
            const formated: PaymentHistory = { ...l };
            formated.p_product_type_name = formated.p_product_type_name.split("%%")[0];
            formated.payment_date = formatUtcToLocalDateTime(formated.payment_date);
            formated.expire_date = formatUtcToLocalDateTime(formated.expire_date);
            return formated;
        });
        if (format === "csv") {
            outputCsv(headers, outputList, "history");
        } else {
            outputExcel(headers, outputList, "history");
        }
    };

    /**
     * 戻る押下時のイベント処理
     */
    const handleBack = () => {
        navigate(MYPAGE_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("LicenseIssueHistory.meta.title")} />
            <ScrollToTop />
            {/* マスターユーザーおよびマネージャーのみ閲覧可 */}
            <OnlyMasterUserAndManagerAuthorized />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow items-center justify-center px-6 py-8 pt-20 lg:px-20">
                <h1 className="h1-common py-3">{t("LicenseIssueHistory.title")}</h1>
                {/* <div className="flex items-center justify-center">
                    <ul className="ml-10 list-disc text-gray-700">
                        <li>{t("LicenseIssueHistory.text1")}</li>
                        <li>{t("LicenseIssueHistory.text2")}</li>
                    </ul>
                </div> */}

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {/* 直販/代理店選択ボックス */}
                <div className="mx-auto flex max-w-xl items-center justify-center">
                    <div className="grid grid-cols-3 px-5 sm:px-10">
                        <div className="col-span-3 my-auto p-3 sm:col-span-1">{t("CommonLabel.directOrAgent")}</div>
                        <div className="col-span-3 my-auto pb-3 sm:col-span-2 sm:p-3">
                            <select
                                id="license-type-dropdown"
                                className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-xs text-gray-900 sm:text-sm"
                                onChange={handleSelectAgency}
                            >
                                {agencyList.map((agency) => (
                                    <option key={agency.agency_id} value={agency.agency_id}>
                                        {agency.agency_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="text-gre-800 p-2 font-bold">Loading...</p>
                    </div>
                ) : (
                    <>
                        {licenseIssueHistoryList.length === 0 ? (
                            // ライセンス発行履歴が存在しない場合
                            <div className="flex items-center justify-center py-4 text-lg font-bold text-gray-700">
                                {t("LicenseIssueHistory.noHistory")}
                            </div>
                        ) : (
                            // ライセンス発行履歴が存在する場合
                            <div className="overflow-x-auto transition duration-500 ease-in-out">
                                <table className="my-3 min-w-full divide-y divide-gray-200 border">
                                    <thead className="bg-gray-400">
                                        <tr
                                            className={`${
                                                i18n.language === "ja" ? "text-base" : "text-sm"
                                            } font-semibold uppercase tracking-wide text-gray-100`}
                                        >
                                            {/* [ヘッダ] 購入日 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.paymentDate")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 商品名 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.productName")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 購入区分 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.purchaseCategory")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 有効期限 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.expirationDate")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 購入者氏名 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.purchaser")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 購入者メールアドレス */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.email")}</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200">
                                        {licenseIssueHistoryList.map((p) => (
                                            <tr
                                                key={p.payment_history_id}
                                                className="text-sm font-semibold text-gray-800"
                                            >
                                                {/* 購入日時 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{formatUtcToLocalDateTime(p.payment_date)}</span>
                                                    </div>
                                                </td>
                                                {/* 商品名 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>
                                                            {i18n.language === "ja"
                                                                ? p.p_product_type_name.split("%%")[0]
                                                                : p.p_product_type_name.split("%%")[1]}
                                                        </span>
                                                    </div>
                                                </td>
                                                {/* 購入区分 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>
                                                            {p.license_updated_count === 0
                                                                ? t("CommonLabel.categoryNew")
                                                                : t("CommonLabel.categoryUpdate")}
                                                        </span>
                                                    </div>
                                                </td>
                                                {/* 有効期限 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{formatUtcToLocalDateTime(p.expire_date)}</span>
                                                    </div>
                                                </td>
                                                {/* 購入者氏名 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{p.p_name}</span>
                                                    </div>
                                                </td>
                                                {/* 購入者メールアドレス */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-3">
                                                        <span>{p.p_email}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </>
                )}

                <div className="flex items-center justify-center gap-5">
                    {/* CSV出力ボタン */}
                    <div className="mb-5 mt-5 flex items-center justify-evenly">
                        <button
                            type="button"
                            onClick={handleOutput}
                            value="csv"
                            className={`${
                                licenseIssueHistoryList.length === 0 ? "btn-primary-disabled" : "btn-primary"
                            } w-[150px]`}
                            disabled={licenseIssueHistoryList.length === 0}
                        >
                            {t("CommonLabel.csvOutput")}
                        </button>
                    </div>
                    {/* Excel出力ボタン */}
                    <div className="mb-5 mt-5 flex items-center justify-evenly">
                        <button
                            type="button"
                            onClick={handleOutput}
                            value="excel"
                            className={`${
                                licenseIssueHistoryList.length === 0 ? "btn-primary-disabled" : "btn-primary"
                            } w-[150px]`}
                            disabled={licenseIssueHistoryList.length === 0}
                        >
                            {t("CommonLabel.excelOutput")}
                        </button>
                    </div>
                </div>

                {/* 戻るボタン */}
                <div className="mb-5 mt-5 flex items-center justify-evenly">
                    <button type="button" onClick={handleBack} className="btn-secondary w-[150px]">
                        {t("LicenseIssueHistory.backToMyPage")}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LicenseIssueHistory;
