import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import dfscanner_icon from "./../../img/dfscanner/icon.png";
import dfscanner_screen from "./../../img/dfscanner/screen.png";
import dfscanner_treetop_treecrown from "./../../img/dfscanner/treetop-treecrown.jpg";
import dfscanner_treespecies from "./../../img/dfscanner/treespecies.png";
import dfscanner_size_estimation from "./../../img/dfscanner/size-estimation.png";
import dfscanner_area_selection from "./../../img/dfscanner/area-setection.jpg";
import { ScrollToTop } from "../../components/ScrollToTop";
import { DOWNLOAD_ROUTE } from "../../consts/routes";
import { Helmet } from "react-helmet";

/**
 * DF Scanner詳細画面
 * @returns JSX.Element
 */
const DetailDfscanner = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("DetailDfscanner.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <img className="h-20 w-20 p-3" src={dfscanner_icon} alt="dfscanner" />
                        <h1 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl ">DF Scanner</h1>
                    </div>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfscanner_screen}
                            alt="DF Scanner Screen Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* 概要 */}
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfscanner.about1" />
                        <br />
                        <br />
                        <Trans i18nKey="DetailDfscanner.about2" />
                        <br />
                        <br />
                        <Trans i18nKey="DetailDfscanner.about3" />
                    </p>

                    {/* 樹頂点検出・樹冠分離 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfscanner.treeTopTreeCrownTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfscanner.treeTopTreeCrownText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfscanner_treetop_treecrown}
                            alt="DF Scanner Tree Top Tree Crown Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* 樹種識別 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfscanner.treeSpeciesTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfscanner.treeSpeciesText1" />
                        <br />
                        <br />
                        <Trans i18nKey="DetailDfscanner.treeSpeciesText2" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfscanner_treespecies}
                            alt="DF Scanner Tree Species Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* サイズ推定 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfscanner.sizeEstimationTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfscanner.sizeEstimationText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfscanner_size_estimation}
                            alt="DF Scanner Size Estimation Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* 森林状況の把握 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfscanner.forestConditionTitie")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfscanner.forestConditionText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfscanner_area_selection}
                            alt="DF Scanner Forest Condition Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* ダウンロード */}
                    <h2 id="download" className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfscanner.downloadTitle")}
                    </h2>
                    <p className="mb-2 text-gray-700 sm:text-lg md:mb-2">
                        <Link to={DOWNLOAD_ROUTE}>
                            <Trans
                                i18nKey="DetailDfscanner.downloadText"
                                components={{
                                    l: <button className="link-text" />,
                                }}
                            />
                        </Link>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DetailDfscanner;
