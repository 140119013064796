import {
    MapContainer,
    TileLayer,
    useMapEvents,
    GeoJSON,
    ImageOverlay,
    LayersControl,
    ScaleControl,
} from "react-leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import { t } from "i18next";

/** ラスターレイヤー */
const ImageOverlayWithFitBounds = ({ name, url, bounds }: any) => {
    const map = useMapEvents({
        overlayadd(event) {
            if (event.name === name) {
                map.fitBounds(bounds);
            }
        },
        overlayremove(event) {
            // ここにレイヤー削除時の処理を書くことができます
        },
    });

    return <ImageOverlay url={url} bounds={bounds} />;
};

/** カラムの最大値、最小値の計算 */
const calculateValueRange = (data: any, column: string) => {
    let min = Infinity,
        max = -Infinity;
    data.features.forEach((feature: any) => {
        const value = feature.properties[column];
        if (value < min) min = value;
        if (value > max) max = value;
    });
    return { min, max };
};

{
    /* GeoJSONレイヤーの定義 */
}
const GeoJsonLayer = ({ geojsonData, name, colorColumn }: any) => {
    const [lineWeight, setLineWeight] = useState(5); // 初期線の太さ
    const [pointRadius, setPointRadius] = useState(5); // 初期線の太さ
    const { min, max } = useMemo(() => calculateValueRange(geojsonData, colorColumn), [geojsonData, colorColumn]);
    const map = useMapEvents({
        overlayadd(event) {
            if (event.name === name) {
                const bounds = L.geoJSON(geojsonData).getBounds();
                map.fitBounds(bounds);
            }
        },
        zoomend: () => {
            // ズームレベルに応じて線の太さを設定
            const newZoomLevel = map.getZoom();
            if (newZoomLevel > 20) {
                setLineWeight(1);
                setPointRadius(5);
            } else if (20 >= newZoomLevel && newZoomLevel > 18) {
                setLineWeight(0.5);
                setPointRadius(0.5);
            } else {
                setLineWeight(0.3);
                setPointRadius(0.1);
            }
        },
    });
    const onEachFeature = (feature: any, layer: any) => {
        let popupContent = "<div>";
        if (feature.properties) {
            for (const [key, value] of Object.entries(feature.properties)) {
                popupContent += `<strong>${key}:</strong> ${value}<br>`;
            }
        }
        popupContent += "</div>";

        // 生成したHTML文字列をポリゴンのポップアップとして設定
        layer.bindPopup(popupContent);
    };

    // 名前からハッシュ値を生成し、それを基に色を決定する関数
    const nameToColor = (name: any) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        const color = `hsl(${hash % 360}, 100%, 70%)`; // HSL色空間を使用
        return color;
    };

    const valueToColor = (value: number, min: number, max: number) => {
        const hue = ((value - min) / (max - min)) * 240; // 240は色相の範囲（例: 青〜赤）
        return `hsl(${240 - hue}, 100%, 50%)`; // 彩度と明度は固定
    };

    const calcColumnColor = (value: any) => {
        return typeof value === "string" ? nameToColor(value) : valueToColor(value, min, max);
    };

    const pointToLayer = (feature: any, latlng: any) => {
        return L.circleMarker(latlng);
    };

    const style = (feature: any) => {
        if (feature.geometry.type === "Point") {
            return {
                radius: pointRadius,
                color: "red",
                fillColor: "red",
                fillOpacity: 1,
            };
        } else {
            return {
                fillColor: calcColumnColor(feature.properties[colorColumn]),
                weight: lineWeight,
                opacity: 1,
                color: "white", // 境界線の色
                fillOpacity: 0.2,
            };
        }
    };

    return <GeoJSON data={geojsonData} style={style} pointToLayer={pointToLayer} onEachFeature={onEachFeature} />;
};

/** マウス位置の緯度経度 */
function LegendWithMousePosition() {
    const [position, setPosition] = useState(`${t("WebGIS.latitude")}: -, ${t("WebGIS.longitude")}: -`);
    const map = useMapEvents({});
    useMapEvents({
        mousemove: (e: any) => {
            setPosition(
                `${t("WebGIS.latitude")}: ${e.latlng.lat.toFixed(4)}, ${t("WebGIS.longitude")}: ${e.latlng.lng.toFixed(
                    4,
                )}`,
            );
        },
    });
    useEffect(() => {
        const legend = new L.Control({ position: "bottomleft" });
        legend.onAdd = function () {
            const div = L.DomUtil.create("div", "info legend");
            div.innerHTML = position;
            div.style.backgroundColor = "rgba(255, 255, 255, 0.7)"; // 透過された白色の背景
            div.style.padding = "6px"; // テキストの周りにパディングを追加
            return div;
        };
        legend.addTo(map);
        return () => {
            legend.remove();
        };
    }, [map, position]);
    return null;
}

/** マップ */
const MapContent = ({ filteredFileList, colorColumnList }: any) => {
    const [position, setPosition] = useState<any>([35.680959106959, 139.76730676352]);
    const mapRef = useRef(null);
    return (
        <MapContainer
            center={position}
            zoom={3}
            maxZoom={24}
            style={{ height: "80vh", width: "100%", zIndex: 0 }}
            ref={mapRef}
        >
            <FullscreenControl />
            <ScaleControl position="bottomright" metric={true} imperial={false} />
            <LegendWithMousePosition />
            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name={t("WebGIS.layerSimple")}>
                    <TileLayer
                        attribution='© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name={t("WebGIS.layerDelail")}>
                    <TileLayer
                        attribution="<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>国土地理院</a>"
                        url="https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name={t("WebGIS.layerSatelite")}>
                    <TileLayer
                        attribution="Tiles © Esri — Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                </LayersControl.BaseLayer>
                {filteredFileList
                    .filter((f: any) => f.type === "raster")
                    .map((layer: any) => {
                        const filename = layer.key?.split("/").slice(2).join("/");
                        return (
                            <LayersControl.Overlay name={filename} key={filename} checked={layer.see}>
                                <ImageOverlayWithFitBounds
                                    name={filename}
                                    url={layer.data.url}
                                    bounds={layer.data.bounds}
                                />
                            </LayersControl.Overlay>
                        );
                    })}
                {filteredFileList
                    .filter((f: any) => f.type === "vector")
                    .map((layer: any) => {
                        const filename = layer.key?.split("/").slice(2).join("/");
                        const colorColumn = colorColumnList.filter((c: any) => c.filename === layer.filename)[0][
                            "column"
                        ];
                        return (
                            <LayersControl.Overlay name={filename} key={filename} checked={layer.see}>
                                <GeoJsonLayer geojsonData={layer.data} name={filename} colorColumn={colorColumn} />
                            </LayersControl.Overlay>
                        );
                    })}
            </LayersControl>
        </MapContainer>
    );
};

export default MapContent;
