import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import qr from "./../../img/dfwalker/qr.png";
import { DF_WALKER_GOOGLE_PLAY_LINK } from "../../consts/Installers";

type ModalProps = {
    showModal: boolean;
    handleClose: () => void;
};

/**
 * DF Walkerダウンロード用モーダル
 *
 * @param ModalProps
 * @returns JSX.Element
 */
const DFWalkerDownloadModal: React.FC<ModalProps> = ({ showModal, handleClose }) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-4/5 max-w-md -translate-x-1/2 -translate-y-1/2 transform rounded-lg border bg-white p-4 shadow-md"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 items-center justify-center p-2 sm:m-2">
                <div className="text-lg font-bold">{t("Download.dfwalker.modalTitle")}</div>
                <hr className="my-2 border-t-2"></hr>
                <div className="max-h-screen overflow-y-auto">
                    {/* URL */}
                    <a
                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                        href={DF_WALKER_GOOGLE_PLAY_LINK}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {DF_WALKER_GOOGLE_PLAY_LINK}
                    </a>
                    {/* QRコード */}
                    <div className="flex justify-center">
                        <img className="h-60 w-auto" src={qr} alt="dfwalker" />
                    </div>

                    {/* モーダルを閉じる */}
                    <div className="mt-2 flex justify-center">
                        <button className="btn-secondary w-1/3" onClick={handleClose}>
                            {t("CommonLabel.close")}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DFWalkerDownloadModal;
