import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";

import navbar_logo from "./../img/navbar-logo.svg";
import { useUserContext } from "../contexts/UserContext";
import {
    HOME_ROUTE,
    SIGNIN_ROUTE,
    PRODUCT_SELECT_ROUTE,
    MYPAGE_ROUTE,
    HELP_ROUTE,
    DOWNLOAD_ROUTE,
    QA_ROUTE,
} from "../consts/routes";
import i18n from "../i18n/configs";

type NavbarType = {
    bottomPadding: boolean;
};

/**
 * コロン区切り
 * @returns
 */
const ColonSeparator = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            className="current-fill h-4 w-4"
            viewBox="0 0 24 24"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
        </svg>
    );
};

/**
 * ナビゲーションバー
 * @param bottomPadding
 * @returns JSX.Element
 */
const DFNavbar = ({ bottomPadding }: NavbarType) => {
    const { t } = useTranslation();
    const [isScrolled, setScrolled] = useState<boolean>(false);
    const [isHumbergerOpen, setIsHumbergerOpen] = useState<boolean>(false);
    const { user, logout } = useUserContext();

    /**
     * 画面スクロールのリスナーを設定する副作用フック
     */
    useEffect(() => {
        window.addEventListener("scroll", handleScrolle);
        return () => {
            window.removeEventListener("scroll", handleScrolle);
        };
    });

    /**
     * 画面スクロール時のイベント処理
     */
    const handleScrolle = () => {
        const offset = window.scrollY;
        if (offset > 20) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    /**
     * ハンバーガーメニューのトグル設定
     */
    const toggleHumbergerMenu = () => {
        setIsHumbergerOpen(!isHumbergerOpen);
    };

    /**
     * 言語切り替え時のイベント処理
     * @param language 言語指定
     */
    const handleChangeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    return (
        <div className={`${bottomPadding} ? 'pb-20': ''`}>
            <nav
                className={`justify fixed top-0 z-50 flex w-full items-center transition-all ${
                    isScrolled ? "py-1 backdrop-brightness-50" : "bg-black py-3"
                }`}
            >
                {/* 会社ロゴ */}
                <Link to={HOME_ROUTE}>
                    <img
                        className="object-contain px-4 transition-all"
                        src={navbar_logo}
                        width={`${isScrolled ? "160px" : "200px"}`}
                        alt="Logo"
                    />
                </Link>

                <ul className="absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 transform lg:mx-auto lg:flex lg:w-auto lg:items-center lg:space-x-4">
                    {/* ホーム */}
                    <li>
                        <Link to={HOME_ROUTE}>
                            <span className="text-lm text-gray-300 hover:text-lime-200 hover:underline active:text-lime-500">
                                {t("Navbar.home")}
                            </span>
                        </Link>
                    </li>
                    <li className="text-gray-300">
                        <ColonSeparator />
                    </li>

                    {/* マイページ */}
                    <li>
                        <Link to={MYPAGE_ROUTE}>
                            <span className="text-lm text-gray-300 hover:text-lime-200 hover:underline active:text-lime-500">
                                {t("Navbar.mypage")}
                            </span>
                        </Link>
                    </li>
                    <li className="text-gray-300">
                        <ColonSeparator />
                    </li>

                    {/* ライセンス購入 */}
                    <li>
                        <Link id="purchase-link" to={PRODUCT_SELECT_ROUTE}>
                            <span className="text-lm text-gray-300 hover:text-lime-200 hover:underline active:text-lime-500">
                                {t("Navbar.purchase")}
                            </span>
                        </Link>
                    </li>
                    <li className="text-gray-300">
                        <ColonSeparator />
                    </li>

                    {/* ダウンロード */}
                    <li>
                        <Link to={DOWNLOAD_ROUTE}>
                            <span className="text-lm text-gray-300 hover:text-lime-200 hover:underline active:text-lime-500">
                                {t("Navbar.download")}
                            </span>
                        </Link>
                    </li>
                    <li className="text-gray-300">
                        <ColonSeparator />
                    </li>

                    {/* Q&A */}
                    <li>
                        <Link to={QA_ROUTE}>
                            <span className="text-lm text-gray-300 hover:text-lime-200 hover:underline active:text-lime-500">
                                {t("Navbar.qa")}
                            </span>
                        </Link>
                    </li>
                    <li className="text-gray-300">
                        <ColonSeparator />
                    </li>

                    {/* ヘルプ */}
                    <li>
                        <Link to={HELP_ROUTE}>
                            <span className="text-lm text-gray-300 hover:text-lime-200 hover:underline active:text-lime-500">
                                {t("Navbar.help")}
                            </span>
                        </Link>
                    </li>
                </ul>

                <div className="ml-auto mr-10 hidden space-x-2 lg:flex lg:items-center">
                    {/* 言語切り替え */}
                    <button onClick={() => handleChangeLanguage(i18n.language === "ja" ? "en" : "ja")}>
                        <div className="flex items-center">
                            <LanguageIcon className="text-gray-300" />
                            <span className="block rounded pl-1 pr-4 text-gray-300">{t("Navbar.language")}</span>
                        </div>
                    </button>

                    {/* ログイン/ログアウト */}
                    <Link to={user ? HOME_ROUTE : SIGNIN_ROUTE} onClick={user ? logout : undefined}>
                        <button
                            id={user ? "logout-btn" : "login-btn"}
                            className={`btn-primary px-4 py-1.5 ${
                                isScrolled ? "text-sm opacity-70" : "text-base opacity-90"
                            }`}
                        >
                            {user ? t("Navbar.logout") : t("Navbar.login")}
                        </button>
                    </Link>
                </div>

                {/* ハンバーガーメニュー */}
                <div className="ml-auto mr-4 flex flex-col justify-center lg:hidden">
                    <div className="relative mx-auto sm:max-w-xl">
                        <button
                            className="relative h-10 w-10 text-gray-300 hover:text-lime-200"
                            onClick={toggleHumbergerMenu}
                        >
                            <span className="sr-only">Open main menu</span>
                            <div className="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2 transform">
                                <span
                                    aria-hidden="true"
                                    className={`absolute block h-0.5 w-5 transform bg-current transition duration-300 ease-in-out ${
                                        isHumbergerOpen ? "rotate-45" : "-translate-y-1.5"
                                    }`}
                                ></span>
                                <span
                                    aria-hidden="true"
                                    className={`absolute block h-0.5 w-5 transform bg-current transition duration-300 ease-in-out ${
                                        isHumbergerOpen ? "opacity-0" : ""
                                    }`}
                                ></span>
                                <span
                                    aria-hidden="true"
                                    className={`absolute block h-0.5 w-5 transform bg-current transition duration-300 ease-in-out ${
                                        isHumbergerOpen ? "-rotate-45" : "translate-y-1.5"
                                    }`}
                                ></span>
                            </div>
                        </button>
                    </div>
                </div>
            </nav>

            {/* ハンバーガーメニューの中身 */}
            <div
                className={`fixed right-0 z-50 mr-2 transition-all ${
                    isScrolled ? "top-12" : "top-16"
                } rounded-lg border border-gray-100 bg-black lg:hidden ${isHumbergerOpen ? "" : "hidden"}`}
            >
                <ul className="flex flex-col p-4 font-medium">
                    {/* ログイン/ログアウト */}
                    <li>
                        <Link to={user ? HOME_ROUTE : SIGNIN_ROUTE} onClick={user ? logout : undefined}>
                            <span className="block rounded py-2 pl-3 pr-4 text-white hover:bg-lime-800 active:bg-lime-900">
                                {user ? t("Navbar.logout") : t("Navbar.login")}
                            </span>
                        </Link>
                    </li>

                    {/* ホーム */}
                    <li>
                        <Link to={HOME_ROUTE}>
                            <span
                                className="block rounded py-2 pl-3 pr-4 text-white hover:bg-lime-800 active:bg-lime-900"
                                aria-current="page"
                            >
                                {t("Navbar.home")}
                            </span>
                        </Link>
                    </li>

                    {/* マイページ */}
                    <li>
                        <Link to={MYPAGE_ROUTE}>
                            <span
                                className="block rounded py-2 pl-3 pr-4 text-white hover:bg-lime-800 active:bg-lime-900"
                                aria-current="page"
                            >
                                {t("Navbar.mypage")}
                            </span>
                        </Link>
                    </li>

                    {/* ライセンス購入 */}
                    <li>
                        <Link to={PRODUCT_SELECT_ROUTE}>
                            <span className="block rounded py-2 pl-3 pr-4 text-white hover:bg-lime-800 active:bg-lime-900">
                                {t("Navbar.purchase")}
                            </span>
                        </Link>
                    </li>

                    {/* ダウンロード */}
                    <li>
                        <Link to={DOWNLOAD_ROUTE}>
                            <span className="block rounded py-2 pl-3 pr-4 text-white hover:bg-lime-800 active:bg-lime-900">
                                {t("Navbar.download")}
                            </span>
                        </Link>
                    </li>

                    {/* Q&A */}
                    <li>
                        <Link to={QA_ROUTE}>
                            <span className="block rounded py-2 pl-3 pr-4 text-white hover:bg-lime-800 active:bg-lime-900">
                                {t("Navbar.qa")}
                            </span>
                        </Link>
                    </li>

                    {/* ヘルプ */}
                    <li>
                        <Link to={HELP_ROUTE}>
                            <span className="block rounded py-2 pl-3 pr-4 text-white hover:bg-lime-800 active:bg-lime-900">
                                {t("Navbar.help")}
                            </span>
                        </Link>
                    </li>

                    {/* 言語切り替え */}
                    <li>
                        <button onClick={() => handleChangeLanguage(i18n.language === "ja" ? "en" : "ja")}>
                            <span className="block rounded py-2 pl-3 pr-4 text-white hover:bg-lime-800 active:bg-lime-900">
                                {t("Navbar.language")}
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default DFNavbar;
