import { Auth, API } from "aws-amplify";
import { API_ENDPOINT_NAME } from "../consts/constants";
import { loggingError, loggingInfo, loggingWarn } from "../utils/LoggingUtils";

type UserUpdateInfo = {
    email?: string;
    userType?: number;
    familyName?: string;
    givenName?: string;
    organization?: string;
};

type GetUsersRequest = {
    email?: string;
    userType?: number;
    groupId?: number;
};

/**
 * Cognitoに登録されたユーザー情報をDBのユーザー情報テーブルに登録する
 */
export async function reflectUserInfo(setDbUserCashe: (dbUser: any) => void) {
    // Cognitoに登録されたユーザー情報を取得
    // ※Cognito登録直後だとユーザー情報が取得できない場合があるので、インターバルを設けて数回リトライさせる
    const maxRetries = 10;
    let count = 0;
    let userData = null;
    const interval = 1000;
    while (count < maxRetries) {
        try {
            await new Promise((resolve) => setTimeout(resolve, interval));
            userData = await Auth.currentAuthenticatedUser();
            break;
        } catch (error) {
            loggingWarn("[CheckUser] Fail to get user info: " + error);
            count++;
        }
    }
    if (!userData) {
        loggingError("[CheckUser] No user information, cannot register DB.");
        return;
    }

    // ユーザー情報の検索
    try {
        const userResponse = await getUser(userData.signInUserSession.idToken.jwtToken, userData.attributes.sub);
        setDbUserCashe(userResponse);
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            // ユーザー情報がDBに存在しない場合（404）、ユーザー登録を実施
            const userResponse = await createUser(
                userData.signInUserSession.idToken.jwtToken,
                userData.attributes.sub,
                userData.attributes.email,
                userData.attributes["custom:user_type"],
                userData.attributes.family_name,
                userData.attributes.given_name,
                userData.attributes["custom:organization"],
                undefined,
            );
            setDbUserCashe(userResponse);
        }
    }
}

/**
 * ユーザー情報取得処理
 * @param idToken IDトークン
 * @param userId ユーザーID
 * @returns ユーザー情報取得APIレスポンス
 */
export async function getUser(idToken: string, userId: string) {
    const path = `/user/${userId}`;
    const request = {
        headers: {
            Authorization: idToken,
        },
    };
    try {
        const response = await API.get(API_ENDPOINT_NAME, path, request);
        loggingInfo("[GetUser] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[GetUser] Failed:", error);
        throw error;
    }
}

/**
 * ユーザー情報一括取得処理
 * @param idToken IDトークン
 * @param groupId グループID
 * @returns ユーザー情報一括取得APIレスポンス
 */
export async function getUsers(idToken: string, getUsersRequest: GetUsersRequest) {
    const path = `/users`;
    const body: { [name: string]: any } = {};
    if (getUsersRequest.email) {
        body["email"] = getUsersRequest.email;
    }
    if (getUsersRequest.userType) {
        body["user_type"] =
            typeof getUsersRequest.userType === "number" ? getUsersRequest.userType : Number(getUsersRequest.userType);
    }
    if (getUsersRequest.groupId) {
        body["group_id"] = getUsersRequest.groupId;
    }
    const request = {
        body: body,
        headers: {
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[GetUsers] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[GetUsers] Failed:", error);
        throw error;
    }
}

/**
 * ユーザー情報登録処理
 * @param idToken IDトークン
 * @param userId ユーザーID
 * @param email メールアドレス
 * @param userType ユーザータイプ
 * @param familyName 姓
 * @param givenName 名
 * @param organization　所属
 * @param adminUserId 管理ユーザーID
 * @param updateCognito Cognito更新フラグ
 * @returns ユーザー情報登録APIレスポンス
 */
export async function createUser(
    idToken: string,
    userId: string | undefined,
    email: string,
    userType: number,
    familyName: string,
    givenName: string,
    organization: string,
    adminUserId: string | undefined,
    updateCognito: boolean = false,
) {
    const path = "/user";
    const body: { [name: string]: any } = {};
    body["email"] = email;
    body["user_type"] = typeof userType === "number" ? userType : Number(userType);
    body["family_name"] = familyName;
    body["given_name"] = givenName;
    body["organization"] = organization;
    if (userId) {
        body["user_id"] = userId;
    }
    if (adminUserId) {
        body["admin_user_id"] = adminUserId;
    }
    if (updateCognito) {
        body["update_cognito"] = updateCognito;
    }
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[CreateUser] Succeeded:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[CreateUser] Failed:", error);
        throw error;
    }
}

/**
 * ユーザー情報更新処理
 * @param idToken IDトークン
 * @param userId ユーザーID
 * @param userInfo ユーザー情報（更新項目）
 * @param updateCognito Cognito更新フラグ
 * @returns ユーザー情報更新APIレスポンス
 */
export async function updateUser(
    idToken: string,
    userId: string,
    userInfo: UserUpdateInfo,
    updateCognito: boolean = false,
) {
    const path = `/user/${userId}`;
    const body: { [name: string]: any } = {};
    if (userInfo.email) {
        body["email"] = userInfo.email;
    }
    if (userInfo.userType) {
        body["user_type"] = typeof userInfo.userType === "number" ? userInfo.userType : Number(userInfo.userType);
    }
    if (userInfo.familyName) {
        body["family_name"] = userInfo.familyName;
    }
    if (userInfo.givenName) {
        body["given_name"] = userInfo.givenName;
    }
    if (userInfo.organization) {
        body["organization"] = userInfo.organization;
    }
    if (updateCognito) {
        body["update_cognito"] = updateCognito;
    }
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.put(API_ENDPOINT_NAME, path, request);
        loggingInfo("[UpdateUser] Succeeded:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[UpdateUser] Failed:", error);
        throw error;
    }
}

/**
 * ユーザー情報削除処理
 * @param idToken IDトークン
 * @param userId ユーザーID
 * @param updateCognito Cognito更新フラグ
 * @returns ユーザー情報取得APIレスポンス
 */
export async function deleteUser(idToken: string, userId: string, updateCognito: boolean = false) {
    const path = `/user/${userId}`;
    const body: { [name: string]: any } = {};
    if (updateCognito) {
        body["update_cognito"] = updateCognito;
    }
    const request = {
        body: body,
        headers: {
            Authorization: idToken,
        },
    };
    try {
        const response = await API.del(API_ENDPOINT_NAME, path, request);
        loggingInfo("[DeleteUser] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[DeleteUser] Failed:", error);
        throw error;
    }
}
