import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import { ScrollToTop } from "../../components/ScrollToTop";
import i18n from "../../i18n/configs";
import QAContentEN from "./QAContentEN";
import QAContentJA from "./QAContentJA";
import { Helmet } from "react-helmet";

/**
 * Q&A画面ベース
 * @returns JSX.Element
 */
const QATop = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={"QA | DF WebService"} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow items-center justify-center px-6 py-8 pt-20 lg:px-20">
                <h1 className="mb-4 mt-2 text-center text-2xl font-bold text-gray-800 sm:text-3xl">
                    {t("CommonLabel.faq")}
                </h1>

                {i18n.language === "ja" ? <QAContentJA /> : <QAContentEN />}
            </div>
            <Footer />
        </div>
    );
};

export default QATop;
