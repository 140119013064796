import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { MYPAGE_ROUTE } from "../consts/routes";
import { CrossCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import i18n from "../i18n/configs";
import { formatUtcToLocalDateTime } from "../utils/DateUtils";
import { PaymentHistoryWithLicenses } from "../types/PaymentHistoryWithLicenses";
import { issueCertificate } from "../utils/LicenseCertificateCreator";
import { OnlyMasterUserAuthorized } from "../components/OnlyMasterUserAuthorized";
import { Helmet } from "react-helmet";

interface LocationState {
    chekoutResult: PaymentHistoryWithLicenses;
}

/**
 * ライセンス発行 発行完了画面
 * @returns JSX.Element
 */
const LicenseIssuePurchaseType = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { chekoutResult } = location.state as LocationState;
    const [errorMessage, setErrorMessage] = useState<string>("");

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {}, []);

    const handleIssueLicenseCertificate = () => {
        issueCertificate(chekoutResult);
    };

    /**
     * 戻る押下時のイベント処理
     */
    const handleBack = () => {
        navigate(MYPAGE_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("LicenseIssueComplete.meta.title")} />
            <ScrollToTop />
            {/* マスターユーザーのみ閲覧可 */}
            <OnlyMasterUserAuthorized />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("LicenseIssueComplete.title")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {/* ライセンス証書を発行 */}
                <div className="my-6 flex items-center justify-evenly">
                    <button type="button" onClick={handleIssueLicenseCertificate} className={"btn-primary px-14"}>
                        {t("LicenseIssueComplete.licenseCertificateButton")}
                    </button>
                </div>

                {/* ライセンス情報 */}
                <div className="flex items-center pt-2">
                    <div className="h-0.5 flex-grow bg-gray-800 opacity-70"></div>
                    <span className="flex-shrink px-2 text-lg font-bold text-gray-800">
                        {t("LicenseIssueComplete.licenseInfo")}
                    </span>
                    <div className="h-0.5 flex-grow bg-gray-800 opacity-70"></div>
                </div>
                <div className="m-0 grid grid-cols-3 items-center justify-center py-2">
                    {/* 利用開始日 */}
                    <div className="col-span-1 pb-0.5">{t("CommonLabel.activationDate")}</div>
                    <div className="col-span-2 pb-0.5">
                        {formatUtcToLocalDateTime(chekoutResult.licenses[0].activation_date)}
                    </div>

                    {/* 有効期限 */}
                    <div className="col-span-1 pb-0.5">{t("CommonLabel.expirationDate")}</div>
                    <div className="col-span-2 pb-0.5">
                        {formatUtcToLocalDateTime(chekoutResult.licenses[0].expire_date)}
                    </div>

                    {/* ユーザー */}
                    <div className="col-span-1 pb-0.5">{t("CommonLabel.user")}</div>
                    <div className="col-span-2 pb-0.5">{chekoutResult.licenses[0].email || "-"}</div>
                </div>
                {chekoutResult.licenses
                    .sort((a, b) => a.package_id - b.package_id)
                    .map((license, index) => (
                        <div key={index} className="m-0 grid grid-cols-3 items-center justify-center py-2">
                            {/* 見出し */}
                            <div className="col-span-3 font-bold">
                                ***&nbsp;{t("CommonLabel.license") + (index + 1)}&nbsp;***
                            </div>

                            {/* 製品名 */}
                            <div className="col-span-1">{t("CommonLabel.packageName")}</div>
                            <div className="col-span-2">{license.package_name}</div>

                            {/* ライセンスキー */}
                            <div className="col-span-1">{t("CommonLabel.licenseKey")}</div>
                            <div className="col-span-2">{license.license_key}</div>
                        </div>
                    ))}

                {/* 発行情報 */}
                <div className="flex items-center pt-4">
                    <div className="h-0.5 flex-grow bg-gray-800 opacity-70"></div>
                    <span className="flex-shrink px-2 text-lg font-bold text-gray-800">
                        {t("LicenseIssueComplete.paymentHistoryInfo")}
                    </span>
                    <div className="h-0.5 flex-grow bg-gray-800 opacity-70"></div>
                </div>
                <div className="m-0 grid grid-cols-3 items-center justify-center py-2">
                    {/* 購入日時 */}
                    <div className="col-span-1 pb-1">{t("CommonLabel.paymentDate")}</div>
                    <div className="col-span-2 pb-1">{formatUtcToLocalDateTime(chekoutResult?.payment_date)}</div>

                    {/* 商品名 */}
                    <div className="col-span-1 pb-1">{t("CommonLabel.productName")}</div>
                    <div className="col-span-2 pb-1">
                        {i18n.language === "ja"
                            ? chekoutResult?.p_product_type_name.split("%%")[0]
                            : chekoutResult?.p_product_type_name.split("%%")[1]}
                    </div>

                    {/* 購入者氏名 */}
                    <div className="col-span-1 pb-1">{t("CommonLabel.purchaser")}</div>
                    <div className="col-span-2 pb-1">{chekoutResult?.p_name}</div>

                    {/* 販売元 */}
                    <div className="col-span-1 pb-1">{t("CommonLabel.seller")}</div>
                    <div className="col-span-2 pb-1">{chekoutResult?.p_agency_name}</div>
                </div>

                {/* マイページへ戻るボタン */}
                <div className="mx-auto flex max-w-xl items-center justify-evenly py-5 ">
                    <button type="button" onClick={handleBack} className="btn-secondary mx-2 w-[150px]">
                        {t("LicenseIssueComplete.backToMyPage")}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LicenseIssuePurchaseType;
