import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import { Trans, useTranslation } from "react-i18next";
import ja from "date-fns/locale/ja";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { PRODUCT_COMPLETE_ROUTE, LICENSE_DETAIL_ROUTE, USER_LICENSE_ROUTE } from "../consts/routes";
import { useUserContext } from "../contexts/UserContext";
import { getProduct } from "../clients/ProductClient";
import { JPY, USD, EUR } from "../consts/constants";
import downarrow from "./../img/downarrow.png";
import { getUser } from "../clients/UserClient";
import { getPaymentLinkUrl } from "../clients/PaymentClient";
import { CrossCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import { Product } from "../types/Product";
import { E400, E401 } from "../consts/ErrorCodes";
import { LicensedUserUnauthorized } from "../components/LicensedUserUnauthorized";
import { convertUtcToLocalDate, formatDateTime, getYearsLaterDate } from "../utils/DateUtils";
import { getRetailPrice, getStripePriceId, getDfScannerNum, getDfLatNum, formatPrice } from "../utils/ProductUtils";
import { PaymentHistory } from "../types/PaymentHistory";
import { License } from "../types/License";
import { Helmet } from "react-helmet";

interface LocationState {
    license: License;
    paymentHistory: PaymentHistory;
}

/**
 * ライセンス購入確認画面（更新時）
 * @returns JSX.Element
 */
const ProductConfirmForUpdate = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { paymentHistory, license } = location.state as LocationState;
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [unitPrice, setUnitPrice] = useState<number>(0);
    const { user, dbUserCashe, setDbUserCashe, getRecentToken } = useUserContext();
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [isExipred, setIsExpired] = useState<Boolean>(false);
    const [selectedCurrency, setSelectedCurrency] = useState<string>(i18n.language === "ja" ? JPY : USD);
    const [dfscannerNum, setDfScannerNum] = useState<number>(0);
    const [dflatNum, setDflatNum] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    registerLocale("ja", ja);

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * 商品情報の取得処理
     * @returns
     */
    async function fetchProduct() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]商品情報取得
            const response: Product = await getProduct(idToken, paymentHistory.product_id);
            setSelectedProduct(response);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!effectRun.current) {
            // 前回購入履歴の商品IDで商品マスタを検索
            fetchProduct();
            return () => {
                effectRun.current = true;
            };
        }
    }, [fetchProduct]);

    // 選択ライセンスの有効期限確認
    useEffect(() => {
        if (new Date() > convertUtcToLocalDate(license.expire_date)) {
            // 有効期限切れの場合 -> 利用開始日は現在日時をセット（選択不可）
            setIsExpired(true);
            setSelectedDate(new Date());
        } else {
            // 有効期限前の場合 -> 利用開始日として有効期限日時をセット（選択不可）
            setIsExpired(false);
            setSelectedDate(convertUtcToLocalDate(license.expire_date));
        }
    }, []);

    // 金額の自動計算
    useEffect(() => {
        if (selectedProduct) {
            let retailPrice = getRetailPrice(selectedProduct, selectedCurrency);
            setTotalPrice(retailPrice);
            setUnitPrice(Math.floor(retailPrice / selectedProduct.quantity));
        } else {
            setTotalPrice(0);
        }
    }, [selectedProduct, selectedCurrency]);

    // ライセンスキー発行数の自動計算
    useEffect(() => {
        if (selectedProduct) {
            setDfScannerNum(getDfScannerNum(selectedProduct));
            setDflatNum(getDfLatNum(selectedProduct));
        }
    }, [selectedProduct]);

    /**
     * 支払い通貨選択時のイベント処理
     * @param event
     */
    const handleCurrencyChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSelectedCurrency(event.target.value);
    };

    /** 日付選択時のイベント処理 */
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    /**
     * ライセンス詳細へ戻る押下時のイベント処理
     */
    const handleBackToLicenseDetail = () => {
        navigate(LICENSE_DETAIL_ROUTE, { state: { license: license } });
    };

    /**
     * 決済画面へ押下時のイベント処理
     */
    const handleForwardToPayment = async () => {
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            if (!selectedDate) {
                setErrorMessage(t("ErrorMessage.activationDateNotSelected"));
                return;
            }
            if (!selectedProduct) {
                setErrorMessage(t("ErrorMessage.productNotSelected"));
                return;
            }
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }

            // [API]ユーザー情報の取得
            let stripeCustomerId;
            try {
                if (dbUserCashe) {
                    stripeCustomerId = dbUserCashe.stripe_customer_id;
                } else {
                    const userResponse = await getUser(idToken, user.attributes.sub);
                    setDbUserCashe(userResponse);
                    stripeCustomerId = userResponse.stripe_customer_id;
                }
            } catch (error: any) {
                setErrorMessage(t("ErrorMessage.userNotFoundAndAbort"));
                return;
            }

            // Stripe価格IDの特定
            let stripePriceId = getStripePriceId(selectedProduct, selectedCurrency);
            if (!stripePriceId) {
                setErrorMessage(t("ErrorMessage.productNotFoundAndAbort"));
                return;
            }

            // 戻り先URLの決定
            const successUrl = window.location.origin + PRODUCT_COMPLETE_ROUTE;
            const canselUrl = window.location.origin + USER_LICENSE_ROUTE;

            let paymentLink;
            try {
                // [API]決済ページURL取得処理
                const paymentResponse = await getPaymentLinkUrl(
                    idToken,
                    stripeCustomerId,
                    stripePriceId,
                    1, // 現状は1固定
                    selectedProduct.product_id,
                    successUrl,
                    canselUrl,
                    selectedDate?.toUTCString(),
                    paymentHistory.payment_history_id, // 更新時は必須
                );
                paymentLink = paymentResponse.payment_link_url;
            } catch (error: any) {
                if (error.response && error.response.data && error.response.data.code) {
                    const errorCode = error.response.data.code;
                    if (errorCode === E400) {
                        setErrorMessage(t("ErrorMessage.freeTrialAlreadyUsed"));
                    } else if (errorCode === E401) {
                        setErrorMessage(t("ErrorMessage.freeTrialOnePerUser"));
                    } else {
                        setErrorMessage(t("ErrorMessage.failToConnectPayment"));
                    }
                } else {
                    setErrorMessage(t("ErrorMessage.failToConnectPayment"));
                }
                return;
            }

            // Stripeページへ移動
            window.location.replace(paymentLink);
        } finally {
            setIsProcessing(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("ProductConfirmForUpdate.meta.title")} />
            <ScrollToTop />
            <LicensedUserUnauthorized />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto max-w-3xl flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("ProductConfirmForUpdate.productConfirmTitle")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center p-2">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="p-2 font-bold text-lime-800">Loading...</p>
                    </div>
                ) : (
                    <>
                        <div className="m-0 items-center justify-center rounded-lg border border-gray-300 p-2 shadow sm:m-2">
                            <p className="mx-auto py-2 text-center text-base font-bold">
                                {t("ProductConfirmForUpdate.selectPurchase")}
                            </p>
                            <div className="grid grid-cols-3 px-5 sm:px-10">
                                {/* 利用開始日 */}
                                <div className="col-span-3 my-auto p-3 sm:col-span-1">
                                    {t("CommonLabel.activationDate")}
                                    <Tooltip
                                        style={{}}
                                        className="text-xl"
                                        title={t("ProductConfirmForUpdate.activationDateHelp")}
                                        arrow
                                    >
                                        <HelpOutlineIcon
                                            fontSize="small"
                                            className="mb-0.5 ml-0.5 cursor-pointer text-gray-500 hover:text-blue-500"
                                        />
                                    </Tooltip>
                                </div>
                                <div className="col-span-3 my-auto pb-3 sm:col-span-2 sm:p-3">
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={(date: Date | null) => handleDateChange(date)}
                                        showTimeSelect
                                        minDate={new Date()}
                                        maxDate={getYearsLaterDate(1)}
                                        className={"bg-white p-2 text-xs text-gray-900 sm:text-sm"}
                                        dateFormat={i18n.language === "ja" ? "yyyy/MM/dd HH:mm" : "dd/MM/yyyy hh:mm a"}
                                        locale={i18n.language === "ja" ? "ja" : undefined}
                                        disabled={true}
                                    />
                                </div>

                                {/* 支払い通貨 */}
                                <div className="col-span-3 my-auto p-3 sm:col-span-1">
                                    {t("CommonLabel.paymentCurrency")}
                                </div>
                                <div className="col-span-3 my-auto pb-3 sm:col-span-2 sm:p-3">
                                    <select
                                        id="currency-dropdown"
                                        className="w-1/2 rounded-lg border border-gray-300 bg-gray-50 p-2 text-xs text-gray-900 sm:text-sm"
                                        onChange={handleCurrencyChange}
                                        defaultValue={i18n.language === "ja" ? JPY : USD}
                                    >
                                        <option key={JPY} value={JPY}>
                                            {JPY}
                                        </option>
                                        <option key={USD} value={USD}>
                                            {USD}
                                        </option>
                                        <option key={EUR} value={EUR}>
                                            {EUR}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* 下矢印 */}
                        <div className="flex items-center justify-center">
                            <img className="h-1/6 w-1/6 p-2" src={downarrow} alt="downarrow" />
                        </div>

                        {/* 購入内容表示パネル */}
                        <div className="m-0 rounded-lg border border-gray-200 bg-yellow-50 p-2 shadow sm:m-2">
                            <p className="mx-auto py-2 text-center text-lg font-bold">
                                {t("ProductConfirmForUpdate.purchaseFollowing")}
                            </p>
                            {/* 商品名 */}
                            <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                                <span className="">{t("CommonLabel.productName")}:&nbsp;&nbsp;&nbsp;</span>
                                <div id="product-name" className="">
                                    {selectedProduct
                                        ? i18n.language === "ja"
                                            ? selectedProduct.product_name_jp
                                            : selectedProduct.product_name_en
                                        : t("CommonLabel.unselected")}
                                </div>
                            </div>

                            {/* ライセンス発行数 */}
                            {selectedProduct && dfscannerNum > 0 ? (
                                <div id="dfscanner-num" className="flex items-center px-10 font-bold text-gray-600">
                                    &nbsp;&nbsp;*
                                    <Trans
                                        i18nKey={"ProductConfirmForUpdate.updateLicensesNum"}
                                        values={{ productName: "DF Scanner", licenseNum: dfscannerNum }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {selectedProduct && dflatNum > 0 ? (
                                <div id="dflat-num" className="flex items-center px-10 font-bold text-gray-600">
                                    &nbsp;&nbsp;*
                                    <Trans
                                        i18nKey={"ProductConfirmForUpdate.updateLicensesNum"}
                                        values={{ productName: "DF LAT", licenseNum: dflatNum }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}

                            {/* 利用開始日 */}
                            <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                                <span className="">{t("CommonLabel.activationDate")}:&nbsp;&nbsp;&nbsp;</span>
                                {/* 表示時のタイムゾーンはランタイム環境に依存 */}
                                <div className="">
                                    {selectedDate ? (
                                        isExipred ? (
                                            <Trans
                                                i18nKey={"ProductConfirmForUpdate.resumeUseFrom"}
                                                values={{ activationDate: formatDateTime(selectedDate) }}
                                            />
                                        ) : (
                                            <Trans
                                                i18nKey={"ProductConfirmForUpdate.usageExtendedFrom"}
                                                values={{ activationDate: formatDateTime(selectedDate) }}
                                            />
                                        )
                                    ) : (
                                        t("CommonLabel.unselected")
                                    )}
                                </div>
                            </div>

                            {/* 単価 */}
                            <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                                <span className="">{t("CommonLabel.unitPrice")}:&nbsp;&nbsp;&nbsp;</span>
                                <div id="unit-price" className="">
                                    {formatPrice(unitPrice, selectedCurrency)}
                                </div>
                            </div>

                            <hr className="mx-8 my-2 border border-gray-300" />

                            {/* 合計金額 */}
                            <div className="flex items-center pl-10 pt-2 text-lg font-bold text-orange-600">
                                <span className="">{t("CommonLabel.totalAmount")}:&nbsp;</span>
                                <div id="total-price" className="">
                                    {formatPrice(totalPrice, selectedCurrency)}
                                </div>
                            </div>
                            <div className="pb-5 pl-10  text-sm font-bold text-gray-600">
                                &nbsp;&nbsp;*{t("ProductConfirmForUpdate.checkTax")}
                            </div>
                        </div>

                        {/* 購入前注意事項 */}
                        <p className="list-disc px-10 pt-7 text-center text-xl font-bold text-red-600">
                            {t("ProductConfirmForUpdate.chackBeforePurchasingTitle")}
                        </p>
                        <ul className="list-disc px-10 pb-10 pt-2 text-gray-700">
                            <li>
                                <Trans i18nKey={"ProductConfirmForUpdate.chackBeforePurchasingText1"} />
                            </li>
                            <li>
                                <Trans i18nKey={"ProductConfirmForUpdate.chackBeforePurchasingText2"} />
                            </li>
                        </ul>

                        {/* 戻る/進むボタン */}
                        <div className="flex justify-evenly pb-5 pt-2 text-sm font-medium">
                            <button onClick={handleBackToLicenseDetail} className="btn-secondary w-1/3">
                                {t("ProductConfirmForUpdate.backToLicenseDetail")}
                            </button>
                            <button
                                id="payment-btn"
                                onClick={handleForwardToPayment}
                                className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                                disabled={isProcessing}
                            >
                                {isProcessing ? (
                                    <div className="flex items-center justify-center">
                                        <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                        <label className="">Processing...</label>
                                    </div>
                                ) : (
                                    t("ProductConfirmForUpdate.goToPaymentPage")
                                )}
                            </button>
                        </div>
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default ProductConfirmForUpdate;
