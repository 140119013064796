import { MouseEventHandler, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { SIGNUP_ROUTE, SIGNIN_ROUTE, HOME_ROUTE } from "../consts/routes";
import { ScrollToTop } from "../components/ScrollToTop";
import { useUserContext } from "../contexts/UserContext";
import { Helmet } from "react-helmet";

/**
 * ログイン要求画面
 *
 * 商品選択後の購入手続きに進む時、未ログイン状態であれば本画面を表示する。
 * @returns JSX.Element
 */
const RequiredSignin = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useUserContext();

    // 認証情報が存在する場合はトップ画面へ遷移
    useEffect(() => {
        if (user) {
            navigate(HOME_ROUTE);
        }
    });

    /**
     * アカウント作成画面へ押下時のイベント処理
     * @param event
     */
    const handleMoveToSignup: MouseEventHandler<HTMLButtonElement> = (event) => {
        navigate(SIGNUP_ROUTE);
    };

    /**
     * ログイン画面へ押下時のイベント処理
     * @param event
     */
    const handleMoveToSignin: MouseEventHandler<HTMLButtonElement> = (event) => {
        navigate(SIGNIN_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("RequiredSignin.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("RequiredSignin.requiredSigninTitle")}</h1>

                {/* アカウント作成画面へ誘導 */}
                <div className="m-5 max-w-md rounded-lg border border-gray-200 px-5 pb-5 shadow">
                    <div className="mt-5 rounded-sm bg-lime-100 px-3 py-1 text-xl text-lime-900">
                        {t("RequiredSignin.firstTimeCustomerTitle")}
                    </div>
                    <p className="text-md py-3">
                        <Trans i18nKey={"RequiredSignin.firstTimeCustomerText"} />
                    </p>
                    <div className="flex items-center justify-center">
                        <button onClick={handleMoveToSignup} className="btn-primary w-3/4">
                            {t("RequiredSignin.toSignup")}
                        </button>
                    </div>
                </div>

                {/* ログイン画面へ誘導 */}
                <div className="m-5 max-w-md rounded-lg border border-gray-200 px-5 pb-5 shadow">
                    <div className="mt-5 rounded-sm bg-lime-100 px-3 py-1 text-xl text-lime-900">
                        {t("RequiredSignin.existingCustomerTitle")}
                    </div>
                    <p className="text-md py-3">
                        <Trans i18nKey={"RequiredSignin.existingCustomerText"} />
                    </p>
                    <div className="flex items-center justify-center">
                        <button onClick={handleMoveToSignin} className="btn-primary w-3/4">
                            {t("RequiredSignin.toSinin")}
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default RequiredSignin;
