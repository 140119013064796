import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import BackupIcon from "@mui/icons-material/Backup";

const TopButtonList = ({
    setShowUploadModal,
    setShowShareFolderModal,
    setShowStrageModal,
    deleteFolder,
    deleteVisible,
    fetchFileList,
    enableSharing,
}: any) => {
    const { t } = useTranslation();
    return (
        <div className="items-left pt-2">
            {/* リストを更新 */}
            <button
                className="mr-1 bg-gray-300 px-2 py-2 text-sm font-bold text-gray-800 hover:bg-gray-400"
                onClick={() => fetchFileList()}
            >
                <RefreshIcon fontSize="small" className="mr-1"></RefreshIcon>
                {t("WebGIS.btnReload")}
            </button>

            {/* ファイルを追加 */}
            <button
                className="mr-1 bg-gray-300 px-2 py-2 text-sm font-bold text-gray-800 hover:bg-gray-400"
                onClick={() => setShowUploadModal(true)}
            >
                <AddIcon fontSize="small" className="mr-1"></AddIcon>
                {t("WebGIS.btnUpload")}
            </button>

            {/* フォルダ共有 */}
            {enableSharing && (
                <button
                    className="mr-1 bg-gray-300 px-2 py-2 text-sm font-bold text-gray-800 hover:bg-gray-400"
                    onClick={() => setShowShareFolderModal(true)}
                >
                    <ShareIcon fontSize="small" className="mr-1"></ShareIcon>
                    {t("WebGIS.btnShare")}
                </button>
            )}

            {/* 容量の確認 */}
            <button
                className="mr-1 bg-gray-300 px-2 py-2 text-sm font-bold text-gray-800 hover:bg-gray-400"
                onClick={() => setShowStrageModal(true)}
            >
                <BackupIcon fontSize="small" className="mr-1"></BackupIcon>
                {t("WebGIS.btnCapacity")}
            </button>
            {deleteVisible && (
                <button
                    className="mr-1 bg-gray-300 px-2 py-2 text-sm font-bold text-gray-800 hover:bg-gray-400"
                    onClick={() => deleteFolder()}
                >
                    <DeleteIcon fontSize="small"></DeleteIcon>
                    {t("WebGIS.btnDeleteProj")}
                </button>
            )}
        </div>
    );
};

export default TopButtonList;
