import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { COMMON_UNAUTHORIZED_ROUTE } from "../consts/routes";
import { USER_TYPE_MANAGER, USER_TYPE_MASTER_USER } from "../consts/constants";
import { useUserContext } from "../contexts/UserContext";
import { loggingInfo } from "../utils/LoggingUtils";

/**
 * [マスターユーザーもしくはマネージャー]以外が参照権限のないページを開いたときにエラー画面へ誘導するためのコンポーネント
 * @returns
 */
export const OnlyMasterUserAndManagerAuthorized = () => {
    const { userType } = useUserContext();
    const navigate = useNavigate();
    // マスターユーザーとマネージャー以外はページ参照不可
    useEffect(() => {
        if (userType && userType !== USER_TYPE_MASTER_USER && userType !== USER_TYPE_MANAGER) {
            loggingInfo(userType);
            navigate(COMMON_UNAUTHORIZED_ROUTE);
        }
    }, [userType]);
    return null;
};
