import { useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { DOWNLOAD_ROUTE, MYPAGE_ROUTE, USER_LICENSE_REFERENCE_ROUTE, USER_LICENSE_ROUTE } from "../consts/routes";
import { CheckmarkCircleIcon, CrossCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useUserContext } from "../contexts/UserContext";
import { PaymentHistory } from "../types/PaymentHistory";
import { getPaymentHistoryList } from "../clients/PaymentHistoryClient";
import { USER_TYPE_LICENSED_USER } from "../consts/constants";
import { Helmet } from "react-helmet";

/**
 * ライセンス購入完了画面
 * @returns JSX.Element
 */
const ProductComplete = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { userType, getRecentToken } = useUserContext();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState(true);

    /** 購入履歴情報取得の最大試行回数 */
    const MAX_RETRY_COUNT = 10;
    /** 購入履歴情報取得の確認インターバル */
    const RETRY_INTERVAL_MILLIS = 1000;

    /**
     * 購入履歴情報の問い合わせ
     */
    async function fetchPaymentHistory(stripePaymentId: string) {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ProductComplete.productCompleteFailureMessage"));
                return;
            }
            for (let i: number = 0; i < MAX_RETRY_COUNT; i++) {
                // [API]購入履歴一覧取得
                const response: PaymentHistory[] = await getPaymentHistoryList(idToken, {
                    stripePaymentId: stripePaymentId,
                });
                if (response.length > 0) {
                    // 結果が取得できた場合はOK
                    break;
                }
                if (i === MAX_RETRY_COUNT - 1) {
                    // 試行回数の上限に達した場合はNG
                    setErrorMessage(t("ProductComplete.productCompleteFailureMessage"));
                    break;
                }
                await new Promise((resolve) => setTimeout(resolve, RETRY_INTERVAL_MILLIS));
            }
        } catch (error) {
            setErrorMessage(t("ProductComplete.productCompleteFailureMessage"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!effectRun.current) {
            // クエリパラメータからStripe決済IDを取得
            const queryParams = new URLSearchParams(location.search);
            const stripePaymentId = queryParams.get("stripe_payment_id");
            if (!stripePaymentId) {
                setErrorMessage(t("ProductComplete.productCompleteFailureMessage"));
                setLoading(false);
                return;
            }

            // 購入履歴情報を取得
            fetchPaymentHistory(stripePaymentId);
            return () => {
                effectRun.current = true;
            };
        }
    }, []);

    /**
     * ユーザー・ライセンス画面へ押下時のイベント処理
     */
    const handleMoveToUserLicense = () => {
        if (userType === USER_TYPE_LICENSED_USER) {
            // 一般ユーザーの場合は参照専用画面へ
            navigate(USER_LICENSE_REFERENCE_ROUTE);
        } else {
            // それ以外の場合は通常の管理画面へ
            navigate(USER_LICENSE_ROUTE);
        }
    };

    /**
     * ホーム画面へ戻る押下時のイベント処理
     */
    const handleBackToMyPage = () => {
        navigate(MYPAGE_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("ProductComplete.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("ProductComplete.productCompleteTitle")}</h1>

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <div className="p-2 text-center font-bold text-lime-800">
                            <Trans i18nKey={"ProductComplete.productCompleteInquiring"} />
                        </div>
                    </div>
                ) : errorMessage ? (
                    /* エラーメッセージ */
                    <>
                        <div className="flex items-center justify-center pb-4 pt-4">
                            <CrossCircleIcon />
                            <span className="pl-2 text-center text-xl font-bold text-red-600">
                                {errorMessage.split("\n").map((line, index) => (
                                    <div key={index}>{line}</div>
                                ))}
                            </span>
                        </div>
                        <div className="mb-8 rounded-lg border-2 border-red-600 p-2">
                            <div className="max-w-2xl pb-2 font-bold text-red-600">
                                <Trans i18nKey={"ProductComplete.productCompleteFailureText1"} />
                            </div>
                            <ul className="ml-5 list-disc pb-2 text-red-600">
                                <li>{t("ProductComplete.productCompleteFailureText2")}</li>
                                <li>{t("ProductComplete.productCompleteFailureText3")}</li>
                                <li>{t("ProductComplete.productCompleteFailureText4")}</li>
                            </ul>
                            <div className="max-w-2xl pb-2 text-center text-lg font-bold text-red-600">
                                <Trans
                                    i18nKey={"ProductComplete.productCompleteFailureText5"}
                                    values={{ email: process.env.REACT_APP_CONTACT_EMAIL }}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    /* 購入完了メッセージ */
                    <>
                        <div className="flex items-center justify-center pb-4 pt-4">
                            <CheckmarkCircleIcon />
                            <span className="pl-2 text-center text-xl font-bold text-green-500">
                                {t("ProductComplete.productCompleteMessage")}
                            </span>
                        </div>
                        <ul className="ml-10 list-disc pb-6 font-bold text-gray-600">
                            <li>
                                <Trans i18nKey={"ProductComplete.productCompleteText1"} />
                            </li>
                            <li>
                                <Trans i18nKey={"ProductComplete.productCompleteText2"} />
                            </li>
                        </ul>
                    </>
                )}

                {/* ご利用の流れ */}
                <div className="flex items-center justify-center">
                    <div className="m-0 max-w-lg items-center justify-center rounded-lg border border-gray-300 p-2 shadow sm:m-2">
                        <div className="mb-2 text-center text-lg font-bold">
                            {t("ProductComplete.flowOfUsageTitle")}
                        </div>
                        <ul className="ml-5 list-decimal text-sm text-gray-800">
                            <li>{t("ProductComplete.flowOfUsageText1")}</li>
                            <ul className="text-gray-600">
                                <li className="">
                                    *
                                    <Trans
                                        i18nKey={"ProductComplete.installerLink"}
                                        components={{
                                            l: <HashLink className="link-text" to={DOWNLOAD_ROUTE} />,
                                        }}
                                    />
                                </li>
                            </ul>
                            <li className="pt-1">{t("ProductComplete.flowOfUsageText2")}</li>
                            <li className="pt-1">{t("ProductComplete.flowOfUsageText3")}</li>
                            <li className="pt-1">{t("ProductComplete.flowOfUsageText4")}</li>
                            <li className="pt-1">{t("ProductComplete.flowOfUsageText5")}</li>
                        </ul>
                        <div className="font ml-4 mt-2 text-sm">
                            <Trans i18nKey={"ProductComplete.flowOfUsageTextOther"} />
                        </div>
                    </div>
                </div>

                {/* ユーザー・ライセンス管理画面へ */}
                <div className="mt-10 flex items-center justify-center">
                    <button onClick={handleMoveToUserLicense} className="text-lx btn-green-white w-max-md h-full w-2/3">
                        {t("ProductComplete.goToUserLicense")}
                    </button>
                </div>

                {/* マイページ画面へ戻る */}
                <div className="flex items-center justify-center">
                    <button onClick={handleBackToMyPage} className="text-lx btn-secondary my-5 w-2/3">
                        {t("ProductComplete.backToMyPage")}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ProductComplete;
