import { Trans, useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { ScrollToTop } from "../components/ScrollToTop";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

/**
 * ヘルプ画面
 * @returns JSX.Element
 */
const Help = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("help.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto max-w-screen-xl flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("help.title")}</h1>

                <div className="sm:flex">
                    {/* ナビゲーションメニュー */}
                    <div className="bg-gray-200 p-4 sm:w-1/4">
                        <nav>
                            <ul>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section1"} smooth>
                                        {t("help.section.1")}
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section2"} smooth>
                                        {t("help.section.2")}
                                    </HashLink>
                                    <ul className="ml-4 mt-2">
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section2-1"}
                                                smooth
                                            >
                                                {t("help.section.2-1")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section2-2"}
                                                smooth
                                            >
                                                {t("help.section.2-2")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section2-3"}
                                                smooth
                                            >
                                                {t("help.section.2-3")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section2-4"}
                                                smooth
                                            >
                                                {t("help.section.2-4")}
                                            </HashLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section3"} smooth>
                                        {t("help.section.3")}
                                    </HashLink>
                                    {/* 以下、クレジット決済を使用していないためコメントアウトしている。 */}
                                    {/* <ul className="ml-4 mt-2">
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section3-1"}
                                                smooth
                                            >
                                                {t("help.section.3-1")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section3-2"}
                                                smooth
                                            >
                                                {t("help.section.3-2")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section3-3"}
                                                smooth
                                            >
                                                {t("help.section.3-3")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section3-4"}
                                                smooth
                                            >
                                                {t("help.section.3-4")}
                                            </HashLink>
                                        </li>
                                    </ul> */}
                                </li>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section4"} smooth>
                                        {t("help.section.4")}
                                    </HashLink>
                                    <ul className="ml-4 mt-2">
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section4-1"}
                                                smooth
                                            >
                                                {t("help.section.4-1")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section4-2"}
                                                smooth
                                            >
                                                {t("help.section.4-2")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section4-3"}
                                                smooth
                                            >
                                                {t("help.section.4-3")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section4-4"}
                                                smooth
                                            >
                                                {t("help.section.4-4")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section4-5"}
                                                smooth
                                            >
                                                {t("help.section.4-5")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section4-6"}
                                                smooth
                                            >
                                                {t("help.section.4-6")}
                                            </HashLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section5"} smooth>
                                        {t("help.section.5")}
                                    </HashLink>
                                    <ul className="ml-4 mt-2">
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section5-1"}
                                                smooth
                                            >
                                                {t("help.section.5-1")}
                                            </HashLink>
                                        </li>
                                        <li className="mb-2">
                                            <HashLink
                                                className="text-blue-500 hover:underline"
                                                to={"#section5-2"}
                                                smooth
                                            >
                                                {t("help.section.5-2")}
                                            </HashLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section6"} smooth>
                                        {t("help.section.6")}
                                    </HashLink>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    {/* メインコンテンツ */}
                    <div className="p-4 sm:w-3/4">
                        {/* クレジット決済を隠しているためコメントアウト */}
                        {/* <div className="mx-3 mb-10 rounded-lg border-2 border-lime-700 px-2 py-1">
                            <h3 className="my-1 text-center text-xl font-bold text-lime-700">
                                {t("help.guide.title")}
                            </h3>
                            <table className="my-3 min-w-full border border-gray-300">
                                <tbody>
                                    <tr>
                                        <td className="border border-gray-300 px-4 py-2 font-bold">
                                            {t("help.guide.licensePurchaseGuide")}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2">
                                            <a
                                                className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                                href={process.env.REACT_APP_LICENSE_PURCHASE_GUIDE_JA_PATH}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                {t("help.guide.ja")}
                                            </a>
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2">
                                            <a
                                                className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                                href={process.env.REACT_APP_LICENSE_PURCHASE_GUIDE_EN_PATH}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                {t("help.guide.en")}
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                        {/* 1. DF WebServiceとは */}
                        <div className="m-2">
                            <h2 id="section1" className="my-2 text-2xl font-bold">
                                {t("help.section.1")}
                            </h2>
                            <p>
                                <Trans i18nKey="help.1.p1" />
                            </p>
                        </div>

                        <hr className="my-8"></hr>

                        {/* 2. DFアカウントについて */}
                        <div className="m-2">
                            <h2 id="section2" className="my-2 text-2xl font-bold">
                                {t("help.section.2")}
                            </h2>
                            <p>
                                <Trans i18nKey="help.2.p1" />
                            </p>

                            {/* 2-1. アカウントの種類 */}
                            <div className="my-5 ml-2">
                                <h3 id="section2-1" className="my-2 text-lg font-bold">
                                    {t("help.section.2-1")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.2-1.p1" />
                                </p>
                                <p className="mt-2 font-bold">
                                    <Trans i18nKey="help.2-1.p2" />
                                </p>
                                <p className="">
                                    <Trans i18nKey="help.2-1.p3" />
                                </p>
                                <p className="mt-2 font-bold">
                                    <Trans i18nKey="help.2-1.p4" />
                                </p>
                                <p className="">
                                    <Trans i18nKey="help.2-1.p5" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.2-1.p6" />
                                </p>
                            </div>

                            {/* 2-2. アカウント作成 */}
                            <div className="my-5 ml-2">
                                <h3 id="section2-2" className="my-2 text-lg font-bold">
                                    {t("help.section.2-2")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.2-2.p1" />
                                </p>
                                <ul className="my-2 ml-5 list-decimal">
                                    <li>
                                        <Trans i18nKey="help.2-2.ul1.li1" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.2-2.ul1.li2" />
                                    </li>
                                    <li>
                                        <Trans
                                            i18nKey="help.2-2.ul1.li3"
                                            values={{ email: process.env.REACT_APP_NO_REPLY_EMAIL }}
                                        />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.2-2.ul1.li4" />
                                    </li>
                                </ul>
                            </div>

                            {/* 2-3. アカウント情報の更新 */}
                            <div className="my-5 ml-2">
                                <h3 id="section2-3" className="my-2 text-lg font-bold">
                                    {t("help.section.2-3")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.2-3.p1" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.2-3.p2" />
                                </p>
                            </div>

                            {/* 2-4. アカウント情報削除（退会） */}
                            <div className="my-5 ml-2">
                                <h3 id="section2-4" className="my-2 text-lg font-bold">
                                    {t("help.section.2-4")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.2-4.p1" />
                                </p>
                                <p className="mt-2 font-bold">
                                    <Trans i18nKey="help.2-4.p2" />
                                </p>
                                <p className="">
                                    <Trans i18nKey="help.2-4.p3" />
                                </p>
                                <p className="mt-2 font-bold">
                                    <Trans i18nKey="help.2-4.p4" />
                                </p>
                                <p className="">
                                    <Trans i18nKey="help.2-4.p5" />
                                </p>
                            </div>
                        </div>

                        <hr className="my-8"></hr>

                        {/* 3. ライセンス購入 */}
                        <div className="m-2">
                            <h2 id="section3" className="my-2 text-2xl font-bold">
                                {t("help.section.3")}
                            </h2>
                            <p className="mt-2">
                                <Trans i18nKey="help.3.p4" />
                            </p>
                            <p className="mt-2">
                                <Trans i18nKey="help.3.p5" />
                            </p>
                            <p className="mt-2">
                                <Trans i18nKey="help.3.p6" />
                            </p>

                            {/* 以下、クレジット決済を使用していないためコメントアウトしている。 */}
                            {/* <p>
                                <Trans i18nKey="help.3.p1" />
                            </p>
                            <p className="mt-2">
                                <Trans i18nKey="help.3.p2" />
                            </p>
                            <p className="">
                                <Trans i18nKey="help.3.p3" />
                            </p>

                            <div className="mx-5 my-2 rounded-lg border-2 border-gray-300 px-2 py-1">
                                <h3 className="my-1 text-center font-bold text-red-600">
                                    <Trans i18nKey="help.3.attentionTitle" />
                                </h3>
                                <ul className="my-2 ml-5 list-disc">
                                    <li>
                                        <Trans i18nKey="help.3.attentiontext1" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3.attentiontext2" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3.attentiontext3" />
                                    </li>
                                </ul>
                            </div> */}

                            {/* 3-1. ライセンス購入手順 */}
                            {/* <div className="my-5 ml-2">
                                <h3 id="section3-1" className="my-2 text-lg font-bold">
                                    {t("help.section.3-1")}
                                </h3>
                                <ul className="my-2 ml-5 list-decimal">
                                    <li>
                                        <Trans i18nKey="help.3-1.ul1.li1" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-1.ul1.li2" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-1.ul1.li3" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-1.ul1.li4" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-1.ul1.li5" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-1.ul1.li6" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-1.ul1.li7" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-1.ul1.li8" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-1.ul1.li9" />
                                    </li>
                                </ul>
                            </div> */}

                            {/* 3-2. ライセンス購入時の注意事項 */}
                            {/* <div className="my-5 ml-2">
                                <h3 id="section3-2" className="my-2 text-lg font-bold">
                                    {t("help.section.3-2")}
                                </h3>
                                <ul className="my-2 ml-5 list-disc">
                                    <li>
                                        <Trans i18nKey="help.3-2.ul1.li1" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-2.ul1.li2" />
                                    </li>
                                </ul>
                            </div> */}

                            {/* 3-3. 無料体験版のご利用 */}
                            {/* <div className="my-5 ml-2">
                                <h3 id="section3-3" className="my-2 text-lg font-bold">
                                    {t("help.section.3-3")}
                                </h3>
                                <p>
                                    <Trans i18nKey="help.3-3.p1" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.3-3.p2" />
                                </p>
                            </div> */}

                            {/* 3-4. ライセンスの更新 */}
                            {/* <div className="my-5 ml-2">
                                <h3 id="section3-4" className="my-2 text-lg font-bold">
                                    {t("help.section.3-4")}
                                </h3>
                                <p>
                                    <Trans i18nKey="help.3-4.p1" />
                                </p>

                                <p className="mt-2 font-bold">
                                    <Trans i18nKey="help.3-4.p2" />
                                </p>
                                <p className="">
                                    <Trans i18nKey="help.3-4.p3" />
                                </p>
                                <ul className="ml-5 list-decimal">
                                    <li>
                                        <Trans i18nKey="help.3-4.ul1.li1" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-4.ul1.li2" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-4.ul1.li3" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-4.ul1.li4" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-4.ul1.li5" />
                                    </li>
                                </ul>

                                <p className="mt-2 font-bold">
                                    <Trans i18nKey="help.3-4.p4" />
                                </p>
                                <p className="">
                                    <Trans i18nKey="help.3-4.p5" />
                                </p>

                                <p className="mt-2">
                                    <Trans i18nKey="help.3-4.p6" />
                                </p>
                                <ul className="my-2 ml-5 list-disc">
                                    <li>
                                        <Trans i18nKey="help.3-4.ul2.li1" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-4.ul2.li2" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.3-4.ul2.li3" />
                                    </li>
                                </ul>
                            </div> */}
                        </div>

                        <hr className="my-8"></hr>

                        {/* 4. ユーザー・ライセンス管理 */}
                        <div className="m-2">
                            <h2 id="section4" className="my-2 text-2xl font-bold">
                                {t("help.section.4")}
                            </h2>
                            <p>
                                <Trans i18nKey="help.4.p1" />
                            </p>

                            {/* 4-1. ユーザー・ライセンス一覧確認 */}
                            <div className="my-5 ml-2">
                                <h3 id="section4-1" className="my-2 text-lg font-bold">
                                    {t("help.section.4-1")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.4-1.p1" />
                                </p>
                            </div>

                            {/* 4-2. ユーザー作成 */}
                            <div className="my-5 ml-2">
                                <h3 id="section4-2" className="my-2 text-lg font-bold">
                                    {t("help.section.4-2")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.4-2.p1" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.4-2.p2" />
                                </p>
                                <ul className="my-2 ml-5 list-decimal">
                                    <li>
                                        <Trans i18nKey="help.4-2.ul1.li1" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.4-2.ul1.li2" />
                                    </li>
                                    <li>
                                        <Trans
                                            i18nKey="help.4-2.ul1.li3"
                                            values={{ email: process.env.REACT_APP_NO_REPLY_EMAIL }}
                                        />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.4-2.ul1.li4" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.4-2.ul1.li5" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.4-2.ul1.li6" />
                                    </li>
                                </ul>
                            </div>

                            {/* 4-3. ユーザー情報編集 */}
                            <div className="my-5 ml-2">
                                <h3 id="section4-3" className="my-2 text-lg font-bold">
                                    {t("help.section.4-3")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.4-3.p1" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.4-3.p2" />
                                </p>
                            </div>

                            {/* 4-4. ユーザー情報削除 */}
                            <div className="my-5 ml-2">
                                <h3 id="section4-4" className="my-2 text-lg font-bold">
                                    {t("help.section.4-4")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.4-4.p1" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.4-4.p2" />
                                </p>
                            </div>

                            {/* 4-5. ライセンス紐づけ */}
                            <div className="my-5 ml-2">
                                <h3 id="section4-5" className="my-2 text-lg font-bold">
                                    {t("help.section.4-5")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.4-5.p1" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.4-5.p2" />
                                </p>
                            </div>

                            {/* 4-6. ライセンス紐づけ解除 */}
                            <div className="my-5 ml-2">
                                <h3 id="section4-6" className="my-2 text-lg font-bold">
                                    {t("help.section.4-6")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.4-6.p1" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.4-6.p2" />
                                </p>
                            </div>
                        </div>

                        <hr className="my-8"></hr>

                        {/* 5. データ管理 */}
                        <div className="m-2">
                            <h2 id="section5" className="my-2 text-2xl font-bold">
                                {t("help.section.5")}
                            </h2>
                            <p>
                                <Trans i18nKey="help.5.p1" />
                            </p>
                            <p className="text-red-600">
                                <Trans i18nKey="help.5.p2" />
                            </p>

                            {/* 5-1. プロジェクト管理 */}
                            <div className="my-5 ml-2">
                                <h3 id="section5-1" className="my-2 text-lg font-bold">
                                    {t("help.section.5-1")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.5-1.p1" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.5-1.p2" />
                                </p>
                            </div>

                            {/* 5-2. データアップロード */}
                            <div className="my-5 ml-2">
                                <h3 id="section5-2" className="my-2 text-lg font-bold">
                                    {t("help.section.5-2")}
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.5-2.p1" />
                                </p>
                                <ul className="my-2 ml-5 list-disc">
                                    <li>
                                        <Trans i18nKey="help.5-2.ul1.li1" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.5-2.ul1.li2" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.5-2.ul1.li3" />
                                    </li>
                                </ul>
                                <p className="mt-2 font-bold">
                                    <Trans i18nKey="help.5-2.p2" />
                                </p>
                                <p className="mt-2">
                                    <Trans i18nKey="help.5-2.p3" />
                                </p>
                                <p className="">
                                    <Trans i18nKey="help.5-2.p4" />
                                </p>
                            </div>
                        </div>

                        {/* 5-3. データダウンロード */}
                        <div className="my-5 ml-2">
                            <h3 id="section5-3" className="my-2 text-lg font-bold">
                                {t("help.section.5-3")}
                            </h3>
                            <p className="">
                                <Trans i18nKey="help.5-3.p1" />
                            </p>
                        </div>

                        <hr className="my-8"></hr>

                        {/* 6. トラブルシューティング */}
                        <div className="m-2">
                            <h2 id="section6" className="my-2 text-2xl font-bold">
                                {t("help.section.6")}
                            </h2>
                            <p className="">
                                <Trans i18nKey="help.6.p1" />
                            </p>

                            <div className="mx-5 my-2 rounded-lg border-2 border-gray-300 p-1">
                                <h3 className="my-1 text-center font-bold">
                                    <Trans i18nKey="help.6.contactTitle" />
                                </h3>
                                <p className="text-center">
                                    <Trans
                                        i18nKey="help.6.contactText"
                                        values={{ email: process.env.REACT_APP_CONTACT_EMAIL }}
                                    />
                                </p>
                            </div>

                            {/* <div className="my-5 ml-2">
                                <h3 className="my-2 font-bold">
                                    <Trans i18nKey="help.6.q1" />
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.6.a1" />
                                </p>
                            </div>

                            <div className="my-5 ml-2">
                                <h3 className="my-2 font-bold">
                                    <Trans i18nKey="help.6.q2" />
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.6.a2" />
                                </p>
                            </div>

                            <div className="my-5 ml-2">
                                <h3 className="my-2 font-bold">
                                    <Trans i18nKey="help.6.q3" />
                                </h3>
                                <p className="">
                                    <Trans i18nKey="help.6.a3.p1" />
                                </p>
                                <ul className="ml-5 list-disc">
                                    <li>
                                        <Trans i18nKey="help.6.a3.li1" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.6.a3.li2" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.6.a3.li3" />
                                    </li>
                                    <li>
                                        <Trans i18nKey="help.6.a3.li4" />
                                    </li>
                                </ul>
                                <p>
                                    <Trans i18nKey="help.6.a3.p2" />
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Help;
