import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import { Trans, useTranslation } from "react-i18next";
import ja from "date-fns/locale/ja";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { LICENSE_DETAIL_ROUTE, MYPAGE_ROUTE, USER_LICENSE_ROUTE } from "../consts/routes";
import { useUserContext } from "../contexts/UserContext";
import { getProduct } from "../clients/ProductClient";
import { CheckmarkCircleIcon, CrossCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import { Product } from "../types/Product";
import { convertUtcToLocalDate, formatDateTime, getYearsLaterDate } from "../utils/DateUtils";
import { getDfScannerNum, getDfLatNum } from "../utils/ProductUtils";
import { PaymentHistory } from "../types/PaymentHistory";
import { License } from "../types/License";
import { Agency } from "../types/Agency";
import { getAgencies } from "../clients/AgencyClient";
import { licenseUpdateRequest } from "../clients/PaymentClient";
import { Helmet } from "react-helmet";

interface LocationState {
    license: License;
    paymentHistory: PaymentHistory;
}

/**
 * ライセンス購入確認画面（更新リクエスト時）
 * @returns JSX.Element
 */
const ProductConfirmForUpdateRequest = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { paymentHistory, license } = location.state as LocationState;
    const { user, getRecentToken } = useUserContext();

    const [agencyList, setAgencyList] = useState<Agency[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [selectedAgency, setSelelctedAgency] = useState<Agency | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const [isExipred, setIsExpired] = useState<Boolean>(false);
    const [dfscannerNum, setDfScannerNum] = useState<number>(0);
    const [dflatNum, setDflatNum] = useState<number>(0);

    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    registerLocale("ja", ja);

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * 代理店情報の取得
     *
     * @returns
     */
    async function fetchAgencies() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]代理店情報一覧取得
            const response: Agency[] = await getAgencies(idToken);
            setAgencyList(response);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    /**
     * 商品情報の取得処理
     * @returns
     */
    async function fetchProduct() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]商品情報取得
            const response: Product = await getProduct(idToken, paymentHistory.product_id);
            setSelectedProduct(response);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!effectRun.current) {
            // 前回購入履歴の商品IDで商品マスタを検索
            fetchProduct();
            // 代理店マスタの情報を取得
            fetchAgencies();
            return () => {
                effectRun.current = true;
            };
        }
    }, [fetchProduct]);

    // 選択した商品から代理店情報を特定する
    useEffect(() => {
        if (selectedProduct && agencyList) {
            setSelelctedAgency(agencyList.find((a) => a.agency_id === selectedProduct.agency_id) || null);
        }
    }, [selectedProduct, agencyList]);

    // 選択ライセンスの有効期限確認
    useEffect(() => {
        if (new Date() > convertUtcToLocalDate(license.expire_date)) {
            // 有効期限切れの場合 -> 利用開始日は現在日時をセット（選択不可）
            setIsExpired(true);
            setSelectedDate(new Date());
        } else {
            // 有効期限前の場合 -> 利用開始日として有効期限日時をセット（選択不可）
            setIsExpired(false);
            setSelectedDate(convertUtcToLocalDate(license.expire_date));
        }
    }, []);

    // ライセンスキー発行数の自動計算
    useEffect(() => {
        if (selectedProduct) {
            setDfScannerNum(getDfScannerNum(selectedProduct));
            setDflatNum(getDfLatNum(selectedProduct));
        }
    }, [selectedProduct]);

    /**
     * ライセンス詳細へ戻る押下時のイベント処理
     */
    const handleBackToLicenseDetail = () => {
        navigate(LICENSE_DETAIL_ROUTE, { state: { license: license } });
    };

    /**
     * 送信押下時のイベント処理
     */
    const handleForwardToPayment = async () => {
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            if (!selectedAgency) {
                return;
            }
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]ライセンス更新依頼通知
            await licenseUpdateRequest(
                idToken,
                user.attributes.sub,
                paymentHistory.payment_history_id,
                formatDateTime(selectedDate),
                selectedAgency.agency_id,
            );
            // 処理成功メッセージをセット
            setSuccessMessage(t("ProductConfirmForUpdateRequest.sendSuccess"));
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToSendRequest"));
            return;
        } finally {
            setIsProcessing(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("ProductConfirmForUpdateRequest.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto max-w-3xl flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("ProductConfirmForUpdateRequest.productConfirmTitle")}</h1>

                {/* 成功メッセージ */}
                {successMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CheckmarkCircleIcon />
                        <span className="pl-2 text-center text-xl font-bold text-green-500">
                            {successMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center p-2">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="p-2 font-bold text-lime-800">Loading...</p>
                    </div>
                ) : (
                    <>
                        {!successMessage && (
                            <>
                                {/* リクエスト内容表示パネル */}
                                <div className="m-0 rounded-lg border border-gray-200 bg-yellow-50 p-2 shadow sm:m-2">
                                    <p className="mx-auto py-2 text-center text-base font-bold">
                                        {t("ProductConfirmForUpdateRequest.requestFollowing")}
                                    </p>
                                    {/* 商品名 */}
                                    <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                                        <span className="">{t("CommonLabel.productName")}:&nbsp;&nbsp;&nbsp;</span>
                                        <div id="product-name" className="">
                                            {selectedProduct
                                                ? i18n.language === "ja"
                                                    ? selectedProduct.product_name_jp
                                                    : selectedProduct.product_name_en
                                                : t("CommonLabel.unselected")}
                                        </div>
                                    </div>

                                    {/* ライセンス発行数 */}
                                    {selectedProduct && dfscannerNum > 0 ? (
                                        <div
                                            id="dfscanner-num"
                                            className="flex items-center px-10 font-bold text-gray-600"
                                        >
                                            &nbsp;&nbsp;*
                                            <Trans
                                                i18nKey={"ProductConfirmForUpdateRequest.updateLicensesNum"}
                                                values={{ productName: "DF Scanner", licenseNum: dfscannerNum }}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {selectedProduct && dflatNum > 0 ? (
                                        <div id="dflat-num" className="flex items-center px-10 font-bold text-gray-600">
                                            &nbsp;&nbsp;*
                                            <Trans
                                                i18nKey={"ProductConfirmForUpdateRequest.updateLicensesNum"}
                                                values={{ productName: "DF LAT", licenseNum: dflatNum }}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    {/* 更新希望日時 */}
                                    <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                                        <span className="">
                                            {t("CommonLabel.updateRequestDateTime")}:&nbsp;&nbsp;&nbsp;
                                        </span>
                                        {/* 表示時のタイムゾーンはランタイム環境に依存 */}
                                        <div className="">
                                            {selectedDate ? formatDateTime(selectedDate) : t("CommonLabel.unselected")}
                                        </div>
                                        <Tooltip
                                            style={{}}
                                            className="text-xl"
                                            title={t("ProductConfirmForUpdateRequest.activationDateHelp")}
                                            arrow
                                        >
                                            <HelpOutlineIcon
                                                fontSize="small"
                                                className="mb-0.5 ml-0.5 cursor-pointer text-gray-500 hover:text-blue-500"
                                            />
                                        </Tooltip>
                                    </div>
                                </div>

                                {/* 注意事項 */}
                                <ul className="list-disc px-10 pb-2 pt-2 text-gray-800">
                                    <li>{t("ProductConfirmForUpdateRequest.chackBeforeRequestText1")}</li>
                                    <li>{t("ProductConfirmForUpdateRequest.chackBeforeRequestText2")}</li>
                                    <li>{t("ProductConfirmForUpdateRequest.chackBeforeRequestText3")}</li>
                                </ul>

                                {/* 販売元情報 */}
                                <div className="mx-8 mb-10 mt-3 border-2 border-gray-800 p-1">
                                    <div className="mx-auto text-center font-bold">
                                        {t("ProductConfirmForUpdateRequest.aboutSeller")}
                                    </div>
                                    <div className="pl-5 font-bold">{selectedAgency?.agency_name}</div>
                                    {selectedAgency?.agency_tel && (
                                        <div className="pl-5 font-bold">
                                            <Trans
                                                i18nKey={"ProductConfirmForUpdateRequest.sellerTel"}
                                                values={{ tel: selectedAgency.agency_tel }}
                                            />
                                        </div>
                                    )}
                                    {selectedAgency?.agency_email && (
                                        <div className="pl-5 font-bold">
                                            <Trans
                                                i18nKey={"ProductConfirmForUpdateRequest.sellerEmail"}
                                                values={{ email: selectedAgency?.agency_email }}
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* 戻る/送信ボタン */}
                                <div className="flex justify-evenly text-sm font-medium">
                                    <button onClick={handleBackToLicenseDetail} className="btn-secondary w-1/3">
                                        {t("ProductConfirmForUpdateRequest.backToLicenseDetail")}
                                    </button>
                                    <button
                                        id="payment-btn"
                                        onClick={handleForwardToPayment}
                                        className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                                        disabled={isProcessing}
                                    >
                                        {isProcessing ? (
                                            <div className="flex items-center justify-center">
                                                <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                                <label className="">Processing...</label>
                                            </div>
                                        ) : (
                                            t("CommonLabel.send")
                                        )}
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                )}
                {/* マイページへ戻る */}
                {successMessage && (
                    <div className="flex justify-center pt-10">
                        <Link to={MYPAGE_ROUTE}>
                            <button className="btn-secondary px-5">{t("UserLicense.backToMypage")}</button>
                        </Link>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default ProductConfirmForUpdateRequest;
