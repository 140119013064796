import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import dfviewer_icon from "./../../img/dfviewer/icon.jpg";
import dfviewer_screen from "./../../img/dfviewer/screen.png";
import dfviewer_data_visualization from "./../../img/dfviewer/data-visualization.png";
import dfviewer_data_visualization2 from "./../../img/dfviewer/data-visualization2.png";
import dfviewer_statistical_info from "./../../img/dfviewer/statistical-info.png";
import { ScrollToTop } from "../../components/ScrollToTop";
import { DOWNLOAD_ROUTE } from "../../consts/routes";
import { Helmet } from "react-helmet";

/**
 * DF Viewer詳細画面
 * @returns JSX.Element
 */
const DetailDfviewer = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("DetailDfviewer.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <img className="h-20 w-20 p-3" src={dfviewer_icon} alt="dflat" />
                        <h1 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl ">DF Viewer</h1>
                    </div>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfviewer_screen}
                            alt="DF Viewer Screen Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* 概要 */}
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfviewer.about1" />
                    </p>

                    {/* データ描画 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfviewer.dataVisualizationTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfviewer.dataVisualizationText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfviewer_data_visualization}
                            alt="DF Viewe Data Visualization Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfviewer_data_visualization2}
                            alt="DF Viewe Data Visualization Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* 統計情報の確認 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfviewer.statisticalInfoTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfviewer.statisticalInfoText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfviewer_statistical_info}
                            alt="DF Viewer Statistical Information Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* ダウンロード */}
                    <h2 id="download" className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfviewer.downloadTitle")}
                    </h2>
                    <p className="mb-2 text-gray-700 sm:text-lg md:mb-2">
                        <Link to={DOWNLOAD_ROUTE}>
                            <Trans
                                i18nKey="DetailDfviewer.downloadText"
                                components={{
                                    l: <button className="link-text" />,
                                }}
                            />
                        </Link>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DetailDfviewer;
