import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { DTM_LINK_ROUTE } from "../../consts/routes";

/**
 * Q&A画面 - 英語 -
 * @returns
 */
const QAContentEN = () => {
    const scrollWidthOffset = (el: any) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -40;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };

    return (
        <div className="mx-auto max-w-3xl">
            {/* ナビゲーションメニュー */}
            <div className="p-4">
                <nav>
                    <ul>
                        <li className="mb-4">
                            <HashLink
                                className="text-xl font-bold text-blue-500 hover:underline"
                                to={"#section1"}
                                scroll={(el) => scrollWidthOffset(el)}
                                smooth
                            >
                                Drone flight
                            </HashLink>
                            <ul className="ml-4 mt-2 text-lg">
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section1-1"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q1-1. Is there a recommended UAV?
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section1-2"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q1-2. Are there any recommended flight settings?
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section1-3"} smooth>
                                        Q1-3. How can I autopilot according to the terrain?
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section1-4"} smooth>
                                        Q1-4. Tell me about the recommended SfM software.
                                    </HashLink>
                                </li>
                            </ul>
                        </li>
                        <li className="mb-4">
                            <HashLink
                                className="text-xl font-bold text-blue-500 hover:underline"
                                to={"#section2"}
                                scroll={(el) => scrollWidthOffset(el)}
                                smooth
                            >
                                DF Scanner
                            </HashLink>
                            <ul className="ml-4 mt-2 text-lg">
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section2-1"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q2-1. What should I do if tree species identification is not accurate?
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section2-2"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q2-2. I want to know about analysis accuracy.
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section2-3"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q2-3. I want to know about the detailed parameter settings for Tree top
                                        detection.
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section2-4"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q2-4. How can I get Digital Terrain Models (DTM) for countries other than Japan?
                                    </HashLink>
                                </li>
                            </ul>
                        </li>
                        <li className="mb-4">
                            <HashLink
                                className="text-xl font-bold text-blue-500 hover:underline"
                                to={"#section3"}
                                scroll={(el) => scrollWidthOffset(el)}
                                smooth
                            >
                                DF LAT
                            </HashLink>
                            <ul className="ml-4 mt-2 text-lg">
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section3-1"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q3-1. Unable to start software.
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section3-2"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q3-2. Analysis of data measured with Zenmuse L2 does not go well.
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section3-3"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q3-3. I want to perform class classification instead of creating data only for
                                        the ground area.
                                    </HashLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>

            <hr className="my-1 border-2"></hr>

            {/* 回答 */}
            <div className="">
                {/* ドローン飛行について */}
                <div className="mx-2 mt-8">
                    <h2 id="section1" className="my-2 text-center text-xl font-bold text-lime-800">
                        Drone flight
                    </h2>

                    {/* 1-1 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section1-1" className="my-2 text-lg font-bold">
                            Q1-1. Is there a recommended UAV?
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                We recommend an aircraft that can follow the terrain and measure while changing
                                altitude.
                                <br />
                                We recommend the following DJI drones.
                            </p>
                            <p className="mt-2 font-semibold">For digital cameras</p>
                            <ul className="ml-5 list-disc">
                                <li>Phantom 4 Pro RTK</li>
                                <li>Mavic 3 Enterprise RTK</li>
                            </ul>
                            <p className="mt-2 font-semibold">For LiDAR</p>
                            <ul className="ml-5 list-disc">
                                <li>Matrice 300, 350 & Zenmuse L1, L2</li>
                            </ul>
                            <p className="mt-2">
                                Even with other drones such as the Mavic 2 Pro, analysis is possible, although the
                                accuracy will be slightly lower, by observing the measurement specifications and taking
                                pictures so that the ground is visible. Please refer to the question below for
                                measurement specifications.
                            </p>
                        </div>
                    </div>

                    {/* 1-2 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section1-2" className="my-2 text-lg font-bold">
                            Q1-2. Are there any recommended flight settings?
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">The following are the recommended conditions.</p>
                            <table className="mx-5 mt-2 border">
                                <tr>
                                    <td className="col-span-2 px-2">Recommended Measurement Specifications </td>
                                </tr>
                                <tr>
                                    <td className="border px-2">Ground Speed </td>
                                    <td className="border px-2">5m/s</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">Ground Resolution</td>
                                    <td className="border px-2">2~3cm</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">Ground Altitude</td>
                                    <td className="border px-2">80~100m※</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">Front Overlap</td>
                                    <td className="border px-2">85% or more</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">Sidelap</td>
                                    <td className="border px-2">75% or more</td>
                                </tr>
                            </table>
                            <p className="mt-2">
                                *The Ground Altitude needs to be changed according to the ground resolution depending on
                                the installed camera. If the camera is equipped with 20 million pixels, it will be
                                measured from about 100 m, and if the camera is 12 million pixels, it will be measured
                                from about 80 m.
                            </p>
                            <p className="">DJI's general phantom 4 and mavic 2-3 series have 20 million pixels.</p>
                            <table className="mx-5 mt-6 border">
                                <tr>
                                    <td className="col-span-2 px-2">Recommended measurement timing</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">Season</td>
                                    <td className="border px-2">Green leaf season (May to September in Japan)</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">Weather</td>
                                    <td className="border px-2">Cloudy</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">Timing</td>
                                    <td className="border px-2">Around 10:00 to 14:00 (for Japan)</td>
                                </tr>
                            </table>
                            <p className="mt-2">
                                We recommend shooting on cloudy or sunny days between 10:00 and 2:00 p.m., when there
                                are few shadows.
                            </p>
                        </div>
                    </div>

                    {/* 1-3 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section1-3" className="my-2 text-lg font-bold">
                            Q1-3. How can I autopilot according to the terrain?
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="font-bold text-orange-600">
                                *The following answers are only for cases within Japan.
                            </p>
                            <p className="mt-2">
                                In Japan, by using the 10mDEM released by the Geospatial Information Authority of Japan,
                                it is possible to configure flight settings according to the terrain.
                            </p>
                            <a
                                className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                href={"https://fgd.gsi.go.jp/download/menu.php"}
                                target="_blank"
                            >
                                参考) 基盤地図情報 ダウンロードサービス
                            </a>
                            <p className="mt-2">
                                RTK-compatible drones such as the Matrice 350 can be flown according to terrain data
                                using the DJI app. The flow will be as follows.
                            </p>
                            <ul className="ml-5 list-decimal">
                                <li>
                                    Convert Geospatial Information Authority of Japan DEM to tif.
                                    <br />
                                    This can be converted from DF Scanner's File＞Import＞Import Geographical Survey
                                    Institute DEM.
                                </li>
                                <li>
                                    Adding geoid height
                                    <br />
                                    You can check the geoid height of the target area from the site below. Add the geoid
                                    height to the tif data by raster calculation using GIS software such as QGIS.
                                    <br />
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={"https://vldb.gsi.go.jp/sokuchi/surveycalc/geoid/calcgh/calc_f.html"}
                                        target="_blank"
                                    >
                                        参考) ジオイド高計算
                                    </a>
                                </li>
                                <li>
                                    Loading in DJI Pilot app
                                    <br />
                                    There is a section called "Import DSM" in the flight settings, so by loading the
                                    data transferred to the microSD, you can set the flight to match the Geospatial
                                    Information Authority of Japan DEM.
                                </li>
                            </ul>
                            <p className="mt-2">
                                For devices that do not support RTK, such as the Mavic 2 Pro, you may be able to fly
                                them according to the terrain by using a third-party app.
                            </p>
                            <p className="mt-2">
                                The following is an introduction to automatic flight settings when using UGCS.
                                <br />
                                <a
                                    className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                    href={"/pdf/UGCSを用いた自動飛行設定.pdf"}
                                    target="_blank"
                                >
                                    参考) UGCSを用いた自動飛行設定.pdf
                                </a>
                                &nbsp;&nbsp; *only Japanese
                            </p>
                        </div>
                        <p className=""></p>
                    </div>

                    {/* 1-4 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section1-4" className="my-2 text-lg font-bold">
                            Q1-4. Tell me about the recommended SfM software.
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">We recommend Pix4D, Metashape, and DJI Terra.</p>
                        </div>
                    </div>
                </div>

                {/* DF Scannerについて */}
                <div className="mx-2 mt-8">
                    <h2 id="section2" className="my-2 text-center text-xl font-bold text-lime-800">
                        DF Scanner
                    </h2>

                    {/* 2-1 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section2-1" className="my-2 text-lg font-bold">
                            Q2-1. What should I do if tree species identification is not accurate?
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                Data measured using measurements other than the recommended measurement specifications
                                or timing may not be accurate. In that case, please create your own training data using
                                site tuning to improve identification accuracy.
                            </p>
                            <p className="mt-2">
                                Additionally, in data taken in winter, trees may be dark or red in color, and
                                identification accuracy may be low.
                            </p>
                            <p className="mt-2">
                                We are currently working on countermeasures to reduce the accuracy of dark data, and we
                                plan to be able to identify dark data with a certain degree of accuracy by the end of
                                2024.
                            </p>
                        </div>
                    </div>

                    {/* 2-2 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section2-2" className="my-2 text-lg font-bold">
                            Q2-2. I want to know about analysis accuracy.
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="font-bold text-orange-600">
                                *The following answers are only for cases within Japan.
                            </p>
                            <p className="mt-2 font-semibold">About tree species identification</p>
                            <p className="">
                                The accuracy is 92% for cedar and cypress, and about 60% for other representative
                                vegetation such as quercus, beech, Japanese chinquapin, and fir by setting the target
                                vegetation. There is still little data on Hokkaido species such as larch and fir, and
                                there are no accuracy figures.
                            </p>
                            <p className="mt-2 font-semibold">About tree height estimation</p>
                            <p className="">
                                When measuring with a laser drone, it is said that the error is generally about 50 cm to
                                1 m. When CHM is created from the DSM created with SfM and the 5m resolution DEM data of
                                the Geospatial Information Authority of Japan, the error of the Geospatial Information
                                Authority of Japan DEM and the error in the XY direction of the drone are affected, and
                                the average error is about 1-3m. will occur. If the Geospatial Information Authority of
                                Japan DEM is 10m or if CHM correction cannot be done well, the error will be even
                                larger.
                            </p>
                            <p className="mt-2 font-semibold">About breast height diameter estimation</p>
                            <p className="">
                                The diameter at breast height is estimated from the tree height and crown area. In the
                                case of cedar and cypress, the estimation accuracy is 80% on average.
                            </p>
                            <p className="mt-2 font-semibold">About stem volume estimation</p>
                            <p className="">
                                Stem volume is estimated from the tree species, tree height, and diameter at breast
                                height, and the number of trees is important overall. Therefore, it will vary depending
                                on the measurement and analysis accuracy of the above items.
                            </p>
                        </div>
                    </div>

                    {/* 2-3 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section2-3" className="my-2 text-lg font-bold">
                            Q2-3. I want to know about the detailed parameter settings for Tree top detection.
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                Two types of parameters can be set in the advanced settings for Tree top detection.※Only
                                for DF Scanner Pro
                            </p>
                            <p className="mt-2 font-semibold">Smoothing Kernel Size</p>
                            <p>
                                Represents the degree to which fine undulations in the image are removed; the higher the
                                value, the more fine undulations will be ignored.
                            </p>
                            <p className="mt-2 font-semibold">Local Maximum Filter Kernel Size (LMF Kernel Size)</p>
                            <p>
                                Represents the frequency of detecting tree vertices (the interval between detected
                                trees); the larger the value, the coarser the number of detections.
                            </p>
                            <p className="mt-2">
                                For many forests, the default values (smoothing kernel size: 0.8, LMF kernel size: 1.2)
                                are effective, but detection accuracy may be improved by adjusting the parameters
                                depending on tree species and tree density.
                            </p>
                            <ul className="ml-5 list-disc">
                                <li>When adjusting the value, we recommend changing it in increments of 0.1 to 0.2.</li>
                                <li>The following is an example of a setting in a major coniferous forest in Japan.</li>
                            </ul>
                            <table className="mx-5 mt-2 border">
                                <tr>
                                    <th className="border px-2"></th>
                                    <th className="border px-2">Smoothing Kernel Size</th>
                                    <th className="border px-2">LMF Kernel Size</th>
                                </tr>
                                <tr>
                                    <td className="border px-2">cryptomeria japonica</td>
                                    <td className="border px-2">0.8</td>
                                    <td className="border px-2">1.2</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">chamaecyparis obtusa</td>
                                    <td className="border px-2">0.8</td>
                                    <td className="border px-2">1.2</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">larix kaempferi</td>
                                    <td className="border px-2">2.0</td>
                                    <td className="border px-2">1.2</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">abies sachalinensis</td>
                                    <td className="border px-2">2.0</td>
                                    <td className="border px-2">1.2</td>
                                </tr>
                            </table>
                            <p className="mt-2">
                                If you would like to know more about each parameter, please check the following
                                materials.
                                <br />
                                <a
                                    className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                    href={"/pdf/樹頂点検出のパラメータについて.pdf"}
                                    target="_blank"
                                >
                                    参考) 樹頂点検出のパラメータについて.pdf
                                </a>
                                &nbsp;&nbsp; *only Japanese
                            </p>
                        </div>
                    </div>

                    {/* 2-4 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section2-4" className="my-2 text-lg font-bold">
                            Q2-4. How can I get Digital Terrain Models (DTM) for countries other than Japan?
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                For CHM calculations, in Japan you can specify the Geospatial Information Authority of
                                Japan data as DTM data, but if you are using it overseas, you will need to prepare the
                                DTM data yourself.
                            </p>
                            <p className="mt-2 font-semibold">Using a LiDAR drone</p>
                            <p className="">
                                DTM data can be easily created by analyzing measured point cloud data with DF LAT.
                            </p>
                            <p className="mt-2 font-semibold">Using a camera drone</p>
                            <p className="">
                                In principle, it is difficult to obtain forest ground information. However, if the image
                                has a large amount of ground, it may be possible to create ground data using SfM
                                software.
                            </p>

                            <p className="mt-2 font-semibold">Measure by yourself</p>
                            <p className="">
                                It is possible to analyze using data published on the Internet. However, please note the
                                following points when using the data.
                            </p>
                            <ul className="ml-5 list-disc">
                                <li>
                                    DF Scanner only supports GeoTIFF format (.tif/.tiff) data. If your data is in a
                                    different format, please convert it before use.
                                </li>
                                <li>
                                    When downloading data, please ensure you review their terms of use and proceed at
                                    your own risk.
                                </li>
                                <li>
                                    Data published on the Internet is often of low resolution. Analysis with DF Scanner
                                    is possible, but the accuracy may not be sufficient.
                                </li>
                                <li>
                                    After CHM calculation, be sure to check the height of the ground part and perform
                                    ground correction operations if necessary.
                                </li>
                            </ul>
                            <p className="mt-2">
                                <Link to={DTM_LINK_ROUTE} className="link-text-blue">
                                    Reference) DTM download page list
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>

                {/* DF LATについて */}
                <div className="mx-2 mt-8">
                    <h2 id="section3" className="my-2 text-center text-xl font-bold text-lime-800">
                        DF LAT
                    </h2>

                    {/* 3-1 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section3-1" className="my-2 text-lg font-bold">
                            Q3-1. Unable to start software.
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                This bug has been fixed in Ver 1.0.1. If you still cannot start, please contact us.
                            </p>
                        </div>
                    </div>

                    {/* 3-2 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section3-2" className="my-2 text-lg font-bold">
                            Q3-2. Analysis of data measured with Zenmuse L2 does not go well.
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                Compared to Zenmuse L1, the data acquired with L2 has fewer reflection points on the
                                tree canopy surface, and it is known that DSM is more likely to create holes. Therefore,
                                please change the resolution to 10cm or 15cm when creating DSM.
                            </p>
                        </div>
                    </div>

                    {/* 3-3 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section3-3" className="my-2 text-lg font-bold">
                            Q3-3. I want to perform class classification instead of creating data only for the ground
                            area.
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">We are currently in the process of developing this.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QAContentEN;
