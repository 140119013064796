import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import {
    EMAIL_MAX_LENGTH,
    FAMILY_NAME_MAX_LENGTH,
    GIVEN_NAME_MAX_LENGTH,
    ORGANIZATION_MAX_LENGTH,
    USER_TYPE_LICENSED_ADMIN,
    USER_TYPE_LICENSED_USER,
    USER_TYPE_MANAGER,
} from "../consts/constants";
import { USER_LICENSE_ROUTE } from "../consts/routes";
import { parseStringToInt } from "../utils/ValidateUtils";
import { useUserContext } from "../contexts/UserContext";
import { createUser } from "../clients/UserClient";
import { CrossCircleIcon } from "../components/Icons";
import { E001, E101 } from "../consts/ErrorCodes";
import { ScrollToTop } from "../components/ScrollToTop";
import { LicensedUserUnauthorized } from "../components/LicensedUserUnauthorized";
import { Helmet } from "react-helmet";

/**
 * ユーザー作成画面
 * @returns JSX.Element
 */
const UserCreate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, userType, getRecentToken } = useUserContext();
    const [selectedUserType, setSelectedUserType] = useState<number>(USER_TYPE_LICENSED_USER);
    const [email, setEmail] = useState<string>("");
    const [familyName, setfamilyName] = useState<string>("");
    const [givenName, setGivenName] = useState<string>("");
    const [organization, setOrganization] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * ユーザータイプ変更時のイベント処理
     * @param event
     */
    const handleUserTypeChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSelectedUserType(parseStringToInt(event.target.value));
    };

    /**
     * メールアドレス入力時のイベント処理
     * @param event
     */
    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setEmail(event.target.value);
    };

    /**
     * 姓入力時のイベント処理
     * @param event
     */
    const handleFamilyNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setfamilyName(event.target.value);
    };

    /**
     * 名入力時のイベント処理
     * @param event
     */
    const handleGivenNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setGivenName(event.target.value);
    };

    /**
     * 所属入力時のイベント処理
     * @param event
     */
    const handleOrganizationChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setOrganization(event.target.value);
    };

    /**
     * 作成押下時のイベント処理
     * @param event
     */
    const handleCreateUser: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]ユーザー情報登録（Cognito更新True）
            await createUser(
                idToken,
                undefined,
                email,
                selectedUserType,
                familyName,
                givenName,
                organization,
                user.attributes.sub,
                true,
            );

            // 処理成功時はユーザー・ライセンス管理画面へ
            navigate(USER_LICENSE_ROUTE);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.code) {
                const errorCode = error.response.data.code;
                if (errorCode === E001) {
                    // 入力値異常
                    setErrorMessage(t("ErrorMessage.invalidParameter"));
                } else if (errorCode === E101) {
                    // ユーザーが既に登録済み
                    setErrorMessage(t("ErrorMessage.emailInUse"));
                } else {
                    // その他エラー
                    setErrorMessage(t("ErrorMessage.failToCreateUser"));
                }
            } else {
                setErrorMessage(t("ErrorMessage.failToCreateUser"));
            }
            window.scrollTo(0, 0);
            return;
        } finally {
            setIsProcessing(false);
        }
    };

    /**
     * 戻る押下時のイベント処理
     */
    const handleBack = () => {
        navigate(USER_LICENSE_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("UserCreate.meta.title")} />
            <ScrollToTop />
            <LicensedUserUnauthorized />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("UserCreate.userCreateTitle")}</h1>
                <ul className="mx-auto ml-10 list-disc text-gray-700">
                    <li>{t("UserCreate.userCreateText1")}</li>
                    <li>{t("UserCreate.userCreateText2")}</li>
                </ul>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                <form onSubmit={handleCreateUser}>
                    <div className="m-0 grid grid-cols-3 items-center justify-center pb-4 pt-2 sm:m-2">
                        {/* ユーザータイプ */}
                        <div className="col-span-1 py-2">
                            <label htmlFor="email" className="block font-medium text-gray-900">
                                {t("CommonLabel.userType")}
                            </label>
                        </div>
                        <div className="col-span-2 py-2">
                            <select
                                name="user_type"
                                id="user_type"
                                className="textbox-common"
                                value={selectedUserType}
                                onChange={handleUserTypeChange}
                            >
                                {userType != null && userType !== USER_TYPE_MANAGER && (
                                    // マネージャーの場合、管理ユーザーは作成できないようにする
                                    <option value={USER_TYPE_LICENSED_ADMIN}>{t("UserType.1")}</option>
                                )}
                                <option value={USER_TYPE_LICENSED_USER}>{t("UserType.2")}</option>
                            </select>
                        </div>

                        {/* メールアドレス */}
                        <div className="col-span-1 py-2">
                            <label htmlFor="email" className="block font-medium text-gray-900">
                                {t("CommonLabel.email")}
                            </label>
                        </div>
                        <div className="col-span-2 py-2">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="name@example.com"
                                className="textbox-common"
                                onChange={handleEmailChange}
                                maxLength={EMAIL_MAX_LENGTH}
                                required
                            />
                        </div>

                        {/* 氏名 */}
                        <div className="col-span-1 py-2">
                            <p className="block font-medium text-gray-900">{t("CommonLabel.fullName")}</p>
                        </div>
                        <div className="col-span-2 flex flex-row items-center justify-center space-x-2 py-2">
                            <div>
                                <label htmlFor="lname" className="sr-only">
                                    {t("CommonLabel.familyName")}
                                </label>
                                <input
                                    type="text"
                                    name="lname"
                                    id="lname"
                                    placeholder={t("CommonLabel.familyName")}
                                    className="textbox-common"
                                    onChange={handleFamilyNameChange}
                                    maxLength={FAMILY_NAME_MAX_LENGTH}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="fname" className="sr-only">
                                    {t("CommonLabel.givenName")}
                                </label>
                                <input
                                    type="text"
                                    name="fname"
                                    id="fname"
                                    placeholder={t("CommonLabel.givenName")}
                                    className="textbox-common"
                                    onChange={handleGivenNameChange}
                                    maxLength={GIVEN_NAME_MAX_LENGTH}
                                    required
                                />
                            </div>
                        </div>

                        {/* 所属 */}
                        <div className="col-span-1 py-1">
                            <label htmlFor="organization" className="block font-medium text-gray-900">
                                {t("CommonLabel.organization")}
                            </label>
                        </div>
                        <div className="col-span-2 py-2">
                            <input
                                type="text"
                                name="organization"
                                id="organization"
                                placeholder={t("CommonLabel.organizationExample")}
                                className="textbox-common"
                                onChange={handleOrganizationChange}
                                maxLength={ORGANIZATION_MAX_LENGTH}
                                required
                            />
                        </div>
                    </div>

                    {/* 戻る/作成ボタン */}
                    <div className="flex items-center justify-evenly">
                        <button type="button" onClick={handleBack} className="btn-secondary w-1/3 ">
                            {t("CommonLabel.back")}
                        </button>
                        <button
                            type="submit"
                            className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                            disabled={isProcessing}
                        >
                            {isProcessing ? (
                                <div className="flex items-center justify-center">
                                    <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                    <label className="">Processing...</label>
                                </div>
                            ) : (
                                t("CommonLabel.create")
                            )}
                        </button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default UserCreate;
