import {
    EUR,
    JPY,
    PRODUCT_TYPE_ID_DF_LAT,
    PRODUCT_TYPE_ID_DF_LAT_LITE,
    PRODUCT_TYPE_ID_DF_LAT_PRO,
    PRODUCT_TYPE_ID_DF_SCANNER,
    PRODUCT_TYPE_ID_DF_SCANNER_DF_LAT,
    PRODUCT_TYPE_ID_DF_SCANNER_LITE,
    PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_LITE,
    PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_PRO,
    PRODUCT_TYPE_ID_DF_SCANNER_PRO,
    PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_LITE,
    PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_PRO,
    USD,
} from "../consts/constants";
import { Product } from "../types/Product";

/**
 * 商品オブジェクトから指定した通貨の小売価格を取得する
 *
 * 不明な通貨が指定された場合は0で返す
 * @param product 商品オブジェクト
 * @param currency 通貨（JPY/USD/EUR）
 * @returns 小売価格
 */
export function getRetailPrice(product: Product, currency: string): number {
    let retailPrice = 0;
    if (currency === JPY) {
        retailPrice = product.retail_price_jpy;
    } else if (currency === USD) {
        retailPrice = product.retail_price_usd;
    } else if (currency === EUR) {
        retailPrice = product.retail_price_eur;
    }
    return retailPrice;
}

/**
 * 商品オブジェクトから指定した通貨のStripe価格IDを取得する
 *
 * 不明な通貨が指定された場合はnullで返す
 * @param product 商品オブジェクト
 * @param currency 通貨（JPY/USD/EUR）
 * @returns Stripe価格ID
 */
export function getStripePriceId(product: Product, currency: string): string | null {
    let stripePriceId = null;
    if (currency === JPY) {
        stripePriceId = product.stripe_price_id_jpy;
    } else if (currency === USD) {
        stripePriceId = product.stripe_price_id_usd;
    } else if (currency === EUR) {
        stripePriceId = product.stripe_price_id_eur;
    }
    return stripePriceId;
}

/**
 * 指定した商品オブジェクト購入時に発行されるDF Scannerのライセンス数を返す
 * @param product 商品オブジェクト
 * @returns DF Scannerライセンス発行数
 */
export function getDfScannerNum(product: Product): number {
    if (
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_DF_LAT ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_PRO ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_LITE ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_PRO ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_LITE ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_LITE ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_PRO
    ) {
        return product.quantity;
    } else {
        return 0;
    }
}

/**
 * 指定した商品オブジェクト購入時に発行されるDF LATのライセンス数を返す
 * @param product 商品オブジェクト
 * @returns DF LATライセンス発行数
 */
export function getDfLatNum(product: Product): number {
    if (
        product.product_type_id === PRODUCT_TYPE_ID_DF_LAT ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_DF_LAT ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_LAT_PRO ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_LAT_LITE ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_PRO ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_LITE ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_LITE ||
        product.product_type_id === PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_PRO
    ) {
        return product.quantity;
    } else {
        return 0;
    }
}

/**
 * 通貨表記変換
 * @param price 金額
 * @param currency 通貨
 * @returns 日本円表記
 */
export function formatPrice(price: number, currency: string): string {
    // ロケール情報をホストの情報から取得
    const locale = navigator.language || "ja-JP";
    return price.toLocaleString(locale, {
        style: "currency",
        currency: currency,
    });
}
