import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { ScrollToTop } from "../components/ScrollToTop";
import { HOME_ROUTE } from "../consts/routes";
import { CheckmarkCircleIcon, CrossCircleIcon } from "../components/Icons";
import { sendPurchaseInquiryEmail } from "../clients/SendEmailClient";
import { loggingWarn } from "../utils/LoggingUtils";
import i18n from "../i18n/configs";
import { Helmet } from "react-helmet";

/**
 * ライセンス購入フォーム画面
 * @returns JSX.Element
 */
const LicensePurchaseForm = () => {
    const { t } = useTranslation();

    const [name, setName] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [selectedPurpose, setSelectedPurpose] = useState<string | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
    const [selectedLicense, setSelectedLicense] = useState<string | null>(null);
    const [quantity, setQuantity] = useState<number>(1);
    const [message, setMessage] = useState<string>("");
    const [knownFrom, setKnownFrom] = useState<string>("");
    const [knownFromDetails, setKnownFromDetails] = useState<string>("");

    const [editable, setEditable] = useState<boolean>(true);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    /** 利用目的リスト */
    const purposeOptions = [
        // 一般利用
        t("LicensePurchaseForm.purposeOption1"),
        // 一般利用（小規模林業事業体向け）
        t("LicensePurchaseForm.purposeOption2"),
        // 教育研究目的
        t("LicensePurchaseForm.purposeOption3"),
    ];

    /** 購入ソフトウェアリスト */
    const productOptions = [
        // DF Scanner Pro版
        `DF Scanner ${t("CommonLabel.pro")}`,
        // DF Scanner Lite版
        `DF Scanner ${t("CommonLabel.lite")}`,
        // DF LAT Pro版
        `DF LAT ${t("CommonLabel.pro")}`,
        // DF LAT Lite版
        `DF LAT ${t("CommonLabel.lite")}`,
        // DF Scanner Pro版 & DF LAT Pro版
        `DF Scanner ${t("CommonLabel.pro")} & DF LAT ${t("CommonLabel.pro")}`,
        // DF Scanner Lite版 & DF LAT Lite版
        `DF Scanner ${t("CommonLabel.lite")} & DF LAT ${t("CommonLabel.lite")}`,
    ];

    /** ライセンスタイプリスト */
    const licenseOptions = [
        // 年間
        t("LicensePurchaseForm.licenseOption1"),
        // 月間
        t("LicensePurchaseForm.licenseOption2"),
        // その他
        t("LicensePurchaseForm.licenseOption3"),
    ];

    /** 弊社サービスをどこで知りましたか？リスト */
    const knownFromOptions = [
        // インターネット検索
        t("KnownFromOptions.internet"),
        // 口コミ
        t("KnownFromOptions.reviews"),
        // 広告
        t("KnownFromOptions.advertisement"),
        // イベントや展示会
        t("KnownFromOptions.event"),
        // ニュース記事
        t("KnownFromOptions.news"),
        // 既存の顧客
        t("KnownFromOptions.existing"),
        // その他
        t("KnownFromOptions.other"),
    ];

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * 名前入力イベント処理
     * @param event
     */
    const handleNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setName(event.target.value);
    };

    /**
     * 会社名入力イベント処理
     * @param event
     */
    const handleCompanyChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setCompany(event.target.value);
    };

    /**
     * メールアドレス入力イベント処理
     * @param event
     */
    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setEmail(event.target.value);
    };

    /**
     * ラジオボタン（利用目的）選択イベント処理
     * @param option
     */
    const handlePurposeChange = (option: string) => {
        setSelectedPurpose(option);
    };

    /**
     * ラジオボタン（購入ソフトウェア）選択イベント処理
     * @param option
     */
    const handleProductChange = (option: string) => {
        setSelectedProduct(option);
    };

    /**
     * ラジオボタン（ライセンスタイプ）選択イベント処理
     * @param option
     */
    const handleLicenseChange = (option: string) => {
        setSelectedLicense(option);
    };

    /**
     * その他お問い合わせ内容入力イベント処理
     * @param event
     */
    const handleMessageChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        setMessage(event.target.value);
    };

    /**
     * [弊社サービスをどこで知りましたか]入力イベント処理
     * @param event
     */
    const handleKnownFromChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setKnownFrom(event.target.value);
    };

    /**
     * [弊社サービスをどこで知りましたか-その他]入力イベント処理
     * @param event
     */
    const handleKnownFromDetailsChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setKnownFromDetails(event.target.value);
    };

    /**
     * 確認画面へ押下時のイベント処理
     * @param event
     */
    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        setEditable(false);
        window.scrollTo(0, 0);
    };

    /**
     * 内容を修正する押下時のイベント処理
     * @param event
     */
    const handleToEdit: FormEventHandler<HTMLButtonElement> = (event) => {
        setErrorMessage("");
        setEditable(true);
    };

    /**
     * 送信押下時のイベント処理
     * @param event
     */
    const handleSending: FormEventHandler<HTMLButtonElement> = async (event) => {
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            const enableSending = process.env.REACT_APP_SEND_INQUIRY_EMAIL;
            if (enableSending && enableSending.toLowerCase() === "true") {
                // メール送信フラグ「REACT_APP_SEND_INQUIRY_EMAIL」がtrueの場合のみ送信する。
                const knownFromText =
                    knownFrom == t("KnownFromOptions.other") ? knownFrom + " (" + knownFromDetails + ")" : knownFrom;
                const request = {
                    name: name ?? "",
                    company: company ?? "",
                    email: email ?? "",
                    purpose: selectedPurpose ?? "",
                    software: selectedProduct ?? "",
                    license: selectedLicense ?? "",
                    number: quantity,
                    other: message ?? "",
                    known_from: knownFromText ?? "",
                };
                await sendPurchaseInquiryEmail(request);
                setSuccessMessage(t("LicensePurchaseForm.successMessage"));
            } else {
                loggingWarn("Skip sending email for test mode.");
                setErrorMessage(t("ErrorMessage.failToSendForm"));
            }
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToSendForm"));
            return;
        } finally {
            setIsProcessing(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("LicensePurchaseForm.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <h1 className="mb-4 mt-2 text-center text-2xl font-bold text-gray-800 sm:text-3xl">
                            {t("LicensePurchaseForm.title")}
                        </h1>
                    </div>

                    {/* 成功メッセージ */}
                    {successMessage && (
                        <>
                            <div className="flex items-center justify-center pt-4">
                                <CheckmarkCircleIcon />
                                <span className="pl-2 text-center text-xl font-bold text-green-500">
                                    {successMessage.split("\n").map((line, index) => (
                                        <div key={index}>{line}</div>
                                    ))}
                                </span>
                            </div>
                            <div className="flex items-center justify-center pt-2">
                                {t("LicensePurchaseForm.thanksMessage1")}
                            </div>
                            <div className="flex items-center justify-center">
                                {t("LicensePurchaseForm.thanksMessage2")}
                            </div>
                            {/* ホーム画面へ戻る */}
                            <div className="mt-8 flex items-center justify-evenly">
                                <Link to={HOME_ROUTE}>
                                    <button type="button" className="btn-secondary w-[230px]">
                                        {t("LicensePurchaseForm.backToHome")}
                                    </button>
                                </Link>
                            </div>
                        </>
                    )}

                    {!successMessage && (
                        <>
                            {/* エラーメッセージ */}
                            {errorMessage && (
                                <div className="flex items-center justify-center p-2">
                                    <CrossCircleIcon />
                                    <span className="pl-1 text-center font-bold text-red-600">
                                        {errorMessage.split("\n").map((line, index) => (
                                            <div key={index}>{line}</div>
                                        ))}
                                    </span>
                                </div>
                            )}

                            {/* 上部メッセージ */}
                            {editable ? (
                                <div className="my-2">
                                    <p className="my-1">{t("LicensePurchaseForm.forQuotation")}</p>
                                    <p className="my-1">{t("LicensePurchaseForm.anyOtherQuestions")}</p>
                                </div>
                            ) : (
                                <p className="my-2">{t("LicensePurchaseForm.sendWithTheFollowingContents")}</p>
                            )}
                            <p className="my-3 font-bold text-orange-500">{t("LicensePurchaseForm.requiredItem")}</p>

                            <form className="" onSubmit={handleSubmit}>
                                {/* 氏名 */}
                                <div className="pt-4">
                                    <label htmlFor="name" className="font-medium text-gray-900">
                                        {t("CommonLabel.fullName")}
                                    </label>
                                    <label className="pl-1 font-bold text-orange-500">*</label>
                                </div>
                                {editable ? (
                                    <div className="pb-2">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="textbox-common rounded-none text-base"
                                            onChange={handleNameChange}
                                            required
                                            value={name}
                                        />
                                    </div>
                                ) : (
                                    <div className="pb-2">{name}</div>
                                )}

                                {/* 会社名 */}
                                <div className="pt-4">
                                    <label htmlFor="company" className="font-medium text-gray-900">
                                        {t("CommonLabel.company")}
                                    </label>
                                    <label className="pl-1 font-bold text-orange-500">*</label>
                                </div>
                                {editable ? (
                                    <div className="pb-2">
                                        <input
                                            type="text"
                                            name="company"
                                            id="company"
                                            className="textbox-common rounded-none text-base"
                                            onChange={handleCompanyChange}
                                            required
                                            value={company}
                                        />
                                    </div>
                                ) : (
                                    <div className="pb-2">{company}</div>
                                )}

                                {/* メールアドレス */}
                                <div className="pt-4">
                                    <label htmlFor="company" className="font-medium text-gray-900">
                                        {t("CommonLabel.email")}
                                    </label>
                                    <label className="pl-1 font-bold text-orange-500">*</label>
                                </div>
                                {editable ? (
                                    <div className="pb-2">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="textbox-common rounded-none text-base"
                                            onChange={handleEmailChange}
                                            required
                                            value={email}
                                        />
                                    </div>
                                ) : (
                                    <div className="pb-2">{email}</div>
                                )}

                                {/* 利用目的 */}
                                <div className="pt-4">
                                    <label className="font-medium text-gray-900">{t("CommonLabel.purposeOfuse")}</label>
                                    <label className="pl-1 font-bold text-orange-500">*</label>
                                </div>
                                {editable ? (
                                    <div className="pb-2">
                                        {purposeOptions.map((option) => (
                                            <div key={option} className="flex items-center space-x-2">
                                                <input
                                                    type="radio"
                                                    id={option}
                                                    name="purposeGroup"
                                                    checked={selectedPurpose === option}
                                                    onChange={() => handlePurposeChange(option)}
                                                    className="form-radio h-5 w-5 text-indigo-600"
                                                    required
                                                />
                                                <label htmlFor={option} className="text-gray-700">
                                                    {option}
                                                </label>
                                            </div>
                                        ))}
                                        <div className="pl-4 pt-2 text-sm text-lime-800">
                                            {t("LicensePurchaseForm.purposeAttention1")}
                                        </div>
                                        <div className="pl-4 text-sm text-lime-800">
                                            {t("LicensePurchaseForm.purposeAttention2")}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="pb-2">{selectedPurpose}</div>
                                )}

                                {/* 購入ソフトウェア */}
                                <div className="pt-4">
                                    <label className="font-medium text-gray-900">
                                        {t("CommonLabel.purchaseSoftware")}
                                    </label>
                                    <label className="pl-1 font-bold text-orange-500">*</label>
                                </div>
                                {editable ? (
                                    <div className="pb-2">
                                        {productOptions.map((option) => (
                                            <div key={option} className="flex items-center space-x-2">
                                                <input
                                                    type="radio"
                                                    id={option}
                                                    name="productGroup"
                                                    checked={selectedProduct === option}
                                                    onChange={() => handleProductChange(option)}
                                                    className="form-radio h-5 w-5 text-indigo-600"
                                                    required
                                                />
                                                <label htmlFor={option} className="text-gray-700">
                                                    {option}
                                                </label>
                                            </div>
                                        ))}
                                        <div className="pl-4 pt-2 text-sm text-lime-800">
                                            <Trans i18nKey="LicensePurchaseForm.softwareAttention1" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="pb-2">{selectedProduct}</div>
                                )}

                                {/* ライセンスタイプ */}
                                <div className="pt-4">
                                    <label className="font-medium text-gray-900">{t("CommonLabel.licenseType")}</label>
                                    <label className="pl-1 font-bold text-orange-500">*</label>
                                </div>
                                {editable ? (
                                    <div className="pb-2">
                                        {licenseOptions.map((option) => (
                                            <div key={option} className="flex items-center space-x-2">
                                                <input
                                                    type="radio"
                                                    id={option}
                                                    name="licenseGroup"
                                                    checked={selectedLicense === option}
                                                    onChange={() => handleLicenseChange(option)}
                                                    className="form-radio h-5 w-5 text-indigo-600"
                                                    required
                                                />
                                                <label htmlFor={option} className="text-gray-700">
                                                    {option}
                                                </label>
                                            </div>
                                        ))}
                                        <div className="pl-4 pt-2 text-sm text-lime-800">
                                            <Trans i18nKey="LicensePurchaseForm.licenseAttention1" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="pb-2">{selectedLicense}</div>
                                )}

                                {/* ライセンス購入数 */}
                                <div className="pt-4">
                                    <label htmlFor="quantity" className="font-medium text-gray-900">
                                        {t("CommonLabel.numberOfLicenses")}
                                    </label>
                                    <label className="pl-1 font-bold text-orange-500">*</label>
                                </div>
                                {editable ? (
                                    <>
                                        <div className="pb-2">
                                            <input
                                                type="number"
                                                id="quantity"
                                                value={quantity}
                                                onChange={(e) => setQuantity(parseInt(e.target.value, 10) || 0)}
                                                className="border border-gray-300 bg-gray-50 p-2"
                                                min={1}
                                            />
                                        </div>
                                        <div className="pl-4 pt-2 text-sm text-lime-800">
                                            <Trans i18nKey="LicensePurchaseForm.licenseNumberAttention1" />
                                        </div>
                                    </>
                                ) : (
                                    <div className="pb-2">{quantity}</div>
                                )}

                                {/* その他お問い合わせ内容 */}
                                <div className="pt-4">
                                    <label htmlFor="quantity" className="block font-medium text-gray-900">
                                        {t("CommonLabel.otherInquiries")}
                                    </label>
                                </div>
                                {editable ? (
                                    <div className="pb-2">
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={message}
                                            onChange={handleMessageChange}
                                            className="w-full border border-gray-300 bg-gray-50 p-2"
                                            required={selectedLicense == t("LicensePurchaseForm.licenseOption3")}
                                        />
                                    </div>
                                ) : (
                                    <div className="pb-2">{message}</div>
                                )}

                                {/* アンケートにご協力ください */}
                                {editable ? (
                                    <div className="flex items-center pb-4 pt-8">
                                        <div className="h-0.5 flex-grow bg-gray-500 opacity-70"></div>
                                        <span className="flex-shrink px-2 text-base font-bold text-gray-500">
                                            {t("LicensePurchaseForm.helpUsWithSurvey")}
                                        </span>
                                        <div className="h-0.5 flex-grow bg-gray-500 opacity-70"></div>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {/* 弊社サービスをどこで知りましたか？ */}
                                <div className="pt-1">
                                    <label className="block font-medium text-gray-900">
                                        {t("LicensePurchaseForm.survey1")}
                                    </label>
                                </div>
                                {editable ? (
                                    <>
                                        <div className="pb-2">
                                            <select
                                                id="knownFrom"
                                                className="textbox-common rounded-none text-base"
                                                onChange={handleKnownFromChange}
                                                defaultValue={knownFrom ? knownFrom : undefined}
                                            >
                                                {!knownFrom && (
                                                    <option hidden>{t("CommonMessage.pleaseSelect")}</option>
                                                )}
                                                {knownFromOptions.map((knownFrom, index) => (
                                                    <option key={index} value={knownFrom}>
                                                        {knownFrom}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* その他選択時に入力ボックスを表示 */}
                                        {knownFrom == t("KnownFromOptions.other") && (
                                            <>
                                                <div className="pt-1">
                                                    <label
                                                        htmlFor="survey1"
                                                        className="block font-medium text-gray-900"
                                                    >
                                                        {t("LicensePurchaseForm.surver1ForOther")}
                                                    </label>
                                                </div>
                                                <div className="pb-2">
                                                    <input
                                                        type="text"
                                                        id="knownFromDetails"
                                                        name="knownFromDetails"
                                                        value={knownFromDetails}
                                                        onChange={handleKnownFromDetailsChange}
                                                        className="w-full border border-gray-300 bg-gray-50 p-2"
                                                        required={knownFrom == t("KnownFromOptions.other")}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="pb-2">
                                            {knownFrom == t("KnownFromOptions.other")
                                                ? knownFrom + " (" + knownFromDetails + ")"
                                                : knownFrom}
                                        </div>
                                    </>
                                )}

                                {/* 送信前にご確認ください */}
                                {editable ? (
                                    <div className="flex items-center pb-4 pt-8">
                                        <div className="h-0.5 flex-grow bg-gray-500 opacity-70"></div>
                                        <span className="flex-shrink px-2 font-bold text-gray-500">
                                            {t("LicensePurchaseForm.checkBeforeSending")}
                                        </span>
                                        <div className="h-0.5 flex-grow bg-gray-500 opacity-70"></div>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {/* 注意事項 */}
                                {editable ? (
                                    <div className="mb-3">
                                        <p className="py-1 font-bold">
                                            {t("LicensePurchaseForm.aboutPersonalInformation")}
                                        </p>
                                        <ul className="list-disc">
                                            <li className="ml-4">{t("LicensePurchaseForm.attention1")}</li>
                                            <li className="ml-4">{t("LicensePurchaseForm.attention2")}</li>
                                            <li className="ml-4">
                                                {
                                                    <Trans
                                                        i18nKey="LicensePurchaseForm.attention3"
                                                        components={{
                                                            l: (
                                                                <a
                                                                    className="link-text"
                                                                    href={
                                                                        i18n.language === "ja"
                                                                            ? process.env
                                                                                  .REACT_APP_PRIVACY_POLICY_JA_PATH
                                                                            : process.env
                                                                                  .REACT_APP_PRIVACY_POLICY_EN_PATH
                                                                    }
                                                                    target="_blank"
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                }
                                            </li>
                                        </ul>
                                        {/* 利用規約チェック */}
                                        <div className="mt-4 flex flex-row items-center justify-center">
                                            <div className="pr-2">
                                                <input className="h-5 w-5" type="checkbox" required />
                                            </div>
                                            <div className="pb-1 font-bold">
                                                {t("LicensePurchaseForm.agreeAbountPersonalInformation")}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {/* 確認画面へ */}
                                {editable ? (
                                    <div className="flex items-center justify-center py-5">
                                        <button className="btn-primary w-[200px]">
                                            {t("LicensePurchaseForm.confirmation")}
                                        </button>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </form>

                            {/* 内容を修正/送信ボタン */}
                            {editable ? (
                                <></>
                            ) : (
                                <div className="mt-8 flex items-center justify-evenly">
                                    <button type="button" className="btn-secondary w-1/3" onClick={handleToEdit}>
                                        {t("LicensePurchaseForm.edit")}
                                    </button>
                                    <button
                                        type="submit"
                                        className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                                        disabled={isProcessing}
                                        onClick={handleSending}
                                    >
                                        {isProcessing ? (
                                            <div className="flex items-center justify-center">
                                                <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                                <label className="">Processing...</label>
                                            </div>
                                        ) : (
                                            t("CommonLabel.send")
                                        )}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LicensePurchaseForm;
