import { API } from "aws-amplify";
import { API_ENDPOINT_NAME } from "../consts/constants";
import { loggingInfo, loggingWarn } from "../utils/LoggingUtils";
import { PaymentHistoryWithLicenses } from "../types/PaymentHistoryWithLicenses";

/**
 * 決済ページURL作成
 * @param idToken IDトークン
 * @param stripeCustomerId Stripe顧客ID
 * @param stripePriceId Stripe価格ID
 * @param quantity 数量
 * @param productId 商品ID
 * @param successUrl 成功時のリダイレクト先URL
 * @param cancelUrl 戻る選択時の遷移URL
 * @param activationDate 利用開始日
 * @param paymentHistoryId 購入管理番号。ライセンス更新の場合は指定する。
 * @returns
 */
export async function getPaymentLinkUrl(
    idToken: string,
    stripeCustomerId: string,
    stripePriceId: string,
    quantity: number,
    productId: number,
    successUrl: string,
    cancelUrl: string,
    activationDate: string,
    paymentHistoryId?: number,
) {
    const path = "/create_payment_link";
    const body: { [name: string]: any } = {
        stripe_customer_id: stripeCustomerId,
        stripe_price_id: stripePriceId,
        quantity: quantity,
        product_id: productId,
        success_url: successUrl,
        cancel_url: cancelUrl,
        activation_date: activationDate,
    };
    if (paymentHistoryId) {
        body["payment_history_id"] = paymentHistoryId;
    }
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[GetPaymentLink] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[GetPaymentLink] Failed:", error);
        throw error;
    }
}

/**
 * Checkout API（直接）
 * @param idToken IDトークン
 * @param productId 商品ID
 * @param email メールアドレス
 * @param quantity 購入数
 * @param activationDate 利用開始日時
 * @param paymentHistoryId 購入管理番号。ライセンス更新の場合は指定する。
 * @returns 購入結果一覧
 */
export async function checkoutWithoutPayment(
    idToken: string,
    productId: number,
    email: string,
    quantity: number,
    activationDate: string,
    paymentHistoryId?: number,
) {
    const path = "/checkout_without_payment";
    const body: { [name: string]: any } = {
        product_id: productId,
        email: email,
        quantity: quantity,
        activation_date: activationDate,
    };
    if (paymentHistoryId) {
        body["payment_history_id"] = paymentHistoryId;
    }
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[CheckoutWithoutPayment] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[CheckoutWithoutPayment] Failed:", error);
        throw error;
    }
}

/**
 * ライセンス更新依頼通知
 * @param idToken IDトークン
 * @param userId ユーザーID
 * @param paymentHistoryId 前回購入時の購入管理番号
 * @param activationDate 利用開始日時
 * @param agencyId 代理店ID
 * @returns
 */
export async function licenseUpdateRequest(
    idToken: string,
    userId: string,
    paymentHistoryId: number,
    activationDate: string,
    agencyId: number,
) {
    const path = "/license_update_request";
    const body: { [name: string]: any } = {
        user_id: userId,
        payment_history_id: paymentHistoryId,
        activation_date: activationDate,
        agency_id: agencyId,
    };
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[LicenseUpdateRequest] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[LicenseUpdateRequest] Failed:", error);
        throw error;
    }
}
