import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { LICENSE_ISSUE_USER_REGISTER_ROUTE, LICENSE_ISSUE_USER_SEARCH_ROUTE, MYPAGE_ROUTE } from "../consts/routes";
import { CrossCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import { OnlyMasterUserAuthorized } from "../components/OnlyMasterUserAuthorized";
import { Helmet } from "react-helmet";

/**
 * ライセンス発行スタート画面
 * @returns JSX.Element
 */
const LicenseIssueStart = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>("");

    /**
     * 戻る押下時のイベント処理
     */
    const handleBack = () => {
        navigate(MYPAGE_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("LicenseIssueStart.meta.title")} />
            <ScrollToTop />
            {/* マスターユーザーのみ閲覧可 */}
            <OnlyMasterUserAuthorized />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("LicenseIssueStart.title")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                <div className="mt-5 flex flex-col items-center justify-normal gap-5">
                    {/* 利用者登録 */}
                    <Link to={LICENSE_ISSUE_USER_REGISTER_ROUTE}>
                        <button className="btn-green-white w-[250px]">
                            <span className="text-sm">{t("LicenseIssueStart.whoDoNotHaveDFAccount")}</span>
                            <br />
                            <span className="text-xl">{t("LicenseIssueStart.registerUser")}</span>
                        </button>
                    </Link>
                    {/* 利用者検索 */}
                    <Link to={LICENSE_ISSUE_USER_SEARCH_ROUTE}>
                        <button className={"btn-green-white w-[250px]"}>
                            <span className="text-sm">{t("LicenseIssueStart.whoHaveDFAccount")}</span>
                            <br />
                            <span className="text-xl">{t("LicenseIssueStart.searchUser")}</span>
                        </button>
                    </Link>
                    {/* 戻る */}
                    <button className="btn-secondary mt-5 w-1/3" onClick={handleBack}>
                        {t("CommonLabel.back")}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LicenseIssueStart;
