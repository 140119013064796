import { t } from "i18next";
import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { PROJECT_NAME_MAX_LENGTH } from "../../consts/constants";

export const NEW_PROJECT = "new";

/** プロジェクト選択 */
export const SelectProject = ({ selectedProject, handleProjectChange, projectList, enableNewProject }: any) => {
    const { t } = useTranslation();
    return (
        <div className="mx-auto flex max-w-sm items-center justify-center py-2">
            <select
                name="project"
                id="project"
                className="textbox-common"
                value={selectedProject}
                onChange={handleProjectChange}
            >
                <option hidden>{t("WebGIS.selectProject")}</option>
                {projectList.map((p: any, index: any) => (
                    <option key={index} value={p}>
                        {p}
                    </option>
                ))}
                {enableNewProject && (
                    <option key={projectList.length} value={NEW_PROJECT}>
                        {t("WebGIS.newProject")}
                    </option>
                )}
            </select>
        </div>
    );
};

/** 新規プロジェクトの作成 */
export const CreateNewProject = ({ setNewProjectName, handleRegisterProject }: any) => {
    const { t } = useTranslation();
    /**
     * 新規プロジェクト名の入力イベント
     * @param event
     */
    const handleProjectNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setNewProjectName(event.target.value);
    };
    return (
        <div className="mx-auto flex max-w-lg items-center justify-center py-2">
            <input
                type="text"
                name="new-project"
                id="new-project"
                placeholder={t("CommonLabel.projectName")}
                className="textbox-common"
                onChange={handleProjectNameChange}
                maxLength={PROJECT_NAME_MAX_LENGTH}
                required
            />
            <button onClick={handleRegisterProject} className="btn-primary ml-2 w-20">
                {t("CommonLabel.register")}
            </button>
        </div>
    );
};

/** フォルダ選択 */
export const SelectFolder = ({ rootFolder, handleChange, rootFolderList }: any) => {
    return (
        <form>
            <div className="mx-auto flex max-w-lg items-center justify-center py-2">
                <label className="px-2">
                    <input
                        type="radio"
                        value={rootFolderList[0]}
                        key="1"
                        checked={rootFolder === rootFolderList[0]}
                        onChange={handleChange}
                    />
                    {t("WebGIS.myFolder")}
                </label>

                <label className="px-2">
                    <input
                        type="radio"
                        value={rootFolderList[1]}
                        key="2"
                        checked={rootFolder === rootFolderList[1]}
                        onChange={handleChange}
                    />
                    {t("WebGIS.sharedFolder")}
                </label>
            </div>
        </form>
    );
};
